<template>
    <div class="welcome-title">
        <span>
            <slot></slot>
        </span>
        <div class="bottom"></div>
    </div>
</template>

<script>
export default {
  prop: ['value']
}
</script>

<style lang="scss" scoped>
.welcome-title {
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    span {
        z-index: 1;
    }
    .bottom {
        position: relative;
        bottom: 3px;
        width: calc(57%);
        height: 6px;
        background: #215796;
    }
}
</style>