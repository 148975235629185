<template>
  <div class="help-box">
    <!-- 帮助支持 -->
    <el-button type="primary" ref="btn1" @click="open = true">
      {{ $store.getters.language === "zh" ? "帮助支持" : "Help" }}
    </el-button>

    <el-dialog
      :title="
        this.language == 'zh' ? '帮助支持文档' : 'Help Support Documentation'
      "
      :visible.sync="open"
      width="70%"
      center
    >
      <!-- 文章正文 -->
      <div class="article">
        <ul>
          <li class="quick-start">
            <p class="title">{{ $t("help.h2Title1") }}</p>
            <div class="content">
              <p v-html="$t('help.articleText1')"></p>
            </div>
          </li>

          <li class="data-brower">
            <p class="title">{{ $t("help.h1Title1") }}</p>
            <div class="content">
              <div v-html="$t('help.articleText2')"></div>
              <!-- 图片 -->
              <div style="text-align: center">
                <img
                  v-if="this.language == 'zh'"
                  src="~@/assets/images/help-down.png"
                  alt=""
                />
                <img v-else src="~@/assets/images/help-down-en.png" alt="" />
              </div>

              <div v-html="$t('help.articleText6')"></div>
            </div>
          </li>
          <li class="fmcc">
            <p class="title">{{ $t("help.h1Title2") }}</p>
            <div class="content">
              <div v-html="$t('help.articleText3')"></div>
            </div>
          </li>
          <li class="connect">
            <p class="title">{{ $t("help.h1Title3") }}</p>
            <div class="content">
              <div v-html="$t('help.articleText4')"></div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer">
        <p v-html="$t('help.articleText5')"></p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "Help",
  data() {
    return {
      open: false,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>
<style lang="scss" scoped>
.help-box {
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  ::v-deep .el-button--primary {
    background-color: transparent;
    border-color: transparent;
    height: 30px;
    width: 80px;
    padding: unset;
  }

  ::v-deep .el-button--primary:hover,
  ::v-deep .el-button--primary:focus {
    background: #2e74b9;
    background-color: #2e74b9;
    border-color: #2e74b9;
  }

  ::v-deep .el-dialog:not(.is-fullscreen) {
    margin-top: 70px !important;
    height: 100%;
    max-height: calc(100vh - 90px);
  }
  // 去掉全屏滚动条
  ::v-deep .el-dialog__wrapper {
    overflow: unset;
    z-index: 100001 !important;
  }
  ::v-deep .el-dialog__title,
  ::v-deep .el-dialog__body,
  ::v-deep .el-dialog__footer {
    color: #1f5080;
  }
  //标题
  ::v-deep .el-dialog__header {
    text-align: left;
    padding: 40px 50px 10px;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 50px 25px;
    // height: calc(100vh - 145px);
    height: calc(100vh - 163px);
    overflow-y: auto;
    .content,
    .title {
      font-size: 12px;
      margin-bottom: 10px;
    }

    .data-brower,
    .fmcc,
    .connect {
      margin-top: 18px;
      word-break: break-word;
      .title {
        font-weight: 700;
      }
    }
    .data-brower {
      img {
        width: 260px;
        margin: 5px 0;
      }
    }
    .fmcc .content {
      p {
        line-height: 23px;
      }
    }
    .footer {
      float: right;
      font-size: 12px;
      margin-top: 30px;
    }
    //如需设置滚动条样式
    // &::-webkit-scrollbar {
    //   background-color: #ccc;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background-color: #0ae;
    // }
    &::-webkit-scrollbar-track {
      // background-color: #ccc;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) inset;
    }
  }
}
</style>