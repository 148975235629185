<template>
    <div class="summary-data-wrapper">
        <el-table
            ref="table"
            :data="data"
            size="mini"
            class="summary-data-table"
            width="100%"
            :height="height || 'calc(100%)'"
            :max-height="height || 'calc(100%)'"
            header-cell-class-name="header-cell"
            cell-class-name="cell-class"
            :cell-style="addTitleStyle"
            :header-cell-style="headerStyle"
            :span-method="arraySpanMethod"
            :row-class-name="addRowClass"
            :border="true"
        >
            <el-table-column
                v-for="col in columns"
                :key="col.prop"
                :prop="col.prop"
                :label="col.label"
                :fixed="col.fixed"
                :min-width="'' + col.width"
                :class-name="col.className"
            >
            </el-table-column>
            <span slot="empty"></span>
        </el-table>
    </div>
</template>

<script>
export default {
  components: {},
  props: {
    totalColumn: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Array,
      default: null
    },
    height: {
      type: String || Number,
      default: null
    }
  },
  data() {
    return {
      columns: [
        {
          prop: 'adverseSustainabilityImpact',
          label: 'Adverse sustainability indicator',
          width: 106
        },
        {
          prop: 'adverseImpactOnSustainabilityFactors',
          label: 'Adverse sustainability indicator',
          width: 250
        },
        {
          prop: 'metric',
          label: 'Metric',
          width: 400
        },
        {
          prop: 'yearlyValue',
          label: '',
          width: 90
        }
      ]
    }
  },
  created() {
    this.columns.forEach((col) => {
      if (col.prop === 'yearlyValue') {
        col.label = this.totalColumn.label
      }
    })
  },
  watch: {
    data(list) {
      this.$refs.table.doLayout()
      console.log('data:', list)
    },
    columns() {
      this.$refs.table.doLayout()
      console.log('columns:', this.columns)
    }
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (row.impactSpan > 0) {
          return [row.impactSpan, 1]
        } else if (row.impactSpan === 0) {
          return [0, 0]
        }
      }

      if (columnIndex === 1) {
        if (row.factorSpan > 0) {
          return [row.factorSpan, 1]
        } else if (row.factorSpan === 0) {
          return [0, 0]
        }
      }

      if (row.isTitle) {
        if (columnIndex === 2) {
          return [1, 4]
        } else {
          return [0, 0]
        }
      }
    },
    headerStyle({ row, column, rowIndex, columnIndex }) {
      const style = {}

      row[1].colSpan = 2 // 第二个表头占两格
      row[0].colSpan = 0 // 第一个表头占零格
      if (columnIndex === 0) {
        // 隐藏第一个表头
        style['display'] = 'none'
      }

      if (columnIndex === 3) {
        style['text-align'] = 'right'
      }

      return style
    },
    addTitleStyle({ row, column, rowIndex, columnIndex }) {
      if (row.isTitle) {
        return 'text-align: center;padding:10px 0;font-weight: bold;'
      }
      const style = {}
      if (columnIndex === 3) {
        style['text-align'] = 'right'
      }
      return style
    },
    addRowClass({ row, rowIndex }) {
      if (row.isTotal) {
        return 'sfdr-portfolio-table-total-row'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-data-wrapper {
    font-family: PingFang SC-Regular;
    height: calc(100% - 214px);
    flex: 1;

    .summary-data-table {
        height: calc(100%);

        ::v-deep .header-cell {
            color: #2f5584;
            background-color: #e0ecfa;
            font-size: 13px;
            font-weight: 400;
            color: #215796;
            background-color: #e1ebf8;
            font-family: PingFang SC-Semibold, PingFang SC;
            // font-weight: 600;
            // line-height: 20px;
        }

        ::v-deep .cell-class {
            div {
                word-break: break-word;
            }
        }
    }
}
</style>
<style lang="scss">
.summary-data-table {
    .sfdr-portfolio-table-total-row {
        text-align: center;
    }
}

.hide {
    display: none;
}
</style>
