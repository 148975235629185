<template>
  <div class="method_instruc_box">
    <!-- 方法学介绍 -->
    <el-button type="primary" ref="btn1" @click="open = true" class="instruc_btn">
      {{ $store.getters.language === "zh" ? "方法学介绍" : "Methodology Introduction" }}
    </el-button>

    <el-dialog
      :title="
        this.language == 'zh' ? 'FMCC服务模块介绍' : 'FMCC Module'
      "
      :visible.sync="open"
      width="70%"
      center
    >
      <!-- 文章正文 -->
      <div class="article">
        <ul>
            <!-- 快速开始 -->
          <li class="quick-start">
            <div class="content">
              <div v-html="$t('methodIntroduc.articleText1')"></div>
            </div>
            <div class="content">
              <div v-html="$t('methodIntroduc.articleText2')"></div>
            </div>
          </li>

          <!-- 方法学介绍，关联性分析，重大性分析Option1,判断准则 -->
          <li class="relevance">
            <p class="title_h1">{{ $t("methodIntroduc.h1Title1") }}</p>
            <p class="title_h2">{{ $t("methodIntroduc.h2Title1") }}</p>
            <div class="content">
              <div v-html="$t('methodIntroduc.articleText3')"></div>
            </div>

            <!-- Option1 -->
            <p class="title_h2">{{ $t("methodIntroduc.h2Title2") }}</p>
            <div class="content">
              <div v-html="$t('methodIntroduc.articleText4')"></div>
              <!-- 图片 -->
              <div>
                <img
                  class="img-formula"
                  v-if="this.language == 'zh'"
                  src="../../assets/tip/help-MI-formula-zh.png"
                  alt=""
                />
                <img v-else  class="img-formula" src="../../assets/tip/help-MI-formular-en.png" alt="" />
              </div>
            </div>

            <!-- 判断准则 -->
            <p class="title_h2">{{ $t("methodIntroduc.h2Title3") }}</p>
            <div class="content">
             <div v-html="$t('methodIntroduc.articleText5')"></div>
            </div>
          </li>
          
          <!-- Option2 -->
          <li class="option2">
            <p class="title_h2">{{ $t("methodIntroduc.h2Title4") }}</p>
            <div class="content">
              <div v-html="$t('methodIntroduc.articleText6')"></div>
            </div>
            <!-- 图片 -->
            <div>
              <img
                class="img-formula"
                v-if="this.language == 'zh'"
                src="../../assets/tip/help-MI-formula-zh.png"
                alt=""
              />
              <img v-else  class="img-formula" src="../../assets/tip/help-MI-formular-en.png" alt="" />
            </div>
            <div class="content">
              <div v-html="$t('methodIntroduc.articleText7')"></div>
            </div>
          </li>

           <!-- 判断准则 -->
          <li class="criterion">
            <p class="title_h2">{{ $t("methodIntroduc.h2Title3") }}</p>
            <div class="content">
             <div v-html="$t('methodIntroduc.articleText8')"></div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer">
        <p v-html="$t('help.articleText5')"></p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "MethodIntroduc",
  data() {
    return {
      open: false,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>
<style lang="scss" scoped>
.method_instruc_box {
  margin-bottom: 15px;
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  // 按钮
  .instruc_btn.el-button--medium{
    padding: 7px 20px;
    font-size: 13px;
  }

  // 按钮
  ::v-deep .el-button--primary:hover,
  ::v-deep .el-button--primary:focus {
    background: #2e74b9;
    background-color: #2e74b9;
    border-color: #2e74b9;
  }

  ::v-deep .el-dialog:not(.is-fullscreen) {
    margin-top: 70px !important;
    height: 100%;
    max-height: calc(100vh - 90px);
  }
  // 去掉全屏滚动条
  ::v-deep .el-dialog__wrapper {
    overflow: unset;
    z-index: 100001 !important;
  }
  ::v-deep .el-dialog__title,
  ::v-deep .el-dialog__body,
  ::v-deep .el-dialog__footer {
    color: #1f5080;
  }

  //标题
  ::v-deep .el-dialog__header {
    text-align: left;
    padding: 40px 50px 10px;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 50px 25px;
    height: calc(100vh - 165px);
    overflow-y: auto;
    .content {
      font-size: 12px;
      margin-bottom: 10px;
      word-break: break-word;
    }
    .title_h2, 
    .title_h1 {    
      font-weight: 700;
    }
    .title_h2{
      font-size: 12px;
    }
    // 图片
    .img-formula {
      height: 100px;
      margin: 5px 0;
    }

    .footer {
      float: right;
      font-size: 12px;
      margin-top: 30px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) inset;
    }
  }
}
</style>