<template>
  <div class="app-container">
    <div>
      <p class="friendli-text">{{ $t("friendly.text") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Friendly",

  data() {
    return {};
  },
  created() {
    // this.getList();
  },
  methods: {
  },
};
</script>
<style scoped lang='scss'>
.friendli-text {
  color: #1f5080;
  margin-top: 20px;
}
</style>