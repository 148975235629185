<template>
  <div class="tip_container">
    <table class="table" cellspacing="20">
      <!-- 标题 -->
      <tr class="tr">
        <td class="column1"></td>
        <!-- <td class="column2">
          <div class="item item_title">{{$t('baseTip.table1')}}</div>
        </td>
        <td class="column3">
          <div class="item item_title">{{$t('baseTip.table2')}}</div>
        </td> -->
        <td class="column4">
          <div class="item item_title">{{$t('baseTip.table3')}}</div>
        </td>
      </tr>

      
      <tr class="tr">
        <td class="column1">
          <div class="item item_title">{{$t('baseTip.table4')}}</div>
        </td>
        <!-- <td class="column2"></td>
        <td class="column3">
          <div class="item_img">
            <el-image style="width: 100%" :src="require('@/assets/tip/equation2.jpg')"></el-image>
          </div>
        </td> -->
        <td class="column4">
          <div class="item_img">
            <el-image style="width: 100%" :src="require('@/assets/tip/equation6.jpg')"></el-image>
          </div>
        </td>
      </tr>

      <tr class="tr">
        <td class="column1">
          <div class="item item_title">{{$t('baseTip.table5')}}</div>
        </td>
        <!-- <td class="column2">
          <div class="item_img">
            <el-image style="width: 100%" :src="require('@/assets/tip/equation1.png')"></el-image>
          </div>
        </td>
        <td class="column3">
          <div class="item_img">
            <el-image style="width: 100%" :src="require('@/assets/tip/equation3.jpg')"></el-image>
          </div>
        </td> -->
        <td class="column4">
          <div class="item_img">
            <el-image style="width: 100%" :src="require('@/assets/tip/equation6.jpg')"></el-image>
          </div>
        </td>
      </tr>

      <tr class="tr">
        <td class="column1">
          <div class="item item_title">{{$t('baseTip.table6')}}</div>
        </td>
        <!-- <td class="column2"></td>
        <td class="column3">
          <div class="item_img">
            <el-image style="width: 100%" :src="require('@/assets/tip/equation4.jpg')"></el-image>
          </div>
        </td> -->
        <td class="column4">
          <div class="item_img">
            <el-image style="width: 100%" :src="require('@/assets/tip/equation6.jpg')"></el-image>
          </div>
        </td>
      </tr>

      <tr class="tr">
        <td class="column1">
          <div class="item item_title">{{$t('baseTip.table7')}}</div>
        </td>
        <!-- <td class="column2"></td>
        <td class="column3">
          <div class="item_img">
            <el-image style="width: 100%" :src="require('@/assets/tip/equation5.jpg')"></el-image>
          </div>
        </td> -->
        <td class="column4">
          <div class="item_img">
            <el-image style="width: 100%" :src="require('@/assets/tip/equation6.jpg')"></el-image>
          </div>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
export default {
  name: 'baseTip',
  data() {
    const vm = window.vm; //多语言
    return {}
  }
}
</script>

<style scoped lang="scss">
.tip_container {
  ::v-deep .el-table .el-table__header-wrapper th,
  ::v-deep .el-table .el-table__fixed-header-wrapper th {
    font-family: 'PingFang SC';
    font-weight: 900;
    font-size: 16px;
    background-color: #DFEBF9;
    color: #1F5080;
  }

  .tip_title {
    width: 100%;
    height: 50px;
    font-family: 'PingFang SC';
    font-weight: 900;
    font-size: 16px;
    line-height: 10px;
    text-align: center;
    color: #1F5080;
  }

  .text {
    padding: 36px 20px 20px 33px;
    font-family: 'PingFang SC';
    font-size: 14px;
    line-height: 22px;
    color: #1F5080;
  }

  .table {
    width: 100%;
    .column1 {
      // width: 285px;
      width: 160px;
    }
    .column2 {
      width: 700px;
    }
    .column3 {
      width: 480px;
    }
    .column4 {
      // width: 290px;
      width: 150px;
    }

    .item {
      height: 40px;
      text-align: center;
      background: #DFEBF9;
      border-radius: 6px;
      font-family: 'PingFang SC';
      font-size: 14px;
      line-height: 40px;
      color: #1F5080;
      &.item_img {
        background-color: #ffffff;
      }
      &.item_title {
        font-weight: 900;
      }
    }
  }
}
</style>
