import { render, staticRenderFns } from "./indicatorDialogDB.vue?vue&type=template&id=5fdf161b"
import script from "./indicatorDialogDB.vue?vue&type=script&lang=js"
export * from "./indicatorDialogDB.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/data-platform-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fdf161b')) {
      api.createRecord('5fdf161b', component.options)
    } else {
      api.reload('5fdf161b', component.options)
    }
    module.hot.accept("./indicatorDialogDB.vue?vue&type=template&id=5fdf161b", function () {
      api.rerender('5fdf161b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/data-platform/carbon/components/indicatorDialogDB.vue"
export default component.exports