var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.render
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "sfdr",
        },
        [
          _c(
            "el-breadcrumb",
            { staticClass: "menu", attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", [
                _vm._v(_vm._s(_vm.$t("sfdr.nav.home"))),
              ]),
              _c("el-breadcrumb-item", { on: { click: _vm.reset } }, [
                _vm._v(_vm._s(_vm.$t("sfdr.nav.sfdr"))),
              ]),
              _vm.step === "upload" && !_vm.showWelcome
                ? _c("el-breadcrumb-item", [
                    _vm._v(_vm._s(_vm.$t("sfdr.nav.upload"))),
                  ])
                : _vm._e(),
              _vm.step === "mandatory"
                ? _c("el-breadcrumb-item", [
                    _vm._v(_vm._s(_vm.$t("sfdr.nav.mandatory"))),
                  ])
                : _vm._e(),
              _vm.step === "additional"
                ? _c("el-breadcrumb-item", [
                    _vm._v(_vm._s(_vm.$t("sfdr.nav.portfolioCalculation"))),
                  ])
                : _vm._e(),
              _vm.step === "additional"
                ? _c("el-breadcrumb-item", [
                    _vm._v(_vm._s(_vm.$t("sfdr.nav.additional"))),
                  ])
                : _vm._e(),
              _vm.step === "review"
                ? _c("el-breadcrumb-item", [
                    _vm._v(_vm._s(_vm.$t("sfdr.nav.review"))),
                  ])
                : _vm._e(),
              _vm.showMetricCrumb
                ? _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.metricName))])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-button",
            { staticClass: "help-button", on: { click: _vm.openNotification } },
            [_vm._v(" Instructions ")]
          ),
          _c("UploadPage", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step === "upload",
                expression: "step === 'upload'",
              },
            ],
            on: {
              nextStep: _vm.nextStep,
              updateOperationId: _vm.updateOperationId,
            },
          }),
          _vm.step === "mandatory"
            ? _c("MandatoryPaiPage", {
                attrs: {
                  impactList: _vm.mandatoryImpactList,
                  paiGroup: _vm.mandatoryPaiGroup,
                  operationId: _vm.operationId,
                  hasDrillDownPermission: _vm.hasDrillDownPermission,
                },
                on: {
                  updateColumns: _vm.handleColumns,
                  updateData: _vm.handleMainData,
                  clickDrillDown: _vm.clickDrillDownHandler,
                  nextStep: _vm.nextStep,
                  backStep: _vm.backStep,
                },
              })
            : _vm._e(),
          _vm.step === "additional"
            ? _c("AdditionalPaiPage", {
                attrs: {
                  operationId: _vm.operationId,
                  paiGroup: _vm.additionalPaiGroup,
                  impactList: _vm.additionalImpactList,
                  hasDrillDownPermission: _vm.hasDrillDownPermission,
                },
                on: {
                  updateData: _vm.handleAdditionalData,
                  clickDrillDown: _vm.clickDrillDownHandler,
                  nextStep: _vm.nextStep,
                  backStep: _vm.backStep,
                },
              })
            : _vm._e(),
          _vm.step === "review"
            ? _c("ReviewPage", {
                attrs: {
                  operationId: _vm.operationId,
                  mandatoryData: _vm.mandatoryData,
                  additionalData: _vm.additionalData,
                  columns: _vm.columns,
                },
                on: { reset: _vm.reset },
              })
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "top" } },
            [
              _c("Help", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showInstructions,
                    expression: "showInstructions",
                  },
                ],
                on: { showHelp: _vm.openNotification },
              }),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.showWelcome
                ? _c("Welcome", {
                    attrs: { notificationData: _vm.notificationData },
                    on: { showWelcome: _vm.enterHome },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: { title: "Reminder", visible: _vm.dialogVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  "If you leave the SFDR page, the data will not be retained. Are you sure you want to leave?"
                ),
              ]),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.next()
                        },
                      },
                    },
                    [_vm._v("Confirm")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }