var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("navbar"),
      _c(
        "div",
        {
          staticClass: "app-wrapper",
          class: _vm.classObj,
          style: { "--current-color": _vm.theme },
        },
        [_c("app-main")],
        1
      ),
      _c("AppFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }