<template>
  <div class="FMCCC_container">
    <!-- 方法学介绍 -->
    <MethodIntroduc />
    <!-- 输入框等头部区域-->
    <FMCCCtop @uploaded="uploaded" @computed="computed" @computedNote="computedNote"></FMCCCtop>
    <!--    初始数据表格-->
    <div class="init_table" v-show="!isComputed">
      <FileTable :data="fileData" :id="id"></FileTable>
    </div>
    <!--    计算后展示的tab-->
    <div class="res_box" v-show="isComputed">
      <Tabs ref="tab" :baseFormData="baseFormData" :note="note" :id="id" :isShowTab="isShowTab"></Tabs>
    </div>
  </div>
</template>

<script>
import FMCCCtop from "@/views/data-platform/carbon/components/FMCCCtop";
import FileTable from "./components/fileTable";
import Tabs from "./components/tabs";
import MethodIntroduc from '@/components/Help/methodIntroduc.vue'

import {
  getList,
  getCalculateResultList,
  getWatchList,
  calculate,
  portfolioCarbonFootprint,
  getCoverage,
} from "@/api/data-platform/FMCC";

export default {
  name: "FMCCClimateRisk",
  components: {
    FMCCCtop,
    FileTable,
    Tabs,
    MethodIntroduc,
  },
  data() {
    return {
      id: null,
      isComputed: false, //控制页面下部分展示
      fileData: [],
      baseFormData: null,
      note: null,
      isShowTab: true,//是否显示tab
    };
  },
  methods: {
    // 上传文件
    uploaded(id, baseFormData) {
      this.id = id;
      this.isComputed = false;
      // 上传后获取上传文件数据
      getList({
        id,
        pageNum: 1,
        ...baseFormData,
        lang: this.$store.getters.language,
      }).then((res) => {
        this.fileData = res;
      });
    },
    // 点击计算
    computed(data, note) {
      this.baseFormData = data;
      this.note = note;
      this.isComputed = true;
      this.$refs.tab.reload();
      this.$refs.tab.getCalculateResultList(data);
    },

    computedNote( note) {
      this.note = note;//语言切换后，更新note
    },
  },
};
</script>

<style lang="scss">

.FMCCC_container {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  padding: 17px 20px 0 20px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: auto;
  .init_table {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .cell {
    word-break: keep-all !important;
  }
  .res_box {
    margin-top: 20px;
  }

  .el-dialog:not(.is-fullscreen) {
    margin-top: 10vh !important;
    border-radius: 6px;
  }

  .el-dialog__wrapper {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
