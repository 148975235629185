var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        {
          staticClass: "set-lang",
          on: { command: _vm.handleSetLang },
          nativeOn: {
            click: function ($event) {
              return _vm.handleDropdown($event)
            },
          },
        },
        [
          _c("el-button", { ref: "btn1", attrs: { type: "primary" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$store.getters.language === "zh" ? "中文" : "English"
                ) +
                " "
            ),
            _c(
              "div",
              { staticClass: "el-arrow-dl" },
              [
                _c("svg-icon", {
                  attrs: { slot: "prefix", "icon-class": "arrow-purple" },
                  slot: "prefix",
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-dropdown-menu",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show",
                },
              ],
              ref: "downdiv",
              staticClass: "lang-dropdown",
              attrs: { slot: "dropdown", "append-to-body": false },
              slot: "dropdown",
            },
            [
              _c("el-dropdown-item", { attrs: { command: "en" } }, [
                _vm._v("English"),
              ]),
              _c("el-dropdown-item", { attrs: { command: "zh" } }, [
                _vm._v("中文"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { ref: "newSpace" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }