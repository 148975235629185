var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar-sub" }, [
    _c(
      "div",
      { staticClass: "top-navbar-btns" },
      [
        _c(
          "el-button",
          { attrs: { plain: "" }, on: { click: _vm.handleImport } },
          [_vm._v(_vm._s(_vm.$t("elbtn.import")) + " ")]
        ),
        _c(
          "el-button",
          { attrs: { plain: "" }, on: { click: _vm.addSelfSelect } },
          [_vm._v(_vm._s(_vm.$t("elbtn.saveSelf")))]
        ),
        _c("el-button", { attrs: { plain: "" }, on: { click: _vm.extract } }, [
          _vm._v(_vm._s(_vm.$t("elbtn.extract"))),
        ]),
        _c(
          "el-button",
          { attrs: { plain: "" }, on: { click: _vm.handleExport } },
          [_vm._v(_vm._s(_vm.$t("elbtn.export")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right-menu top-navbar-btns" },
      [
        _c(
          "el-button",
          { attrs: { plain: "" }, on: { click: _vm.instructions } },
          [_vm._v(_vm._s(_vm.$t("elbtn.instructions")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }