import request from '@/utils/request'

/**
 * 1.获取 FlopUtil 列表数据
 * @param type 公司类型  <0 所有公司  =0非异常公司 >0异常公司
 * @return Promise
 * */
export function getFlopUtilList(type) {
  return request({
    url: `/flop-util/list/${type}`,
    method: 'get',
  })
}

/**
 * 2.获取 flopUtil 公司的详细信息
 * @param id 上传公司id
 * @return Promise
 * */
export function getFlopUtilInfo(id) {
  return request({
    url: `/flop-util/info/${id}`,
    method: 'get'
  })
}

/**
 * 3.获取 异常指标的数据
 * @param id 当前公司id
 * @param number  异常指标编码
 * @return Promise
 * */
export function getFlopIndicator(params) {
  return request({
    url: '/flop-util/indicator',
    method: 'get',
    params: params
  })
}