var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "welcome" },
    [
      _c("img", {
        staticClass: "title-img",
        attrs: {
          src: require("@/assets/sfdr/images/welcome-title.png"),
          alt: "",
        },
      }),
      _c(
        "div",
        { staticClass: "background flex-column" },
        [
          _c("Title", { staticClass: "title" }, [_vm._v(" BACKGROUND ")]),
          _vm._m(0),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "steps flex-column" },
        [_c("Title", [_vm._v(" STEPS ")]), _vm._m(1)],
        1
      ),
      !_vm.isSystemDisabled
        ? _c(
            "div",
            { staticClass: "flex-center" },
            [
              _c(
                "el-button",
                {
                  staticClass: "enter-button",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("showWelcome")
                    },
                  },
                },
                [_vm._v("Enter")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Notification",
            visible: _vm.isShowNotification,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowNotification = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm.isShowNotification && _vm.notificationData.notice)
            ),
          ]),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.isShowNotification = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "background-bottom" }, [
      _c("div", { staticClass: "text" }, [
        _c("p", [
          _vm._v(
            " In March 2020, the European Union introduced the Sustainable Finance Disclosure Regulation (SFDR) to support the transition towards a low-carbon, sustainable, resource-efficient, and circular economy in alignment with the Sustainable Development Goals (SDGs). Subsequently, on April 6, 2022, the European Commission adopted the SFDR Delegated Regulation (Commission Delegated Regulation (EU) 2022/1288), which further enhances the transparency and credibility of sustainable finance. "
          ),
        ]),
        _c("br"),
        _c("p", [
          _vm._v(
            " The SFDR Delegated Regulation mandates a comprehensive reporting template to demonstrate the Principal Adverse Impacts (PAI) of investment decisions on sustainability factors. These disclosures emphasize a range of indicators that address both environmental and social adverse impacts. The regulation prescribes 18 mandatory indicators (with 14 applicable to investments in investee companies) and an additional 46 opt-in indicators (33 of which apply to investments in investee companies). The regulation shall apply from 1st January 2023. "
          ),
        ]),
      ]),
      _c("img", {
        staticClass: "history-img",
        attrs: {
          src: require("@/assets/sfdr/images/welcome-history.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "steps-bottom" }, [
      _c("div", { staticClass: "background-line" }),
      _c("div", { staticClass: "contents" }, [
        _c("div", { staticClass: "item flex-column-center" }, [
          _c("div", { staticClass: "icon-container" }, [
            _c("div", { staticClass: "icon" }, [
              _c("i", { staticClass: "el-icon-check" }),
            ]),
          ]),
          _c("div", { staticClass: "number" }, [_vm._v("01")]),
          _c("div", { staticClass: "text" }, [
            _vm._v("Upload Portfolio first"),
          ]),
        ]),
        _c("div", { staticClass: "item flex-column-center" }, [
          _c("div", { staticClass: "icon-container" }, [
            _c("div", { staticClass: "icon" }, [
              _c("i", { staticClass: "el-icon-check" }),
            ]),
          ]),
          _c("div", { staticClass: "number" }, [_vm._v("02")]),
          _c("div", { staticClass: "text" }, [
            _vm._v('Preview "Mandatory PAI"'),
          ]),
        ]),
        _c("div", { staticClass: "item flex-column-center" }, [
          _c("div", { staticClass: "icon-container" }, [
            _c("div", { staticClass: "icon" }, [
              _c("i", { staticClass: "el-icon-check" }),
            ]),
          ]),
          _c("div", { staticClass: "number" }, [_vm._v("03")]),
          _c("div", { staticClass: "text", staticStyle: { width: "204px" } }, [
            _vm._v(
              " Then you may go to the 'Additional PAI' section for choosing additional indicators "
            ),
          ]),
        ]),
        _c("div", { staticClass: "item flex-column-center" }, [
          _c("div", { staticClass: "icon-container" }, [
            _c("div", { staticClass: "icon" }, [
              _c("i", { staticClass: "el-icon-check" }),
            ]),
          ]),
          _c("div", { staticClass: "number" }, [_vm._v("04")]),
          _c("div", { staticClass: "text", staticStyle: { width: "255px" } }, [
            _vm._v(
              ' Click the calculated value, you may review the underlying data for both "Mandatory PAI" and "Additional PAI" '
            ),
          ]),
        ]),
        _c("div", { staticClass: "item flex-column-center" }, [
          _c("div", { staticClass: "icon-container" }, [
            _c("div", { staticClass: "icon" }, [
              _c("i", { staticClass: "el-icon-check" }),
            ]),
          ]),
          _c("div", { staticClass: "number" }, [_vm._v("05")]),
          _c("div", { staticClass: "text" }, [
            _vm._v(" Generate and download the PAI data "),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }