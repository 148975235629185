export default {
    //数据浏览器表单标题
    elTable: {
        index: '序号',
        secname: '证券名称',
        seccode: '证券代码',
    },

    treeTitle: {
        labelClimate: '气候相关风险数据',
        labelCarbonData: '碳排放数据',
        indicatorName: '待选指标',
        scopeName: '待选范围'
    },

    //自选清单
    selfSelect: {
        label: '自选股清单',
        dialogTitle: '添加自选清单',
        dialogTitle2: '编辑自选清单',
        formLabel: '自选清单名称',
        delete: '删除自选清单',
        rename: '重命名',
        nameTip1: '请输入自选清单的名称',
        nameTip2: '自选清单的名称长度为1~20',
    },
    // 指标
    indicator: {
        title: '指标信息填写',
        time: '交易日期',
        placeholder: '选择年份',
        tip: '请选择交易日期',
    },
    //指标说明表头
    instructions: {
        title1: '数据点分类',
        title2: '数据点名称',
        title3: '解释',
    },
    // 导出
    export: {
        title: '股票碳指标'
    },
    // 数据校验
    importCheck:{
        checkTitle:'数据校验结果'
    }
}

//指标说明
export const instructionsTableData = [
    {
        type: "碳排放数据",
        code: "span1",
        name: "范围1排放",
        desc: "公司直接温室气体排放量。数据由鼎力碳策提供",
    },
    {
        // type: "碳排放数据",
        code: "span1",
        name: "范围2排放",
        desc: `公司通过购买或者获取电力，蒸汽，加热或冷却服务带来的间接温室气体排放。范围2排放实际上是由使用这些服务的公司来承担。数据由鼎力碳策提供`,
    },
    {
        // type: "碳排放数据",
        code: "span1",
        name: "范围 1+2 排放",
        desc: "温室气体范围1+2数据，数据由鼎力碳策提供",
    },
    {
        // type: "碳排放数据",
        code: "span1",
        name: "数据类型",
        desc: "碳数据来源（企业自主披露或模型估算）",
    },
    {
        // type: "碳排放数据",
        code: "span1",
        name: "碳排放强度",
        desc: "碳排放强度，基于范围1+2排放数据和收入数据计算所得",
    },

    {
        type: "气候相关风险数据",
        code: "span2",
        name: "SASB行业",
        desc: "基于SASB方法的行业划分",
    },
    {
        // type: "气候相关风险数据",
        code: "span2",
        name: "物理风险分数",
        desc: "基于FMCC所推荐的SASB方法评估资产物理风的关联性和重大性",
    },
    {
        // type: "气候相关风险数据",
        code: "span2",
        name: "转型风险分数1",
        desc: "基于FMCC所推荐的SASB方法评估资产转型风的关联性和重大性",
    },
    {
        // type: "气候相关风险数据",
        code: "span2",
        name: "转型风险分数2",
        desc: "基于FMCC所推荐的SASB方法评估资产转型风险的关联性和重大性，这里考虑未来低碳转型发展情况下碳价成本对于企业运营的影响",
    },
    {
        // type: "气候相关风险数据",
        code: "span2",
        name: "影子碳成本",
        desc: "影子碳成本反映了低碳经济转型对于碳排放成本的影响",
    },
]
