export default {
  FMCCTop: {
    upload: 'Upload Portfolio',
    date: 'Date',
    unit: 'Currency Unit',
    name: 'Fund Name',
    calculate: 'Calculate',
    down: 'Download',
    more: 'Advanced Options',
    title1: 'Climate Risk Assessment - Relevance',
    content1: 'Relevance threshold for risks exposure',
    title2: 'Climate Risk Assessment - Materiality (Option 1)',
    content2: 'Materiality threshold for risks exposure',
    title3: 'Climate Risk Assessment - Materiality (Option 2)',
    table1: 'Portfolio exposure to the climate risks (%)',
    table2: 'Shadow cost / EBITDA over',
    table3: 'Threshold of physical risk',
    table4: 'Threshold of transition risk',
    useBtn: 'Apply',
    confirmBtn: 'Confirm',
    cancelBtn: 'Cancel',
    contactBtn:'Contact',
    uploadRes: 'Upload result',
    CalculateRes: 'Calculation'
  },
  baseTip: {
    title: 'Portfolio Carbon Footprint Assessment',
    tableTh1: '*Indicator',
    tableTh2: 'Unit',
    tableTh3: 'Value',
    indicator1: 'Total Emissions',
    indicator2: '***Carbon Footprint',
    indicator3: 'Carbon Intensity',
    indicator4: 'Weighted average carbon intensity (WACI)',
    indicator5: 'Weightings of investee with reported emission',
    indicator6: 'Weightings of investee with modelled emissions',
    unit1: 'tCO2e',
    unit2: 'tCO2e/Million CNY',
    text1: `* PCAF and TCFD are the most widely accepted mainstream portfolio carbon footprint methodologies globally. Between the two different methodologies, indicators are designated to represent the same meanings while differences remain in detailed calculation process. A key difference in the calculation process is the allocation factor which determine how to allocate the investee's total emission to the certain held value of the portfolio. More details are listed below. In general, EVIC is greater than Market Cap, both of which are denominators of the allocation factor. Hence, allocation factor calculated with Market Cap, as TCFD defined, will result in higher allocation and higher emission of the portfolio compared to calculating with EVIC per PCAF definition.During calculation and assessment process for the portfolio, GSG chooses allocation factor aligned with PCAF as recommended by FMCC.`,
    text2: '** Carbon Footprint is the indicator that FMCC require Large Fund Managers to disclose.',
    table1: 'FMCC definition',
    table2: 'TCFD definition',
    table3: 'PCAF allocation factor',
    table4: 'Total Carbon Emission',
    table5: 'Carbon Footprint',
    table6: 'Carbon Intensity',
    table7: 'WACI'
  },
  fileTable: {
    fundId: 'Fund ID',
    fundName: 'Fund Name',
    portfolioDate: 'Portfolio Date',
    isin: 'ISIN',
    ticker: 'Ticker',
    securityName: 'Security Name',
    totalNetAsset: 'Total Net Asset',
    assetType: 'Asset Type',
    currency: 'Currency',
    shares: 'Shares',
    marketValue: 'Market Value',
    nodataText: 'Your investment list is currently empty, please upload your file first.'
  },
  tabs: {
    tab1: 'Processed Data',
    tab2: 'Risk Assessment',
    tab3: 'Portfolio Carbon Footprint',
    tab4: 'Watchlist'
  },
  processed: {
    fundId: 'Fund ID',
    fundName: 'Fund Name',
    portfolioDate: 'Portfolio Date',
    isin: 'ISIN',
    ticker: 'Ticker',
    securityName: 'Security Name',
    totalNetAsset: 'Total Net Asset',
    assetType: 'Asset Type',
    currency: 'Currency',
    shares: 'Shares',
    marketValue: 'Market Value({0})',
    scope12Emissions: 'Scope 1+2 Emissions(tCO2e)',
    revenue: 'Revenue(Million {0})',
    intensity: 'Int(tCO2e/Million {0}）',
    weighting: 'Weighting',
    shadowCost: 'Shadow cost ({0})',
    ebitda: 'EBITDA ({0})',
    evic: 'EVIC ({0})',
    allocationFactor: 'Allocation factor',
    allocatedAbs: 'Allocated abs emissions',
    emissionDataType: 'Emission data type',
    sasbIndustry: 'SASB Industry',
    physicalRiskScore: 'PHYSICAL RISK SCORE',
    transitionRiskScoreA: 'TRANSITION RISK SCORE A',
    transitionRiskScoreB: 'TRANSITION RISK SCORE B (Shadow cost/ EBITDA)',
  },
  watchList: {
    fundId: 'Fund ID',
    fundName: 'Fund Name',
    portfolioDate: 'Portfolio Date',
    isin: 'ISIN',
    ticker: 'Ticker',
    securityName: 'Security Name',
    sasbIndustry: 'SASB Industry',
    text1: `Climate transition risks have been further assessed by taking carbon emission and shadow carbon price of the underlying assets into consideration. The ratio of shadow carbon price to EBITDA is calculated, serving as an alternative to quantify the impact on the portfolio result from the cost of carbon emissions. The threshold was set for Shadow carbon price/ EBITDA to select those underlying assets that are relatively sensitive to transition development to form the Watchlist for Ex-ante warning purpose and facilitating climate risks management.`
  },
  footprint: {
    title1: 'Portfolio Carbon Footprint Assessment',
    table1Th1: '*Indicator',
    table1Th2: 'Unit',
    table1Th3: 'Value',
    text1: `* Methdologies regarding carbon footprint calculation in PCAF Standards and TCFD are widely accepted globally. Between the two methodologies, indicators are designated to represent the same meanings while differences remain in detailed calculation process. A key difference in the calculation process is the allocation factor which determines how to allocate the investee's total emission to the certain held value of the portfolio. More details are listed below. In general, EVIC is greater than Market Cap, both of which are denominators of the allocation factor. Hence, allocation factor calculated with Market Cap, as TCFD defined, will result in higher allocation and higher emission of the portfolio compared to calculating with EVIC per PCAF definition.During calculation and assessment process for the portfolio, we choose the allocation factor aligned with PCAF as recommended by FMCC.`,
    text2: '**Carbon Footprint is a representation of carbon emissions that FMCC requires Large Fund Managers to disclose.',
    title2: 'Stats according to SASB Industry Classification',
    table2Th1: 'SASB Industry',
    table2Th2: 'Amount',
    table2Th3: 'Value',
    table2Th4: 'Weightings',
    table2Th5: 'Scope 1+2 Emission (tCO₂e)',
    table2Th6: 'Emission shares',
    title3: 'Comparison of weightings and emission shares according to SASB industry'
  },
  risk: {
    title1: 'Climate Risk Assessment - Relevance',
    title2: 'Relevance threshold for risks exposure',
    title3: 'Stats according to SASB Industry Classification',
    title4: 'Comparison of weightings and emission shares according to SASB industry',
    title5: 'Climate Risk Assessment - Materiality (Option 1)',
    title6: 'Climate Risk Assessment - Materiality (Option 2)',
    text1_1: `<p>The relevance of climate risk is assessed with reference to international methodologies as recommended by FMCC. Please see the statistical results as below.<p> </br>`,
    text1_2: `Exposure to physical and transition risks are assessed with the reference to SASB methodology and its assessment logic, in which climate risks have been demonstrated from different categories for public to understand potential risks exposure. By incorporating with weightings distribution across different industries with the reference of SASB industry classification approach, the overall risks quantification would be aggregated from the underlying assets to the portfolio level. On this basis, fund managers could set a threshold at portfolio level according to the risk appetite to decide whether the physical and transition risks are relevant. The risks would be assessed as relevant if the result is above the threshold, and irrelevant if not.`,
    table1_1: 'Threshold',
    table1_2: 'Risk Type',
    table1_3: `Portfolio exposure to the climate risks (%)`,
    table1_4: 'Relevance',
    table1_5: 'Note',
    table1_6: 'Physical risk',
    table1_7: `PHYSICAL RISK SCORE is 0, indicating that the physical risk of the underlying asset is not material with reference to the analysis logics from SASB methodology, and if PHYSICAL RISK SCORE is 1, indicating such underlying asset may expose itself to material physical risks. The portfolio exposure to the physical risk would be quantified by calculating the total weightings of the "material" ones, and a threshold (e.g., 5%) would be adpoted as the materiality assessment criteria.`,
    table1_8: 'Transition risk',
    table1_9: `TRANSITION RISK SCORE is 0, indicating that the transition risk of the underlying asset is not material with reference to the analysis logics from SASB methodology, and if TRANSITION RISK SCORE is 1, indicating such underlying asset may expose itself to material transition risks. The portfolio exposure to the transition risk would be quantified by calculating the total weightings of the "material" ones, and a threshold (e.g., 5%) would be adpoted as the materiality assessment criteria.`,
    table1_10: `* Portfolio exposure to the climate risks (%): Total weightings of the underlying assets included for calculation is lower than the threshold, and the result of relevance or materiality assessment might not be able to reflect the overall portfolio exposure in terms of completeness.`,
    table1_11: `Weighting of investee included in the assessment`,
    table2_1: `SASB Industry`,
    table2_2: `Amount`,
    table2_3: `Value`,
    table2_4: `Weightings`,
    table2_5: `Scope 1+2 Emission (tCO₂e)`,
    table2_6: `Emission shares`,
    text2: `<p>The materiality of climate risk is assessed with reference to international methodologies as recommended by FMCC. Please see the statistical results as below.</p></br>Exposure to physical and transition risks are assessed based on the SASB methodology, in which a climate-focused view of the SASB Materiality map has been broken down by climate risk type (physical and transition) as reference. By incorporating with weightings distribution across different industries with the reference of SASB industry classification approach, the overall risks quantification would be aggregated from the underlying assist to the portfolio level.On this basis, fund managers could set a threshold at portfolio level according to the risk appetite to decide whether the physical and transition risks are material. The risks would be assessed as material if the result is above the threshold, and not material if not.`,
    table3: 'Materiality threshold for risks exposure',
    Materiality: 'Materiality',
    // 已经废弃
    text3_1: `Exposure to physical and transition risks are assessed based on the SASB methodology, in which a climate-focused
        view of the SASB Materiality map has been broken down by climate risk type (physical and transition) as
        reference.
        By incorporating with weightings distribution across different industries with the reference of SASB industry
        classification approach, the overall risks quantification would be aggregated from the underlying assist to the
        portfolio level.`,
    text3_2: `On this basis, fund managers could set a threshold at portfolio level according to the risk appetite to decide
        whether the physical and transition risks are material. The risks would be assessed as material if the result is
        above the threshold, and not material if not.`,
    // 已经废弃
    text3_3: `Considering the fact that the majority of industries would be identified as material for transition risks based
        on the SASB methodology , leading a relatively high exposure of transition risks at the portfolio level in terms
        of
        the results outcome. Besides, carbon related data could serve as an effective indicator in reflecting the social development 
        with low-carbon transition, which could facilitate transition risks quantification from another dimension. 
        To that end, we also present Option 2 for further analysis of transitional risk with the consideration of carbon prices and EBITDA. 
        The ratio of shadow carbon price to EBITDA is calculated , serving as an alternative to quantify the transition risk. 
        The first threshold would be set (i.e 5%) to filter those assets that are not sensitve to transition development and 
        the second threshold would be set to decide if the sum weightings of those assets that are sensitive to carbon prices 
        would exceed the threshold (i.e 10%). The transition risk would be assesed as material if the sum weightings exceed the second threshold, 
        and not material if below`,
    table3_1: `Threshold type`,
    table3_2: `Portfolio exposure to the climate risks (%)`,
    table3_3: `Shadow cost/EBITDA over {0}%`,
    table3_4: `Threshold of physical risk`,
    table3_5: `Threshold of transition risk`,
    table3_node1: `PHYSICAL RISK SCORE is 0, indicating that the physical risk of the underlying asset is not material with reference to the analysis logics from SASB methodology, and if PHYSICAL RISK SCORE is 1, indicating such underlying asset may expose itself to material physical risks. The portfolio exposure to the physical risk would be quantified by calculating the total weightings of the "material" ones, and a threshold (e.g., 50%) would be adpoted as the materiality assessment criteria.`,
    table3_node2: `Take carbon prices into account and analyze the influence shadow prices could exert on EBITDA. The ratio of shadow carbon price to EBITDA is calculated as shown, serving as an alternative to quantify the transition risk. The first threshold would be set (i.e 5%) to filter those assets that are not sensitve to transition development and the second threshold would be set to decide if the sum weightings of those assets that are sensitive to carbon prices would exceed the threshold. The transition risk would be assesed as material if the sum of weightings exceed the second threshold, and not material if below.`,
    text4: `Both Climate Risk Assessment-Materiality Option 1&2 are presented for your reference. We suggest only selecting one of the options and keeping the assessment results accordingly for internal record keeping purpose. `
  }
}
