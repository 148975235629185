<template>
  <div class="production-page">
      <div class="page-title">
          <span style="font-size: 28px;">产品信息</span>
          <div>
              <el-button type="primary" size="small" @click="handleAdd">新增</el-button>
          </div>
      </div>
      <div>
    <el-form :model="queryParams" ref="queryForm"  :inline="true" v-show="showSearch" label-width="100px">
      <el-form-item label="接口名称" prop="apiName">
        <el-input
          v-model="queryParams.apiName"
          placeholder="请输入接口名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="生效日期">
        <el-date-picker
          v-model="daterangeEffectiveDate"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="queryParams.status" clearable>
          <el-option
            v-for="status in statusList"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleQuery">搜索</el-button>
<!--        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>-->
      </el-form-item>
    </el-form>
      </div>

      <div class="table-area common-table-style">
          <el-table :data="productionList" height="100%" stripe>
              <el-table-column label="产品ID" align="center" prop="productId" ></el-table-column>
              <el-table-column label="接口名称" align="center" prop="apiName"></el-table-column>
              <el-table-column label="生效日期" align="center" prop="effectiveDate" width="180">
                  <template slot-scope="scope">
                      <span>{{ parseTime(scope.row.effectiveDate, '{y}-{m}-{d}') }}</span>
                  </template>
              </el-table-column>
              <el-table-column label="状态" align="center" prop="status" >
                  <template #default="{ row }">
                      <span>{{ listValue2label(statusList, row.status) }}</span>
                  </template>
              </el-table-column>
              <el-table-column label="更新者" align="center" prop="updateBy" />
              <el-table-column label="更新时间" align="center" prop="updateTime" width="180">
                  <template slot-scope="scope">
                      <span>{{ parseTime(scope.row.updateTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
                  </template>
              </el-table-column>
              <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                  <template slot-scope="scope">
                      <el-button
                              size="mini"
                              type="text"
                              icon="el-icon-edit"
                              @click="handleUpdate(scope.row)"
                      >修改</el-button>
                      <el-button
                              size="mini"
                              type="text"
                              icon="el-icon-delete"
                              @click="handleDelete(scope.row)"
                      >删除</el-button>
                  </template>
              </el-table-column>
          </el-table>
      </div>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改api产品状态数据对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="接口名称" prop="apiName">
          <el-input v-model="form.apiName" placeholder="请输入接口名称" />
        </el-form-item>
        <el-form-item label="生效日期" prop="effectiveDate">
          <el-date-picker
            v-model="form.effectiveDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择生效日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status">
            <el-option
              v-for="status in statusList"
              :key="status.value"
              :label="status.label"
              :value="status.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listProduction, getProduction, delProduction, addProduction, updateProduction } from "@/api/data-platform/production";
import {listValue2label} from "../../../utils/utils";
import pagination from "@/components/Pagination";

export default {
  name: "Production",
    components: {
        pagination
    },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // api产品状态数据表格数据
      productionList: [],
      statusList: [{label: '准备中', value: 0}, {label: '已完成', value: 1}],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 更新人时间范围
      daterangeEffectiveDate: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        apiName: null,
        effectiveDate: null,
        status: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        apiName: [
          { required: true, message: "接口名称不能为空", trigger: "blur" }
        ],
        effectiveDate: [
          { required: true, message: "生效日期不能为空", trigger: "blur" }
        ],
        status: [
          { required: true, message: "状态不能为空", trigger: "change" }
        ],
      }
    };
  },
  computed: {
    userList () {
      return this.$store.state.common.userList;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    listValue2label,
    /** 查询api产品状态数据列表 */
    getList() {
      this.loading = true;
      this.queryParams.params = {};
      if (null != this.daterangeEffectiveDate && '' != this.daterangeEffectiveDate) {
        this.queryParams.beginEffectiveDate = this.daterangeEffectiveDate[0]+' 00:00:00';
        this.queryParams.endEffectiveDate = this.daterangeEffectiveDate[1]+' 23:59:59';
      }else{
          this.queryParams.beginEffectiveDate = null;
          this.queryParams.endEffectiveDate = null;
      }
      listProduction(this.queryParams).then(response => {
        this.productionList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        productId: null,
        apiName: null,
        effectiveDate: null,
        status: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeEffectiveDate = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.productId)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加api产品状态数据";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const productId = row.productId || this.ids
      getProduction(productId).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改api产品状态数据";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.productId != null) {
            updateProduction(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addProduction(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const productIds = row.productId || this.ids;
      this.$modal.confirm('是否确认删除api产品状态数据编号为"' + productIds + '"的数据项？').then(function() {
        return delProduction(productIds);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('dc/production/export', {
        ...this.queryParams
      }, `production_${new Date().getTime()}.xlsx`)
    }
  }
};
</script>
<style lang="scss" scoped>
.production-page {
  padding: 20px;
  background-color: #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  .page-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e7ed;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .table-area {
    flex: 1;
    height: 0;
  }

}
</style>
