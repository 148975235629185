export default {
    //帮助支持
    help: {
        h2Title1: 'Quickstart:',
        articleText1: `<p>Welcome to GSG Data Platform!</p> 
        <p>New user? Why not try our Data Browser as a start point and know more about our products and services?</p>
        <p>Our data-driven functionalities and customized professional services will meet your demands. It’s time to leverage our data capabilities to facilitate decision-making!</p>`,

        h1Title1: 'Data Browser',
        articleText2: `<p>For the first time you use the Data Browser, you need to first “Import” your portfolio or holdings list into the system, securities information needed in the uploading process is provided in the “Import” module.</p>`,
        articleText6: `<p>After uploading your list successfully, you can save your list as “Optional List”, so that you can use the list in the future.</br>
        Once the list is settled, you can choose the data points you want. By clicking on the data point, you can choose the date you need and add that data point. Those which data points you don’t need, you can remove the columns using the red button next to the data point in the data list.</br>
        Finally, you can “Extract” the selected data. The system will search the data basing on the security ID and the data points you selected before, and return the result in a few second. You can read the result on this web, or you can save them as an Excel file using “Export” function.</p>`

        , h1Title2: 'FMCC Climate Risk Analyze',
        articleText3: `<p>Before doing any calculation under this module, you need to first upload your portfolio. Some information is required for calculation, and you can download the standard template and fill the template as instructed.</br>
        Once you upload your portfolio, you can do the calculation now. </br>
        The calculation result include:</br>

        <strong>Processed Data:</strong>basing on the portfolio information you provided and the information in our database, we can provide you with carbon data and SASB industry analysis of the securities;</br>
        <strong>Risk Assessment:</strong>using the processed data, we can do the relevancy and materiality analyses of the portfolio. You can use “Advanced Options” to set your threshold in deciding relevancy and materiality of the portfolio, the underlying result will change too as you change your setting and apply in the Advanced Options”;</br>
        <strong>Portfolio Carbon Footprint:</strong>the threshold was set for Shadow carbon price/ EBITDA to select those underlying assets that are relatively sensitive to transition development to form the Watchlist for Ex-ante warning purpose and facilitating climate risks management.</br>
        Finally, using the “Download” function, you can save the shown result as an Excel file to your computer.</br>
        </p>`,

        h1Title3: 'Contact Support',
        articleText4: `<p>If you have any inquiry for our products and services for your first time using the GSG Data Platform, please do not hesitate to contact us. 
        Hotline: clientsupport@governance-solutions.com</p>`,

        articleText5: `<p>Dingli Sustainable Digital Technology (Shenzhen) Co., Ltd.</p>`,
    },
    // FMCC服务模块介绍
    methodIntroduc: {
        articleText1: `<p>On 21 August 2021, the Securities and Futures Commission of Hong Kong (SFC) issued the Circular to Licensed Corporations Management and Disclosure of Climate-Related Risks by Fund Managers and would amend the Fund Manager Code of Conduct (FMCC) to require Fund Managers managing collective investment schemes to consider climate-related risks in their investment and risk management processes and make appropriate disclosures. Implementation of the regulatory requirements will be phased in. For Large Fund Managers with over 8 billion HKD assets under management, a 12-month transition period would be given to comply with baseline requirements and the rules would come into effect after such transition period (ie, until 20 August 2022). High-quality carbon data is key in FMCC's climate-related risk regulatory requirements. Effective data supporting will be important to climate-related risk assessments results, which will make impact on risk and investment management. Data availability is vital for effective climate-related risks management and disclosures.</p></br>
         <p>This module helps Fund Managers meet the FMCC's regulatory requirements for climate-related risks and further facilitate investment decisions by quantifying the relevance and materiality of climate-related risks based on the portfolio information provided. Furthermore, the module could also support climate scenario analysis and carbon footprint calculation. We follow the formula mentioned by FMCC for calculation of portfolio carbon footprint, which could be used for both internal record keeping and disclosures. We will also provide the Watchlist for risk warning purpose to facilitate climate-related risks management.</p></br>
         <p>The underlying data of the module is provided by the GSG Carbon Discovery ™ Database. From a forward-looking perspective, with the collaboration of the National Center for Research and International Cooperation on Climate Change (NCSC) of the Ministry of Ecology and Environment, we jointly developed GSG Carbon Discovery ™, serving as a database and measurement tool for portfolio carbon footprint. We follow the methodology of global GHG Accounting & Reporting Standards of the Partnership for Carbon Accounting Financials (PCAF Standards), which is recommended by FMCC for carbon footprint calculation. We conduct in-depth analysis of the portfolio. To that end, we comprehensively assess the physical risk and transition risk of each underlying asset from multiple dimensions, such as its industry, the location of operation, financial indicators and etc.</p></br>
         <p>By fully taking the FMCC recommendations in assessing the risks into consideration, with the reference of the approach in Sustainability Accounting Standards Board (SASB) and the Task Force on Climate-Related Financial Disclosures (TCFD), we offer diverse services for complying FMCC regulatory requirements and empowering better climate risks management.</p>`,

        articleText2: `<ul style='list-style-type: disc;'>
         <li>Relevance Assessment of Climate related Risks</li>
         <li>Materiality Assessment of Climate related Risks</li>
         <li>Portfolio Carbon Footprint Calculation</li>
         <li>Watchlist for Ex-ante warning</li></ul>`,

        h1Title1: 'Methodology ',
        h2Title1: 'Relevance Assessment of Climate related Risks',
        articleText3: `<p>The relevance of climate risk is assessed with reference to international methodologies as recommended by FMCC. Exposure to physical and transition risks are assessed with the reference to SASB methodology and its assessment logic. By incorporating with weightings distribution across different industries with the reference of SASB industry classification approach, the overall risks quantification would be aggregated from the underlying assets to the portfolio level.</p></br>
         <p>On this basis, fund managers could set a threshold at portfolio level according to the risk appetite to decide whether the physical and transition risks are relevant. The risks would be assessed as relevant if the result is above the threshold, and irrelevant if not.</p>`,

        h2Title2: 'Materiality Assessment of Climate related Risks-Option1',
        articleText4: `<p>The materiality of climate risk is assessed with reference to international methodologies as recommended by FMCC. Exposure to physical and transition risks are assessed with the reference to SASB methodology and its assessment logic. By incorporating with weightings distribution across different industries with the reference of SASB industry classification approach, the overall risks quantification would be aggregated from the underlying assets to the portfolio level.</p></br>
         <p>On this basis, fund managers could set a threshold at portfolio level according to the risk appetite to decide whether the physical and transition risks are material. The risks would be assessed as material if the result is above the threshold, and not material if not.</p>`,

        h2Title3: 'Criterion:',
        articleText5: `<p>Indicator for Physical risks > 50%--> Physical risks are considered as material.</p>
         <p>Indicator for Transition risks > 50%--> Transition risks are considered as material.</p>`,

        h2Title4: 'Materiality Assessment of Transition Risks Combined with Shadow Carbon Pricing-Option2',
        articleText6: `<p>The materiality of climate risk is assessed with reference to international methodologies as recommended by FMCC. Exposure to physical risks are assessed with the reference to SASB methodology and its assessment logic. By incorporating with weightings distribution across different industries with the reference of SASB industry classification approach, the physical risks quantification would be aggregated from the underlying assets to the portfolio level.</p></br>
         <p>For transition risks, considering the data availability with high granularity in the markets, we are able to quantify transition risks from another perspective.</p></br>
         <p>Carbon related data could serve as an effective indicator in reflecting the social development with low-carbon transition, which could facilitate transition risks quantification from another dimension. To that end, we also present Option 2 for further analysis of transitional risk with the consideration of carbon prices and EBITDA. The ratio of shadow carbon price to EBITDA is calculated, serving as an alternative to quantify the transition risk. The first threshold would be set (i.e 5%) to filter those assets that are not sensitive to transition development and the second threshold would be set to decide if the sum weightings of those assets that are sensitive to carbon prices would exceed the threshold (i.e 10%).</p></br>
         <p>The transition risk would be assessed as material if the sum weightings exceed the second threshold, and not material if below.</p>`,
        articleText7: `<p>In the formula of , “the total market value of assets with more than 5% of EBITDA affected shadow carbon cost” means that the market value of assets that satisfy the condition of “Shadow carbon cost/EBITDA > 0.05” would be added up.</p>`,

        //h2Title5: '判断准则：',
        articleText8: `<p>Indicator for Physical risks > 50%--> Physical risks are considered as materia</p>
           <p>Indicator for Transition risks > 10%--> Transition risks are considered as material</p>`,
    }
}
