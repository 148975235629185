var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "method_instruc_box" },
    [
      _c(
        "el-button",
        {
          ref: "btn1",
          staticClass: "instruc_btn",
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              _vm.open = true
            },
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$store.getters.language === "zh"
                  ? "方法学介绍"
                  : "Methodology Introduction"
              ) +
              " "
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: this.language == "zh" ? "FMCC服务模块介绍" : "FMCC Module",
            visible: _vm.open,
            width: "70%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c("div", { staticClass: "article" }, [
            _c("ul", [
              _c("li", { staticClass: "quick-start" }, [
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("methodIntroduc.articleText1")),
                    },
                  }),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("methodIntroduc.articleText2")),
                    },
                  }),
                ]),
              ]),
              _c("li", { staticClass: "relevance" }, [
                _c("p", { staticClass: "title_h1" }, [
                  _vm._v(_vm._s(_vm.$t("methodIntroduc.h1Title1"))),
                ]),
                _c("p", { staticClass: "title_h2" }, [
                  _vm._v(_vm._s(_vm.$t("methodIntroduc.h2Title1"))),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("methodIntroduc.articleText3")),
                    },
                  }),
                ]),
                _c("p", { staticClass: "title_h2" }, [
                  _vm._v(_vm._s(_vm.$t("methodIntroduc.h2Title2"))),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("methodIntroduc.articleText4")),
                    },
                  }),
                  _c("div", [
                    this.language == "zh"
                      ? _c("img", {
                          staticClass: "img-formula",
                          attrs: {
                            src: require("../../assets/tip/help-MI-formula-zh.png"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          staticClass: "img-formula",
                          attrs: {
                            src: require("../../assets/tip/help-MI-formular-en.png"),
                            alt: "",
                          },
                        }),
                  ]),
                ]),
                _c("p", { staticClass: "title_h2" }, [
                  _vm._v(_vm._s(_vm.$t("methodIntroduc.h2Title3"))),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("methodIntroduc.articleText5")),
                    },
                  }),
                ]),
              ]),
              _c("li", { staticClass: "option2" }, [
                _c("p", { staticClass: "title_h2" }, [
                  _vm._v(_vm._s(_vm.$t("methodIntroduc.h2Title4"))),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("methodIntroduc.articleText6")),
                    },
                  }),
                ]),
                _c("div", [
                  this.language == "zh"
                    ? _c("img", {
                        staticClass: "img-formula",
                        attrs: {
                          src: require("../../assets/tip/help-MI-formula-zh.png"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        staticClass: "img-formula",
                        attrs: {
                          src: require("../../assets/tip/help-MI-formular-en.png"),
                          alt: "",
                        },
                      }),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("methodIntroduc.articleText7")),
                    },
                  }),
                ]),
              ]),
              _c("li", { staticClass: "criterion" }, [
                _c("p", { staticClass: "title_h2" }, [
                  _vm._v(_vm._s(_vm.$t("methodIntroduc.h2Title3"))),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("methodIntroduc.articleText8")),
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t("help.articleText5")) },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }