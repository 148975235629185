var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "summary-data-wrapper" },
    [
      _c(
        "el-table",
        {
          ref: "table",
          staticClass: "summary-data-table",
          attrs: {
            data: _vm.data,
            size: "mini",
            width: "100%",
            height: _vm.height || "calc(100%)",
            "max-height": _vm.height || "calc(100%)",
            "header-cell-class-name": "header-cell",
            "cell-class-name": "cell-class",
            "cell-style": _vm.addTitleStyle,
            "header-cell-style": _vm.headerStyle,
            "span-method": _vm.arraySpanMethod,
            "row-class-name": _vm.addRowClass,
            border: true,
          },
        },
        [
          _vm._l(_vm.columns, function (col) {
            return _c("el-table-column", {
              key: col.prop,
              attrs: {
                prop: col.prop,
                label: col.label,
                fixed: col.fixed,
                "min-width": "" + col.width,
                "class-name": col.className,
              },
            })
          }),
          _c("span", { attrs: { slot: "empty" }, slot: "empty" }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }