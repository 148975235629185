var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "worldMapBoard" }, [
    _c("div", { staticClass: "worldMap", attrs: { id: "worldMap" } }),
    _c(
      "div",
      { staticClass: "top-content" },
      [
        _c(
          "div",
          { staticClass: "logo" },
          [
            _c("img", {
              staticStyle: { height: "3.375rem" },
              attrs: { src: require("../../assets/images/HSBC-logo.png") },
              on: {
                click: function ($event) {
                  return _vm.openOfficialWebsite("HSBC")
                },
              },
            }),
            _c("el-divider", { attrs: { direction: "vertical" } }),
            _c("img", {
              staticStyle: { height: "1.8rem", "margin-right": "1.125rem" },
              attrs: { src: require("../../assets/images/SEE-logo.png") },
              on: {
                click: function ($event) {
                  return _vm.openOfficialWebsite("SEE")
                },
              },
            }),
            _c("el-divider", { attrs: { direction: "vertical" } }),
            _c("img", {
              staticStyle: { height: "1.2rem" },
              attrs: { src: require("../../assets/images/dl-logo1.png") },
              on: {
                click: function ($event) {
                  return _vm.openOfficialWebsite("DL")
                },
              },
            }),
          ],
          1
        ),
        _c("el-tooltip", { attrs: { content: _vm.userPhone } }, [
          _c("img", {
            staticClass: "user",
            attrs: { src: require("../../assets/images/user.png") },
          }),
        ]),
        _c("img", {
          staticStyle: { width: "100%", "max-height": "90px" },
          attrs: { src: require("../../assets/images/map-head.png") },
        }),
        _c("div", { staticClass: "title" }, [_vm._v("物理风险交互式地图集")]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "filter-area" },
      [
        _c(
          "el-select",
          {
            staticStyle: { width: "240px" },
            attrs: { placeholder: "请选择" },
            on: { change: _vm.statisticTypeChange },
            model: {
              value: _vm.statisticType,
              callback: function ($$v) {
                _vm.statisticType = $$v
              },
              expression: "statisticType",
            },
          },
          _vm._l(_vm.riskTypeList, function (item) {
            return _c("el-option", {
              key: item.key,
              attrs: { value: item.key, label: item.label },
            })
          }),
          1
        ),
        _c("el-input", {
          staticStyle: { width: "100px", "margin-left": "20px" },
          attrs: { placeholder: "请输入经度" },
          model: {
            value: _vm.longitude,
            callback: function ($$v) {
              _vm.longitude = $$v
            },
            expression: "longitude",
          },
        }),
        _c("el-input", {
          staticStyle: { width: "100px", margin: "0 10px" },
          attrs: { placeholder: "请输入纬度" },
          model: {
            value: _vm.latitude,
            callback: function ($$v) {
              _vm.latitude = $$v
            },
            expression: "latitude",
          },
        }),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.search } },
          [_vm._v("搜索")]
        ),
      ],
      1
    ),
    _vm._m(0),
    _vm._m(1),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        staticClass: "location-detail",
      },
      [
        _c("div", { staticClass: "detail-top" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/location_1.png") },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "font-size": "14px", "margin-right": "30px" } },
            [
              _vm._v(
                _vm._s(
                  _vm.currentLocation +
                    "（" +
                    (_vm.currentLngLat.lng.toFixed(1) || "-") +
                    ", " +
                    (_vm.currentLngLat.lat.toFixed(1) || "-") +
                    "）"
                )
              ),
            ]
          ),
          _c("i", {
            staticClass: "el-icon-close",
            on: {
              click: function ($event) {
                _vm.show = false
              },
            },
          }),
        ]),
        _c("div", { staticClass: "radar" }, [
          _c(
            "div",
            {
              staticClass: "risk-name",
              style: {
                "background-color": _vm.coordInfo.color,
                color: _vm.coordInfo.name === "低风险" ? "#323232" : "#fff",
              },
            },
            [_vm._v(_vm._s(_vm.coordInfo.name))]
          ),
          _c("div", { staticClass: "echart", attrs: { id: "echart" } }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "legend" }, [
      _c("div", { staticStyle: { "font-size": "14px", color: "#323233" } }, [
        _c("i", { staticClass: "el-icon-warning-outline" }),
        _vm._v(" 风险等级"),
      ]),
      _c("div", { staticClass: "linear-gradient" }),
      _c("div", { staticClass: "level" }, [
        _c("div", [_vm._v("高风险")]),
        _c("div", [_vm._v("中高风险")]),
        _c("div", [_vm._v("中风险")]),
        _c("div", [_vm._v("中低风险")]),
        _c("div", [_vm._v("低风险")]),
      ]),
      _c("div", { staticClass: "mark-area" }, [
        _c("div", {
          staticClass: "mark",
          staticStyle: { "background-color": "#969799" },
        }),
        _vm._v(" 无风险 "),
      ]),
      _c("div", { staticClass: "mark-area" }, [
        _c("div", { staticClass: "mark" }),
        _vm._v(" 无数据 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom-area" }, [
      _c("a", { attrs: { href: "/about-data", target: "_blank" } }, [
        _vm._v("关于数据"),
      ]),
      _c("a", { attrs: { href: "/about-project", target: "_blank" } }, [
        _vm._v("关于本项目"),
      ]),
      _c(
        "a",
        {
          attrs: {
            href: "https://governance-solutions.com/",
            target: "_blank",
          },
        },
        [_vm._v("关于我们")]
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://l7.antv.antgroup.com/tutorial/l7",
            target: "_blank",
          },
        },
        [_vm._v("@AntVL7")]
      ),
      _c("a", { attrs: { href: "https://lbs.amap.com/", target: "_blank" } }, [
        _vm._v("@高德地图"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }