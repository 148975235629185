import {
  requestFileTemplate,
  requestUnderLyingData
} from '@/api/data-platform/SFDR.js'

const mixin = {
  methods: {
    async downloadTemplate() {
      console.log(this)
      if (this.hasDownloadTemplate) {
        this.$notify({
          title: 'Note',
          type: 'info',
          duration: 3000,
          dangerouslyUseHTMLString: true,
          customClass: 'sfdr-notification-download',
          message: 'You have downloaded the <strong>Portfolio Template File</strong> already. Please check the download list of browser.'
        })
        return
      }

      const fileStream = await requestFileTemplate()
      const blob = new Blob([fileStream], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      const a = document.createElement('a')
      const href = window.URL.createObjectURL(blob) // 创建下载连接
      a.href = href
      a.download = "Portfolio Template"
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a) // 下载完移除元素
      window.URL.revokeObjectURL(href) // 释放掉blob对象
      this.hasDownloadTemplate = true
    },
    async downloadExcelReportData() {
      // if (this.hasDownloadTemplate) {
      //   this.$notify({
      //     title: 'Note',
      //     type: 'info',
      //     duration: 3000,
      //     dangerouslyUseHTMLString: true,
      //     customClass: 'sfdr-notification',
      //     message: 'You have downloaded the <strong>Portfolio Template File</strong> already. Please check the download list of browser.'
      //   })
      //   return
      // }

      const fileStream = await requestUnderLyingData(this.operationId)
      const blob = new Blob([fileStream], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      const a = document.createElement('a')
      const href = window.URL.createObjectURL(blob) // 创建下载连接
      a.href = href
      a.download = `SFDR Report Data`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a) // 下载完移除元素
      window.URL.revokeObjectURL(href) // 释放掉blob对象
      // this.hasDownloadTemplate = true
    }
  }
};
export default mixin;
