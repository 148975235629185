var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.flag === "FootPrint"
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "fileTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.rows,
                stripe: "",
                fit: "",
                "header-row-class-name": _vm.tableHead,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("span", [
                  _vm._v(
                    "Your investment list is currently empty, please upload your file first."
                  ),
                ]),
              ]),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "120px",
                  prop: "portfolioDate",
                  label: _vm.$t("processed.portfolioDate"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "100px",
                  prop: "fundId",
                  label: _vm.$t("processed.fundId"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "200px",
                  prop: "fundName",
                  label: _vm.$t("processed.fundName"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "isin",
                  "min-width": "130px",
                  label: _vm.$t("processed.isin"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "130px",
                  prop: "ticker",
                  label: _vm.$t("processed.ticker"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "200px",
                  prop: "securityName",
                  label: _vm.$t("processed.securityName"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "140px",
                  prop: "totalNetAsset",
                  label: _vm.$t("processed.totalNetAsset"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "120px",
                  prop: "assetType",
                  label: _vm.$t("processed.assetType"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "currency",
                  "min-width": "120px",
                  label: _vm.$t("processed.currency"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "shares",
                  label: _vm.$t("processed.shares"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "160px",
                  prop: "marketValue",
                  label: _vm.$t("processed.marketValue", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "240px",
                  prop: "scope12Emissions",
                  label: _vm.$t("processed.scope12Emissions"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "revenue",
                  "min-width": "180px",
                  label: _vm.$t("processed.revenue", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "intensity",
                  "min-width": "195px",
                  label: _vm.$t("processed.intensity", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "150px",
                  prop: "weighting",
                  label: _vm.$t("processed.weighting"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "160px",
                  prop: "shadowCost",
                  label: _vm.$t("processed.shadowCost", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "160px",
                  prop: "ebitda",
                  label: _vm.$t("processed.ebitda", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "150px",
                  prop: "evic",
                  label: _vm.$t("processed.evic", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "140px",
                  prop: "allocationFactor",
                  label: _vm.$t("processed.allocationFactor"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "190px",
                  prop: "allocatedAbs",
                  label: _vm.$t("processed.allocatedAbs"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "160px",
                  prop: "emissionDataType",
                  label: _vm.$t("processed.emissionDataType"),
                },
              }),
            ],
            2
          )
        : _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "fileTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.rows,
                stripe: "",
                fit: "",
                "header-row-class-name": _vm.tableHead,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("span", [
                  _vm._v(
                    "Your investment list is currently empty, please upload your file first."
                  ),
                ]),
              ]),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "120px",
                  prop: "portfolioDate",
                  label: _vm.$t("processed.portfolioDate"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "100px",
                  prop: "fundId",
                  label: _vm.$t("processed.fundId"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "200px",
                  prop: "fundName",
                  label: _vm.$t("processed.fundName"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "isin",
                  "min-width": "130px",
                  label: _vm.$t("processed.isin"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "130px",
                  prop: "ticker",
                  label: _vm.$t("processed.ticker"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "200px",
                  prop: "securityName",
                  label: _vm.$t("processed.securityName"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "140px",
                  prop: "totalNetAsset",
                  label: _vm.$t("processed.totalNetAsset"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "120px",
                  prop: "assetType",
                  label: _vm.$t("processed.assetType"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "currency",
                  "min-width": "120px",
                  label: _vm.$t("processed.currency"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "shares",
                  label: _vm.$t("processed.shares"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "160px",
                  prop: "marketValue",
                  label: _vm.$t("processed.marketValue", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "240px",
                  prop: "scope12Emissions",
                  label: _vm.$t("processed.scope12Emissions"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "revenue",
                  "min-width": "180px",
                  label: _vm.$t("processed.revenue", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "intensity",
                  "min-width": "195px",
                  label: _vm.$t("processed.intensity", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "150px",
                  prop: "weighting",
                  label: _vm.$t("processed.weighting"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "160px",
                  prop: "shadowCost",
                  label: _vm.$t("processed.shadowCost", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "160px",
                  prop: "ebitda",
                  label: _vm.$t("processed.ebitda", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "150px",
                  prop: "evic",
                  label: _vm.$t("processed.evic", [_vm.unit]),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "140px",
                  prop: "allocationFactor",
                  label: _vm.$t("processed.allocationFactor"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "190px",
                  prop: "allocatedAbs",
                  label: _vm.$t("processed.allocatedAbs"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "160px",
                  prop: "emissionDataType",
                  label: _vm.$t("processed.emissionDataType"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "180px",
                  prop: "sasbIndustry",
                  label: _vm.$t("processed.sasbIndustry"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "120px",
                  prop: "physicalRiskScore",
                  label: _vm.$t("processed.physicalRiskScore"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "120px",
                  prop: "transitionRiskScoreA",
                  label: _vm.$t("processed.transitionRiskScoreA"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "220px",
                  prop: "transitionRiskScoreB",
                  label: _vm.$t("processed.transitionRiskScoreB"),
                },
              }),
            ],
            2
          ),
      _c("div", { staticClass: "text" }, [
        _vm._v(" " + _vm._s(_vm.renderNote) + " "),
      ]),
      _c("el-pagination", {
        staticClass: "pagination",
        attrs: {
          background: "",
          layout: "prev, pager, next",
          total: _vm.renderData.total,
        },
        on: { "current-change": _vm.handleCurrentChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }