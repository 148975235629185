export default {
  // 语言
  language: {
    name: 'English'
  },
  // 登录页面
  login: {
    title: 'GSG Sustainability Data Platform',
    label: 'Log in',
    username: 'Username',
    password: 'Password',
    logIn: 'Log in',
    status: 'logging in...',
    verifyCode: 'Code',
    rememberPsw: 'Remember My Password',
    slogan: 'Sustainable Development Strategy',
    vision1: 'Influencing and accelerating the sustainable development of enterprises',
    vision2: 'Assisting financial institutions in making better investment analyzes',
    advant1: 'Innovation',
    advant2: 'Research+Data',
    advant3: 'Risk Assessment',
    advant4: 'Management Software',
    advant5: 'Evaluation Method',
    advant6: 'Optimization',
    advant7: 'Technoloy+Model',
    advant8: 'Risk Management',
    advant9: 'SaaS Platform',
    advant10: 'Indicator System',
    connect: 'Contact: clientsupport@governance-solutions.com',
    company: 'Copyright by © 2022 鼎力可持续数字科技（深圳）有限公司',
    rulesCode: 'Please enter verification code',
    rulesAccount: 'Please input user name',
    rulesPws: 'Please input password'
  },
  // 标签页面
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  // 设置页面
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo',
    accountMenu1: 'Account Center',
    accountMenu2: 'Sign Out',
  },
  // 按钮
  elbtn: {
    import: 'Import',
    export: 'Export',
    extract: 'Extract',
    instructions: 'Instructions',
    delete: 'Delete',
    add: 'Add',
    cancel: 'Cancel',
    apply: 'Apply',
    confirm: 'Confirm',
    download: 'Download Template',
    saveSelf: 'Save as Optional List',
    help: 'Help',
    deleteSelf: 'Delete',
    rename: 'Rename',
    operate: 'Operation',
    sysTips: 'Warning',
    tips: 'Tips',
  },
  // 下载模板提示
  downloadTemplate: {
    tips: `You have downloaded the <strong>Template File</strong> already. Please check the download list of browser.`
  }
}

