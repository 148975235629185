<template>
  <div class="app-container">
    <el-breadcrumb class="menu" separator="/">
      <el-breadcrumb-item
          :to="{ path: '/data-platform/carbon/dataBrowser' }"
          >Home</el-breadcrumb-item
      >
      <el-breadcrumb-item>Flop</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="outer-box">
      <!-- 左侧公司列表 -->
      <div class="left-box">
        <CompanyList
          :companyTitle="companyTitle"
          @handleCellClick="handleCellClick"
        />
      </div>
      <!-- 右侧内容展示区 -->
      <div class="content-right">
        <CompanyDetailInfo
          v-if="form && form.part1 && form.part1.id"
          :form="form"
          :companyAbnormal="companyAbnormal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CompanyList from './components/companyList.vue'
import CompanyDetailInfo from './components/companyDetailInfo.vue'
import { getFlopUtilInfo } from '@/api/data-platform/FLOP.js'

export default {
  components: {
    CompanyList,
    CompanyDetailInfo
  },
  data() {
    return {
      companyTitle: '公司列表',
      form: {},
      companyAbnormal: true,  // 当前公司是否为异常公司  异常：true  无异常：false
    }
  },
  methods: {
    // 公司行点击获取详情
    handleCellClick(param) {
      this.companyAbnormal = param.type === 0 ? false : true
      getFlopUtilInfo(param.id).then(res => {
        this.form = res.data
      }).catch(() => {
        this.form = {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu{
  margin-top: 20px;
}
.outer-box {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  .left-box {
    height: calc(100vh - 215px);
    max-height: calc(100vh - 220px);
    margin-right: 20px;
    margin-top: 30px;
  }
  .content-right{
    width: 200px;
    flex-grow: 1;
    background-color: #fff;
    padding: 0 15px;
  }
}
</style>