var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-category" },
    [
      _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleAdd },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-area common-table-style" },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "row-key": "templateId",
                "default-expand-all": true,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
                stripe: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "模板名称", prop: "templateName" },
              }),
              _c("el-table-column", {
                attrs: { label: "绑定API名称", prop: "apiName" },
              }),
              _c("el-table-column", {
                attrs: { label: "排序", prop: "orderNum" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parseTime(scope.row.createTime))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "180",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.templateName !== "未分组"
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    icon: "el-icon-edit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              !scope.row.apiName
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "text",
                                        icon: "el-icon-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleAdd(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("新增")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    icon: "el-icon-delete",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "680px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级分类", prop: "parentId" } },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.menuOptions,
                      normalizer: _vm.normalizer,
                      "show-count": true,
                      placeholder: "选择上级分类",
                    },
                    model: {
                      value: _vm.formData.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "parentId", $$v)
                      },
                      expression: "formData.parentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模板名称：", prop: "templateName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.templateName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "templateName", $$v)
                      },
                      expression: "formData.templateName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示顺序：", prop: "orderNum" } },
                [
                  _c("el-input-number", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "orderNum", $$v)
                      },
                      expression: "formData.orderNum",
                    },
                  }),
                ],
                1
              ),
              !_vm.formData.children || _vm.formData.children.length === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "绑定API：", prop: "apiName" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.formData.apiName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "apiName", $$v)
                            },
                            expression: "formData.apiName",
                          },
                        },
                        _vm._l(_vm.apiList, function (item) {
                          return _c("el-option", {
                            key: item.apiName,
                            attrs: { value: item.apiName, label: item.apiName },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }