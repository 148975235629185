export const simplifiedColumns = [{
  prop: "simpleMetric",
  label: "Adverse sustainability indicator",
  width: 300,
}];

export const fullColumns = [{
  prop: "adverseSustainabilityImpact",
  label: "Adverse sustainability indicator",
  width: 106,
  fixed: true,
}, {
  prop: "adverseImpactOnSustainabilityFactors",
  label: "Adverse sustainability indicator",
  width: 300,
  fixed: true,
}, {
  prop: "metric",
  label: "Metric",
  width: 300,
  fixed: true,
}];
