export default {
    tips: {
        searchTip: 'Please input query keywords',
        emptyTableTip: 'Your stock list is currently empty, please upload your file first.',
        importTip: 'Drag file here, or ',
        clickTip: 'click to upload',
        fitmitTip: 'Only xls and xlsx format files are allowed to be imported.',
        langTip: 'Set language successfully',
        warning: 'Warning',
        msg: 'Please add the stocks first!',
        msg2: 'Please add the stocks and indicators first!',
        msg3: 'Please complete the extraction first!',
        msg4: "is already exists, please don't add it again!",
        msg5: 'Are you sure you want to delete the list?',
        msg6: 'Are you sure to remove this security?',
        msg7: 'No More Data',
        msg8: 'Loading Data...',
        msg9: "You don't have permission to access this module!",
        masg10: 'Are you sure you want to log off and exit the system?',

        selfTip2: "Modified successfully",
        selfTip3: "Added successfully",
        selfTip4: "Deleted successfully",
        downloadTemplate:"Download Template",
        templateTitleFMCC:'FMCC+Upload+Template',
        templateTitleDataBrowser:'carbon-data-upload-template',
    },
    selfSelectTip: {
        stockTip: 'Added stock successfully',
        uploadTitle: 'Import',
        result: 'Import results',
        selfTip1: "Name already exists!",
        selfTip2: "Modified successfully",
        selfTip3: "Added successfully",
        selfTip4: "Deleted successfully",
    },
    rules: {
        msg1: 'Please input your email',
        msg2: 'E-mail format is incorrect',
        msg3: 'The file format is incorrect, please upload the image type, such as: JPG, PNG suffixed files.',
    },
    friendly:{
        text:'We’re sorry, we haven’t set content for this page yet...'
    }
}
