<template>
  <div class="common-table-style">
    <div class="sub-title">
      <span style="font-size: 24px;">API用户绑定</span>
      <div>
        <el-button icon="el-icon-plus" size="small" @click="handleAdd">添加</el-button>
        <el-button v-if="batchBind" size="small" @click="handleDetail">api详情</el-button>
      </div>
    </div>
    <el-table :height="batchBind ? 'calc(100% - 48px)' : '500px'" :data="tableData.filter((e) => e.deleted !== 1)" border>
      <el-table-column label="API名称" prop="apiName" show-overflow-tooltip min-width="200"></el-table-column>
      <el-table-column label="API版本" prop="apiVersion" width="100"></el-table-column>
      <!-- <el-table-column v-if="batchBind" label="模板名称" prop="apiTemplateName" min-width="150" show-overflow-tooltip></el-table-column> -->
      <el-table-column label="用户名称" prop="clientName" min-width="120">
        <template #default="{ row }">
          <span>{{ row.clientName }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="用户Id" prop="clientId"></el-table-column> -->
      <el-table-column label="服务截止时间" prop="expirationDate"></el-table-column>
      <el-table-column label="绑定状态" width="80">
        <template #default="{ row }">
          <span>{{ row.status == 1 ? '正常' : '停用'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row, $index }">
          <el-button type="text" size="small" @click="handleEdit(row, $index)">编辑</el-button>
          <el-button type="text" size="small" @click="handleDelete(row, $index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible="visible" title="绑定用户" width="600px" @close="visible = false" :append-to-body="true">
      <el-form :model="formData" :rules="formRules" ref="form" label-width="120px" style="padding: 0 20px">
        <el-form-item label="API名称：" prop="apiName">
          <el-input v-model="formData.apiName" disabled></el-input>
        </el-form-item>
        <el-form-item label="API版本：" prop="apiVersion">
          <el-input v-model="formData.apiVersion" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="batchBind" label="模板名称：" prop="apiTemplateName">
          <el-input v-model="formData.apiTemplateName" disabled></el-input>
        </el-form-item>
        <el-form-item label="用户：" prop="clientIdList">
          <el-select v-model="formData.clientIdList" :disabled="currentIndex || currentIndex === 0 ? true : false" filterable multiple @change="userChange" style="width: 100%">
            <el-option v-for="item in userList.filter((e) => e.status === '0')" :key="item.userId" :disabled="tableData.map((e) => e.clientId).indexOf(item.userId) > -1" :label="`${item.nickName}(${item.userName})`" :value="item.userId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务截止时间：" prop="expirationDate">
          <el-date-picker v-model="formData.expirationDate" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
        </el-form-item>
        <el-form-item label="服务状态：" prop="status">
          <el-select v-model="formData.status" style="width: 100%">
            <el-option label="正常" :value="1"></el-option>
            <el-option label="停用" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="visible = false">取消</el-button>
        <el-button type="primary" size="small" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
    <apiEditDrawer
      :visible="apiEditDrawerVisible"
      :apiId="apiId"
      :batchBind="batchBind"
      @close="apiEditDrawerVisible = false"
    />
  </div>
</template>
<script>
import { listUser } from "@/api/system/user";
import { updateApiUserBinding, deleteApiUserBinding } from "@/api/data-platform/admin-center.js";
export default {
  name: 'bindingUser',
  components: {
    apiEditDrawer: () => import('./apiEditDrawer.vue')
  },
  props: {
    apiId: {
      type: [String, Number],
      default: () => ''
    },
    apiName: {
      type: String,
      default: () => ''
    },
    apiVersion: {
      type: [String, Number],
      default: () => null
    },
    apiTemplateName: {
      type: String,
      default: () => ''
    },
    userApiBindingList: {
      type: Array,
      default: () => []
    },
    batchBind: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      tableData: [],
      visible: false,
      userList: [],
      formData: {
        apiName: '',
        apiTemplateName: '',
        apiVersion: '',
        clientName: '',
        clientIdList: [],
        expirationDate: '',
        status: 1
      },
      formRules: {
        clientIdList: [{ required: true, message: '用户不能为空', trigger: 'change' }],
        expirationDate: [{ required: true, message: '截止时间不能为空', trigger: 'change' }],
      },
      currentIndex: null,
      apiEditDrawerVisible: false
    }
  },
  watch: {
    userApiBindingList: {
      handler (val) {
        this.tableData = JSON.parse(JSON.stringify(val));
        if (this.apiName) {
          this.tableData.forEach((e) => {
            e.apiName = this.apiName;
            e.apiVersion = this.apiVersion;
            e.status = Number(e.status || 0)
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.getUserList();
  },
  methods: {
    // 打开api详情
    handleDetail () {
      if (!this.apiId) {
        this.$modal.msgWarning('请先选择左侧api');
        return;
      }
      this.apiEditDrawerVisible = true;
    },
    getUserList () {
      const params = {
        pageNum: 1,
        pageSize: 1000
      }
      listUser(params).then((res) => {
        this.userList = res.rows;
        this.$emit('getUserList', this.userList);
      })
    },
    // 切换用户
    userChange () {
      // const data = this.userList.filter((e) => e.userId === this.formData.clientId);
      // if (data.length) {
      //   this.formData.clientName = `${data[0].nickName}(${data[0].userName})`;
      // }
    },
    // 添加
    handleAdd () {
      if (!this.apiName || !this.apiVersion) {
        this.$modal.msgWarning(this.batchBind ? '请先选择左侧api' : '请先输入api名称及api版本');
        return;
      }
      this.formData = {
        apiId: this.apiId,
        apiName: this.apiName,
        apiVersion: this.apiVersion,
        apiTemplateName: this.apiTemplateName,
        clientName: '',
        clientIdList: [],
        expirationDate: '',
        status: 1
      }
      this.currentIndex = null;
      this.visible = true;
    },
    // 编辑
    handleEdit (row, index) {
      if (!this.apiName || !this.apiVersion) {
        this.$modal.msgWarning('请先输入api名称及api版本');
        return;
      }
      this.formData = {
        bindingId: row.bindingId,
        apiId: row.apiId,
        apiName: row.apiName,
        apiTemplateName: row.apiTemplateName,
        apiVersion: row.apiVersion,
        clientName: row.clientName,
        clientIdList: [row.clientId],
        expirationDate: row.expirationDate,
        status: row.status
      }
      this.currentIndex = index;
      this.visible = true;
    },
    // 删除
    handleDelete (row, index) {
      if (this.batchBind) {
        this.$modal.confirm('请确认是否删除此用户？', '确定').then(() => {
          deleteApiUserBinding(row.bindingId).then((res) => {
            if (res.code === 200) {
              this.$modal.msgSuccess('删除成功');
              this.$emit('saveSuccess');
            }
          })
        }).catch(function() {
        });
      } else {
        if (row.bindingId) {
          row.deleted = 1;
        } else {
          this.tableData.splice(index, 1);
        }
      }
      
    },
    // 保存
    handleSave () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.batchBind) {
            const params = {
              ...this.formData,
              clientIdList: this.formData.clientIdList.join(',')
            }
            updateApiUserBinding(params).then((res) => {
              if (res.code === 200) {
                this.$modal.msgSuccess('操作成功');
                this.$emit('saveSuccess');
                this.visible = false;
              }
            })
          } else {
            if (this.currentIndex || this.currentIndex === 0) {
              this.tableData.splice(this.currentIndex, 1, {...this.formData, clientId: this.formData.clientIdList.join(','), clientIdList: undefined});
            } else {
              for (const item of this.formData.clientIdList) {
                const data = this.userList.filter((e) => e.userId === item);
                let clientName = '';
                if (data.length) {
                  clientName = `${data[0].nickName}(${data[0].userName})`;
                }
                this.tableData.unshift({
                  ...this.formData,
                  clientId: item,
                  clientName: clientName,
                  clientIdList: undefined
                })
              }
              
            }
            this.visible = false;
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.sub-title {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
</style>
