// 模拟接口
// 1.引入Mock
import Mock from 'mockjs'
// import qs from 'qs'

// 2.配置mock
Mock.setup({
    timeout: '500-1000' // 3.延时调用接口
})

// //1.获取所有股票
// Mock.mock(/\/carbon-data\/stock\/all/, 'get', config => {
//     // const queryString = config.url.split('?')[1] //请求参数
//     let arr1 = [1, 2, 3, 4, 5, null, 6]
//     const items = []
//     // 模拟返回值
//     for (let i = 0; i < 300; i++) {
//         items.push(Mock.mock({
//             secname: '@ctitle(1,10)',//股票名称
//             'seccode': '@increment(100000)',//股票代码
//             market: '@ctitle(1,10)',//market
//             'isin|1-100': 1,// isin 
//             secnameEn: '@string("upper", 3, 5)',//股票英文简称
//             secnameEnFull: '@title(2,10)',//股票英文全称

//         }))
//     }
//     return {
//         code: 200,
//         msg: '获取数据成功',
//         data: items
//     }
// })

// // 3.导入
// //2.数据浏览器结果区列表
// Mock.mock(/\/carbon-data\/stock\/list/, 'post', config => {
//     // console.log(config)
//     const items = []
//     // 模拟返回值
//     for (let i = 0; i < 900; i++) {
//         items.push(Mock.mock({
//             secname: '@ctitle(1,10)',//股票名称
//             secnameEn: "@string('upper',5)",
//             'seccode|1-100': 1,//股票代码
//             market: '@ctitle(1,10)',//market
//             'isin|1-100': 1,// isin 
//         }))
//     }
//     return {
//         code: 200,
//         msg: '导入成功',
//         data: items
//     }
// })

// // 3.根据指数查询股票
// Mock.mock(/\/carbon-data\/stockIndex\/list/, 'get', config => {
//     const queryString = config.url.split('?')[1] //请求参数
//     const queryObject = qs.parse(queryString)

//     const items = []
//     for (let i = 0; i < +queryObject.pageSize; i++) {
//         items.push(Mock.mock({
//             secname: '@ctitle(1,10)',//股票名称
//             secnameEn: '@ctitle(1,10)',//英文名字
//             'seccode': '@increment(100000)',//股票代码
//             market: '@ctitle(1,10)',//market
//             'isin|1-100': 1,// isin 
//         }))
//     }
//     return {
//         code: 200,
//         msg: '获取数据成功',
//         data: {
//             total: 60,
//             pageSize: +queryObject.pageSize,
//             page: +queryObject.pageNum,
//             rows: items //数组
//         }
//     }
// })

// // 4.提取
// Mock.mock(/\/carbon-data\/stockCarbonIndicator\/list/, 'get', config => {
//     // console.log(config)
//     const queryString = config.url.split('?')[1] //请求参数
//     const queryObject = qs.parse(queryString)
//     let arr1 = ['t100', 't101', 't102', 't103', 't104', 't201', 't202', 't203', 't204', 't205', 't206']
//     // 固定列表
//     const items = []
//     for (let i = 0; i < +queryObject.pageSize; i++) {
//         items.push(Mock.mock({
//             secname: '@ctitle(1,10)',//股票名称
//             secnameEn: "@string('upper',5)",//股票英文名称
//             'seccode|1-100': 1,//股票代码
//             market: '@ctitle(1,10)',//market
//             'isin|1-100': 1,// isin 
//             "indicator|4": [
//                 {
//                     'code|1': arr1,//指数字典值
//                     value: '@ctitle(1,10)',//指数字典value
//                     whichYear: '@date("yyyy")',//指标年
//                 }
//             ]
//         }))
//     }

//     // 动态列表
//     // const items2 = []
//     // for (let i = 0; i < 20; i++) {
//     //     items2.push(Mock.mock({
//     //         'code|1-100': 1,//指数字典值
//     //         value: '@ctitle(1,10)',//指数字典value
//     //         whichYear: '@date("yyyy-MM-dd")',//指标年
//     //     }))
//     // }
//     return {
//         code: 200,
//         msg: '导入成功',
//         data: {
//             total: 60,
//             pageSize: +queryObject.pageSize,
//             page: +queryObject.pageNum,
//             rows: items //数组
//         }
//     }
// })

// //5.删除自选订单-股票
// Mock.mock(/\/carbon-data\/selfSelectionDetail\/delete/, 'post', (data) => {
//     let $body = JSON.parse(data.body)
//     if ($body) {
//         return { code: 200, msg: '删除成功' }
//     } else {
//         return { code: 400, msg: '删除失败，缺少参数！' }
//     }
// })

// //6.删除自选订单
// Mock.mock(/\/carbon-data\/selfSelection\/delete/, 'post', (data) => {
//     let $body = JSON.parse(data.body)
//     if ($body) {
//         return { code: 200, msg: '删除成功' }
//     } else {
//         return { code: 400, msg: '删除失败，缺少参数！' }
//     }
// })

// // 7.获取所有自选清单
// Mock.mock(/\/carbon-data\/selfSelection\/all/, 'get', config => {
//     // const queryString = config.url.split('?')[1] //请求参数
//     const items = []
//     for (let i = 0; i < 5; i++) {
//         items.push(Mock.mock({
//             name: '@ctitle(1,10)',//自选清单名字
//             'id|1-100': 1//自选清单id
//         }))
//     }
//     return {
//         code: 200,
//         msg: '获取数据成功',
//         data: items
//     }
// })

// //8.根据指数查询股票
// Mock.mock(/\/carbon-data\/selfSelectionDetail\/list/, 'get', config => {
//     const queryString = config.url.split('?')[1] //请求参数
//     const queryObject = qs.parse(queryString)
//     const items = []
//     for (let i = 0; i < +queryObject.pageSize; i++) {
//         items.push(Mock.mock({
//             secname: '@ctitle(1,10)',//股票名称
//             secnameEn: '@ctitle(1,10)',//英文名字
//             'seccode': '@increment(100000)',//股票代码
//             market: '@ctitle(1,10)',//market
//             'isin|1-100': 1,// isin 
//             'id|1-100': 1//自选清单id
//         }))
//     }
//     return {
//         code: 200,
//         msg: '获取数据成功',
//         data: {
//             total: 60,
//             pageSize: +queryObject.pageSize,
//             page: +queryObject.pageNum,
//             rows: items //数组
//         }
//     }
// })

// //9.保存为自选清单
// Mock.mock(/\/carbon-data\/selfSelectionDetail\/add/, 'post', (data) => {
//     let $body = JSON.parse(data.body)
//     if ($body) {
//         return { code: 200, msg: '删除成功' }
//     } else {
//         return { code: 400, msg: '删除失败，缺少参数！' }
//     }
// })

// //10.修改自选订单
// Mock.mock(/\/carbon-data\/selfSelection\/modify/, 'post', (data) => {
//     let $body = JSON.parse(data.body)
//     if ($body) {
//         return { code: 200, msg: '修改成功' }
//     } else {
//         return { code: 400, msg: '删除失败，缺少参数！' }
//     }
// })