var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-data-wrapper" },
    [
      _c(
        "el-table",
        {
          ref: "table",
          staticClass: "portfolio-data-table",
          attrs: {
            data: _vm.data,
            size: "mini",
            "header-cell-style": _vm.getHeaderCellStyle,
            height: "calc(100%)",
            width: "100%",
            border: true,
            "row-class-name": _vm.addRowClass,
            "cell-style": _vm.getCellStyle,
            lazy: true,
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "orderNumber", label: "Number", width: "90" },
          }),
          _c("el-table-column", { attrs: { prop: "isin", label: "ISIN" } }),
          _c("el-table-column", { attrs: { prop: "ticker", label: "Ticker" } }),
          _c("el-table-column", {
            attrs: {
              prop: "securityName",
              label: "Security Name",
              "min-width": "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.securityName) + " "),
                    row.isCashItem
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content:
                                "The weighting of your uploaded portfolio is less than 100%. As per our methology, we will make up the shortfall with CASH.",
                              placement: "bottom",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question" })]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "shares", label: "Shares", "min-width": "100" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cvForOrigin",
              label: "Current Value - Uploaded",
              "min-width": "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cvForLocalCurrency",
              label:
                "Current Value - SFDR (" +
                (_vm.currency ? _vm.currency : "Local Currency") +
                ")",
              "min-width": "200",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (ref) {
                  var column = ref.column
                  return [
                    _vm._v(" " + _vm._s(column.label) + " "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            "This Current Value is calculated as per the methodology from the RTS. The ESAs are aware that there is a potential misalignment between the timing of the (at least) quarterly calculations of the adverse impacts under Section II and Annex I of the Delegated Regulation. The quarterly impacts should be based on the current value of the investment derived from the valuation the individual investment (e.g. share) price valued at fiscal year-end multiplied by the quantity of investments (e.g. shares) held at the end of each quarter. In such manner the composition of the investments at the end of each quarter is taken into account, but the valuation reflects the fiscal-year end point in time.",
                          placement: "bottom",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-question" })]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cvForEur",
              label: "Current Value - SFDR (EUR)",
              "min-width": "150",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (ref) {
                  var column = ref.column
                  return [
                    _vm._v(" " + _vm._s(column.label) + " "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            "The Current Value - SFDR(EUR) is converted from the currency uploaded to EUR.",
                          placement: "bottom",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-question" })]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "weightingForOrigin",
              label: "Weighting - Uploaded",
              "min-width": "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "weightingForEur",
              label: "Weighting - SFDR",
              "min-width": "103",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (ref) {
                  var column = ref.column
                  return [
                    _vm._v(" " + _vm._s(column.label) + " "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            "The weighting - SFDR is calculated as Current Value - SFDR (EUR) of the security / Total Current Value - SFDR (EUR) of the portfolio.",
                          placement: "bottom",
                          transition: null,
                        },
                      },
                      [_c("i", { staticClass: "el-icon-question" })]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "template",
            { slot: "empty" },
            [_c("EmptyBlock", { on: { showUploadDialog: _vm.showDialog } })],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }