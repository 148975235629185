var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.optionCardShow
    ? _c(
        "div",
        {
          style: {
            "z-index": 9999,
            position: "fixed",
            left: _vm.optionCardX + 25 + "px",
            top: _vm.optionCardY + "px",
            width: "120px",
            background: "white",
            "box-shadow": "0 3px 4px rgba(0,0,0,.12),0 0 6px rgba(0,0,0,.04)",
          },
          attrs: { id: "option-button-group" },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "option-card-button",
              attrs: { type: "info", plain: "" },
              on: { click: _vm.remove },
            },
            [_vm._v(_vm._s(_vm.$t("elbtn.deleteSelf")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "option-card-button",
              attrs: { type: "info", plain: "" },
              on: { click: _vm.rename },
            },
            [_vm._v(_vm._s(_vm.$t("elbtn.rename")))]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }