<template>
  <div>
    <el-drawer
      :visible="visible"
      custom-class="api-edit-drawer"
      size="90%"
      :title="`${apiId ? '修改' : '新增'}API`"
      :wrapperClosable="false"
      :append-to-body="true"
      @close="close">
      <div class="api-content">
        <el-form :model="detailInfo" ref="form" :rules="formRules" :disabled="batchBind" label-width="150px">
          <div class="sub-title">API定义</div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="API名称：" prop="apiMeta.apiName">
                <template #label>
                  <span>
                    <el-tooltip placement="top" content="API名称是标识API的重要属性，它和API版本一起，可以标识唯一的API。API 名称建议使用英文输入，但也支持中文（在Postman或APIFox中使用中文时需要设置对中文的编码）">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                    <span>API名称：</span>
                  </span>
                </template>
                <el-input v-model="detailInfo.apiMeta.apiName" :disabled="detailInfo.apiMeta.apiId ? true : false" @blur="textTrim(detailInfo.apiMeta, 'apiName')" placeholder="请输入API名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="API状态：" prop="apiMeta.apiStatus">
                <span>
                  <el-tag v-if="detailInfo.apiMeta.apiStatus === 1">开发中</el-tag>
                  <el-tag v-else-if="detailInfo.apiMeta.apiStatus === 2" type="success">已发布</el-tag>
                  <el-tag v-else-if="detailInfo.apiMeta.apiStatus === 3" type="danger">已停用</el-tag>
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="API版本：" prop="apiMeta.apiVersion">
                <template #label>
                  <span>
                    <el-tooltip placement="top" content="API版本是一个自动管理的属性， 是为了解决同名API配置变化而设立的属性。默认值为1。当对发布后的API进行修改时，API版本将自动将其同API名称的最大API版本加1">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                    <span>API版本：</span>
                  </span>
                </template>
                <el-input v-model="detailInfo.apiMeta.apiVersion" disabled placeholder="请输入API版本"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数据库：" prop="apiCategoryGroup">
                <el-select v-model="detailInfo.apiMeta.apiCategoryGroup" placeholder="请选择数据库">
                  <el-option label="data_platform" value="data_platform"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="是否保存为模板：" prop="apiAsTemplate">
                <template #label>
                  <span>
                    <el-tooltip placement="top" content="“是否保存模板”是API的一个布尔属性。此处配置者需了解关于模板和API的关系，即：我们可以通过模板名称找到对应的API, 但特定的API不一定是业务模板">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                    <span>是否保存为模板：</span>
                  </span>
                </template>
                <el-radio-group v-model="detailInfo.apiMeta.apiAsTemplate">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
                <el-checkbox v-if="detailInfo.apiMeta.apiAsTemplate === 1" style="margin-left: 30px" v-model="detailInfo.apiMeta.apiAsBusinessTemplate" @change="apiAsBusinessTemplateChange" :true-label="1" :false-label="0">保存为数据模板，输出到数据浏览器</el-checkbox>
              </el-form-item>
            </el-col>
            <!-- <template v-if="detailInfo.apiMeta.apiAsTemplate === 1">
              <el-col :span="12">
                <el-form-item label="模板名称：" prop="apiMeta.apiTemplateName">
                   <el-input v-model="detailInfo.apiMeta.apiTemplateName" @blur="textTrim(detailInfo.apiMeta, 'apiTemplateName')" placeholder="请输入模板名称"></el-input>
                </el-form-item>
              </el-col>
            </template> -->
          </el-row>
          <el-row v-if="detailInfo.apiMeta.apiAsTemplate === 1 && detailInfo.apiMeta.apiAsBusinessTemplate === 1">
            <!-- <el-col v-if="detailInfo.apiMeta.apiAsTemplate === 1" :span="12">
              <el-form-item label="模板分类：" prop="apiTemplateCategoryList">
                <el-tag
                  :key="tag"
                  v-for="(tag, index) in detailInfo.apiMeta.apiTemplateCategoryList"
                  closable
                  :disable-transitions="false"
                  @close="deleteCategory(index)">
                  {{tag}}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput">新增分类</el-button>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="日期选择类型：" prop="apiMeta.apiDateType">
                <el-select v-model="detailInfo.apiMeta.apiDateType" @change="apiDateTypeChange">
                  <el-option v-for="item in dateFilterTypeList" :label="item.label" :value="item.value" :key="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item v-if="detailInfo.apiMeta.apiDateType" :label="startDateLabel" prop="apiMeta.dataBeginDate">
                <el-date-picker v-if="[2].indexOf(detailInfo.apiMeta.apiDateType) > -1" v-model="detailInfo.apiMeta.dataBeginDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择"></el-date-picker>
                <el-date-picker v-else-if="[4].indexOf(detailInfo.apiMeta.apiDateType) > -1" v-model="detailInfo.apiMeta.dataBeginDate" value-format="yyyy" type="year" placeholder="请选择"></el-date-picker>
                <el-date-picker v-else-if="[6].indexOf(detailInfo.apiMeta.apiDateType) > -1" v-model="detailInfo.apiMeta.dataBeginDate" value-format="yyyy-MM" type="month" placeholder="请选择"></el-date-picker>
                <ElQuarterPicker v-else-if="detailInfo.apiMeta.apiDateType === 5" v-model="detailInfo.apiMeta.dataBeginDate" valueFormat="yyyy-q" />
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="detailInfo.apiMeta.apiAsTemplate === 1 && detailInfo.apiMeta.apiAsBusinessTemplate === 1">
            <el-row>
              <el-col :span="12">
                <el-form-item label="日期标签文本：" prop="apiMeta.apiDateTitle">
                  <el-input v-model="detailInfo.apiMeta.apiDateTitle" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否输出变更时间：" prop="apiMeta.apiShowUpdate">
                  <el-radio-group v-model="detailInfo.apiMeta.apiShowUpdate">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row>
              <el-col :span="12">
                <el-form-item label="是否输出交易所：" prop="apiMeta.apiShowExchange">
                  <el-radio-group v-model="detailInfo.apiMeta.apiShowExchange">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="统计数据输出格式：" prop="apiMeta.statisticsFiscalYear">
                  <el-radio-group v-model="detailInfo.apiMeta.statisticsFiscalYear">
                    <el-radio :label="0">EffectiveDate</el-radio>
                    <el-radio :label="1">FiscalYear</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <el-row>
            <el-col :span="24">
              <el-form-item label="API描述：" prop="apiDescription">
                <el-input type="textarea" v-model="detailInfo.apiMeta.apiDescription" @blur="textTrim(detailInfo.apiMeta, 'apiDescription')" placeholder="请输入API的描述信息"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="sub-title">API行为设定</div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="API请求方法：" prop="apiRequestMethod">
                <el-select v-model="detailInfo.apiMeta.apiRequestMethod">
                  <el-option label="Post" value="Post"></el-option>
                  <el-option label="Get" value="Get"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="API操作模式：" prop="apiOperationType">
                <el-select v-model="detailInfo.apiMeta.apiOperationType">
                  <el-option label="查询数据" :value="0"></el-option>
                  <!-- <el-option label="更新数据" :value="1"></el-option> -->
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="数据是否需要缓存：" prop="apiSupportCache">
                <el-radio-group v-model="detailInfo.apiMeta.apiSupportCache" @change="(val) => val === 1 ? detailInfo.apiData.apiLimitExp = 0 : detailInfo.apiData.apiLimitExp = 1">
                  <el-radio :label="1">需要</el-radio>
                  <el-radio :label="0">不需要</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否过滤掉空数据：" prop="apiFilterNull">
                <el-radio-group v-model="detailInfo.apiMeta.apiFilterNull">
                  <el-radio :label="1">需要</el-radio>
                  <el-radio :label="0">不需要</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="sub-title">API安全设定</div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="API访问权限码：" prop="apiAuthCode">
                <el-input v-model="detailInfo.apiMeta.apiAuthCode" @blur="textTrim(detailInfo.apiMeta, 'apiAuthCode')" placeholder="请输入API访问权限码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="sub-title">API帮助指引</div>
          <el-row style="margin-left: 20px">
            <el-col :span="12">
              <el-upload
                class="upload-demo"
                action="#"
                accept=".pdf"
                :before-upload="handleBeforeUpload"
                :on-success="handleUploadSuccess"
                :on-change="handleUploadChange"
                :on-remove="handleRemoveFile"
                :on-preview="handlePreview"
                :limit="1"
                :auto-upload="false"
                :file-list="fileList"
              >
                <div>
                  <el-button icon="plus" text>{{ $t("上传文件") }}</el-button>
                </div>
                <template #tip> </template>
              </el-upload>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <div class="sub-title">API数据源设定</div>
          <!-- <el-radio-group v-model="detailInfo.apiData.apiUseAdvModeForData">
            <el-radio :label="0">
              使用编辑模式
              <el-tooltip placement="top" content="“使用编辑模式”-- 配置者可以通过在页面上配置数据源、输出字段和过滤条件等实现普通数据API的定义">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-radio>
            <el-radio :label="1">
              使用高级模式
              <el-tooltip placement="top" content="“使用高级模式”-- 对于不能直接通过“使用编辑模式”来实现API定义的场景，可以通过“使用高级模式” 进行SQL编码来定义API">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-radio>
          </el-radio-group> -->
          <div class="edit-mode" v-if="detailInfo.apiData.apiUseAdvModeForData === 0">
            <div class="left-content">
              <div class="tree-data">
                <div style="margin-bottom: 10px;">数据表
                  <el-tooltip placement="top" content="用户可以在当前数据库中选择业务需求定义的数据表作为API的数据源">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
                <TreeComponent
                  ref="tree"
                  :treeData="treeData"
                  style="height: calc(100% - 37px)"
                  defaultExpandAll
                  :defaultProps="{label: 'TABLE_NAME', value: 'TABLE_NAME'}"
                >
                  <span slot-scope="{ data }">
                    <span v-if="data.children" style="font-weight: 700">
                      <svg-icon icon-class="database" />
                      {{ data.TABLE_NAME }}
                    </span>
                    <div v-else class="custom-tree-node">
                      <div class="node-label" :title="`${data.TABLE_NAME}(${data.TABLE_COMMENT || ''})`">
                        <div class="node-mark">{{ data.TABLE_TYPE === 'BASE TABLE' ? 'T' : 'V' }}</div>
                        {{ data.TABLE_NAME }}
                      </div>
                      <span class="node-btn">
                        <el-button
                          type="text"
                          :disabled="setIsDisabled(data.TABLE_NAME)"
                          icon="el-icon-circle-plus-outline"
                          @click.stop="() => dataTableClick(data)">
                        </el-button>
                      </span>
                    </div>
                  </span>
                </TreeComponent>
              </div>
              <div class="tree-data">
                <div style="margin-bottom: 10px;">已有数据模板</div>
                  <TreeComponent
                    ref="templateTree"
                    style="height: calc(100% - 37px)"
                    :treeData="templateData"
                    defaultExpandAll
                    :defaultProps="{ children: 'children', label: 'templateName' }"
                  >
                    <span slot-scope="{ data }">
                      <span v-if="data.children" style="font-weight: 700">
                        <svg-icon icon-class="category" />
                        {{ data.templateName }}
                      </span>
                      <div v-else class="custom-tree-node">
                        <div class="node-label" :title="data.templateName">
                          <svg-icon icon-class="data-table" />
                          {{ data.templateName }}
                        </div>
                        <span class="node-btn">
                          <el-button
                            type="text"
                            icon="el-icon-circle-plus-outline"
                            :disabled="setTemplateIsDisabled(data.apiId)"
                            @click.stop="() => templateClick(data)">
                          </el-button>
                        </span>
                      </div>
                    </span>
                  </TreeComponent>
              </div>
            </div>
            <div class="right-content common-table-style">
              <div style="margin-bottom: 20px;">
                数据源
                <el-tooltip placement="top" content="数据源 -- API 要输出数据的来源，可以是直接选定的数据表，也可以是某个定义为业务模板的API">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <el-table :data="detailInfo.dataSourceMetaList.filter((e) => e.deleted !== 1)" border>
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column label="数据库名" prop="databaseName" show-overflow-tooltip></el-table-column>
                <el-table-column label="数据表名" prop="tableName"  min-width="200" show-overflow-tooltip></el-table-column>
                <el-table-column label="数据表别名" prop="tableAlias" width="200">
                  <template #default="{ row }">
                    <el-input v-model="row.tableAlias" @blur="textTrim(row, 'tableAlias')" @change="tableAliasChange(row)"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="来源于模板" prop="templateApiId" show-overflow-tooltip>
                  <template #default="{ row }">
                    {{ getApiTemplateName(row) }}
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template #default="{ row, $index }">
                    <el-button type="text" size="small" @click="deleteDataSource(row, $index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="third-title">
                <span>
                  <span>
                    定义输出字段
                    <el-tooltip placement="top" content="您可以在“定义输出字段”区域定义选用要输出的API字段，设定其输出名称和输出的排序顺序。其中输出名称不填写的话即为该字段在数据表中的名称。输出顺序不填的话，默认为0， API中将以字段在表中的原有顺序进行输出。">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </span>
                  <span style="margin-left: 24px">已选<span style="color: #01579b;">&nbsp;{{detailInfo.dataPointMetaList.filter((e) => e.chooseColumn === 1 && e.deleted !== 1).length}}&nbsp;</span>条</span>
                </span>
                <div>
                  <el-button icon="el-icon-plus" size="small" @click="addField" style="margin-right: 4px">添加自定义字段</el-button>
                  <el-tooltip placement="top" content="“自定义字段” 是为了提供对现有数据库字段进行补充，实现函数计算和自定义计算">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
              </div>
              <el-table class="table-form" :data="detailInfo.dataPointMetaList.filter((e) => e.deleted !== 1)" border max-height="600px">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column label="选用输出" width="120px">
                  <template #header>
                    <el-checkbox v-model="allCheck" @change="allCheckChange" :true-label="1" :false-label="0">选用输出</el-checkbox>
                  </template>
                  <template #default="{ row }">
                    <el-checkbox v-model="row.chooseColumn" :true-label="1" :false-label="0">选用</el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column label="数据库名" prop="databaseName" show-overflow-tooltip width="120px"></el-table-column>
                <el-table-column label="表名" prop="tableName" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column label="列名" prop="columnName" show-overflow-tooltip width="150"></el-table-column>
                <!-- <el-table-column label="注释" prop="columnComment" show-overflow-tooltip></el-table-column> -->
                <el-table-column label="输出字段" prop="columnAlias" width="150" sortable class-name="cell-overflow">
                  <template #default="{ row }">
                    <el-input v-model="row.columnAlias" @blur="textTrim(row, 'columnAlias')" :disabled="row.isApplyFuncColumn || row.isCalculationColumn" :title="row.columnAlias" style="width: 100%"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="输出名称" prop="columnOutputTitle" min-width="200" class-name="cell-overflow">
                  <template #default="{ row, $index }">
                    <el-form-item class="columnOutputTitle-item" :prop="`dataPointMetaList.${$index}.columnOutputTitle`" :rules="[{required: row.chooseColumn === 1 ? true : false, message: '输出名称不能为空', trigger: 'blur'}]" label-width="0px">
                      <el-input v-model="row.columnOutputTitle" @blur="textTrim(row, 'columnOutputTitle')" :disabled="row.isApplyFuncColumn || row.isCalculationColumn" :title="row.columnOutputTitle" style="width: 100%"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="自定义表达式" prop="expressionContent" show-overflow-tooltip width="150"></el-table-column>
                <el-table-column label="输出顺序" prop="finalOrder" sortable width="100">
                  <template #default="{ row }">
                    <el-input-number v-model="row.finalOrder" :precision="0" :controls="false" :disabled="row.isApplyFuncColumn || row.isCalculationColumn" style="width: 100%;"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column label="列数据类型" prop="dataType" show-overflow-tooltip width="120"></el-table-column>
                <el-table-column label="字段顺序" prop="ordinalPosition" width="80"></el-table-column>
                <el-table-column label="是否锁定" prop="fixedColumn" width="80" align="center">
                  <template #default="{ row }">
                    <el-checkbox v-model="row.fixedColumn" :true-label="1" :false-label="0" @change="(val) => fixedChange(row, val)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="90">
                  <template #default="{ row, $index }">
                    <el-button v-if="row.isApplyFuncColumn || row.isCalculationColumn"  type="text" size="small" @click="editField(row, $index)">编辑</el-button>
                    <el-button v-if="row.isApplyFuncColumn || row.isCalculationColumn" type="text" size="small" @click="deleteField(row, $index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
               <div class="third-title">
                <span>请求参数
                  <el-tooltip placement="top" content="“请求参数” 定义API对外暴露给客户调用时使用的其他非默认参数。请求参数作为数据的过滤条件，要求在“过滤条件设定”区块中同步定义">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </span>
                <el-button icon="el-icon-plus" size="small" @click="addParams">添加</el-button>
              </div>
              <el-table class="table-form" :data="detailInfo.requestParameterList.filter((e) => e.deleted !== 1)" border>
                <el-table-column label="参数名称" class-name="cell-overflow">
                  <template #default="{ row }">
                    <el-form-item :prop="`requestParameterList.${detailInfo.requestParameterList.findIndex((e) => e.key === row.key)}.parameterName`" :rules="[{required: true, message: '参数名称不能为空', trigger: 'blur'}]" label-width="0px">
                      <el-input v-model="row.parameterName" @blur="textTrim(row, 'parameterName')"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="参数类型" class-name="cell-overflow">
                  <template #default="{ row }">
                    <el-select v-model="row.paraType">
                      <el-option label="Params" :value="0"></el-option>
                      <el-option label="Body" :value="1"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="子类型" class-name="cell-overflow">
                  <template #default="{ row }">
                    <el-select v-model="row.paraSubType">
                      <el-option v-for="item in paraSubTypeList" :label="item.label" :value="item.value" :key="item.value"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="参数占位符" width="220" show-overflow-tooltip class-name="cell-overflow">
                  <template #default="{ row }">
                    <el-form-item :prop="`requestParameterList.${detailInfo.requestParameterList.findIndex((e) => e.key === row.key)}.bindQueryConditionExpressionPattern`" :rules="[{required: true, message: '参数占位符不能为空', trigger: 'blur'}]" label-width="0px">
                      <el-input v-model="row.bindQueryConditionExpressionPattern" @blur="textTrim(row, 'bindQueryConditionExpressionPattern')" placeholder="请将值输入在{}中，如{xxx}"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="参数数据类型" show-overflow-tooltip class-name="cell-overflow">
                  <template #default="{ row }">
                    <el-select v-model="row.parameterDataType">
                      <el-option v-for="item in dataTypeList" :label="item.label" :value="item.value" :key="item.value"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="是否有效">
                  <template #default="{ row }">
                    <el-switch v-model="row.parameterStatus" :active-value="1" :inactive-value="0" active-text="有效" inactive-text="无效"></el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="顺序">
                  <template #default="{ row }">
                    <el-input-number v-model="row.parameterOrder" :precision="0" :controls="false" style="width: 100%;"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column label="描述">
                  <template #default="{ row }">
                    <el-input v-model="row.parameterDescription" @blur="textTrim(row, 'parameterDescription')"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template #default="{ row, $index }">
                    <el-button type="text" size="small" @click="deleteParams(row, $index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="third-title">
                <span>过滤条件设定
                  <el-tooltip placement="top" content="“过滤条件设定”定义了查询数据的过滤条件。这些条件来源于“请求参数” 或 额外的固化的查询条件。">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </span>
                <el-button icon="el-icon-plus" size="small" @click="addCondition">添加</el-button>
              </div>
              <el-table class="table-form" :data="detailInfo.whereConditionList.filter((e) => e.deleted !== 1)" border max-height="600px">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column label="输出字段" class-name="cell-overflow">
                  <template #default="{ row }">
                    <el-form-item :prop="`whereConditionList.${detailInfo.whereConditionList.findIndex((e) => e.key === row.key)}.selectId`" :rules="[{required: true, message: '输出字段不能为空'}]" label-width="0px">
                      <el-select v-model="row.selectId" filterable @change="(val) => outputFieldChange(row, val)">
                        <el-option v-for="item in fieldList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作符" class-name="cell-overflow">
                  <template #default="{ row, $index }">
                    <el-form-item :prop="`whereConditionList.${detailInfo.whereConditionList.findIndex((e) => e.key === row.key)}.queryConditionOperator`" :rules="[{required: true, message: '操作符不能为空', trigger: 'change'}]" label-width="0px">
                      <el-select v-model="row.queryConditionOperator" filterable @change="(val) => queryOperatorChange(row, val, $index)">
                        <el-option v-for="item in operatorList" :key="item" :value="item" :label="item"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="值1" width="300" class-name="cell-overflow">
                  <template #default="{ row }">
                    <el-form-item :prop="`whereConditionList.${detailInfo.whereConditionList.findIndex((e) => e.key === row.key)}.queryConditionValue1ExpPattern`" :rules="[{required: ['is null', 'is not null'].indexOf(row.queryConditionOperator) === -1, message: '值1不能为空', trigger: 'blur'}]" label-width="0px">
                      <el-input v-model="row.queryConditionValue1ExpPattern" @blur="textTrim(row, 'queryConditionValue1ExpPattern')" :disabled="['is null', 'is not null'].indexOf(row.queryConditionOperator) !== -1" style="width: 100%"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="值2" class-name="cell-overflow">
                  <template #default="{ row }">
                    <el-form-item :prop="`whereConditionList.${detailInfo.whereConditionList.findIndex((e) => e.key === row.key)}.queryConditionValue2ExpPattern`" label-width="0px">
                      <el-input v-model="row.queryConditionValue2ExpPattern" @blur="textTrim(row, 'queryConditionValue2ExpPattern')" :disabled="['between'].indexOf(row.queryConditionOperator) === -1"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="条件顺序">
                  <template #default="{ row }">
                    <el-input-number v-model="row.queryConditionOrder" :precision="0" :controls="false" style="width: 100%;"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column label="条件组合">
                  <template #default="{ row }">
                    <el-select v-model="row.queryConditionGroupOperator">
                      <el-option label="and" value="and"></el-option>
                      <el-option label="or" value="or"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right">
                  <template #default="{ row, $index }">
                    <el-button type="text" size="small" @click="deleteQueryCondition(row, $index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div>
                <div class="third-title">
                  <span>脚本表达式</span>
                </div>
                <el-form-item label="From 表达式：" prop="apiData.apiTableJoinExp" label-width="180px">
                  <template #label>
                    <span>
                      <el-tooltip placement="top">
                        <template #content>
                          <div>配置者可以定义API操作的数据表名或多表关联的On语句。例如：</div>
                          <div>对于多表，可以设定为 “ tablename_A as alias_a inner join tablename_B as alias_b on a.x=b.x”，</div>
                          <div>对于单表，可以设定为“tablename as alias”，</div>
                          <div>请注意，由于系统开放了灵活性， 配置者需要保证表的别名需要和数据源区块定义的别名一致。</div>
                        </template>
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                      <span>From 表达式：</span>
                    </span>
                  </template>
                  <el-input type="textarea" v-model="detailInfo.apiData.apiTableJoinExp" @blur="textTrim(detailInfo.apiData, 'apiTableJoinExp')" rows="4"></el-input>
                </el-form-item>
                <el-form-item label="Group/Having 表达式：" label-width="180px">
                  <template #label>
                    <span>
                      <el-tooltip placement="top" content="系统支持对数据表进行group by/having 条件的设定， 配置者可以按需设定">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                      <span>Group/Having 表达式：</span>
                    </span>
                  </template>
                  <el-input type="textarea" v-model="detailInfo.apiData.apiGroupHavingExp" @blur="textTrim(detailInfo.apiData, 'apiGroupHavingExp')" rows="4"></el-input>
                </el-form-item>
                <el-form-item label="OrderBy 表达式：" label-width="180px">
                  <template #label>
                    <span>
                      <el-tooltip placement="top" content="系统支持对数据表进行排序设定， 配置者可以设定API数据输出的顺序，例如： order by alias.column_a asc, alias.column_b desc请注意，不要写语句结尾的分号">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                      <span>OrderBy 表达式：</span>
                    </span>
                  </template>
                  <el-input v-model="detailInfo.apiData.apiOrderByExp" @blur="textTrim(detailInfo.apiData, 'apiOrderByExp')"></el-input>
                </el-form-item>
                <el-form-item label="是否使用limit分页：" label-width="180px">
                  <template #label>
                    <span>
                      <el-tooltip placement="top" content="系统支持limit 语句对数据进行分页输出，该功能需结合pageIndex 和pageSize两个参数使用">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                      <span>是否使用limit分页：</span>
                    </span>
                  </template>
                  <el-radio-group v-model="detailInfo.apiData.apiLimitExp" @change="(val) => val === 1 ? detailInfo.apiMeta.apiSupportCache = 0 : detailInfo.apiMeta.apiSupportCache = 1">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
          </div>
          <div v-else>
            <el-form-item style="margin-top: 16px" label="设置SQL：" prop="apiData.apiAdvModeDataScript">
              <el-input type="textarea" v-model="detailInfo.apiData.apiAdvModeDataScript" @blur="textTrim(detailInfo.apiData, 'apiAdvModeDataScript')" rows="8" placeholder="直接输入SQL脚本"></el-input>
            </el-form-item>
            <el-form-item label="设置countSQL：" prop="apiData.apiAdvModeCountScript">
              <el-input type="textarea" v-model="detailInfo.apiData.apiAdvModeCountScript" @blur="textTrim(detailInfo.apiData, 'apiAdvModeCountScript')" rows="8" placeholder="直接输入countSQL脚本"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <bindingUser ref="bindingUser" :apiName="detailInfo.apiMeta.apiName" :apiVersion="detailInfo.apiMeta.apiVersion" :userApiBindingList="detailInfo.userApiBindingList"/>
      </div>
      <div class="footer-btn">
        <el-button v-if="batchBind" size="small" @click="close">关 闭</el-button>
        <template v-else>
          <el-button size="small" @click="close">取 消</el-button>
         <el-button type="primary" size="small" @click="handleSave">保 存</el-button>
        </template>
      </div>
    </el-drawer>
    <addFieldDialog
      v-if="addFieldDialogVisible"
      :visible="addFieldDialogVisible"
      :fieldInfo="currentFieldInfo"
      @saveField="saveField"
      @close="addFieldDialogVisible = false"
    />
  </div>
</template>
<script>
import { getTableListByTableSchemaName, getColumnsByTableName, saveOrUpdateApi, getConfigAPIByApiId, getDataPointListByApiName, getDataSourceListByApiName, uploadHelpDoc, downloadHelpDoc } from "@/api/data-platform/admin-center.js";
import { listAuthTemplateTree } from "@/api/data-platform/dl-databrowser.js";
import { operatorList, paraSubTypeList, dataTypeList, dateFilterTypeList } from "@/utils/data-platform/constens.js";
import addFieldDialog from "./addFieldDialog.vue";
import bindingUser from "./bindingUser.vue";
import TreeComponent from "@/components/TreeComponent";
import ElQuarterPicker from "@/components/ElQuarterPicker";
import { getToken } from "@/utils/auth";
import { objectToFormData, downloadFile } from "@/utils/index.js";
export default {
  name: 'apiEditDrawer',
  components: {
    addFieldDialog,
    bindingUser,
    TreeComponent,
    ElQuarterPicker,
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    apiId: {
      type: [Number, String],
      default: () => null
    },
    batchBind: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      operatorList: operatorList,
      paraSubTypeList: paraSubTypeList,
      dataTypeList: dataTypeList,
      dateFilterTypeList: dateFilterTypeList,
      formRules: {
        'apiMeta.apiName': [{required: true, message: 'api名称不能为空', trigger: 'blur'}],
        'apiMeta.apiVersion': [{required: true, message: 'api版本不能为空', trigger: 'blur'}],
        // 'apiMeta.apiTemplateName': [{required: true, message: '模板名称不能为空', trigger: 'blur'}],
        'apiMeta.apiDateType': [{required: true, message: '日期选择类型不能为空', trigger: 'blur'}],
        'apiMeta.dataBeginDate': [{required: true, message: '不能为空', trigger: 'change'}],
        'apiMeta.apiDateTitle': [{required: true, message: '日期标签文本不能为空', trigger: 'blur'}],
        'apiData.apiTableJoinExp': [{required: true, message: 'From 表达式不能为空', trigger: 'blur'}],
        'apiData.apiAdvModeDataScript': [{required: true, message: 'SQL脚本不能为空', trigger: 'blur'}],
        'apiData.apiAdvModeCountScript': [{required: true, message: 'countSQL脚本不能为空', trigger: 'blur'}],
      },
      helpDoc: null,
      fileList: [],
      detailInfo: {
        deleteHelpDoc: false,
        hasHelpDoc: false,
        apiMeta: {
          apiName: '',
          apiVersion: '1',
          apiCategoryGroup: 'data_platform',
          apiDescription: '',
          apiStatus: '',
          apiAsTemplate: 0,
          apiTemplateName: '',
          apiAsBusinessTemplate: 0,
          apiTemplateCategory: '',
          apiTemplateCategoryList: [],
          apiRequestMethod: 'Post',
          apiOperationType: 0,
          apiSupportPaging: 1,
          apiSupportCache: 0,
          apiFilterNull: 0,
          apiAuthCode: '',
          apiDateType: '', // API日期选择类型
          apiDateTitle: '', // API日期标签文本
          apiShowUpdate: 0,
          apiShowExchange: 0, // 交易所
          statisticsFiscalYear: 0, // 统计 Fiscal Year
          dataBeginDate: null
        },
        apiData: {
          apiUseAdvModeForData: 0,
          apiAdvModeDataScript: '', // SQL脚本
          apiAdvModeCountScript: '', // countSQL脚本
          apiTableJoinExp: '',
          apiGroupHavingExp: '',
          apiOrderByExp: '',
          apiLimitExp: 1
        },
        dataSourceMetaList: [],
        dataPointMetaList: [],
        whereConditionList: [],
        requestParameterList: [],
        userApiBindingList: []
      },
      keyword: '',
      treeData: [],
      templateKeyword: '',
      templateData: [],
      addFieldDialogVisible: false,
      currentFieldInfo: {},
      currentFieldIndex: null,
      allCheck: 0,
      inputVisible: false,
      inputValue: '',
      fileType: ['pdf'],
      uploadFileUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传的图片服务器地址
      headers: {
          Authorization: "Bearer " + getToken(),
      },
    }
  },
  created() {
    this.getTableListByTableSchemaName(); // 查询数据库表
  },
  computed: {
    fieldList () {
      const list = this.detailInfo.dataPointMetaList.filter((e) => !e.isCalculationColumn && !e.isApplyFuncColumn); // 过滤出数据库查出来的字段
      list.forEach((e) => {
        e.label = `${e.columnName}(${e.tableName})`;
        e.value = `${e.columnName}(${e.tableName})`;
      })
      const obj = {};
      const arr = [];
      for (const item of list) {
        if (!obj[item.value]) {
          arr.push(item);
          obj[item.value] = true;
        }
      }
      return arr;
    },
    startDateLabel () {
      if (this.detailInfo.apiMeta.apiDateType === 2) {
        return '起始日期';
      } else if (this.detailInfo.apiMeta.apiDateType === 4) {
        return '起始年份';
      } else if (this.detailInfo.apiMeta.apiDateType === 5) {
        return '起始季度';
      } else if (this.detailInfo.apiMeta.apiDateType === 6) {
        return '起始月份';
      }
    }
  },
  watch: {
    keyword (val) {
      this.$refs.tree.filter(val);
    },
    templateKeyword (val) {
      this.$refs.templateTree.filter(val);
    },
    visible (val) {
      if (val) {
        this.helpDoc = false;
        Object.assign(this.$data.detailInfo, this.$options.data().detailInfo);
        this.loadAllBusinessTemplateApi(); // 查询已上线业务模板
        this.$nextTick(() => {
          if (this.apiId) {
            this.getDetailInfo();
          }
          this.$refs['form'] && this.$refs['form'].clearValidate();
        })
      }
    }
  },
  methods: {
    // 是否锁定变化
    fixedChange (row, val) {
      const data = this.detailInfo.dataPointMetaList.filter((e) => e.deleted !== 1);
      const list = data.filter((e) => e.fixedColumn === 1);
      if (list.length > 5) {
        this.$modal.msgWarning('最多设置锁定5个字段');
        row.fixedColumn = 0;
      }
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {

    },
    handlePreview (file) {
      if (this.fileList.length) {
        const params = {
          apiName: this.detailInfo.apiMeta.apiName
        }
        downloadHelpDoc(params).then((res) => {
          downloadFile(res, file.name);
        })
      }

    },
    handleUploadChange (file) {
      // 校检文件类型
      file = file.raw;
      if (this.fileType) {
          let fileExtension = "";
          if (file.name.lastIndexOf(".") > -1) {
              fileExtension = file.name.slice(
                  file.name.lastIndexOf(".") + 1
              );
          }
          const isTypeOk = this.fileType.some((type) => {
              if (file.type.indexOf(type) > -1) return true;
              if (fileExtension && fileExtension.indexOf(type) > -1)
                  return true;
              return false;
          });
          if (!isTypeOk) {
              this.$modal.msgError(
                  `文件格式不正确, 请上传${this.fileType.join(
                      "/"
                  )}格式文件!`
              );
              return false;
          }
      }
      // 校检文件大小
      if (this.fileSize) {
          const isLt = file.size / 1024 / 1024 < 5;
          if (!isLt) {
              this.$modal.msgError(
                  `上传文件大小不能超过 5 MB!`
              );
              return false;
          }
      }
      this.helpDoc = file;
      this.detailInfo.deleteHelpDoc = false;
    },
    handleRemoveFile () {
      if (this.detailInfo.hasHelpDoc) {
        this.detailInfo.deleteHelpDoc = true;
      }
      this.fileList = [];
      this.helpDoc = null;
    },
    // 上传成功回调
    handleUploadSuccess (res) {
      this.$modal.closeLoading();
    },
    // 新增分类
    showInput () {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    deleteCategory (index) {
      this.detailInfo.apiMeta.apiTemplateCategoryList.splice(index, 1);
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.detailInfo.apiMeta.apiTemplateCategoryList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    textTrim (obj, key) {
      obj[key] = obj[key]?.trim();
    },
    // 设置过滤条件数据
    setWhereConditionList () {
      const value1List = this.detailInfo.whereConditionList.filter((e) => e.deleted !== 1).map((e) => e.queryConditionValue1ExpPattern);
      const data = {
        apiId: this.apiId,
        queryConditionFieldTableName: '',
        queryConditionFieldTableAlias: '',
        queryConditionFieldName: '',
        queryConditionOperator: '',
        queryConditionValue1ExpPattern: '',
        queryConditionValue2ExpPattern: '',
        queryConditionOrder: '',
        queryConditionGroupOperator: 'and',
        deleted: 0,
        key: Date.now()
      }
      if (value1List.indexOf('{secCodeList}') === -1) {
        this.detailInfo.whereConditionList.push({
          ...data,
          queryConditionOperator: 'in',
          queryConditionValue1ExpPattern: '{secCodeList}'
        })
      }
      if (value1List.indexOf('{exchangeIdList}') === -1) {
        this.detailInfo.whereConditionList.push({
          ...data,
          queryConditionOperator: 'in',
          queryConditionValue1ExpPattern: '{exchangeIdList}'
        })
      }
      // 使用年份选择
      if ([4].indexOf(this.detailInfo.apiMeta.apiDateType) > -1)  {
        const index = this.detailInfo.whereConditionList.findIndex((e) => e.queryConditionValue1ExpPattern === '{effectiveDateStart}' && e.deleted !== 1);
        if (index > -1) {
          if (this.detailInfo.whereConditionList[index].queryConditionId) {
            this.detailInfo.whereConditionList[index].deleted = 1;
          } else {
            this.detailInfo.whereConditionList.splice(index, 1);
          }
        }
        if (value1List.indexOf('{effectiveDateRangeList}') === -1) {
          this.detailInfo.whereConditionList.push({
            ...data,
            queryConditionOperator: 'in',
            queryConditionValue1ExpPattern: '{effectiveDateRangeList}',
          })
        }
      } else { // 其他
        const index = this.detailInfo.whereConditionList.findIndex((e) => e.queryConditionValue1ExpPattern === '{effectiveDateRangeList}' && e.deleted !== 1);
        if (index > -1) {
          if (this.detailInfo.whereConditionList[index].queryConditionId) {
            this.detailInfo.whereConditionList[index].deleted = 1;
          } else {
            this.detailInfo.whereConditionList.splice(index, 1);
          }
        }
        if (value1List.indexOf('{effectiveDateStart}') === -1) {
          this.detailInfo.whereConditionList.push({
            ...data,
            queryConditionOperator: 'between',
            queryConditionValue1ExpPattern: '{effectiveDateStart}',
            queryConditionValue2ExpPattern: '{effectiveDateEnd}'
          })
        }
      }

      if (value1List.indexOf('{updateStartTime}') === -1) {
        this.detailInfo.whereConditionList.push({
          ...data,
          queryConditionOperator: 'between',
          queryConditionValue1ExpPattern: '{updateStartTime}',
          queryConditionValue2ExpPattern: '{updateEndTime}'
        })
      }
      this.$forceUpdate();
    },
    // 数据表别名改变事件
    tableAliasChange (row) {
      for (const item of this.detailInfo.whereConditionList) {
        if (item.queryConditionFieldTableName === row.tableName) {
          item.queryConditionFieldTableAlias = row.tableAlias;
        }
      }
    },
    // 切换日期类型
    apiDateTypeChange () {
      this.detailInfo.apiMeta.dataBeginDate = null;
      this.setWhereConditionList();
    },
    // 是否保存为业务模板切换事件
    apiAsBusinessTemplateChange (val) {
      if (val) {
        this.setWhereConditionList();
      }
    },
    // 选用输出全选/取消全选
    allCheckChange () {
      this.detailInfo.dataPointMetaList.forEach((e) => {
        this.$set(e, 'chooseColumn', this.allCheck)
      })
    },
    // 设置数据表是否可以添加
    setIsDisabled (name) {
      const list = this.detailInfo.dataSourceMetaList.filter((e) => !e.templateApiId);
      const nameList = list.map((e) => e.tableName);
      return nameList.indexOf(name) > -1;
    },
    // 设置模板是否可以添加
    setTemplateIsDisabled (id) {
      const idList = this.detailInfo.dataSourceMetaList.map((e) => e.templateApiId);
      return idList.indexOf(id) > -1;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.TABLE_NAME.indexOf(value) !== -1;
    },
    templateFilterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 查询api详情
    getDetailInfo () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      getConfigAPIByApiId({apiId: this.apiId}).then((res) => {
        loading.close();
        for (const key in this.detailInfo) {
          this.detailInfo[key]  = res.data[key] || [];
        }
        this.detailInfo.hasHelpDoc = res.data.hasHelpDoc;
        this.detailInfo.deleteHelpDoc = res.data.deleteHelpDoc;
        this.detailInfo.whereConditionList.forEach((e) => {
          this.$set(e, 'selectId', `${e.queryConditionFieldName}(${e.queryConditionFieldTableName})`);
        })
        this.detailInfo.apiMeta.apiTemplateCategoryList = this.detailInfo.apiMeta.apiTemplateCategory ? this.detailInfo.apiMeta.apiTemplateCategory.split(',') : [];
        this.fileList = this.detailInfo.hasHelpDoc ? [{name: this.detailInfo.apiMeta.apiName + '帮助指引.pdf', url: ''}] : [];
      }).catch((e) => { loading.close(); })
    },
    // 查询数据库表
    getTableListByTableSchemaName () {
      getTableListByTableSchemaName({dataSourceName: this.detailInfo.apiMeta.apiCategoryGroup, tableSchema: 'data_platform'}).then((res) => {
        if (res.code === 200) {
          this.treeData = [
            {
              TABLE_NAME: 'data_platform',
              children: res.data
            }
          ]
        }
      })
    },
    // 查询已上线业务模板
    loadAllBusinessTemplateApi () {
      listAuthTemplateTree().then((res) => {
        if (res.code === 200) {
          this.templateData = res.data || []
        }
      })
    },
    // 点击数据表节点
    dataTableClick (data) {
      const params = {
        dataSourceName: this.detailInfo.apiMeta.apiCategoryGroup,
        tableSchema: 'data_platform',
        tableName: data.TABLE_NAME
      }
      getColumnsByTableName(params).then((res) => {
        if (res.code === 200) {
          this.detailInfo.dataSourceMetaList.unshift({
            templateApiId: '',
            databaseName: this.treeData[0].TABLE_NAME,
            tableName: data.TABLE_NAME,
            tableAlias: '',
            deleted: 0,
            key: Date.now()
          });
          res.data.forEach((e) => {
            e.columnOutputTitle = e.columnComment;
          })
          this.detailInfo.dataPointMetaList = this.detailInfo.dataPointMetaList.concat(res.data);
        }
      })
    },
    // 点击模板添加
    templateClick (data) {
      const params = {
        apiName: data.apiName
      }
      getDataSourceListByApiName(params).then((res) => {
        if (res.code === 200) {
          const list = [];
          for (const item of res.data) {
            item.templateApiId = item.apiId;
            item.deleted = 0;
            item.tableMetaId = null;
            item.key = String(Math.random());
            const arr = list.filter((e) => e.databaseName === item.databaseName && e.tableName === item.tableName);
            if (arr.length === 0) {
              list.push(item);
            }
          }
          this.detailInfo.dataSourceMetaList = this.detailInfo.dataSourceMetaList.concat(list);
        }
      })
      getDataPointListByApiName(params).then((res) => {
        if (res.code === 200) {
          for (const item of res.data) {
            item.templateApiId = item.apiId;
            item.columnMetaId = null;
            item.chooseColumn = 0;
            item.columnOutputTitle = item.columnComment;
          }
          this.detailInfo.dataPointMetaList = this.detailInfo.dataPointMetaList.concat(res.data || []);
        }
      })
    },
    // 循环遍历模板树获取模板名称
    getTemplateName (data, templateApiId) {
      for (const item of data) {
        if (item.apiId === templateApiId) {
          return item.templateName
        } else {
          if (item.children && item.children.length) {
            return this.getTemplateName(item.children, templateApiId);
          }
        }
      }
    },
    // 获取api模板名称
    getApiTemplateName (row) {
      let value = '';
      if (row.templateApiId) {
        value = this.getTemplateName(this.templateData, row.templateApiId);
      }
      return value;
    },
    // 删除数据源
    deleteDataSource (row, index) {
      if (row.tableMetaId) {
        row.deleted = 1;
        // 删除的为已有业务模板添加的数据时
        if (row.templateApiId) {
          this.detailInfo.dataPointMetaList.forEach((e) => {
            if (row.templateApiId === e.templateApiId && row.tableName === e.tableName && row.databaseName === e.databaseName) {
              e.deleted = 1;
            }
          })
        } else {
          this.detailInfo.dataPointMetaList.forEach((e) => {
            if (row.tableName === e.tableName && row.databaseName === e.databaseName && !e.templateApiId) {
              e.deleted = 1;
            }
          })
        }
      } else {
        // const index = this.detailInfo.dataSourceMetaList.findIndex((e) => e.key === row.key);
        // this.detailInfo.dataSourceMetaList.splice(index, 1);
        this.detailInfo.dataSourceMetaList = this.detailInfo.dataSourceMetaList.filter((e) => e.key !== row.key);
        // 删除的为已有业务模板添加的数据时
        if (row.templateApiId) {
          this.detailInfo.dataPointMetaList = this.detailInfo.dataPointMetaList.filter((e) => !(row.templateApiId === e.templateApiId && row.tableName === e.tableName && row.databaseName === e.databaseName));
        } else {
          this.detailInfo.dataPointMetaList = this.detailInfo.dataPointMetaList.filter((e) => !(row.tableName === e.tableName && row.databaseName === e.databaseName && !e.templateApiId));
        }
      }
      // 删除条件表格相关数据
      const fieldValueList = this.fieldList.map((e) => e.value);
      this.detailInfo.whereConditionList.forEach((e) => {
        if (e.selectId && fieldValueList.indexOf(e.selectId) === -1) {
          e.selectId = '';
          // if (e.queryConditionId) {
          //   e.deleted = 1; // 假删除
          // } else {
          //   e.isDelete = 1; // 真删除
          // }
        }
      })
      // this.detailInfo.whereConditionList = this.detailInfo.whereConditionList.filter((e) => e.isDelete !== 1);
    },
    // 输出字段切换
    outputFieldChange (row, val) {
      const data = this.fieldList.filter((e) => e.value === val);
      if (data.length) {
        // 实时从数据源表格数据中取选择的数据表别名
        const list = this.detailInfo.dataSourceMetaList.filter((e) => e.databaseName === data[0].databaseName && e.tableName === data[0].tableName);
        if (list.length) {
          row.queryConditionFieldTableAlias = list[0].tableAlias;
        }
        row.queryConditionFieldTableName = data[0].tableName;
        row.queryConditionFieldName = data[0].columnName;
      }
    },
    // 删除查询条件
    deleteQueryCondition (row) {
      if (row.queryConditionId) {
        row.deleted = 1;
      } else {
        const index = this.detailInfo.whereConditionList.findIndex((e) => e.key === row.key);
        this.detailInfo.whereConditionList.splice(index, 1);
      }
    },
    // 切换操作符
    queryOperatorChange (row, val, index) {
      if (['is null', 'is not null'].indexOf(row.queryConditionOperator) > -1) {
        row.queryConditionValue1ExpPattern = '';
        row.queryConditionValue2ExpPattern = '';
      }
      if (['between'].indexOf(row.queryConditionOperator) === -1) {
        row.queryConditionValue2ExpPattern = '';
      }
      this.$refs.form.clearValidate(`whereConditionList.${index}.queryConditionValue1ExpPattern`);
      this.$refs.form.clearValidate(`whereConditionList.${index}.queryConditionValue2ExpPattern`);
    },
    // 添加字段
    addField () {
      this.currentFieldInfo = {};
      this.currentFieldIndex = null;
      this.addFieldDialogVisible = true;
    },
    // 编辑字段
    editField (row, index) {
      this.currentFieldInfo = row;
      this.currentFieldIndex = index;
      this.addFieldDialogVisible = true;
    },
    // 删除字段
    deleteField (row, index) {
      if (row.columnMetaId) {
        row.deleted = 1;
      } else {
        this.detailInfo.dataPointMetaList.splice(index, 1);
      }
    },
    // 保存字段
    saveField (val) {
      if (this.currentFieldIndex || this.currentFieldIndex === 0) {
        this.detailInfo.dataPointMetaList.splice(this.currentFieldIndex, 1, val);
      } else {
        const data = {
          ...val,
          apiId: this.apiId,
          deleted: 0,
          chooseColumn: 1, // 默认选用
          databaseName: this.treeData[0].TABLE_NAME
        }
        this.detailInfo.dataPointMetaList.unshift(data);
      }
    },
    // 添加条件
    addCondition () {
      this.detailInfo.whereConditionList.push({
        apiId: this.apiId,
        queryConditionFieldTableName: '',
        queryConditionFieldTableAlias: '',
        queryConditionFieldName: '',
        queryConditionOperator: '',
        queryConditionValue1ExpPattern: '',
        queryConditionValue2ExpPattern: '',
        queryConditionOrder: '',
        queryConditionGroupOperator: 'and',
        deleted: 0,
        key: Date.now()
      })
    },

    // 添加
    addParams () {
      this.detailInfo.requestParameterList.push({
        apiId: this.apiId,
        paraType: 0,
        paraSubType: 0,
        parameterName: '',
        parameterDataType: 0,
        parameterDescription: '',
        parameterOrder: '',
        bindQueryConditionExpressionPattern: '',
        parameterStatus: 1,
        deleted: 0,
        key: Date.now()
      })
    },
    deleteParams (row, index) {
      if (row.requestParaId) {
        row.deleted = 1;
      } else {
        const index = this.detailInfo.requestParameterList.findIndex((e) => e.key === row.key);
        this.detailInfo.requestParameterList.splice(index, 1);
      }
    },
    // 保存
    handleSave () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          for (const item of this.detailInfo.whereConditionList) {
            if (item.queryConditionOperator === 'between' && !item.queryConditionValue2ExpPattern) {
              this.$modal.msgWarning('过滤条件设定表格值2不能为空，请检查');
              return;
            }
          }

          const params = {
            ...this.detailInfo,
            userApiBindingList: this.$refs.bindingUser.tableData
          }

          if (this.detailInfo.apiMeta.apiStatus === 2) { // 当编辑已发布的api时，设置parentApiId为当前apiId,即会新增一条版本加1的api数据，
            let isClose = false;
            params.apiMeta.parentApiId = params.apiMeta.apiId;
            await this.$confirm('是否变更API版本？', '确认信息', {
              distinguishCancelAndClose: true,
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'warning'
            })
              .then(() => {
                params.apiMeta.changeApiVersion = 1;
              })
              .catch(action => {
                if (action === 'cancel') {
                  params.apiMeta.changeApiVersion = 0;
                } else {
                  isClose = true;
                }
              });
            if (isClose) {
              return;
            }
          }
          if (this.detailInfo.apiMeta.apiAsTemplate === 0) {
            params.apiMeta.apiAsBusinessTemplate = 0;
            params.apiMeta.apiTemplateName = '';
            params.apiMeta.apiTemplateCategory = '';
          } else {
            params.apiMeta.apiTemplateCategory = params.apiMeta.apiTemplateCategoryList.join(',');
          }
          // 不保存为数据模板时，重置跟其相关参数的值
          if (params.apiMeta.apiAsBusinessTemplate === 0) {
            params.apiMeta.apiDateType = '';
            params.apiMeta.apiDateTitle = '';
            params.apiMeta.apiShowUpdate = 0;
          }
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          if (this.helpDoc) {
            const fileInfo = {
              helpDoc: this.helpDoc,
              apiName: this.detailInfo.apiMeta.apiName
            }
            const formData = objectToFormData(fileInfo);
            uploadHelpDoc(formData).then((res) => {

            }).catch((e) => { loading.close(); });
          }

          saveOrUpdateApi(params).then((res) => {
            loading.close();
            if (res.code === 200) {
              this.$modal.msgSuccess(`${this.apiId ? '修改' : '新增'}成功`);
              this.$emit('saveSuccess');
              this.close();
            }
          }).catch((e) => { loading.close(); })
        }
      })
    },
    // 关闭
    close () {
      this.$emit('close');
    }
  },
}
</script>
<style lang="scss" scoped>
.api-edit-drawer {
  .api-content {
    padding: 0 20px 64px 20px;
    .sub-title {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }
}
.edit-mode {
  margin: 16px 0;
  overflow: hidden;
  .third-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.left-content {
  width: 360px;
  float: left;
  height: 1200px;
  border: 1px solid #e4e7ed;
  .tree-data {
    padding: 10px;
    height: 50%;
  }
}
.right-content {
  float: right;
  width: calc(100% - 380px);
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .node-label {
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .node-btn {
    display: none;
  }
}
.el-tree-node__content {
  &:hover {
    .node-btn {
      display: block;
    }
  }
}
.footer-btn {
  padding: 16px 20px 16px 0;
  text-align: right;
  position: absolute;
  bottom: 0;
  padding: 16px;
  background-color: #FFFFFF;
  z-index: 100;
  width: 100%;
}
.node-mark {
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  margin-right: 4px;
  background-color: #01579b;
  color: #FFFFFF;
  float: left;
}
.table-form {
  .el-form-item {
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  .el-form-item__content {
    width: 100%;
  }
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 120px;
  margin-left: 10px;
  vertical-align: bottom;
}
.columnOutputTitle-item {
  ::v-deep .el-form-item__content {
    width: 100%;
  }
}
::v-deep .cell-overflow .cell {
  overflow: visible;
}
</style>
<style lang="scss">

</style>
