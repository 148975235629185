import request from '@/utils/request';

// 查询模板树
export function listTemplateTree (params) {
  return request({
    url: '/DingLiApi/DataBrowser/listTemplateTree',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 查询可绑定api的列表
export function getCanBindApiList (params) {
  return request({
    url: '/DingLiApi/DataBrowser/getCanBindApiList',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 新增/修改模板
export function updateApiTemplate (data) {
  return request({
    url: '/DingLiApi/DataBrowser/updateApiTemplate',
    method: 'post',
    data
  })
}

// 删除模板
export function deleteTemplate (params) {
  return request({
    url: '/DingLiApi/DataBrowser/deleteTemplate',
    method: 'get',
    params: {
      ...params
    }
  })
}