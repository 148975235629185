var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-item",
      class: _vm.isSelected ? "selected" : "",
      on: { click: _vm.handleSelect },
    },
    [
      _c(
        "span",
        { staticClass: "select-item-label", attrs: { alt: _vm.name } },
        [_vm._v(_vm._s(_vm.name))]
      ),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSelected,
            expression: "isSelected",
          },
        ],
        staticClass: "selected-icon",
        attrs: {
          src: require("@/assets/sfdr/images/selected-icon.png"),
          alt: "",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }