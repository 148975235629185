var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instructions-box" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "span-method": _vm.objectSpanMethod,
            "row-class-name": _vm.tableRowClassName,
            "cell-class-name": _vm.addItemClass,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: _vm.$t("instructions.title1"),
              width: "170",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: _vm.$t("instructions.title2"),
              width: "210",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "desc", label: _vm.$t("instructions.title3") },
          }),
          _c(
            "el-table-column",
            { attrs: { width: "20", align: "left" } },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "div",
                  { staticClass: "el-icon-ins", on: { click: _vm.close } },
                  [
                    _c("svg-icon", {
                      attrs: {
                        slot: "prefix",
                        "icon-class": "instructions-close",
                      },
                      slot: "prefix",
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }