var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "review-menu" }, [
    _c("div", { staticClass: "menu-group" }, [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "menu-item",
          class: _vm.viewId === "main" ? "selected" : "",
          on: {
            click: function ($event) {
              _vm.viewId = "main"
            },
          },
        },
        [_vm._v(" Mandatory PAI ")]
      ),
      _c(
        "div",
        {
          staticClass: "menu-item",
          class: _vm.viewId === "option" ? "selected" : "",
          on: {
            click: function ($event) {
              _vm.viewId = "option"
            },
          },
        },
        [_vm._v(" Additional PAI ")]
      ),
      _c(
        "div",
        {
          staticClass: "menu-item",
          class: _vm.viewId === "summary" ? "selected" : "",
          on: {
            click: function ($event) {
              _vm.viewId = "summary"
            },
          },
        },
        [_vm._v(" Summary ")]
      ),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menu-group-title" }, [
      _c("img", {
        staticClass: "menu-icon",
        attrs: { src: require("@/assets/sfdr/images/file.png") },
      }),
      _vm._v(" PAI Calculation "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menu-group" }, [
      _c("div", { staticClass: "menu-group-title" }, [
        _c("img", {
          staticClass: "menu-icon",
          attrs: { src: require("@/assets/sfdr/images/file.png") },
        }),
        _vm._v(" Report Editor "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }