import { getAllStocks } from '@/api/data-platform/dataBrowser.js'
const platform = {
    state: {
        allStockList: [],
    },
    mutations: {
        SET_STOCK_LIST: (state, data) => {
            state.allStockList = data
        },
    },
    actions: {
        // 获取所有股票
        getAllStocks({ commit, state }) {
            // 调用接口，获取数据，存储数据
            return new Promise((resolve, reject) => {
                getAllStocks().then(res => {
                    const list = res.data
                    if (res.data && res.data.length > 0) { // 验证返回的roles是否是一个非空数组
                        commit('SET_STOCK_LIST', list)
                        resolve(list)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
export default platform