<template>
  <div
    :style="{
      'z-index': 9999,
      position: 'fixed',
      left: optionCardX + 25 + 'px',
      top: optionCardY + 'px',
      width: '120px',
      background: 'white',
      'box-shadow': '0 3px 4px rgba(0,0,0,.12),0 0 6px rgba(0,0,0,.04)',
    }"
    v-if="optionCardShow"
    id="option-button-group"
  >
    <el-button @click="remove" class="option-card-button" type="info" plain
      >{{$t('elbtn.deleteSelf')}}</el-button
    >
    <el-button @click="rename" class="option-card-button" type="info" plain
      >{{$t('elbtn.rename')}}</el-button
    >
  </div>
</template>
<script>
export default {
  name: "FolderOption",
  props: {
    optionCardX: {
      type: [Number, String],
      required: true,
    },
    optionCardY: {
      type: [Number, String],
      required: true,
    },
    // 是否显示弹框
    optionCardShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 删除
    remove() {
      this.$emit("remove");
    },
    // 重命名
    rename() {
      this.$emit("rename");
    },
  },
};
</script>
<style scoped lang="scss">
.option-card-button {
  color: #01579B;
  width: 100%;
  margin-left: 0;
  font-size: 10px;
  border-radius: 0;
}
</style>