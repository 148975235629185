var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "footprint_container",
    },
    [
      !_vm.isHidden
        ? _c("div", { staticClass: "footprint_title title_bg" }, [
            _vm._v(" " + _vm._s(_vm.$t("footprint.title1")) + " "),
          ])
        : _vm._e(),
      _vm.renderData && !_vm.isHidden
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.renderData.carbonFootprintAssessment,
                stripe: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "indicator",
                  label: _vm.$t("footprint.table1Th1"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "unit",
                  label: _vm.$t("footprint.table1Th2"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "value",
                  label: _vm.$t("footprint.table1Th3"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isHidden
        ? _c("div", { staticClass: "text" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("footprint.text1")) + " ")]),
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("footprint.text2")) + " ")]),
          ])
        : _vm._e(),
      !_vm.isHidden && _vm.flag !== "FootPrint"
        ? _c("div", { staticClass: "footprint_title title_bg" }, [
            _vm._v(" " + _vm._s(_vm.$t("footprint.title2")) + " "),
          ])
        : _vm._e(),
      _vm.renderData && !_vm.isHidden && _vm.flag !== "FootPrint"
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.renderData.sasbClassification, stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "sasb",
                  label: _vm.$t("footprint.table2Th1"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "amount",
                  label: _vm.$t("footprint.table2Th2"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "value",
                  label: _vm.$t("footprint.table2Th3"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "weightings",
                  label: _vm.$t("footprint.table2Th4"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "scope12Emission",
                  label: _vm.$t("footprint.table2Th5"),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "emissionShares",
                  label: _vm.$t("footprint.table2Th6"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isHidden && _vm.flag !== "FootPrint"
        ? _c("div", { staticClass: "footprint_title title_bg" }, [
            _vm._v(" " + _vm._s(_vm.$t("footprint.title3")) + " "),
          ])
        : _vm._e(),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.flag !== "FootPrint",
            expression: "flag !== 'FootPrint'",
          },
        ],
        ref: "imageDom",
        attrs: { id: "bar_box" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }