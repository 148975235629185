<template>
  <div class="FMCCCtop_container">
    <!--    默认展示的按钮、输入框-->
    <div class="main_box">
      <div class="upload_box" @click="handleUpload">
        {{ $t("FMCCTop.upload") }}
      </div>
      <div class="form_box">
        <!-- 输入框展示-->
        <el-form v-show="!isComputed" :inline="true" :model="baseForm" class="demo-form-inline">
          <el-form-item :label="$t('FMCCTop.date')" class="time">
            <el-date-picker v-model="baseForm.date" type="date" format="yyyy/MM/dd" value-format="yyyy/MM/dd"
              :picker-options="pickerOptionsStart">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="TNA" class="nav">
            <el-input v-model="baseForm.nav" placeholder="TNA"></el-input>
          </el-form-item>
          <el-form-item :label="$t('FMCCTop.unit')">
            <el-select v-model="baseForm.navCurrency" disabled>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('FMCCTop.name')">
            <el-input v-model="baseForm.fundName"></el-input>
          </el-form-item>
        </el-form>
        <!--        计算后展示结果-->
        <div class="res_box" v-show="isComputed">
          <div>
            <span v-show="baseForm.fundName">{{ $t("FMCCTop.name") }}: {{ baseForm.fundName }}</span>
            <span v-show="baseForm.date">
              {{ $t("FMCCTop.date") }}: {{ baseForm.date }}</span>
            <span v-show="baseForm.nav">TNA: {{ baseForm.nav }}</span>
          </div>

          <div v-if="isShowSenior">
            <span v-show="baseForm.relevanceThreshold">{{ $t("FMCCTop.content1") }}:
              {{ baseForm.relevanceThreshold }}%</span>
            <span v-show="baseForm.materialityThresholdOption1">{{ $t("FMCCTop.content1") }}:
              {{ baseForm.materialityThresholdOption1 }}%</span>
          </div>
          <div v-if="isShowSenior">
            <span v-show="baseForm.shadowDevideEbitdaOption2">{{ $t("FMCCTop.table2") }}:
              {{ baseForm.shadowDevideEbitdaOption2 }}%</span>
            <span v-show="baseForm.physicalThresholdOption2">{{ $t("FMCCTop.table3") }}:
              {{ baseForm.physicalThresholdOption2 }}%</span>
            <span v-show="baseForm.transitionThresholdBOption2">{{ $t("FMCCTop.table4") }}:
              {{ baseForm.transitionThresholdBOption2 }}%</span>
          </div>
        </div>
      </div>
      <!-- 计算按钮 -->
      <el-button class="compute_btn btn" @click="submit" v-loading.fullscreen.lock="fullscreenLoading">
        {{ $t("FMCCTop.calculate") }}
      </el-button>

      <el-button class="down_btn btn" @click="download">{{
        $t("FMCCTop.down")
      }}</el-button>
    </div>
    <!--    高级选项按钮-->
    <div class="more_box" v-if="isShowSenior">
      <div class="more" @click="showMore">
        <svg-icon v-show="!isActive" icon-class="db-down-arrow" />
        <svg-icon v-show="isActive" icon-class="db-up-arrow" />
        <span class="text">{{ $t("FMCCTop.more") }}</span>
      </div>
    </div>
    <!--    下拉展示的高级选项-->
    <div class="senior_box" :class="isActive ? 'active' : 'close'">
      <div class="senior_form">
        <div class="senior_title">
          {{ $t("FMCCTop.title1") }}
        </div>
        <div class="senior_content">
          <div class="content_left">
            {{ $t("FMCCTop.content1") }}
          </div>
          <div class="content_right">
            <el-input v-model.lazy="relevanceThreshold" clearable placeholder=""></el-input>
          </div>
        </div>
        <div class="senior_title">
          {{ $t("FMCCTop.title2") }}
        </div>
        <div class="senior_content">
          <div class="content_left">
            {{ $t("FMCCTop.content2") }}
          </div>
          <div class="content_right">
            <el-input v-model.lazy="materialityThresholdOption1" clearable placeholder=""></el-input>
          </div>
        </div>
        <div class="senior_table">
          <div class="senior_title">
            {{ $t("FMCCTop.title3") }}
          </div>
          <table class="table" cellspacing="0">
            <tr class="tr">
              <td></td>
              <td>{{ $t("FMCCTop.table1") }}</td>
              <td>
                {{ $t("FMCCTop.table2") }}
                <el-input v-model.lazy="shadowDevideEbitdaOption2" clearable placeholder=""></el-input>
              </td>
            </tr>
            <tr class="tr">
              <td>{{ $t("FMCCTop.table3") }}</td>
              <td>
                <el-input v-model.lazy="physicalThresholdOption2" clearable placeholder=""></el-input>
              </td>
              <td></td>
            </tr>
            <tr class="tr">
              <td>{{ $t("FMCCTop.table4") }}</td>
              <td></td>
              <td>
                <el-input v-model.lazy="transitionThresholdBOption2" clearable placeholder=""></el-input>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="btn_box">
        <el-button class="use_btn btn" @click="handleUse">{{
          $t("FMCCTop.useBtn")
        }}</el-button>
        <el-button class="cancel_btn btn" @click="handleCancel">{{
          $t("FMCCTop.cancelBtn")
        }}</el-button>
      </div>
    </div>
    <!--    下载报告时生成echarts图截图后传给后端-->
    <div class="echarts_box" v-if="downloadshow" ref="echarts_box">
      <Echarts :baseFormData="baseForm" :id="id" :fatherFlag="fatherFlag" :key="new Date().getTime()"
        @downloaded="downloaded"></Echarts>
    </div>
    <!-- 导入EXCEL模态框 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="460px">
      <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" :action="upload.url"
        :data="langData" :disabled="upload.isUploading" :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess" :auto-upload="false" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          {{ $t("tips.importTip") }}<em>{{ $t("tips.clickTip") }}</em>
        </div>
        <div class="el-upload__tip text-center" slot="tip">
          <span>{{ $t("tips.fitmitTip") }}</span>
          <el-link type="primary" :underline="false" style="font-size: 12px; vertical-align: baseline"
            @click="downloadTemplate">
            {{ $t("tips.downloadTemplate") }}
          </el-link>
          <!-- 英文 -->
          <!-- <el-link v-if="$store.getters.language === 'en'" type="primary" :underline="false"
            style="font-size: 12px; vertical-align: baseline"
            href="https://wiki.governance-solutions.com/zyplayer-doc-wiki/common/file?uuid=60c0a2f6bd3943f1a341f156dd0f2a3e">Download
            Template
          </el-link> -->
          <!-- 中文 -->
          <!-- <el-link v-if="$store.getters.language === 'zh'" type="primary" :underline="false"
            style="font-size: 12px; vertical-align: baseline"
            href="https://wiki.governance-solutions.com/zyplayer-doc-wiki/common/file?uuid=f887ffe5669b40588102957d16e2aaed">下载模板
          </el-link> -->
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">{{ $t("FMCCTop.confirmBtn") }}
        </el-button>
        <el-button class="right_btn" @click="upload.open = false">{{ $t("FMCCTop.cancelBtn") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 计算结果弹框 -->
    <el-dialog :title="vm.$t('FMCCTop.CalculateRes')" :visible.sync="open" class="msg-dialog" width="420px"
      :before-close="close">
      <p v-html="message"></p>
      <span slot="footer" class="dialog-footer">
        <!-- 联系我们 -->
        <el-button @click="connect" v-show="isShowBtn">{{
          $t("FMCCTop.contactBtn")
        }}</el-button>

        <el-button @click="close">{{ $t("FMCCTop.cancelBtn") }}</el-button>
        <el-button type="primary" @click="confirm">{{
          $t("FMCCTop.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Echarts from "./echarts";
import { getCoverage, sendMail } from "@/api/data-platform/FMCC";
import { getToken } from "@/utils/auth";
import moment from "moment";
import downloadMixin from '@/utils/mixin/downloadMixin'

let _this;

export default {
  name: "FMCCCtop",
  components: {
    Echarts,
  },
  props: {
    // 是否显示高级选项
    isShowSenior: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [downloadMixin],
  data() {
    const vm = window.vm; //多语言
    return {
      // 日期选择框限制选择
      pickerOptionsStart: {
        disabledDate: (time) => {
          const startTime = 1640880000000; // 2021-12-31时间戳
          return time.getTime() < startTime;
        },
      },
      downloadshow: false,
      loading: null,
      note: null,
      message: undefined, //用于弹框显示
      isShowBtn: false,
      open: false,
      isUsed: undefined,
      fullscreenLoading: false,
      vm: vm,
      // 导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: "Bearer " + getToken() },
        // 导入--自选清单
        url: process.env.VUE_APP_BASE_API + "/carbon-data/fmcc/uploadPortfolio",
      },
      id: null,
      baseForm: {
        date: null,
        nav: null,
        navCurrency: null,
        fundName: null,
        relevanceThreshold: 5,
        materialityThresholdOption1: 50,
        shadowDevideEbitdaOption2: 5,
        physicalThresholdOption2: 50,
        transitionThresholdBOption2: 10,
      },
      seniorForm: {
        relevanceThreshold: 5,
        materialityThresholdOption1: 50,
        shadowDevideEbitdaOption2: 5,
        physicalThresholdOption2: 50,
        transitionThresholdBOption2: 10,
      },
      isActive: false,
      isComputed: false,
      isUpload: false,
      options: [
        {
          value: "CNY",
          label: "1/CNY",
        },
        {
          value: "USD",
          label: "1/USD",
        },
        {
          value: "HKD",
          label: "1/HKD",
        },
        {
          value: "EUR",
          label: "1/EUR",
        },
      ],
      fatherFlag: "fmcc",
      hasDownloadTemplate: false,
      templateType: "fmcc",
      templateTitle: "",//下载模板题目
    };
  },
  created() {
    _this = this;
  },
  computed: {
    // 高级选项输入框自动加%符号
    relevanceThreshold: {
      get: () => {
        return _this.seniorForm.relevanceThreshold
          ? _this.seniorForm.relevanceThreshold + "%"
          : null;
      },
      set: (newValue) => {
        _this.seniorForm.relevanceThreshold =
          newValue.indexOf("%") !== -1 ? newValue.replace(/%/, "") : newValue;
      },
    },
    materialityThresholdOption1: {
      get: () => {
        return _this.seniorForm.materialityThresholdOption1
          ? _this.seniorForm.materialityThresholdOption1 + "%"
          : null;
      },
      set: (newValue) => {
        _this.seniorForm.materialityThresholdOption1 =
          newValue.indexOf("%") !== -1 ? newValue.replace(/%/, "") : newValue;
      },
    },
    shadowDevideEbitdaOption2: {
      get: () => {
        return _this.seniorForm.shadowDevideEbitdaOption2
          ? _this.seniorForm.shadowDevideEbitdaOption2 + "%"
          : null;
      },
      set: (newValue) => {
        _this.seniorForm.shadowDevideEbitdaOption2 =
          newValue.indexOf("%") !== -1 ? newValue.replace(/%/, "") : newValue;
      },
    },
    physicalThresholdOption2: {
      get: () => {
        return _this.seniorForm.physicalThresholdOption2
          ? _this.seniorForm.physicalThresholdOption2 + "%"
          : null;
      },
      set: (newValue) => {
        _this.seniorForm.physicalThresholdOption2 =
          newValue.indexOf("%") !== -1 ? newValue.replace(/%/, "") : newValue;
      },
    },
    transitionThresholdBOption2: {
      get: () => {
        return _this.seniorForm.transitionThresholdBOption2
          ? _this.seniorForm.transitionThresholdBOption2 + "%"
          : null;
      },
      set: (newValue) => {
        _this.seniorForm.transitionThresholdBOption2 =
          newValue.indexOf("%") !== -1 ? newValue.replace(/%/, "") : newValue;
      },
    },
    lang() {
      return this.$store.getters.language;
    },
    // 上传组件参数
    // 初次上传加上用户输入的表单数据，再次上传表单置空，不用上传
    langData() {
      if (this.isComputed) {
        return {
          lang: this.$store.getters.language,
        };
      } else {
        return {
          lang: this.$store.getters.language,
          ...this.baseForm,
        };
      }
    },
  },
  watch: {
    lang: {
      handler(newV, oldV) {
        this.templateTitle = this.$t("tips.templateTitleFMCC")
        if (oldV !== newV) {
          if (!newV) {
            this.getNode(); //根据当前语言，重新获取底部note
          } else {
            this.getNode();
          }
          this.hasDownloadTemplate = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    //下载报告结束
    downloaded() {
      this.downloadshow = false;
      this.loading.close();
    },
    // 下载报告
    download() {
      if (this.isComputed) {
        this.loading = this.$loading({
          lock: true,
          text:
            this.lang == "zh"
              ? "正在生成fmcc报告，请耐心等待 。。。。"
              : "FMCC report is being generated, please wait patiently....",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.downloadshow = true;
      } else {
        this.$message({
          message:
            this.lang == "zh"
              ? "请先上传投资组合并计算"
              : "Please upload the portfolio and calculate it first",
          type: "warning",
        });
      }
    },
    // 文件上传中处理---样式调整
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 点击上传
    handleUpload() {
      if (this.isComputed) {
        // console.log("zhikong");
        this.baseForm = {
          relevanceThreshold: 5,
          materialityThresholdOption1: 50,
          shadowDevideEbitdaOption2: 5,
          physicalThresholdOption2: 50,
          transitionThresholdBOption2: 10,
          date: null,
          nav: null,
          navCurrency: null,
          fundName: null,
        };
      }
      this.upload.open = true;
    },
    // 导入成功处理
    // 将dangerouslyUseHTMLString属性设置为 true， response.msg 就会被当作 HTML 片段处理
    handleFileSuccess(response, file, fileList) {
      this.dataBrowserList = [];
      this.upload.open = false; //上传成功，关闭弹出框
      this.upload.isUploading = false; //状态
      this.$refs.upload.clearFiles();

      // 提示信息
      this.$alert(
        "<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" +
        response.msg +
        "</div>",
        this.vm.$t("FMCCTop.uploadRes"),
        { dangerouslyUseHTMLString: true }
      );

      // 渲染结果区
      if (
        response.code == 200 &&
        response.data.id !== "" &&
        response.data.id !== null
      ) {
        this.id = response.data.id;
        // this.id = 1
        this.isUpload = true;
        this.isComputed = false;
        this.baseForm = {
          relevanceThreshold: 5,
          materialityThresholdOption1: 50,
          shadowDevideEbitdaOption2: 5,
          physicalThresholdOption2: 50,
          transitionThresholdBOption2: 10,
          date: this.baseForm.date
            ? this.baseForm.date
            : moment(response.data.date).format("YYYY/MM/DD"),
          nav: this.baseForm.nav ? this.baseForm.nav : response.data.nav,
          navCurrency: this.baseForm.navCurrency
            ? this.baseForm.navCurrency
            : response.data.navCurrency,
          fundName: this.baseForm.fundName
            ? this.baseForm.fundName
            : response.data.fundName,
        };
        this.seniorForm = {
          relevanceThreshold: 5,
          materialityThresholdOption1: 50,
          shadowDevideEbitdaOption2: 5,
          physicalThresholdOption2: 50,
          transitionThresholdBOption2: 10,
        };
        // console.log(this.baseForm);
        this.$emit("uploaded", this.id, this.baseForm);
      }
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
    // 计算按键。isUsed：是否应用高级选项数据
    submit(event, isUsed) {
      this.isUsed = isUsed;
      if (!this.isUpload) {
        this.$message({
          message:
            this.lang == "zh"
              ? "请先上传投资组合"
              : "Please upload the portfolio first",
          type: "warning",
        });
        return;
      }
      this.fullscreenLoading = true;
      // 弹窗
      getCoverage({
        id: this.id,
        lang: this.lang,
        ...this.baseForm,
      }).then((res) => {
        this.note = null;
        if (res.msg.indexOf("Warning") != -1 || res.msg.indexOf("警告") != -1) {
          this.note = res.msg;
          this.isShowBtn = true;
        } else {
          this.isShowBtn = false;
        }
        this.message = res.msg; //用于弹框显示
        this.open = true;

        // this.confirm(res.msg, this.vm.$t("FMCCTop.CalculateRes"), {
        //   confirmButtonText: this.lang == "zh" ? "确定" : "Confirm",
        //   cancelButtonText: this.lang == "zh" ? "取消" : "Cancel",
        //   dangerouslyUseHTMLString: true,
        // })
        //   .then(() => {
        //     // 默认高级选项数据添加到基础表单中
        //     this.fullscreenLoading = false;
        //     if (isUsed) {
        //       this.baseForm = {
        //         ...this.baseForm,
        //         ...this.seniorForm,
        //       };
        //     }
        //     this.isActive = false;
        //     this.isComputed = true;
        //     this.$emit("computed", this.baseForm, this.note);
        //   })
        //   .catch(() => {
        //     this.note = null;
        //     this.fullscreenLoading = false;
        //   });
      });
    },
    // 点击确定
    confirm() {
      // 默认高级选项数据添加到基础表单中
      this.fullscreenLoading = false;
      if (this.isUsed) {
        this.baseForm = {
          ...this.baseForm,
          ...this.seniorForm,
        };
      }

      this.isActive = false;
      this.open = false;
      this.isComputed = true;
      this.$emit("computed", this.baseForm, this.note);
    },
    // 点击联系我们
    connect() {
      sendMail({
        id: this.id,
        lang: this.lang,
        date: this.baseForm.date,
        fundName: this.baseForm.fundName,
        warnInfo: this.note, //警告信息
      })
        .then((res) => {
          this.$message({
            message: this.lang == "zh" ? "已发送邮件" : "Email sent",
            type: "success",
          });
          this.open = false;
          this.note = null;
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //点击取消
    close() {
      this.open = false;
      this.note = null;
      this.fullscreenLoading = false;
    },

    // 根据当前语言，重新获取底部note
    getNode() {
      if (this.id && this.baseForm !== null) {
        getCoverage({
          id: this.id,
          lang: this.lang,
          ...this.baseForm,
        })
          .then((res) => {
            this.note = null;
            if (
              res.msg.indexOf("Warning") != -1 ||
              res.msg.indexOf("警告") != -1
            ) {
              this.note = res.msg;
              this.isShowBtn = true;
            } else {
              this.isShowBtn = false;
            }
            this.message = res.msg; //用于弹框显示
            this.$emit("computedNote", this.note);
          })
          .catch(() => {
            this.note = null;
          });
      }
    },
    // 下拉
    showMore() {
      this.isActive = !this.isActive;
    },
    // 高级选项
    handleUse() {
      if (!this.isUpload) {
        this.$message({
          message:
            this.lang == "zh"
              ? "请先上传投资组合"
              : "Please upload the portfolio first",
          type: "warning",
        });
        return;
      }
      this.submit(null, true);
    },
    // 取消按钮，高级选项重置
    handleCancel() {
      this.seniorForm = {
        relevanceThreshold: this.baseForm.relevanceThreshold
          ? this.baseForm.relevanceThreshold
          : 5,
        materialityThresholdOption1: this.baseForm.materialityThresholdOption1
          ? this.baseForm.materialityThresholdOption1
          : 50,
        shadowDevideEbitdaOption2: this.baseForm.shadowDevideEbitdaOption2
          ? this.baseForm.shadowDevideEbitdaOption2
          : 5,
        physicalThresholdOption2: this.baseForm.physicalThresholdOption2
          ? this.baseForm.physicalThresholdOption2
          : 50,
        transitionThresholdBOption2: this.baseForm.transitionThresholdBOption2
          ? this.baseForm.transitionThresholdBOption2
          : 10,
      };
      this.$message({
        message: this.lang == "zh" ? "已重置" : "Has been reset",
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-message-box__wrapper {
  background-color: rgba(0, 0, 0, 0.5);
}

.echarts_box {
  position: absolute;
  top: 100vh;
  width: 100%;
}

.FMCCCtop_container {
  position: relative;
  width: 100%;
  //height: 126px;
  background: #dfebf9;
  border-radius: 6px;
  padding-top: 30px;
  padding-bottom: 10px;

  .main_box {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    height: 40px;

    .upload_box {
      //width: 140px;
      height: 40px;
      padding: 0 20px;
      margin-right: 20px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      font-family: "PingFang SC";
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      color: #2d5380;
      cursor: pointer;

      &:hover {
        background-color: #4598f7;
        color: #ffffff;
        border: 1px solid #4598f7;
      }
    }

    .form_box {
      display: flex;
      align-items: center;
      width: 770px;
      height: 80px;
      margin-right: 15px;
      font-family: "PingFang SC";
      font-size: 14px;
      color: #1f5080;

      .res_box {
        margin-top: 0;

        span {
          margin-right: 30px;
        }
      }
    }

    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    ::v-deep .el-form-item__label {
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 14px;
      color: #1f5080;
    }

    .time {
      .el-input {
        width: 140px;
      }
    }

    .nav {
      .el-input {
        width: 110px;
      }
    }

    ::v-deep .el-input {
      width: 100px;
    }

    ::v-deep .el-input__inner {
      height: 30px;
      color: #1f5080;
      font-family: "PingFang SC";
    }

    .btn {
      width: 110px;
      height: 40px;
      border-radius: 6px;
      font-family: "PingFang SC";
      font-size: 14px;
    }

    .compute_btn {
      margin-right: 20px;
      background: #4598f7;
      color: #ffffff;
    }

    .down_btn {
      margin-left: 0;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      color: #2d5380;

      &:hover {
        background: #4598f7;
        border: 1px solid #4598f7;
        color: #ffffff;
      }
    }
  }

  .more_box {
    position: relative;
    width: 1200px;
    margin: 15px auto 0 auto;
    height: 30px;
    //margin-top: 15px;
    font-family: "PingFang SC";
    font-size: 14px;
    color: #2d5380;

    .more {
      width: 150px;
      height: 30px;
      line-height: 40px;
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
    }

    .text {
      margin-left: 6px;
    }
  }

  .senior_box {
    height: 0;
    overflow: hidden;

    .senior_title {
      height: 50px;
      background-color: #ffffff;
      text-align: center;
      font-family: "PingFang SC";
      font-weight: 900;
      font-size: 16px;
      line-height: 50px;
      color: #01579b;
    }

    .senior_content {
      display: flex;
      justify-content: center;
      height: 40px;
      font-family: "PingFang SC";
      font-weight: 900;
      font-size: 14px;
      line-height: 40px;
      color: #01579b;
      background: #dfebf9;

      .content_left {
        margin-right: 680px;
      }

      .content_right {
        ::v-deep .el-input {
          width: 90px;

          ::v-deep .el-input__inner {
            height: 30px;
            text-align: center;
          }
        }
      }
    }

    .senior_table {
      background-color: #ffffff;

      .table {
        width: 1044px;
        height: 120px;
        margin: 0 auto;
        border-bottom: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
        background: #f7f8fb;

        td {
          height: 40px;
          border-left: 1px solid #d9d9d9;
          border-top: 1px solid #d9d9d9;
          text-align: center;
          font-family: "PingFang SC";
          font-weight: 900;
          font-size: 14px;
          color: #1f5080;

          ::v-deep .el-input {
            width: 90px;

            ::v-deep .el-input__inner {
              height: 30px;
              text-align: center;
            }
          }
        }
      }
    }

    .btn_box {
      display: flex;
      justify-content: center;
      padding-top: 20px;
      height: 140px;
      background-color: #ffffff;

      .btn {
        width: 137px;
        height: 40px;
        font-family: "PingFang SC";
        font-size: 16px;
        border-radius: 6px;
      }

      .use_btn {
        background: #4598f7;
        color: #ffffff;
      }

      .cancel_btn {
        margin-left: 24px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        color: #2d5380;
      }
    }
  }

  .active {
    height: 440px;
    padding-top: 10px;
    transition: all 0.3s ease-in-out;
  }

  .close {
    height: 0;
    padding-top: 0;
    transition: all 0.3s ease-in-out;
  }

  ::v-deep .el-dialog__body {
    padding: 30px 50px;
  }

  ::v-deep .el-message-box__content {
    padding: 10px 25px;
  }

  .dialog-footer {
    width: 360px;
    margin: 0 auto 20px auto;
    text-align: center;

    button {
      width: 170px;

      &.right_btn {
        margin-left: 20px;
      }
    }
  }

  ::v-deep .el-upload-dragger {
    background: linear-gradient(0deg, #f4f9ff 0%, rgba(223, 235, 249, 0) 100%);
  }

  // 计算结果弹框
  .msg-dialog {
    ::v-deep .el-dialog__body {
      padding: 10px 15px;
    }

    .dialog-footer button {
      width: unset;
    }
  }
}
</style>
