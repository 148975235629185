var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mandatory-data-wrapper" },
    [
      _c(
        "el-table",
        {
          ref: "table",
          staticClass: "mandatory-data-table",
          attrs: {
            data: _vm.data,
            size: "mini",
            width: "100%",
            height: _vm.height || "calc(100%)",
            "max-height": _vm.height || "calc(100%)",
            "header-cell-class-name": "header-cell",
            "cell-style": _vm.addCellStyle,
            "header-cell-style": _vm.headerStyle,
            "span-method": _vm.arraySpanMethod,
            "row-class-name": _vm.addRowClass,
            "cell-class-name": _vm.addCellClass,
            border: true,
          },
          on: { "cell-click": _vm.cellClickHandler },
        },
        [
          _vm._l(_vm.columns, function (col) {
            return _c("el-table-column", {
              key: col.prop,
              attrs: {
                prop: col.prop,
                label: col.label,
                fixed: col.fixed,
                "min-width": "" + col.width,
                "class-name": col.className,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function (ref) {
                      var column = ref.column
                      return [
                        _vm._v(" " + _vm._s(column.label) + " "),
                        column.property.startsWith("coverage")
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    "Coverage = Σ(Current Value of securities, which the underlying data is available) / Total Current Value for the portfolio",
                                  placement: "bottom",
                                  transition: null,
                                },
                              },
                              [_c("i", { staticClass: "el-icon-question" })]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("span", { attrs: { slot: "empty" }, slot: "empty" }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }