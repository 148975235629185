<template>
  <div class="login-box">
    <div class="top">
      <!-- logo -->
      <div class="logo_box"></div>

      <!-- 中英文切换 -->
      <!-- <div class="language_box">
        <div class="el-icon-dl">
          <svg-icon slot="prefix" icon-class="login-earth" />
        </div>
        <lang-select class="set-language" />
      </div> -->
    </div>

    <div class="login" ref="login">
      <div class="el-login-center">
        <!-- 口号 -->
        <div class="main_box">
          <div class="main_title" v-show="language === 'zh'">鼎力可持续数据平台</div>
          <div class="main_title_en" v-show="language === 'en'">GSG Sustainability Data Platform</div>
          <!-- 毛笔字 -->
          <div class="main_img" v-show="language === 'zh'"></div>
          <div class="main_img_en" v-show="language === 'en'"></div>

          <div class="subhead" v-if="language === 'zh'">
            <span>影响和推动企业可持续健康发展</span>
            <span>帮助金融机构做出更好的分析决策</span>
          </div>
          <div v-else class="subhead_en">
            <p>
              Influencing and accelerating the sustainable development of
              enterprises
            </p>
            <p>
              Assisting financial institutions in making better investment
              analyzes
            </p>
          </div>

          <div class="label_box" v-if="language === 'zh'">
            <div class="label_box_zh_1">
              <div class="label_item row_first">
                <div class="label_img"></div>
                <div class="label_text">创新</div>
              </div>
              <div class="label_item">
                <div class="label_img"></div>
                <div class="label_text">研究+数据</div>
              </div>
              <div class="label_item">
                <div class="label_img"></div>
                <div class="label_text">风险评估</div>
              </div>
              <div class="label_item lang_item">
                <div class="label_img"></div>
                <div class="label_text">管理软件</div>
              </div>
              <div class="label_item">
                <div class="label_img"></div>
                <div class="label_text">评价方法</div>
              </div>
            </div>
            <div class="label_box_zh_2">
              <div class="label_item row_first">
                <div class="label_img"></div>
                <div class="label_text">优化</div>
              </div>
              <div class="label_item">
                <div class="label_img"></div>
                <div class="label_text">技术+模型</div>
              </div>
              <div class="label_item">
                <div class="label_img"></div>
                <div class="label_text">风险管理</div>
              </div>
              <div class="label_item">
                <div class="label_img"></div>
                <div class="label_text">SaaS平台</div>
              </div>
              <div class="label_item">
                <div class="label_img"></div>
                <div class="label_text">指标体系</div>
              </div>
            </div>
          </div>

          <!-- 英文 -->
          <div class="label_box_en label_box" v-else>
            <div class="label_box_zh_1">
              <div>
                <div class="label_item row_first">
                  <div class="label_img"></div>
                  <div class="label_text">Innovation</div>
                </div>
                <div class="label_item row_first">
                  <div class="label_img"></div>
                  <div class="label_text">Optimization</div>
                </div>
              </div>

              <div>
                <div class="label_item">
                  <div class="label_img"></div>
                  <div class="label_text">Research+Data</div>
                </div>
                <div class="label_item">
                  <div class="label_img"></div>
                  <div class="label_text">Technoloy+Model</div>
                </div>
              </div>

              <div>
                <div class="label_item">
                  <div class="label_img"></div>
                  <div class="label_text">Risk Assessment</div>
                </div>
                <div class="label_item">
                  <div class="label_img"></div>
                  <div class="label_text">Risk Management</div>
                </div>
              </div>

              <div>
                <div class="label_item lang_item">
                  <div class="label_img"></div>
                  <div class="label_text">Management Software</div>
                </div>
                <div class="label_item">
                  <div class="label_img"></div>
                  <div class="label_text">SaaS Platform</div>
                </div>
              </div>

              <div>
                <div class="label_item">
                  <div class="label_img"></div>
                  <div class="label_text">Evaluation Method</div>
                </div>
                <div class="label_item">
                  <div class="label_img"></div>
                  <div class="label_text">Indicator System</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
        >
          <div class="title_box">
            <div class="title">{{ $t("login.label") }}</div>
          </div>
          <!-- 用户名 -->
          <el-form-item prop="username" class="username">
            <el-input
              v-model="loginForm.username"
              type="text"
              auto-complete="off"
              :placeholder="$t('login.username')"
            >
              <div slot="prefix" class="input_icon user_icon"></div>
            </el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password" class="password">
            <el-input
              v-model="loginForm.password"
              type="password"
              auto-complete="off"
              :placeholder="$t('login.password')"
              @keyup.enter.native="handleLogin"
            >
              <div slot="prefix" class="input_icon password_icon"></div>
            </el-input>
          </el-form-item>

          <!-- 验证码 -->
          <el-form-item prop="code" v-if="captchaOnOff">
            <el-input
              v-model="loginForm.code"
              auto-complete="off"
              :placeholder="$t('login.verifyCode')"
              style="width: 63%"
              @keyup.enter.native="handleLogin"
            >
            </el-input>
            <div class="login-code">
              <img :src="codeUrl" @click="getCode" class="login-code-img" />
            </div>
          </el-form-item>

          <!-- 登录按钮 -->
          <el-form-item style="width: 100%" class="submit">
            <el-button
              :loading="loading"
              size="medium"
              type="primary"
              class="submit_btn"
              @click.native.prevent="handleLogin"
            >
              <span v-if="!loading">{{ $t("login.logIn") }}</span>
              <span v-else>{{ $t("login.status") }}</span>
            </el-button>
            <div style="float: right" v-if="register">
              <router-link class="link-type" :to="'/register'"
                >立即注册</router-link
              >
            </div>
          </el-form-item>
          <el-checkbox v-model="loginForm.rememberMe" class="check_item">{{
            $t("login.rememberPsw")
          }}</el-checkbox>
        </el-form>
      </div>

      <!--  底部  -->
      <div class="el-login-footer">
        <!-- 官方客服 -->
        <span class="text">{{ $t("login.connect") }}</span>
        <!-- 公司 -->
        <span>Copyright by © 2022 鼎力可持续数字科技（深圳）有限公司</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import LangSelect from "@/components/LangSelectDL_V2/index.vue";
const vm = window.vm; //多语言

//校验验证码
let validateCode = (rules, value, callback) => {
  if (!value) {
    return callback(new Error(vm.$t("login.rulesCode")));
  } else {
    return callback();
  }
};
//校验用户名
let validateAccount = (rules, value, callback) => {
  if (!value) {
    return callback(new Error(vm.$t("login.rulesAccount")));
  } else {
    return callback();
  }
};
//校验密码
let validatePsw = (rules, value, callback) => {
  if (!value) {
    return callback(new Error(vm.$t("login.rulesPws")));
  } else {
    return callback();
  }
};
export default {
  name: "Login",
  components: {
    LangSelect,
  },
  data() {
    return {
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      // loginRules: {
      //   username: [
      //     { required: true, trigger: "blur", message: this.$store.getters.language == "zh" ? "请输入您的账号" : "Please input user name"},
      //   ],
      //   password: [
      //     { required: true, trigger: "blur", message: this.$store.getters.language == "zh" ? "请输入您的密码" : "Please input password" },
      //   ],
      //   code: [{ required: true, trigger: "change", message:  this.$store.getters.language == "zh" ? "请输入您的密码" : "Please input password"}],
      // },
      loginRules: {
        username: [{ validator: validateAccount, trigger: "blur" }],
        password: [{ validator: validatePsw, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
      },
      loading: false,
      // 验证码开关
      captchaOnOff: true,
      // 注册开关
      register: false,
      redirect: undefined,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
    language: {
      handler(newV, oldV) {
        if (oldV !== newV) {
          this.reset(); //监听语言变化，重置表单校验
        }
      },
    },
  },
  created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.captchaOnOff =
          res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password:
          password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), {
              expires: 30,
            });
            Cookies.set("rememberMe", this.loginForm.rememberMe, {
              expires: 30,
            });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rememberMe");
          }
          this.$store
            .dispatch("Login", this.loginForm)
            .then(() => {
              this.$router.push({ path: this.redirect || "/" }).catch(() => {});
            })
            .catch(() => {
              this.loading = false;
              if (this.captchaOnOff) {
                this.getCode();
              }
            });
        }
      });
    },
    // 重置表单校验
    reset() {
      // this.resetForm("loginForm");
      this.$refs["loginForm"].clearValidate();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.login-box {
  height: 100%;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  .logo_box {
    // position: absolute;
    // top: 30px;
    // left: 56px;
    width: 120px;
    height: 20px;
    background-image: url("../assets/login/LOGO.svg");
    // background-image: url("../assets/login/logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .language_box {
    .set-language {
      margin-left: 5px;
      display: inline;
    }
    ::v-deep .lang-dropdown {
      top: 24px !important;
    }
  }
}
.login {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  // align-items: center;
  position: relative;
  height: 100%;
  background-image: url("../assets/login/bg.jpg");
  background-size: cover;
  .el-login-center {
    display: flex;
    justify-content: space-evenly;
  }
}

.main_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // 中文标题
  .main_title, .main_title_en {
    margin-bottom: 26px;
    font-family: "PingFang SC";
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #384fbe;
  }

  // 英文标题
  .main_title_en{
    margin-bottom: 14px;
    font-size: 30px;
  }

  .main_img {
    width: 489px;
    height: 64px;
    margin-bottom: 35px;
    background-image: url("../assets/login/title.png");
    background-size: cover;
  }
  .main_img_en {
    width: 489px;
    height: 64px;
    // margin-bottom: 35px;
    background-image: url("../assets/login/login-title-en.png");
    background-repeat: no-repeat;
    background-size: 100% 60%;
  }
  // 中文的标语
  .subhead {
    display: flex;
    margin-bottom: 32px;
    font-family: "PingFang SC";
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    color: #384fbe;
    span {
      display: flex;
      align-items: center;
      margin-right: 6px;
    }

    span::before {
      display: inline-block;
      margin-right: 5px;
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #384fbe;
    }
  }

  // 英文的标语
  .subhead_en {
    margin-bottom: 32px;
    font-family: "PingFang SC";
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    color: #384fbe;
    p::before {
      display: inline-block;
      margin-right: 5px;
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #384fbe;
    }
  }

  .label_box {
    // width: 500px;
    // display: flex;
    // flex-wrap: wrap;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .label_box_zh_1,
    .label_box_zh_2{
      display: flex;
    }
    .label_item {
      display: flex;
      justify-content: left;
      align-items: center;
      margin: 0 19px 13px 0;
      &.row_first {
        margin-left: 12px;
      }
      &.lang_item {
        margin-right: 23px;
      }

      .label_img {
        width: 17px;
        height: 17px;
        margin-right: 8px;
        background-image: url("../assets/login/icon.svg");
        background-size: cover;
      }

      .label_text {
        font-family: "PingFang SC";
        font-size: 14px;
        line-height: 20px;
        color: #384fbe;
      }
    }
  }
  .label_box_en {
    .label_item {
       margin: 0 13px 13px 0;
      .label_text {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}

.login-form {
  //position: absolute;
  //top: 50%;
  //transform: translateY(-50%);
  //left: 1211px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 1px 1px 4px 2px rgba(12, 56, 100, 0.12);
  // width: 420px;
  // height: 420px;
  width: 360px;
  height: 388px;
  padding: 30px 30px 5px 34px;

  .title_box {
    display: flex;
    margin-bottom: 24.5px;

    .title {
      width: 73px;
      padding-bottom: 14px;
      border-bottom: 2px solid #384fbe;
      font-family: "Corbel";
      font-size: 18px;
      color: #384fbe;
    }

    .title_line {
      flex: 1;
      border-bottom: 1px solid #dcdcdc;
    }
  }

  .el-form-item {
    margin-bottom: 12px;
  }

  .username,
  .password {
    .el-input {
      height: 48px;

      input {
        height: 48px;
        background: #f4f4f4;
      }
    }

    ::v-deep .el-input__inner {
      padding-left: 40px;
    }
  }

  ::v-deep.el-input {
    height: 48px;

    input {
      height: 48px;
      border-radius: 6px;
    }
  }

  ::v-deep .el-input__prefix {
    display: flex;
    align-items: center;
  }

  .input_icon {
    height: 20px;
    width: 20px;
    margin: 0 6px;
    //margin-left: 2px;
  }

  .user_icon {
    background-image: url("../assets/login/username.png");
    background-size: cover;
  }

  .password_icon {
    background-image: url("../assets/login/password.png");
    background-size: cover;
  }

  .el-checkbox__inner {
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &::after {
      height: 8px;
      left: 5px;
      top: 1px;
    }
  }
}

.submit_btn {
  margin-top: 10px;
  width: 100%;
  height: 48px;
  background: #384fbe;
  border-radius: 6px;
  border: none;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.check_item {
  margin: 0px 0px 0px 0px;

  .el-checkbox__label {
    font-family: "Corbel";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #606060;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 48px;
  float: right;

  img {
    height: 43px;
    margin-top: 2px;
    cursor: pointer;
    vertical-align: middle;
  }
}

.el-login-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // position: absolute;
  // bottom: 20px;
  // bottom:80px;
  font-family: "PingFang SC";
  font-size: 12px;
  line-height: 20px;
  color: #384fbe;
  margin-bottom: 20px;
  .text {
    margin-bottom: 15px;
    font-family: "PingFang SC";
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: #384fbe;
  }
}

// 大屏幕
@media (min-width: 1281px) {
  .el-login-footer {
    position: absolute;
    bottom: 80px;
    margin-bottom: unset;
  }
}
</style>
