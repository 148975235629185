import Picker from '../picker';
import DatePanel from '../panel/date';
import DateRangePanel from '../panel/date-range';
import MonthRangePanel from '../panel/month-range';

const getPanel = function(type) {
  if (type === 'daterange' || type === 'datetimerange') {
    return DateRangePanel;
  } else if (type === 'monthrange') {
    return MonthRangePanel;
  }
  return DatePanel;
};

export default {
  mixins: [Picker],

  name: 'ElDatePicker',

  props: {
    rightCurrentTime: {
      type: Boolean,
      default: () => false
    },//******获取传入的值******
    type: {
      type: String,
      default: 'date'
    },
    timeArrowControl: Boolean
  },

  watch: {
    type(type) {
      if (this.picker) {
        this.unmountPicker();
        this.panel = getPanel(type);
        this.mountPicker();
      } else {
        this.panel = getPanel(type);
      }
    },
    rightCurrentTime: {
      handler (val) {
        this.rightshowCurrentTime = val;
        if (this.picker) {
          this.picker.rightCurrentTime = this.rightshowCurrentTime;//******将值往下传递******
        }
      },
      immediate: true
    }
  },

  created() {
    this.panel = getPanel(this.type);
  }
};
