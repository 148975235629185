export const riskTypeList = [
  {
    value: '1',
    label: '总物理风险',
    key: 'total'
  },
  {
    value: '2',
    label: '作物歉收',
    key: 'pec'
  },
  {
    value: '3',
    label: '海平面上升',
    key: 'slr'
  },
  {
    value: '4',
    label: '水压力',
    key: 'bws'
  },
  {
    value: '5',
    label: '干旱',
    key: 'drr'
  },
  {
    value: '6',
    label: '热应激反应',
    key: 'ec1'
  },
  {
    value: '7',
    label: '热浪',
    key: 'peh'
  },
  {
    value: '8',
    label: '最大气温升高',
    key: 'tas'
  },
  {
    value: '9',
    label: '林火',
    key: 'lew'
  },
  {
    value: '10',
    label: '洪涝',
    key: 'fldfrc'
  }
]