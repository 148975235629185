<template>
  <div class="worldMapBoard">
    <div id="worldMap" class="worldMap"></div>
    <div class="top-content">
      <div class="logo">
        <img style="height: 3.375rem;" src="../../assets/images/HSBC-logo.png" @click="openOfficialWebsite('HSBC')" />
        <el-divider direction="vertical" />
        <img style="height: 1.8rem;margin-right: 1.125rem;" src="../../assets/images/SEE-logo.png" @click="openOfficialWebsite('SEE')" />
        <el-divider direction="vertical" />
        <img style="height: 1.2rem;" src="../../assets/images/dl-logo1.png" @click="openOfficialWebsite('DL')" />
      </div>
      <el-tooltip :content="userPhone">
        <img class="user" src="../../assets/images/user.png"/>
      </el-tooltip>
      <img style="width: 100%;max-height: 90px;" src="../../assets/images/map-head.png"/>
      <div class="title">物理风险交互式地图集</div>
    </div>
    <div class="filter-area">
      <el-select v-model="statisticType" style="width: 240px;" placeholder="请选择" @change="statisticTypeChange">
        <el-option v-for="item in riskTypeList" :key="item.key" :value="item.key" :label="item.label"></el-option>
      </el-select>
      <el-input placeholder="请输入经度" v-model="longitude" style="width: 100px; margin-left: 20px;"></el-input>
      <el-input placeholder="请输入纬度" v-model="latitude" style="width: 100px; margin: 0 10px;"></el-input>
      <el-button type="primary" @click="search">搜索</el-button>
    </div>
    <div class="legend">
      <div style="font-size: 14px;color: #323233"><i class="el-icon-warning-outline"></i>&nbsp;风险等级</div>
      <div class="linear-gradient"></div>
      <div class="level">
        <div>高风险</div>
        <div>中高风险</div>
        <div>中风险</div>
        <div>中低风险</div>
        <div>低风险</div>
      </div>
      <div class="mark-area">
        <div class="mark" style="background-color: #969799;"></div>
        无风险
      </div>
      <div class="mark-area">
        <div class="mark"></div>
        无数据
      </div>
    </div>
    <div class="bottom-area">
      <a href="/about-data" target="_blank">关于数据</a>
      <a href="/about-project" target="_blank">关于本项目</a>
      <a href="https://governance-solutions.com/" target="_blank">关于我们</a>
      <a href="https://l7.antv.antgroup.com/tutorial/l7" target="_blank">@AntVL7</a>
      <a href="https://lbs.amap.com/" target="_blank">@高德地图</a>
    </div>
    <div v-show="show" class="location-detail" >
      <div class="detail-top">
        <img src="@/assets/images/location_1.png" />&nbsp;&nbsp;
        <div style="font-size: 14px;margin-right: 30px;">{{ `${currentLocation}（${(currentLngLat.lng).toFixed(1) || '-'}, ${(currentLngLat.lat).toFixed(1) || '-'}）` }}</div>
        <i class="el-icon-close" @click="show = false"></i>
      </div>
      <div class="radar">
        <div class="risk-name" :style="{'background-color': coordInfo.color, color: coordInfo.name === '低风险' ? '#323232' : '#fff'}">{{ coordInfo.name }}</div>
        <div id="echart" class="echart"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Scene, Source, PolygonLayer, Zoom, Marker, LayerPopup, PointLayer, Popup, ImageLayer } from '@antv/l7';
import { GaodeMap } from '@antv/l7-maps';
import * as echarts from 'echarts';
// import { pointData } from '../../../public/dataSource.js';
import { riskTypeList } from './constant.js';
import location from '@/assets/images/location.png';
import { loadJS } from '@/utils/index.js';

var fullPopup = null
var fullSource = null
var fullScene = null
var imageLayer = null
export default {
  data () {
    return {
      pointData: [],
      riskTypeList: riskTypeList,
      statisticType: 'total', // 统计类型
      riskLevel: '', // 风险等级
      longitude: '',
      latitude: '',
      coordinate: { x: 500, y: 500 },
      coordInfo: {
        color: '',
        name: ''
      }, // 选中经纬度的信息
      currentLngLat: { lng: 0, lat: 0}, // 当前经纬度
      currentLocation: '',
      showRadar: false,
      show: false,
      filterTop: 0,
      riskMap: {
        '0': {
          color: '#969799',
          name: '无风险'
        },
        '1': {
          color: '#FFFDBD',
          name: '低风险'
        },
        '2': {
          color: '#FFC700',
          name: '中低风险'
        },
        '3': {
          color: '#FF7A00',
          name: '中风险'
        },
        '4': {
          color: '#F40C0C',
          name: '中高风险'
        },
        '5': {
          color: '#BB0909',
          name: '高风险'
        }
      },
      keyMap: {
        lat: 'a',
        lon: 'b',
        name_CN: 'c',
        name_EN: 'd',
        administrative_level: 'e',
        pec_raw: 'f',
        pec_cat: 'g',
        fldfrc_raw: 'h',
        fldfrc_cat: 'i',
        lew_raw: 'j',
        lew_cat: 'k',
        peh_raw: 'l',
        peh_cat: 'm',
        ec1_raw: 'n',
        ec1_cat: 'o',
        tas_raw: 'p',
        tas_cat: 'q',
        bws_raw: 'r',
        bws_cat: 's',
        drr_raw: 't',
        drr_cat: 'u',
        slr_raw: 'v',
        slr_cat: 'w',
        total_raw: 'x',
        total_cat: 'y'
      }
    }
  },
  computed: {
    userPhone () {
      return sessionStorage.getItem('mapPhone');
    }
  },
  beforeCreate () {
    let url = window.location.origin + '/dataSource.js';
    // if (url.indexOf('localhost') > -1) {
    //   url = 'https://data-test.governance-solutions.com/dataSource.js';
    // }
    loadJS(url);
  },
  created () {
    // pointData = pointData // 静态文件存放于服务器中，渲染时加载
  },
  methods: {
    // 生成图片
    handleClick () {
      fullScene.exportMap('png').then((res) => {
        console.log(res)
        const createEl = document.createElement('a');
        createEl.href = res;

        createEl.download = "download-this-canvas";
        createEl.click();
        createEl.remove();
      });

      var canvas = document.getElementsByClassName('amap-layer')[0];  // 取得网页中的画布对象
      var imgDataSrc = canvas.toDataURL('image/png');
      console.log(imgDataSrc)
    },
    // 打开官网
    openOfficialWebsite (type) {
      let url = '';
      if (type === 'HSBC') {
        url = 'https://www.hsbc.com.cn/';
      } else if (type === 'SEE') {
        url = 'http://foundation.see.org.cn/';
      } else {
        url = 'https://governance-solutions.com/';
      }
      window.open(url, '_blank');
    },
    // 风险类型切换
    async statisticTypeChange () {
      // const data = this.getMapData();
      // this.show = false;
      // this.$modal.loading("数据加载中...");
      // await fullSource.setData(data);
      // this.$modal.closeLoading();

      if (fullScene.removeAllMakers) {
        fullScene.removeAllMakers()
      } else {
        fullScene.removeAllMarkers();
      }
      this.show = false;
      fullScene.removeAllLayer();

      imageLayer = new ImageLayer({});
      imageLayer.source(
        require(`./img/${this.statisticType}.png`),
        {
          parser: {
            type: 'image',
            extent: [-180, -90, 180.2, 83.74],
            options: {
              autoFit: true
            }
          },
        },
      ).style({opacity: 0.8});
      fullScene.addLayer(imageLayer)
    },
    // 经纬度搜索
    search () {
      if (!this.longitude) {
        this.$modal.msgWarning('请输入经度');
        return
      }
      if (!this.latitude) {
        this.$modal.msgWarning('请输入纬度');
        return
      }
      const lng = Number(this.longitude);
      const lat = Number(this.latitude);
      fullScene.panTo([lng, lat]);
      let isPolygon = false;
      for (const item of pointData) {
        if ((item[this.keyMap.lon] > lng - 0.25 && item[this.keyMap.lon] < lng + 0.25) && (item[this.keyMap.lat] > lat - 0.25 && item[this.keyMap.lat] < lat + 0.25 )) {
          isPolygon = true;
          break;
        }
      }
      if (isPolygon) {
        const pixel = fullScene.lngLatToContainer([lng, lat]);
        this.mapClick({x: pixel.x, y: pixel.y, lnglat: { lng, lat }});
        this.show = true;
      } else {
        this.show = false;
        if (fullScene.removeAllMakers) {
          fullScene.removeAllMakers()
        } else {
          fullScene.removeAllMarkers();
        }
        this.addMarker({ lng, lat });
      }
    },
    // 获取地图数据
    getMapData () {
      let data = {
        type: 'FeatureCollection',
        features: []
      }
      let list = pointData
      for (let i = 0; i < list.length; i++) {
        let COLOR = '';
        if (list[i][this.keyMap[this.statisticType + '_cat']]) {
          let r = Math.round(255 - 68 * list[i][this.keyMap[this.statisticType + '_raw']]);
          let g = Math.round(255 - 255 * list[i][this.keyMap[this.statisticType + '_raw']]);
          COLOR = `rgb(${r}, ${g}, 9)`;
        } else if (list[i][this.keyMap[this.statisticType + '_cat']] === 0) {
          COLOR = '#969799';
        } 
        else {
          COLOR = '#C8C9CC';
        }
        const lon = list[i][this.keyMap.lon];
        const lat = list[i][this.keyMap.lat];
        var obj = {
          type: "Feature",
          properties: {
            COLOR 
          },
          geometry: {
            type: "Polygon",
            coordinates: [
              [[lon - 0.25, lat + 0.25], [lon + 0.25, lat + 0.25], [lon + 0.25, lat - 0.25], [lon - 0.25, lat - 0.25], [lon - 0.25, lat + 0.25]]
            ]
          }
        }
        data.features.push(obj)
      }
      return data;
    },
    initMap () {
      let map = new GaodeMap({
        style: 'normal', // 'dark',
        center: [0, 39],
        pitch: 0,
        zoom: 1.8, // 2.4,
        minZoom: 0,
        maxZoom: 5,
        token:  '6a607b9c4e7e9117e442757b763ba197' // 企业账号的key  'f62719ab17582c59c0eab781275ec957' // 个人账号的key 
      })
     
      const scene = new Scene({
        id: 'worldMap',
        logoVisible: false,
        stencil: true,
        map: map
      });
      // 根据实际数据生成覆盖物
      // const data = this.getMapData();
      // console.log(data)
      // fullSource = new Source(data, {
      //   parser: {
      //     type: 'geojsonvt',
      //     tileSize: 256,
      //     zoomOffset: 0,
      //     minZoom: 3,
      //     maxZoom: 9,
      //   },
      // });
      // const polygon = new PolygonLayer({
      //   featureId: 'COLOR',
      //   mask: true
      // })
      //   .source(fullSource)
      //   .color('COLOR')
      //   .shape('fill')
      //   .style({
      //     opacity: 1,
      //   });
      
      // scene.addLayer(polygon);

      const zoom = new Zoom();
      scene.addControl(zoom);
      this.$modal.loading("数据加载中...");
      //地图加载完成触发
      fullScene = scene;
      scene.on('loaded', () => {
        // 图片图层
        imageLayer = new ImageLayer({});
        imageLayer.source(
          require('./img/total.png'),
          {
            parser: {
              type: 'image',
              extent: [-180, -90, 180.2, 83.74],
            },
          },
        ).style({opacity: 0.8});
        scene.addLayer(imageLayer)

        this.$modal.closeLoading();
        scene.on('click', (e) => {
          this.mapClick({...e, x: e.pixel.x, y: e.pixel.y});
        });
      });
    },
    mapClick (e) {
      const { lng, lat } = e.lnglat;
      if (fullScene.removeAllMakers) {
        fullScene.removeAllMakers()
      } else {
        fullScene.removeAllMarkers();
      }
      this.addMarker(e.lnglat);
      let isPolygon = false;
      for (const item of pointData) {
        if ((item[this.keyMap.lon] > lng - 0.25 && item[this.keyMap.lon] < lng + 0.25) && (item[this.keyMap.lat] > lat - 0.25 && item[this.keyMap.lat] < lat + 0.25 )) {
          isPolygon = true;
          this.currentLocation = item[this.keyMap.name_CN] + item[this.keyMap.administrative_level];
          if (this.currentLocation.indexOf('中国') > -1) {
            this.currentLocation = this.currentLocation.replace(/[\t\r\f\n\s]*/g,'');
          }
          break;
        }
      }
      if (isPolygon) {
        // 创建默认 marker
        this.currentLngLat = e.lnglat;

        let offsetX = 170;
        let offsetY = 25;
        if (e.x + 320 > document.body.offsetWidth) {
          offsetX = -170;
        }
        if (e.y < 450) {
          offsetY = -300;
        }
        fullPopup = new Popup({
          html: document.getElementsByClassName('location-detail')[0],
          closeOnClick: true,
          closeButton: false,
          maxWidth: '320px',
          offsets: [offsetX, offsetY],
          lngLat: {
            lng,
            lat,
          },
        });

        fullScene.addPopup(fullPopup);
        this.show = true;
        this.updateRadarMapData(e.lnglat);
      }
    },
    // 添加marker
    addMarker ({lng, lat}) {
      const img = document.createElement('img')
      img.setAttribute('src', location)
      const marker = new Marker({
        element: img
      })
        .setLnglat([ lng, lat ])
        
      fullScene.addMarker(marker);
    },
    // 更新雷达图数据
    updateRadarMapData (lnglat) {
      const { lng, lat } = lnglat;
      const data = [
        {
          name: '',
          value: []
        }
      ]
      let COLOR = '';
      for (const item of pointData) {
        if ((item[this.keyMap.lon] > lng - 0.25 && item[this.keyMap.lon] < lng + 0.25) && (item[this.keyMap.lat] > lat - 0.25 && item[this.keyMap.lat] < lat + 0.25 )) {
          data[0].value = [item[this.keyMap.pec_cat], item[this.keyMap.slr_cat], item[this.keyMap.bws_cat], item[this.keyMap.drr_cat], item[this.keyMap.ec1_cat], item[this.keyMap.peh_cat], item[this.keyMap.tas_cat], item[this.keyMap.lew_cat], item[this.keyMap.fldfrc_cat]];
          if (item[this.keyMap[this.statisticType + '_cat']]) {
            let r = Math.round(255 - 68 * item[this.keyMap[this.statisticType + '_raw']]);
            let g = Math.round(255 - 255 * item[this.keyMap[this.statisticType + '_raw']]);
            this.coordInfo.name = this.riskMap[item[this.keyMap[this.statisticType + '_cat']] + ''].name;
            COLOR = `rgb(${r}, ${g}, 9)`;
          } else if (item[this.keyMap[this.statisticType + '_cat']] === 0) {
            COLOR = '#969799';
            this.coordInfo.name = this.riskMap[item[this.keyMap[this.statisticType + '_cat']] + ''].name;
          } else {
            COLOR = '#C8C9CC';
            this.coordInfo.name = '无数据';
          }
          break;
        }
      }
      this.coordInfo.color = COLOR;
      //刷新数据
      var chart = echarts.getInstanceByDom(document.getElementById('echart'));
      var option = chart.getOption();
      option.series[0].data = data;
      chart.setOption(option);
    },
    // 初始化雷达图
    initRadarChart () {
      var chartDom = document.getElementById('echart');
      var myChart = echarts.init(chartDom);
      var option;
      let this_ = this;
      option = {
        radar: {
          indicator: [
            { name: '作物歉收', max: 5 },
            { name: '海平面上升', max: 5 },
            { name: '水压力', max: 5 },
            { name: '干旱', max: 5 },
            { name: '热应激反应', max: 5 },
            { name: '热浪', max: 5 },
            { name: '最大气温升高', max: 5 },
            { name: '林火', max: 5 },
            { name: '洪涝', max: 5 }
          ],
          radius: '60%',
          splitArea: {
            show: false
          },
          name: {
            color: '#646566',
            fontWeight: 400,
            formatter: function (value, indicator) {
                if (indicator.name === '最大气温升高') {
                  return '最大气\n温升高'
                }
                return value;
            }
          },
          nameGap: 10,
          axisLine: {
            lineStyle: {
              color: '#EBEDF0'
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#008FBE', '#EBEDF0', '#EBEDF0', '#EBEDF0', '#EBEDF0'],
              width: 0.5
            }
          }
        },
        series: [
          {
            type: 'radar',
            label: {
              show: true,
              color: '#008FBE',
              textBorderWidth: 1,
              textBorderColor: '#fff',
              fontSize: 12,
              formatter: function (e) {
                return this_.riskMap[e.value + '']?.name.slice(0, this_.riskMap[e.value + '']?.name.length - 2);
              }
            },
            
            itemStyle:{
              color:'#008FBE'
            },
            lineStyle: {
              color: '#008FBE'
            },
            areaStyle: {
              color: '#008FBE'
            },
            data: [
              {
                value: [],
                name: ''
              }
            ]
          }
        ]
      };

      option && myChart.setOption(option);
    },
    calcFontSize () {
      const zoom = document.body.offsetWidth / 1920;
      const size = zoom * 16;
      document.documentElement.style.fontSize = size + 'px';
      this.$nextTick(() => {
        this.filterTop = document.getElementsByClassName('top-content')[0].offsetHeight;
      })
    }
  },
  mounted() {
    this.initMap();
    this.initRadarChart();
    this.calcFontSize();
    window.onresize = () => {
      this.calcFontSize();
    }
  }
}
  

</script>
<style lang="scss" scoped>
.worldMapBoard {
  position: relative;
  height: 100%;
  width: 100%;
  .worldMap {
    height: 100%;
    width: 100%;
  }
  .top-content {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 4;
    .logo {
      position: absolute;
      left: 8px;
      top: 0;
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
      }
      .el-divider {
        height: 1.218rem;
        margin-right: 1.125rem;
        margin-left: 0;
        background-color: #FFF;
      }
    }
    .user {
      position: absolute;
      right: 70px;
    }
    .title {
      position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      font-size: 1.687rem;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #323233;
    }
  }
  .filter-area {
    position: absolute;
    top: calc(2rem + 65px);
    left: 32px;
    z-index: 4;
    ::v-deep.el-button--primary {
      color: #FFFFFF;
      background-color: #008FBE;
      border-color: #008FBE;
    }
  }
  .legend {
    width: 124px;
    background: #FFFFFF;
    box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.1);
    border-radius: 4px 4px 4px 4px;
    position: absolute;
    bottom: 50px;
    right: 20px;
    padding: 16px;
    z-index: 10;
    .mark-area {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 30px;
      margin-left: 10px;
      .mark {
        width: 20px;
        height: 6px;
        margin-right: 8px;
        background-color: #C8C9CC;
        opacity: 0.8;
      }
    }
    .linear-gradient {
      background: linear-gradient(to top, rgb(255, 255, 9), rgb(187, 9, 9));
      height: 133px;
      width: 20px;
      margin: 8px 0 12px 8px;
    }
    .level {
      height: 138px;
      position: absolute;
      top: 40px;
      font-size: 12px;
      left: 54px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .bottom-area {
    position: absolute;
    bottom: 0;
    height: 24px;
    width: 100%;
    background: #FFFFFF;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    a {
      font-size: 12px;
      font-weight: 400;
      color: #008FBE;
      margin: 0 12px;
      text-decoration: underline;
    }
  }
  .location-detail {
    width: 320px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #008FBE;
    // position: absolute;
    z-index: 100;
    .detail-top {
      background-color: #008FBE;
      display: flex;
      align-items: center;
      padding: 10px 0 10px 8px;
      line-height: 20px;
      color: #fff;
    }
    .el-icon-close {
      font-size: 16px;
      position: absolute;
      right: 8px;
      top: 14px;
      cursor: pointer;
    }
    .radar {
      .risk-name {
        float: right;
        padding: 2px 6px;
        margin: 16px;
        background-color: #C8C9CC;
        color: #FFF;
        font-size: 12px;
        opacity: 0.8;
      }
      .echart {
        width: 280px;
        height: 240px;
      }
    }
  }
}

</style>
<style lang="scss">
.worldMapBoard {
  .amap-copyright { display: none !important; }
  .l7-control-container .l7-column.l7-right {
    bottom: 330px !important;
    right: 20px !important;
  }
  .l7-popup .l7-popup-content {
    padding: 0;
  }
  .l7-popup-anchor-bottom .l7-popup-tip {
    display: none;
  }
}
</style>