<template>
    <el-dialog :visible="visible" :title="`${id ? '修改' : '新增'}自选清单`" width="800px" @close="dialogClose">
        <el-form :model="formData" ref="form" :rules="formRules" label-width="120px">
            <el-form-item label="清单名称：" prop="secCodeListName">
                <el-input v-model="formData.secCodeListName" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="清单内容：" prop="secCodeListContent">
                <el-radio-group v-model="formData.type">
                    <el-radio label="1">手动输入</el-radio>
                    <el-radio label="2">导入</el-radio>
                </el-radio-group>
                <el-input v-if="formData.type === '1'" type="textarea" v-model="formData.secCodeListContent" @blur="blur" rows="4" placeholder="请输入,如:000001,000002"></el-input>
                <div v-else>
                    <el-upload ref="upload" action="/" :show-file-list="false" :on-change="importExcel" :auto-upload="false">
                        <el-button class="d2-mt-10 d2-mb-10" slot="trigger" icon="el-icon-upload" size="small">
                            上传文件
                        </el-button>
                        <el-button style="margin-left: 20px" type="text" size="small" @click="downloadTemplate">下载模板</el-button>
                    </el-upload>
                    <el-table v-if="tableData.length" :data="tableData" border max-height="600px" style="margin-top: 16px">
                      <el-table-column label="secCode" prop="secCode"></el-table-column>
                    </el-table>
                </div>
            </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button size="small" @click="dialogClose">取 消</el-button>
          <el-button type="primary" size="small" @click="handleSave">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { saveSecCodeCustomListDef, saveSecCodeTemplateListDef, loadSecCodeListById } from "@/api/data-platform/dl-databrowser.js";
import * as XLSX from 'xlsx';
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: [Number, String],
      default: () => null
    },
    scope: {
      type: String,
      default: () => ''
    }
  },
    data () {
        return {
            formData: {
                secCodeListName: '',
                type: '1',
                secCodeListContent: '',
            },
            formRules: {
                secCodeListName: [{ required: true, message: '模板名称不能为空', trigger: 'blur'}]
            },
            xlscList: [],
            tableData: [],
            wb: null
        }
    },
    created () {
      if (this.id) {
        this.getDetail();
      }
    },
    methods: {
      blur () {
        this.formData.secCodeListContent = this.formData.secCodeListContent.replaceAll('\n', ',');
      },
      // 查询详情
      getDetail () {
        loadSecCodeListById({id: this.id}).then((res) => {
          if (res.code === 200) {
            Object.assign(this.formData, res.data);
          }
        })
      },
      // 确定
      handleSave () {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const params = {
              ...this.formData,
            }
            if (this.formData.type === '2') {
              params.secCodeListContent = this.tableData.map((e) => e.secCode).join(',');
            }
            const requestMethod = saveSecCodeCustomListDef;
            requestMethod(params).then((res) => {
              if (res.code === 200) {
                this.$modal.msgSuccess('操作成功');
                this.$emit('saveSuccess');
                this.dialogClose();
              }
            })
          }
        })
      },
      // 下载模板
      downloadTemplate () {
        this.download('/DingLiApi/DataBrowser/downloadIdTemplate', {}, `secCode模板.xlsx`);
      },
        //第一步上传文件
        importExcel(file) {
            let self = this;
            const types = file.name.split(".")[1];
            const fileType = ["xlsx", "xlc"].some(
                item => item === types
            );
            if (!fileType) {
                alert("格式错误！请重新选择");
                return;
            }
            this.file2Xce(file).then(tab => {
                // console.log(tab)
                if (tab && tab.length > 0) {
                this.tab = tab;
                if (this.tab.length != 0) {
                    console.log(tab)
                    this.xlscList = [];
                    this.tab[0].sheet.forEach(item => {
                    if (item.indexOf("") != -1) {
                        let inputInfo = item.split("'");//excel的数据类型都是text所以通过item.split("'")截取，拿到所以表的数据
                        if (inputInfo.length == 2) {
                        self.xlscList.push(inputInfo[1]);
                        }
                    }
                    });
                }
                if (this.xlscList.length != 0) {
                    this.setInputToForm();
                }
                }
            });
        },
        //对组进行切割
        setInputToForm(){
          const len = 1; // 表头数量
            if (this.xlscList.length >= len) {//判断表头的长度
                /*
                * 将一个数组分成几个同等长度的数组
                * array[分割的原数组]
                * size[每个子数组的长度]
                */
                this.xlscList=this.xlscList.slice(len)
                let result = [];
                let list = []
                for (var x = 0; x < Math.ceil(this.xlscList.length / len); x++) {
                    var start = x * len;
                    var end = start + len;
                    result.push(this.xlscList.slice(start, end));
                }
                // console.log(result)
                //获取每一个数组的第一个，第二个，第三个...通过push重新拼装成一个新的数组对象
                for(let i = 0;i<result.length;i++) {
                  for(let j = 0;j<result[i].length;j++) {
                      if(j == 0) {
                        list.push({secCode:result[i][j]})
                      }
                  }
                }
                this.tableData = list;
            }else{
                this.$message({
                message: '上传格式不對',
                type: 'warning'
                })
            }
        },
        file2Xce(file) {
            return new Promise(function(resolve, reject) {
                const reader = new FileReader();
                reader.onload = function(e) {
                const data = e.target.result;
                this.wb = XLSX.read(data, {
                    type: "binary"
                });
                const result = [];
                this.wb.SheetNames.forEach(sheetName => {
                    result.push({
                    sheetName: sheetName,
                    sheet: XLSX.utils.sheet_to_formulae(this.wb.Sheets[sheetName])
                    });
                });
                resolve(result);
                };
                reader.readAsBinaryString(file.raw);
            });
        },
        dialogClose () {
            this.$emit('dialogClose')
        }
    }
}
</script>