var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-box" }, [
    _vm._m(0),
    _c("div", { ref: "login", staticClass: "login" }, [
      _c(
        "div",
        { staticClass: "el-login-center" },
        [
          _c("div", { staticClass: "main_box" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.language === "zh",
                    expression: "language === 'zh'",
                  },
                ],
                staticClass: "main_title",
              },
              [_vm._v("鼎力可持续数据平台")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.language === "en",
                    expression: "language === 'en'",
                  },
                ],
                staticClass: "main_title_en",
              },
              [_vm._v("GSG Sustainability Data Platform")]
            ),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.language === "zh",
                  expression: "language === 'zh'",
                },
              ],
              staticClass: "main_img",
            }),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.language === "en",
                  expression: "language === 'en'",
                },
              ],
              staticClass: "main_img_en",
            }),
            _vm.language === "zh"
              ? _c("div", { staticClass: "subhead" }, [
                  _c("span", [_vm._v("影响和推动企业可持续健康发展")]),
                  _c("span", [_vm._v("帮助金融机构做出更好的分析决策")]),
                ])
              : _c("div", { staticClass: "subhead_en" }, [
                  _c("p", [
                    _vm._v(
                      " Influencing and accelerating the sustainable development of enterprises "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " Assisting financial institutions in making better investment analyzes "
                    ),
                  ]),
                ]),
            _vm.language === "zh"
              ? _c("div", { staticClass: "label_box" }, [_vm._m(1), _vm._m(2)])
              : _c("div", { staticClass: "label_box_en label_box" }, [
                  _vm._m(3),
                ]),
          ]),
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: { model: _vm.loginForm, rules: _vm.loginRules },
            },
            [
              _c("div", { staticClass: "title_box" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("login.label"))),
                ]),
              ]),
              _c(
                "el-form-item",
                { staticClass: "username", attrs: { prop: "username" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: "text",
                        "auto-complete": "off",
                        placeholder: _vm.$t("login.username"),
                      },
                      model: {
                        value: _vm.loginForm.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "username", $$v)
                        },
                        expression: "loginForm.username",
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "input_icon user_icon",
                        attrs: { slot: "prefix" },
                        slot: "prefix",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "password", attrs: { prop: "password" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: "password",
                        "auto-complete": "off",
                        placeholder: _vm.$t("login.password"),
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleLogin($event)
                        },
                      },
                      model: {
                        value: _vm.loginForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "password", $$v)
                        },
                        expression: "loginForm.password",
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "input_icon password_icon",
                        attrs: { slot: "prefix" },
                        slot: "prefix",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm.captchaOnOff
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "code" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "63%" },
                        attrs: {
                          "auto-complete": "off",
                          placeholder: _vm.$t("login.verifyCode"),
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "code", $$v)
                          },
                          expression: "loginForm.code",
                        },
                      }),
                      _c("div", { staticClass: "login-code" }, [
                        _c("img", {
                          staticClass: "login-code-img",
                          attrs: { src: _vm.codeUrl },
                          on: { click: _vm.getCode },
                        }),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { staticClass: "submit", staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submit_btn",
                      attrs: {
                        loading: _vm.loading,
                        size: "medium",
                        type: "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin($event)
                        },
                      },
                    },
                    [
                      !_vm.loading
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("login.logIn")))])
                        : _c("span", [_vm._v(_vm._s(_vm.$t("login.status")))]),
                    ]
                  ),
                  _vm.register
                    ? _c(
                        "div",
                        { staticStyle: { float: "right" } },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "link-type",
                              attrs: { to: "/register" },
                            },
                            [_vm._v("立即注册")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-checkbox",
                {
                  staticClass: "check_item",
                  model: {
                    value: _vm.loginForm.rememberMe,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "rememberMe", $$v)
                    },
                    expression: "loginForm.rememberMe",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("login.rememberPsw")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "el-login-footer" }, [
        _c("span", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.$t("login.connect"))),
        ]),
        _c("span", [
          _vm._v("Copyright by © 2022 鼎力可持续数字科技（深圳）有限公司"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "logo_box" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label_box_zh_1" }, [
      _c("div", { staticClass: "label_item row_first" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("创新")]),
      ]),
      _c("div", { staticClass: "label_item" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("研究+数据")]),
      ]),
      _c("div", { staticClass: "label_item" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("风险评估")]),
      ]),
      _c("div", { staticClass: "label_item lang_item" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("管理软件")]),
      ]),
      _c("div", { staticClass: "label_item" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("评价方法")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label_box_zh_2" }, [
      _c("div", { staticClass: "label_item row_first" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("优化")]),
      ]),
      _c("div", { staticClass: "label_item" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("技术+模型")]),
      ]),
      _c("div", { staticClass: "label_item" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("风险管理")]),
      ]),
      _c("div", { staticClass: "label_item" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("SaaS平台")]),
      ]),
      _c("div", { staticClass: "label_item" }, [
        _c("div", { staticClass: "label_img" }),
        _c("div", { staticClass: "label_text" }, [_vm._v("指标体系")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label_box_zh_1" }, [
      _c("div", [
        _c("div", { staticClass: "label_item row_first" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [_vm._v("Innovation")]),
        ]),
        _c("div", { staticClass: "label_item row_first" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [_vm._v("Optimization")]),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "label_item" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [_vm._v("Research+Data")]),
        ]),
        _c("div", { staticClass: "label_item" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [_vm._v("Technoloy+Model")]),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "label_item" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [_vm._v("Risk Assessment")]),
        ]),
        _c("div", { staticClass: "label_item" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [_vm._v("Risk Management")]),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "label_item lang_item" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [
            _vm._v("Management Software"),
          ]),
        ]),
        _c("div", { staticClass: "label_item" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [_vm._v("SaaS Platform")]),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "label_item" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [
            _vm._v("Evaluation Method"),
          ]),
        ]),
        _c("div", { staticClass: "label_item" }, [
          _c("div", { staticClass: "label_img" }),
          _c("div", { staticClass: "label_text" }, [
            _vm._v("Indicator System"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }