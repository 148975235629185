<template>
  <div class="tree-component">
    <el-input
      v-model="keyword"
      v-if="showFilterInput"
      placeholder="请输入"
      clearable
      prefix-icon="el-icon-search"
      style="margin-bottom: 12px"
    />
    <el-tree
      class="tree"
      :style="{height: showFilterInput ? 'calc(100% - 47px)' : '100%'}"
      ref="tree"
      :data="treeData"
      :node-key="nodeKey"
      :default-expand-all="defaultExpandAll"
      highlight-current
      :props="treeProps"
      :expand-on-click-node="expandOnClickNode"
      :default-expanded-keys="defaultExpandedKeys"
      :filter-node-method="filterNode"
      @node-click="nodeClick"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <!-- <div v-if="data[treeProps.children] && data[treeProps.children].length" class="el-icon-arrow-blue">
          <svg-icon slot="prefix" icon-class="arrow" />
        </div> -->
        <slot v-if="$scopedSlots.default" :data="data"></slot>
        <div v-else class="node-label" :title="node.label">{{ node.label }}</div>
      </span>
    </el-tree>
  </div>
</template>
<script>
export default {
  props: {
    treeData: {
      type: Array,
      default: () => []
    },
    showFilterInput: {
      type: Boolean,
      default: () => true
    },
    defaultProps: {
      type: Object,
      default: () => {}
    },
    nodeKey: {
      type: String,
      default: () => undefined
    },
    // 是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。
    expandOnClickNode: {
      type: Boolean,
      default: () => true
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => []
    },
    defaultExpandAll: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      keyword: '',
      treeProps: { children: "children", label: "label" }
    }
  },
  computed: {
    treeRef () {
      return this.$refs.tree;
    }
  },
  watch: {
    keyword (val) {
      this.$refs.tree.filter(val);
    },
  },
  created () {
    Object.assign(this.treeProps, this.defaultProps);
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data[this.treeProps.label].indexOf(value) !== -1;
    },
    filter () {
      this.$refs.tree.filter(this.keyword);
    },
    nodeClick (val) {
      this.$emit('node-click', val);
    }
  }
}
</script>
<style lang="scss" scoped>
.tree-component {
  height: 100%;
}
.tree {
  height: calc(100% - 47px);
  font-size: 13px;
  color: #01579b;
  overflow: auto;
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    // padding: 0 10px !important;
    width: 100%;
    .node-label {
      max-width: calc(100% - 0px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .el-icon-arrow-blue {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin-right: 6px;
    .svg-icon {
      width: 0.7em;
    }
  }
  ::v-deep .el-tree-node__expand-icon.is-leaf {
    visibility: hidden;
  }
  ::v-deep .el-tree-node__expand-icon {
    color: #01579B;
    font-size: 14px;
  }
  // .el-tree-node.is-expanded {
  //   .el-icon-arrow-blue {
  //     -webkit-transform: rotate(0deg);
  //     transform: rotate(0deg);
  //   }
  // }
  ::v-deep .el-tree-node__children {
    .el-tree-node__content {
      &:hover {
        background-color: #dfebf9 !important;
      }
    }
  }
  
  // ::v-deep .el-icon-caret-right {
  //   display: none;
  // }
}
</style>
