var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs_container" },
    [
      _c("svg-icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeName === "third",
            expression: "activeName === 'third'",
          },
        ],
        staticClass: "tip",
        attrs: { "icon-class": "iconfont-wenhao" },
        on: {
          click: function ($event) {
            _vm.dialogVisible = true
          },
        },
      }),
      _c(
        "el-tabs",
        {
          staticClass: "tabs",
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.tab1"), name: "first" } },
            [
              _c("ProcessedData", {
                ref: "processed",
                attrs: {
                  baseFormData: _vm.baseFormData,
                  id: _vm.id,
                  note: _vm.note,
                  flag: _vm.flag,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.$t("tabs.tab3"), name: "third" } },
            [
              _c("FootprintTab", {
                ref: "footprint",
                attrs: {
                  baseFormData: _vm.baseFormData,
                  id: _vm.id,
                  flag: _vm.flag,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "40%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("BaseTip"),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("FMCCTop.confirmBtn")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }