<template>
  <div class="abnormal-target">
    <!-- 左list -->
    <div class="left-tree">
      <TreeDL
        :isLazy="isLazyForIndicator"
        :treeList="abnormalTargetList"
        :treeTitle="''"
        class="tree-list"
        ref="indTree"
        @handleTreeNodeClick="clickTreeIndicator"
      />
    </div>
    <!-- 右echarts -->
    <div style="width: 20px;flex-grow: 1;margin-left: 20px;">
      <div id="abnormalTarget" style="height: 400px;width: 100%;"></div>
      <!-- 缩略图部分 -->
      <div style="height: 175px;width: 100%;overflow-x: auto;">
        <div style="white-space: nowrap">
          <div v-for="(item,index) in imgList" :key="index" class="img-box">
            <div class="img-top-title">
              <div class="title-content">{{ item.name }}{{ (item.correlation==0?'(负)':'(正)') }}</div><i class="el-icon-close" @click="closeImage(index)"></i>
            </div>
            <img :src="item.url" @click="clickTreeIndicator({ number: item.number, label: item.name })" class="image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import TreeDL from "@/components/Tree/TreeDL.vue";
import {getFlopIndicator} from '@/api/data-platform/FLOP.js'

export default {
  components: {
    TreeDL,
  },
  props: {
    abnormalTargetData: {
      type: Array,
      default: () => {
        return []
      }
    },
    unitId: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      isLazyForIndicator: false,
      abnormalTargetList: [{
        arr: [],
        name: '异常数据点'
      }],
      imgList: [],
      abnormalTargetEchartsData: {},
      oldNumber: null,
    }
  },
  created() {
    this.setTreeData()
    // 监听echarts随屏幕缩放
    window.addEventListener("resize", ()=>{
      echarts.init(document.getElementById('abnormalTarget')).resize();
    })
  },
  mounted() {
  },
  watch: {
    // 切换公司时初始化 树数据、echarts图、缩略图列表
    abnormalTargetData() {
      this.abnormalTargetList = [{
        arr: [],
        name: '异常数据点'
      }]
      this.imgList = []
      var myChart = echarts.init(document.getElementById('abnormalTarget'));
      myChart.dispose()
      this.setTreeData()
    }
  },
  methods: {
    // 处理生成树数据
    setTreeData() {
      this.abnormalTargetData.map((item, index) => {
        this.abnormalTargetList[0].arr.push({
          label: item.name,
          number: item.number || null
        })
        if (item.children) {
          if (!this.abnormalTargetList[0].arr[index]) {
            this.abnormalTargetList[0].arr[index].children = []
          }
          this.abnormalTargetList[0].arr[index].children = item.children.map(items => {
            return {
              label: items.name,
              number: items.number || null,
              correlation: items.correlation
            }
          })
        }
      })
    },
    // 树节点点击
    clickTreeIndicator(data, node) {
      if (data.number) {
        getFlopIndicator({id: this.unitId, number: data.number}).then(res => {
          this.abnormalTargetEchartsData = res.data
          this.generateImage(data)
        })
      }
    },
    // 生成echarts图表
    setAbnormalTargetEcharts(label, correlation) {
      var myChart = echarts.init(document.getElementById('abnormalTarget'));
      var option;
      // 获取横坐标数据 dataList
      const dateSet = this.abnormalTargetEchartsData.indicatorValues.map(item => item.year).concat(this.abnormalTargetEchartsData.medianValues.map(item => item.year))
      let dateList = dateSet.filter((item, index) => {
        return dateSet.indexOf(item) === index
      })
      // 筛选需要标记的点组成MarkPoint数组
      const markPointData = []
      this.abnormalTargetEchartsData.indicatorValues.filter((item) => {
        if(item.isFrd127 === 1){
          markPointData.push({
            coord: [String(item.year),String(item.val)]
          })
        }
      })
      option = {
        title: [
          {
            left: 'center',
            text: `${label}${correlation==0?'(负相关)':'(正相关)'}`
          }
        ],
        xAxis: {
          name: '年份',
          nameLocation: 'middle',
          nameGap: 35,
          nameTextStyle: {
            fontSize: 16,
          },
          type: 'category',
          data: dateList
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
        },
        legend: {
          orient: 'vertical',
          top: 0,
          right: 10,
          y: 'center',
          textStyle: {
            padding: [8, 0, 0, 0],
          },
          lineStyle: {
            type: 'solid'
          },
          data: [
            {name: '行业75%分位', icon: 'path://M0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z'},
            {name: '行业50%分位', icon: 'path://M0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z'},
            {name: '行业25%分位', icon: 'path://M0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z'},
            {name: this.unitId},
            {name: '行业内异常公司指数中位数'},
            {name: '行业内无异常公司指数中位数'},
            {name: '监管认定违规',icon: 'triangle'},
          ]
        },
        series: [
          {
            name: this.unitId,
            data: this.abnormalTargetEchartsData.indicatorValues.map(item => item.val),  // 指标数据
            type: 'line',
            itemStyle: {
              color: '#DA6969',
            },
            lineStyle: {
              color: '#DA6969',
              width: 2,
              type: 'solid'
            },
            // markPoint:{
            //   symbol: 'triangle',
            //   symbolSize: 15,
            //   itemStyle: {
            //     color: 'red'
            //   },
            //   data: markPointData,
            // },
          },
          {
            name: '行业内异常公司指数中位数',
            data: this.abnormalTargetEchartsData.indicatorValues.map(item => item.valFrd),
            type: 'line',
            itemStyle: {
              color: '#4B6182',
            },
            lineStyle: {
              color: '#4B6182',
              width: 2,
              type: 'solid'
            },
          },
          {
            name: '行业内无异常公司指数中位数',
            data: this.abnormalTargetEchartsData.indicatorValues.map(item => item.valNorm),
            type: 'line',
            itemStyle: {
              color: 'green',
            },
            lineStyle: {
              color: 'green',
              width: 2,
              type: 'solid'
            },
          },
          {
            name: '行业75%分位',
            data: this.abnormalTargetEchartsData.medianValues.map(item => item.val75),
            type: 'line',
            itemStyle: {
              color: '#FDF0BD',
            },
            stack: 'Total',
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            sampling: 'average',
            areaStyle: {
              color: '#FDF0BD',
            },
            emphasis: {
              focus: 'series'
            },
          },
          {
            name: '行业50%分位',
            data: this.abnormalTargetEchartsData.medianValues.map(item => item.val),
            type: 'line',
            itemStyle: {
              color: '#F9D23A'
            },
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            sampling: 'average',
            areaStyle: {
              color: '#F9D23A',
            },
          },
          {
            name: '行业25%分位',
            data: this.abnormalTargetEchartsData.medianValues.map(item => item.val25),
            type: 'line',
            itemStyle: {
              color: '#D6AC08',
            },
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            sampling: 'average',
            areaStyle: {
              color: '#D6AC08',
            },
          },
          {
            name: '监管认定违规',
            type: 'line',
            itemStyle: {
              color: 'rgb(94,148,162)',
            },
            symbolSize: 0,
            data: this.abnormalTargetEchartsData.indicatorValues.map(item => item.val),  // 指标数据
            lineStyle: {
              color: '#DA6969',
              width: 2,
              type: 'solid'
            },
            markPoint:{
              symbol: 'triangle',
              symbolSize: 15,
              itemStyle: {
                color: 'rgb(94,148,162)'
              },
              data: markPointData,
            },
          },
        ],
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
        },
        animation: false
      };
      // 配置point图例点击切换显示/隐藏
      if (option && typeof option === "object") {
        myChart.on('legendselectchanged', (params) => {
          var selected = params.selected
          var name = params.name; // current clicked one
          if(name == this.unitId){
            myChart.dispatchAction({
              type: selected[name]?'legendSelect':'legendUnSelect',
              name: '监管认定违规'
            })
          }
          if(name == '监管认定违规'){
            myChart.dispatchAction({
              type: selected[name]?'legendSelect':'',
              name: this.unitId
            })
          }
        })
        option && myChart.setOption(option);
        return myChart
      }
      // option && myChart.setOption(option);
    },
    // 生成缩略图
    generateImage(data) {
      var myChart = this.setAbnormalTargetEcharts(data.label, data.correlation)
      let imgUrl = myChart.getDataURL({
        type: "png",
        pixelRatio: 1,//放大2倍
        backgroundColor: "#fff",
      })
      const exist = this.imgList.find((i)=>{
        return i.number === data.number
      })
      if(!exist){
        this.imgList.push({
          url: imgUrl,
          id: this.unitId,
          number: data.number,
          name: data.label,
          correlation: data.correlation
        })
      }
    },
    // 点击 x 删除当前图片
    closeImage(index) {
      this.imgList.splice(index,1)
    },
  },
}
</script>

<style lang="scss" scoped>
.abnormal-target {
  display: flex;
  .left-tree{
    height: 540px;
    overflow-y: auto;
    background-color: #fff;
  }
  .img-box{
    display: inline-block;
    border: 1px solid #323233;
    .img-top-title{
      height: 40px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #323233;
      .title-content{
        flex-grow: 1;
        text-align: center;
        line-height: 38px;
      }
      i{
        line-height: 38px;
        width: 20px;
        cursor: pointer;
      }
    }
    .image{
      height: 110px;
      width: 260px;
      cursor: pointer;
      margin: 5px;
    }
  }
}
</style>
