export default {
    tips: {
        searchTip: '请输入查询关键词',
        emptyTableTip: '您的股票清单目前为空，请先上传您的文件。',
        importTip: '将文件拖到此处，或',
        clickTip: '点击上传',
        fitmitTip: '仅允许导入xls、xlsx格式文件。',
        langTip: '设置语言成功',
        warning: '警告',
        msg: '请先添加待提取股票！',
        msg2: '请先添加待提取股票和指标！',
        msg3: '请先完成提取操作！',
        msg4: '已存在，请勿重复添加！',
        msg5: '是否确认删除该自选清单？',
        msg6: '是否确认删除该股票？',
        msg7: '没有更多啦！',
        msg8: '正在努力加载中....',
        msg9: '您没有该模块的权限！',
        masg10: '确定注销并退出系统吗？',

        selfTip2: "修改成功",
        selfTip3: "添加成功",
        selfTip4: "删除成功",
        downloadTemplate:"下载模板",
        templateTitleFMCC:'FMCC上传中文模板',
        templateTitleDataBrowser:'数据浏览器上传模板',
    },
    selfSelectTip: {
        selfTip1: "自选清单已存在，请重新命名！",
        selfTip2: "修改自选清单成功",
        selfTip3: "新增自选清单成功",
        stockTip: '新增股票成功',
        uploadTitle: '导入',
        result: '导入结果',
    },
    rules: {
        msg1: '邮箱地址不能为空',
        msg2: '请输入正确的邮箱地址',
        msg3: '文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。',
    },
    friendly: {
        text: '抱歉，该页面我们还没有设置内容。'
    }
}