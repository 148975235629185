const mixin = {
  methods: {
    popError(message, duration) {
      this.$message({
        message,
        dangerouslyUseHTMLString: true,
        type: 'error',
        duration: duration || 2000
      })
    },
    popSuccess(message, duration) {
      this.$message({
        message,
        dangerouslyUseHTMLString: true,
        type: 'success',
        duration: duration || 4000
      })
    },
    popWarning(message, duration, showClose = true) {
      this.$message({
        message,
        dangerouslyUseHTMLString: true,
        type: 'warning',
        duration: duration || 4000,
        showClose
      })
    },
  }
};
export default mixin;
