var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "abnormal-target" }, [
    _c(
      "div",
      { staticClass: "left-tree" },
      [
        _c("TreeDL", {
          ref: "indTree",
          staticClass: "tree-list",
          attrs: {
            isLazy: _vm.isLazyForIndicator,
            treeList: _vm.abnormalTargetList,
            treeTitle: "",
          },
          on: { handleTreeNodeClick: _vm.clickTreeIndicator },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: { width: "20px", "flex-grow": "1", "margin-left": "20px" },
      },
      [
        _c("div", {
          staticStyle: { height: "400px", width: "100%" },
          attrs: { id: "abnormalTarget" },
        }),
        _c(
          "div",
          {
            staticStyle: {
              height: "175px",
              width: "100%",
              "overflow-x": "auto",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "white-space": "nowrap" } },
              _vm._l(_vm.imgList, function (item, index) {
                return _c("div", { key: index, staticClass: "img-box" }, [
                  _c("div", { staticClass: "img-top-title" }, [
                    _c("div", { staticClass: "title-content" }, [
                      _vm._v(
                        _vm._s(item.name) +
                          _vm._s(item.correlation == 0 ? "(负)" : "(正)")
                      ),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          return _vm.closeImage(index)
                        },
                      },
                    }),
                  ]),
                  _c("img", {
                    staticClass: "image",
                    attrs: { src: item.url },
                    on: {
                      click: function ($event) {
                        return _vm.clickTreeIndicator({
                          number: item.number,
                          label: item.name,
                        })
                      },
                    },
                  }),
                ])
              }),
              0
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }