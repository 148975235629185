<template>
    <div class="drillDown-data-wrapper">
        <el-table
            ref="table"
            :data="data"
            size="mini"
            class="drillDown-data-table"
            width="100%"
            :height="height || 'calc(100%)'"
            :max-height="height || 'calc(100%)'"
            header-cell-class-name="header-cell"
            cell-class-name="cell-class"
            :cell-style="addCellStyle"
            :header-cell-style="headerStyle"
            :row-class-name="addRowClass"
            :border="true"
        >
            <el-table-column
                v-for="col in columns"
                :key="col.prop"
                :prop="col.prop"
                :label="col.label"
                :fixed="col.fixed"
                :min-width="'' + col.width"
                :class-name="col.className"
            >
            </el-table-column>
            <span slot="empty"></span>
        </el-table>
    </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Array,
      default: null
    },
    columns: {
      type: Array,
      default: () => []
    },
    height: {
      type: String || Number,
      default: null
    }
  },
  data() {
    return {}
  },
  created() {
    this.impactObject = {}
    this.factorsObject = {}
    this.positionObject = {}
  },
  watch: {
    data(list) {
      this.$refs.table.doLayout()
      console.log('data:', list)
    },
    columns() {
      this.$refs.table.doLayout()
      console.log('columns:', this.columns)
    }
  },
  methods: {
    headerStyle({ row, column, rowIndex, columnIndex }) {
      const style = {}
      if (columnIndex === 0) {
        style['text-align'] = 'center'
      }
      if (columnIndex > 3) {
        style['text-align'] = 'right'
      }
      return style
    },
    addCellStyle({ row, column, rowIndex, columnIndex }) {
      const style = {}
      if (columnIndex === 0) {
        style['text-align'] = 'center'
      }
      if (columnIndex > 3) {
        style['text-align'] = 'right'
      }
      return style
    },
    addRowClass({ row, rowIndex }) {
      if (row.isTotal) {
        return 'sfdr-portfolio-table-total-row'
      }
    },
    groupBy(list, fn) {
      const groups = {}
      list.forEach(function(o) {
        const group = fn?.(o)
        if (!group) {
          return
        }
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return groups
    }
  }
}
</script>

<style lang="scss" scoped>
// @import "../../assets/footer.scss";
.drillDown-data-wrapper {
    font-family: PingFang SC-Regular;
    height: calc(100% - 113px);
    // height: calc(100% - 214px);

    flex: 1;

    .drillDown-data-table {
        height: calc(100%);

        ::v-deep .header-cell {
            color: #2f5584;
            background-color: #e0ecfa;
            font-size: 13px;
            font-weight: 400;
            color: #215796;
            background-color: #e1ebf8;
            font-family: PingFang SC-Regular, PingFang SC;
            // font-weight: 600;
            // line-height: 20px;
        }

        ::v-deep .cell-class {
            div {
                word-break: break-word;
            }
        }
    }
}
</style>
<style lang="scss">
.drillDown-data-table {
    // .el-table__body-wrapper {
    //     overflow-x: hidden !important;
    // }
}

.hide {
    display: none;
}
</style>