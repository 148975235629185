<template>
  <!-- 指标模态框 -->
  <el-dialog
    :title="$t('indicator.title')"
    :visible.sync="visible"
    :before-close="handleClose"
    class="dialog_DB"
    width="460px"
  >
    <el-form :model="form" :rules="rules" label-width="150px" ref="form">
      <el-form-item prop="indicatorDate" :label="$t('indicator.time')">
        <el-date-picker
          v-model="form.indicatorDate"
          type="year"
          :placeholder="$t('indicator.placeholder')"
          clearable
          :picker-options="{ disabledDate: pickerOptions }"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">{{ $t("elbtn.cancel") }}</el-button>
      <el-button type="primary" @click="submit">{{
        $t("elbtn.apply")
      }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "IndicatorDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // 时间组件-指定时间不可用
      pickerOptions(time) {
        let year = new Date(time).getFullYear();
        return year !== 2021;
      },
      // 时间弹出框数据
      form: {
        indicatorDate: '2021', //指标时间
      },
      rules: {
        indicatorDate: [
          {
            required: true,
            message: vm.$t("indicator.tip"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //确定按钮
    submit() {
      this.$emit("submit", this.form.indicatorDate);
    },
    // 取消按钮
    handleClose() {
      this.$emit("handleClose");
    },
    // 表单重置
    reset() {
      this.form = {
        indicatorDate: '2021',
      };
      this.resetForm("form");
    },
    //校验表单
    validate() {
      let flag = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          flag = true;
        } else {
          flag = false;
        }
      });
      return flag;
    },
  },
};
</script>
