var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-outer-box" },
    [
      _c("div", { staticClass: "navbar" }, [
        _c(
          "div",
          { staticClass: "logo" },
          [
            _c(
              "router-link",
              { attrs: { to: "/" } },
              [_c("svg-icon", { attrs: { "icon-class": "left-top-logo" } })],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("top-nav", {
              staticClass: "topmenu-container",
              attrs: { id: "topmenu-container" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-menu" },
          [
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container right-menu-item hover-effect",
                attrs: { trigger: "click" },
              },
              [
                _c(
                  "div",
                  { staticClass: "avatar-wrapper" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        slot: "prefix",
                        "icon-class": "personal-center",
                      },
                      slot: "prefix",
                    }),
                  ],
                  1
                ),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "dropdown-menu2",
                    staticStyle: { "z-index": "99999 !important" },
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _vm.name === "admin"
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "/user/profile/" + _vm.language,
                              },
                            },
                          },
                          [
                            _c("el-dropdown-item", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("settings.accountMenu1"))
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-dropdown-item",
                      {
                        attrs: { divided: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout($event)
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("settings.accountMenu2"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("el-divider", { staticClass: "nav-line" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }