<template>
  <!-- 初始数据浏览器-Home -->
  <div class="app-container">
    <div class="outer-box">
      <div class="left-box">
        <!-- 左侧树状结构 -->
        <div class="left-content">
          <div class="c-tree">
            <!-- 待选指标 -->
            <TreeDL :isLazy="isLazyForIndicator" :treeList="indicatorTreeLists"
              :treeTitle="vm.$t('treeTitle.indicatorName')" class="tree-list" ref="indTree"
              @handleTreeNodeClick="clickTreeIndicator" />

            <!-- 待选范围 -->
            <TreeDL :isLazy="isLazy" :treeList="scopeTreeLists" :treeTitle="vm.$t('treeTitle.scopeName')"
              class="tree-last-list" @handleTreeNodeClick="clickTreeIndex" ref="selfTree"
              @rightClick="handleRightClick" />
          </div>
        </div>
        <!-- 快速查找 输入框 -->
        <el-input :value="keyword" :placeholder="$t('tips.searchTip')" clearable size="small" prefix-icon="el-icon-search"
          class="tree-bottom-input" @input="serchByKeyWorlds" />
      </div>

      <div class="content">
        <!-- 功能区 -->
        <FuncSection @handleImport="importDB" @handleExport="exportDB" @handleExtract="extractData"
          @addSelfSelect="saveSelfSelection" @handleInstructions="instructions" />

        <!-- 右侧list -->
        <el-col class="c-list" v-show="!isShowInstructions">
          <!-- <el-scrollbar style="display: none"></el-scrollbar> -->
          <div class="out-div">
            <el-table v-loading="loading" class="table_DB" v-table-scroll="setTableData" ref="DBList"
              :data="dataBrowserList" width="100%" height="100%" border :row-class-name="TableRowColor">
              <template slot="empty">
                <!-- 表格无数据时的文字 -->
                <span class="empty-text">
                  {{ vm.$t("tips.emptyTableTip") }}</span>
              </template>
              <el-table-column type="index" :label="vm.$t('elTable.index')" width="70" align="center" fixed="left">
              </el-table-column>
              <el-table-column prop="seccode" :label="vm.$t('elTable.seccode')" width="120" align="center" fixed="left">
              </el-table-column>
              <el-table-column v-if="$store.getters.language === 'zh'" prop="secname" :label="vm.$t('elTable.secname')"
                width="180" align="center" fixed="left">
              </el-table-column>
              <el-table-column v-if="$store.getters.language === 'en'" prop="secnameEnFull"
                :label="vm.$t('elTable.secname')" width="200" align="center" fixed="left">
              </el-table-column>
              <!-- 操作 -->
              <el-table-column v-if="apiFlag && apiFlag === 'selfSelec'" :label="vm.$t('elbtn.operate')" width="100"
                fixed="left" align="center">
                <template slot-scope="scope">
                  <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">{{
                    vm.$t("elbtn.delete") }}</el-button>
                </template>
              </el-table-column>

              <!-- 动态列 -->
              <el-table-column class="dynamic-column" v-for="(item, index) in dynamicColumns"
                :key="item.indicatorCode + item.date + Math.random()" width="300">
                <template slot="header">
                  <DictTagDL :options1="treeClimateChildren1" :options2="treeCarbonDataChildren1"
                    :value="item.indicatorCode" class="dynamic-indicator-code" />
                  <div class="dynamic-indicator-other">
                    <span>-{{ item.date }}</span>
                    <i class="el-icon-remove" style="color: red; cursor: pointer" @click="deleteColumn(item)"></i>
                  </div>
                </template>

                <template slot-scope="scope" v-if="scope.row.indicator && scope.row.indicator[index]">
                  <p :class="`indicator-item-${index}`">
                    <!-- <span>{{ scope.row.indicator }}</span> -->
                    <span v-if="
                      item.indicatorCode == 't103' ||
                      item.indicatorCode == 't104'
                    ">
                      {{ scope.row.indicator[index].value || "" }}
                    </span>
                    <span v-else-if="item.indicatorCode == 't105'">
                      {{ scope.row.indicator[index].value | ChangeToPercent }}
                    </span>
                    <span v-else>
                      {{ scope.row.indicator[index].value | KeepTwo }}
                    </span>
                  </p>
                </template>
              </el-table-column>

              <template slot="append">
                <!-- 没有更多了 -->
                <div class="no-more" slot="no-results" v-show="!hasMore && isShowAlert">
                  <el-alert :title="vm.$t('tips.msg7')" type="warning" center class="lazy-alert">
                  </el-alert>
                </div>

                <!-- 加载中 -->
                <div class="no-more" slot="error" v-show="hasMore && isShowAlert">
                  <el-alert class="lazy-alert" :title="vm.$t('tips.msg8')" type="success" center :closable="false">
                  </el-alert>
                </div>
              </template>
            </el-table>
          </div>
        </el-col>
        <!-- 指示说明 -->
        <InstructionsEn v-if="isShowInstructions && lang === 'en'" @handleClose="isShowInstructions = false" />
        <InstructionsZh v-if="isShowInstructions && lang === 'zh'" @handleClose="isShowInstructions = false" />
      </div>
    </div>
    <!-- 关键字搜索模态框 -->
    <div class="dialog-box">
      <el-dialog :visible.sync="open" class="key-world-dialog" width="400px" @close="closeDialog" :modal="false">
        <el-table v-loading="searchLoading" :data="stockData" :show-header="false" height="140">
          <el-table-column property="seccode" :label="vm.$t('elTable.seccode')" width="70"></el-table-column>
          <el-table-column v-if="$store.getters.language === 'zh'" prop="secname"
            :label="vm.$t('elTable.secname')"></el-table-column>

          <el-table-column v-if="$store.getters.language === 'en'" prop="secnameEn"
            :label="vm.$t('elTable.secname')"></el-table-column>

          <el-table-column width="80">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleAddRow(scope.$index, scope.row)">{{ vm.$t("elbtn.add")
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>

    <!-- 导入EXCEL模态框 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="460px" append-to-body class="dialog_DB">
      <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers"
        :action="upload.url + '?updateSupport=' + upload.updateSupport" :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          {{ vm.$t("tips.importTip") }} <em>{{ vm.$t("tips.clickTip") }}</em>
        </div>
        <div class="el-upload__tip text-center" slot="tip">
          <span>{{ vm.$t("tips.fitmitTip") }}</span>
          <el-link type="primary" :underline="false" style="font-size: 12px; vertical-align: baseline"
            @click="downloadTemplate">
            {{ $t("tips.downloadTemplate") }}
          </el-link>
          <!-- 英文 -->
          <!-- <el-link
            v-if="$store.getters.language === 'en'"
            type="primary"
            :underline="false"
            style="font-size: 12px; vertical-align: baseline"
            href="https://wiki.governance-solutions.com/zyplayer-doc-wiki/common/file?uuid=5145c6a52a1848b49bc0f19670517cfe"
            >{{ vm.$t("elbtn.download") }}</el-link
          > -->
          <!-- 中文 -->
          <!-- <el-link
            v-if="$store.getters.language === 'zh'"
            type="primary"
            :underline="false"
            style="font-size: 12px; vertical-align: baseline"
            href="https://wiki.governance-solutions.com/zyplayer-doc-wiki/common/file?uuid=08e6cf01bd454a43be5f948dfabb1f50"
            >下载模板</el-link
          > -->
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">{{
          vm.$t("elbtn.confirm")
        }}</el-button>
        <el-button class="right_btn" @click="upload.open = false">{{
          vm.$t("elbtn.cancel")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 修改/添加自选清单模态框 -->
    <SelfSelectDialog :visible="selfSelectOpen" ref="formSelfSelection" :titleSelfSelect="titleSelfSelect"
      :selfSelName="selfForm.name" @submit="submitSelfSelect" @handleClose="handleClose" />
    <!-- 指标模态框 -->
    <IndicatorDialog :visible="indicatorOpen" @submit="submit" @handleClose="handleClose" ref="formIndicator" />
    <!-- 右键菜单模态框 -->
    <FolderOption :optionCardShow="optionCardOpen" :optionCardX="optionCardX" :optionCardY="optionCardY" @remove="remove"
      @rename="rename" />
  </div>
</template>

<script>
import FuncSection from "@/components/FuncSectionDL/index.vue";
import TreeDL from "@/components/Tree/TreeDL.vue";
import cache from "@/plugins/cache";
import { getToken } from "@/utils/auth";
import FolderOption from "@/components/FolderOption/index.vue";
import InstructionsEn from "./components/instructionsDB_En.vue";
import InstructionsZh from "./components/instructionsDB_Zh.vue";
import IndicatorDialog from "./components/indicatorDialogDB.vue";
import SelfSelectDialog from "./components/selfSelectDialogDB.vue";
import downloadMixin from '@/utils/mixin/downloadMixin'

import {
  indicatorTreeLists,
  getDictsForTree,
} from "@/utils/data-platform/constens.js";
import {
  getStockCarbonIndicator,
  delSelfSelectionDetail,
  delSelfSelection,
  getAllSelfSelection,
  getStocksBySelfSelec,
  addSelfSelection,
  updateSelfSelection,
  getStocksByExport,
  addStockToSelfSelection,
  importCheck,
  addStockToImport,
} from "@/api/data-platform/dataBrowser.js";

export default {
  name: "DataBrowser",
  components: {
    TreeDL,
    FuncSection,
    FolderOption,
    InstructionsEn,
    InstructionsZh,
    IndicatorDialog,
    SelfSelectDialog,
  },
  mixins: [downloadMixin],
  data() {
    const vm = window.vm; //多语言
    return {
      loading: false, //页面遮罩层
      vm: vm, //多语言
      dataBrowserList: [], //结果区list
      hasMore: undefined, //是否还有更多数据
      isShowAlert: false,
      loadFinished: false, //标记API是否已返回
      totalPage: 0, //总页数
      keyword: undefined, //关键词搜索
      stocksAllList: [],
      open: false, //关键字搜索框
      stockData: [], //关键字搜索弹框数据
      searchLoading: false, //搜索弹出框遮罩层
      isLazy: true, //树状图是否显示loading
      isLazyForIndicator: true,
      optionCardOpen: false, //右键菜单弹框
      optionCardX: 0,
      optionCardY: 0,
      treeId: undefined, //树结构节点id
      apiFlag: undefined, //标记掉哪个API/getResultList
      titleSelfSelect: "", //自选清单弹框标题
      selfSelectOpen: false, //自选清单弹框
      selfLabelArr: [], //所有自选清单名字
      indicatorOpen: false, //指标弹框

      //当前指标信息
      indicatorInfo: {
        indicator: undefined, //指标id/指标字典值
        value: undefined, //指标名字
        whichYear: undefined, //交易时间
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 40,
        indicatorList: [], //指标信息
        index: undefined, //指数类型
        seccodeList: [], //seccode代码列表
        importId: "", //用于excel导入后回显数据
        selfSelectionId: "", //自选清单id
        stockList: [], //追加股票
        lang: "en", //当前语言
      },

      selfForm: {
        name: "", //自选清单名称
        id: undefined, //清单id
      },
      // 导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: "Bearer " + getToken() },
        // 导入--自选清单
        url:
          process.env.VUE_APP_BASE_API +
          "/carbon-data/dataBrowserImportDetail/uploadFile",
      },
      dynamicColumns: [], //存放动态列
      //自选清单
      scopeTreeLists: [
        {
          arr: [
            {
              flag: "selfSelect",
              children: [],
            },
          ],
        },
      ],
      isShowInstructions: false, //是否显示指示说明
      indicatorTreeLists: indicatorTreeLists,
      treeClimateChildren1: [], //指标--气候相关风险
      treeCarbonDataChildren1: [], //指标--碳排放数据
      countIndicator: [], //点击了几次指标
      indicatorSelected: [], //已经选择的指标
      seccodeArr: [], //股票集合用于追加
      hasDownloadTemplate: false,
      templateType: "carbon",
      templateTitle: "",//下载模板题目
    };
  },
  created() {
    this.getSelfSelection(); // 获取自选清单
    this.getAllStock(); //获取所有股票信息
  },
  computed: {
    lang() {
      return this.$store.getters.language;
    },
    // 获取所有股票
    allStockList() {
      return this.$store.state.dataPlatform.allStockList;
    },
  },
  watch: {
    lang: {
      handler(newV, oldV) {
        this.templateTitle = this.$t("tips.templateTitleDataBrowser");//下载模板
        this.upload.title = this.vm.$t("selfSelectTip.uploadTitle");//导入弹窗标题
        if (oldV !== newV) {
          if (!newV) {
            this.getDictsIndicator(oldV); //根据当前语言，重新获取字典值
            this.queryParams.lang = oldV;
          } else {
            this.getDictsIndicator(newV);
            this.queryParams.lang = newV;
          }
          this.hasDownloadTemplate = false;
        }
      },
      deep: true,
      immediate: true,
    },
    // 点击除菜单外其他区域,隐藏菜单
    optionCardOpen(val) {
      if (val) {
        document.body.addEventListener("click", (event) => {
          var currentCli = document.getElementById("option-button-group");
          if (currentCli) {
            if (!currentCli.contains(event.target)) {
              this.optionCardOpen = false;
            }
          }
        });
      } else {
        document.body.removeEventListener("click", () => { });
      }
    },

    keyword: {
      handler(newV, oldV) {
        // 监听输入框变化，输入值为空，隐藏dialog
        if (!newV || newV.length < 1) {
          this.open = false;
        } else {
          this.open = true;
        }
      },
    },
    // 点击除菜单外其他区域,隐藏搜索dialog
    open(val) {
      if (val) {
        document.body.addEventListener("click", (event) => {
          let currentDia = document.getElementsByClassName("dialog-box")[0]; //弹框
          let currentInput =
            document.getElementsByClassName("tree-bottom-input")[0]; //input输入框
          if (currentDia && currentInput) {
            if (
              !currentDia.contains(event.target) &&
              !currentInput.contains(event.target)
            ) {
              this.open = false;
            }
          }
        });
      } else {
        document.body.removeEventListener("click", () => { });
      }
    },
  },
  methods: {
    // 指示说明
    instructions() {
      this.isShowInstructions = true; // 显示指示说明组件
    },
    // 获取指标字典值
    getDictsIndicator(lang) {
      if (this.$refs.indTree) {
        this.$refs.indTree.openLoading(); //开启loading
      }
      let arrClimate = [],
        arrCarbonData = [];
      // 如果中文，传中文key值
      if (lang == "zh") {
        this.treeClimateChildren1 = getDictsForTree("tree_climate_risk_zh_cn");
        this.treeCarbonDataChildren1 = getDictsForTree("carbon_data_zh_cn");
      } else if (lang == "en") {
        this.treeClimateChildren1 = getDictsForTree("tree_climate_risk_en");
        this.treeCarbonDataChildren1 = getDictsForTree("carbon_data_en");
      }
      this.indicatorTreeLists[1].arr[0].label = vm.$t("treeTitle.labelClimate");
      this.indicatorTreeLists[0].arr[0].label = vm.$t(
        "treeTitle.labelCarbonData"
      );

      this.indicatorTreeLists[1].arr[0].children = this.treeClimateChildren1;
      this.indicatorTreeLists[0].arr[0].children = this.treeCarbonDataChildren1;

      this.isLazyForIndicator = false;
      if (this.$refs.indTree) {
        this.$refs.indTree.hideLoading(); //关闭loading
      }
    },

    // 获取自选清单
    getSelfSelection() {
      let children = [];
      let that = this;
      if (this.$refs.selfTree) {
        this.$refs.selfTree.openLoading(); //开启loading
      }
      that.selfLabelArr = []; //清空list
      getAllSelfSelection()
        .then(({ data }) => {
          let arr = data;
          arr.map((item) => {
            children.push({
              id: item.id,
              label: item.name,
              type: "selfSelec",
            });
            that.selfLabelArr.push({ label: item.name }); //所有自选清单的名字
          });

          this.scopeTreeLists[0].arr[0].children = children; //自选清单tree list
          if (this.$refs.selfTree) {
            this.$refs.selfTree.hideLoading(); //关闭loading
          }
        })
        .catch((error) => {
          console.log(error);
          this.$refs.selfTree.hideLoading(); //关闭loading
        });
    },

    // 获取结果区列表(导入，自选清单，追加)
    getResultList(APIFlag = "") {
      this.loadFinished = false; //标记异步接口是否已执行完
      // 自选清单list
      if (APIFlag === "selfSelec") {
        let obj = {
          selfSelectionId: "",
          pageNum: 1,
          pageSize: 40,
        };
        obj.selfSelectionId = this.queryParams.selfSelectionId;
        obj.pageNum = this.queryParams.pageNum;
        obj.pageSize = this.queryParams.pageSize;
        getStocksBySelfSelec(obj)
          .then((data) => {
            this.pagination(data);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.loadFinished = true;
            console.log(err);
          });
      } else if (APIFlag === "exportData") {
        //导入
        let obj = {
          importId: "",
          pageNum: 1,
          pageSize: 40,
        };
        obj.importId = this.queryParams.importId;
        obj.pageNum = this.queryParams.pageNum;
        obj.pageSize = this.queryParams.pageSize;
        getStocksByExport(obj)
          .then((data) => {
            this.pagination(data);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.loadFinished = true;
            console.log(err);
          });
      }
    },

    //获取结果区列表(提取)
    getResultListForExtract() {
      this.loadFinished = false; //标记异步接口是否已执行完
      if (this.queryParams.selfSelectionId !== "") {
        //提取自选清单
        let obj = {
          selfSelectionId: "",
          pageNum: 1,
          pageSize: 40,
        };
        obj.selfSelectionId = this.queryParams.selfSelectionId;
        obj.pageNum = this.queryParams.pageNum;
        obj.pageSize = this.queryParams.pageSize;
        getStocksBySelfSelec(obj)
          .then((data) => {
            this.getStockCarbonIndicator(data); //调用提取接口
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.queryParams.importId !== "") {
        //提取导入
        let obj = {
          importId: "",
          pageNum: 1,
          pageSize: 40,
        };
        obj.importId = this.queryParams.importId;
        obj.pageNum = this.queryParams.pageNum;
        obj.pageSize = this.queryParams.pageSize;
        getStocksByExport(obj)
          .then((data) => {
            this.getStockCarbonIndicator(data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        //提取追加
        this.getStockCarbonIndicator(this.seccodeArr);
      }
    },

    // 滚动触发
    setTableData() {
      if (this.loadFinished && this.hasMore) {
        // 大于等于总页数
        this.queryParams.pageNum++; //当前页面加1

        if (this.apiFlag === "selfSelec" || this.apiFlag === "exportData") {
          this.getResultList(this.apiFlag);
        } else {
          this.getResultListForExtract();
        }
      }
    },

    pagination(data) {
      let res = data;
      this.loadFinished = true;
      this.isShowAlert = true;
      this.totalPage = Math.ceil(res.total / this.queryParams.pageSize); //总页数

      //当前页码大于总页数
      if (this.queryParams.pageNum > this.totalPage) {
        this.hasMore = false; //没有更多了
      } else if (this.queryParams.pageNum < this.totalPage) {
        //当前页码小于总页数
        this.hasMore = true;
        //提取接口,返回值为data
        this.dataBrowserList =
          this.apiFlag === "extractData"
            ? this.dataBrowserList.concat(res.data)
            : this.dataBrowserList.concat(res.rows);

        this.$nextTick(() => {
          this.$refs.DBList.doLayout(); //每次改变list数据，都重新渲染table
        });
      } else if (this.queryParams.pageNum == this.totalPage) {
        //当前页码等于总页数
        this.hasMore = false;
        // this.dataBrowserList = this.dataBrowserList.concat(res.rows);
        this.dataBrowserList =
          this.apiFlag === "extractData"
            ? this.dataBrowserList.concat(res.data)
            : this.dataBrowserList.concat(res.rows);

        this.$nextTick(() => {
          this.$refs.DBList.doLayout();
        });
      }
    },

    //提取接口
    getStockCarbonIndicator(data) {
      this.queryParams.lang = this.$store.getters.language; //语言
      // 通过导入/自选清单的方式添加证券，点击提取
      if (
        (this.queryParams.selfSelectionId !== "" ||
          this.queryParams.importId !== "") &&
        data !== null
      ) {
        let res = data; //参数：seccodeList
        res.rows.map((o) => {
          this.queryParams.seccodeList.push(o.seccode);
        });
        getStockCarbonIndicator(this.queryParams)
          .then((respons) => {
            this.isShowAlert = true;
            this.dataBrowserList = []; //清空列表
            this.pagination(respons); //下拉分页
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.loadFinished = true;
          });
      } else {
        // 通过动态追加的方式添加证券，点击提取
        data.map((o) => {
          this.queryParams.seccodeList.push(o.seccode);
        });
        getStockCarbonIndicator(this.queryParams)
          .then(({ data }) => {
            this.isShowAlert = true;
            this.dataBrowserList = data; //清空列表
            // this.pagination(respons); //下拉分页
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.loadFinished = true;
          });
      }
    },
    //点击提取按钮
    extractData() {
      this.isShowInstructions = false; //隐藏指示说明
      this.countIndicator = []; //清空指标
      let ind = Object.assign([], this.indicatorSelected); //指标信息
      this.queryParams.indicatorList = ind; //指标-参数
      this.queryParams.seccodeList = []; //每次点击提取按钮，清空股票参数
      if (
        !this.dataBrowserList ||
        this.dataBrowserList <= 0 ||
        !this.dynamicColumns ||
        this.dynamicColumns <= 0
      ) {
        this.$notify({
          title: this.vm.$t("tips.warning"), // 判断是否已经添加了指标
          message: this.vm.$t("tips.msg2"),
          type: "warning",
          offset: 100,
        });
      } else {
        // 判断如果是导出，数据上传校验
        let importID = this.queryParams.importId || "0";
        importCheck(importID).then(({ data }) => {
          if (data.flag) {
            this.$modal
              .confirmForImportCheck(
                // data.msg
                "<div style='max-height: 200px;word-break: break-word; overflow-y: auto;'>" +
                data.msg +
                "</div>",
                this.vm.$t("importCheck.checkTitle")
              )
              .then(() => {
                this.queryParams.pageNum = 1;
                this.apiFlag = "extractData";
                this.loading = true; //显示loading效果
                this.dataBrowserList = []; //清空列表
                // 每次点击，让滚动条回到顶部
                this.$nextTick(() => {
                  this.$refs.DBList.bodyWrapper.scrollTop = 0;
                });
                this.getResultListForExtract();
              })
              .catch(() => {
                this.note = null;
                this.fullscreenLoading = false;
              });
          } else {
            this.queryParams.pageNum = 1;
            this.apiFlag = "extractData";
            this.loading = true; //显示loading效果
            this.dataBrowserList = []; //清空列表
            // 每次点击，让滚动条回到顶部
            this.$nextTick(() => {
              this.$refs.DBList.bodyWrapper.scrollTop = 0;
            });
            this.getResultListForExtract();
          }
        });
      }
    },

    // 获取所有股票
    getAllStock() {
      if (cache.local.getJSON("ALL_STOCK")) {
        this.stocksAllList = cache.local.getJSON("ALL_STOCK");
      } else {
        this.$store.dispatch("getAllStocks").then((response) => {
          this.stocksAllList = JSON.parse(JSON.stringify(response));
        });
      }
    },
    // 关闭关键字弹出框
    closeDialog() {
      this.open = false;
      this.keyword = "";
    },

    // 关键词模糊搜索
    serchByKeyWorlds(keyword) {
      this.isShowInstructions = false; //隐藏指示说明
      this.open = true; // 2.展示模态框
      this.searchLoading = true;
      let arr = [];
      this.keyword = keyword; //用于显示
      if (keyword !== "" && keyword !== null && keyword !== undefined) {
        // 模糊搜索-股票代码，股票中文名称，股票英文简称
        arr = this.stocksAllList.filter((item) => {
          return (
            String(item.secname).indexOf(keyword) > -1 ||
            String(item.seccode).indexOf(keyword) > -1 ||
            String(item.secnameEn).indexOf(keyword) > -1 ||
            String(item.secnameEnFull).indexOf(keyword) > -1
          );
        });
      }
      let n = arr.length > 10 ? 10 : arr.length;
      this.stockData = arr.slice(0, n); //每次最多渲染10条结果值
      this.searchLoading = false; //隐藏loading
    },

    // 动态追加行
    handleAddRow(i, row) {
      // 判断是否重复添加
      if (this.dataBrowserList.some((o) => o.seccode === row.seccode)) {
        this.$modal.msgError(`${row.seccode} ${this.vm.$t("tips.msg4")}`);
      } else {
        this.dataBrowserList.unshift(row);
        let list = JSON.parse(JSON.stringify(this.dataBrowserList));
        this.seccodeArr = list;

        // 有自选清单id，add
        if (this.queryParams.selfSelectionId) {
          this.queryParams.stockList = []; //每次只追加一条数据,所以要清空数据
          this.queryParams.stockList.push({
            seccode: row.seccode,
            secname: row.secname,
            secnameEn: row.secnameEn,
            secnameEnFull: row.secnameEnFull,
          });
          addStockToSelfSelection(this.queryParams)
            .then((data) => {
              this.$modal.msgSuccess(this.vm.$t("selfSelectTip.stockTip"));
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (this.queryParams.importId) {
          // 有importIid，add
          let obj = {
            importId: this.queryParams.importId,
            seccode: row.seccode,
            secname: row.secname,
            secnameEn: row.secnameEn,
            secnameEnFull: row.secnameEnFull,
          };

          addStockToImport(obj)
            .then((data) => {
              this.$modal.msgSuccess(this.vm.$t("selfSelectTip.stockTip"));
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    // 重置参数
    resetQueryParams() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 40,
        indicatorList: [], //指标信息
        index: undefined, //指数类型
        seccodeList: [], //seccode代码列表
        importId: "", //用于excel导入后回显数据
        selfSelectionId: "", //自选清单id
        stockList: [], //追加股票
        lang: "en", //当前语言
      };
    },
    // 重置自选清单表单
    resetSelfForm() {
      this.selfForm = {
        name: "", //自选清单名称
        id: undefined, //清单id
      };
    },
    //2.导入
    importDB() {
      // this.upload.title = this.vm.$t("selfSelectTip.uploadTitle");
      this.upload.open = true;
      this.resetQueryParams();
      this.isShowInstructions = false; //隐藏指示说明
    },
    // 5.文件上传中处理---样式调整
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },

    // 6.导入成功处理
    // 将dangerouslyUseHTMLString属性设置为 true， response.msg 就会被当作 HTML 片段处理
    handleFileSuccess(response, file, fileList) {
      this.indicatorSelected = [];
      this.resetQueryParams(); //清空参数
      this.dataBrowserList = [];
      this.countIndicator = []; //清空指标数组
      this.dynamicColumns = []; // 清除动态列
      this.upload.open = false; //上传成功，关闭弹出框
      this.upload.isUploading = false; //状态
      this.$refs.upload.clearFiles();

      // 提示信息
      this.$alert(
        "<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" +
        response.msg +
        "</div>",
        this.vm.$t("selfSelectTip.result"),
        { dangerouslyUseHTMLString: true }
      );

      // 渲染结果区
      if (
        response.code == 200 &&
        response.data.id !== "" &&
        response.data.id !== null
      ) {
        this.queryParams.importId = response.data.id; //excel id
        this.apiFlag = "exportData";
        this.queryParams.pageNum = 1; //重置页码,避免追加数据
        this.loading = true; //显示loading效果
        this.getResultList(this.apiFlag); //获取列表
      }
    },
    // 7.提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },

    // 3.导出-参数：接口地址，接口参数
    exportDB() {
      let ind = Object.assign([], this.indicatorSelected); //指标信息
      this.queryParams.indicatorList = ind; //指标
      this.isShowInstructions = false; //隐藏指示说明
      // 判断是否已经添加了指标
      this.queryParams.seccodeList = []; //每次点击提取清空参数：股票代码list
      if (
        this.apiFlag !== "extractData" ||
        this.countIndicator.length > 0 ||
        !this.queryParams.indicatorList ||
        this.queryParams.indicatorList.length < 1
      ) {
        this.$notify({
          title: this.vm.$t("tips.warning"),
          message: this.vm.$t("tips.msg3"),
          type: "warning",
          offset: 100,
        });
      } else {
        let data = {
          indicatorList: [],
          seccodeList: [],
          selfSelectionId: "", //清单id
          importId: "", //导入id
          lang: "en",
        };
        this.dataBrowserList.map((o) => {
          data.seccodeList.push(o.seccode);
        });
        data.selfSelectionId = this.queryParams.selfSelectionId;
        data.importId = this.queryParams.importId;
        data.lang = this.queryParams.lang;
        data.indicatorList = this.queryParams.indicatorList;
        this.download(
          "/carbon-data/stockCarbonIndicator/export",
          data,
          `${this.vm.$t("export.title")}_${new Date().getTime()}.xlsx`
        );
      }
    },

    // 10.点击左侧树结构--指标
    clickTreeIndicator(data, node) {
      this.isShowInstructions = false; //隐藏指示说明
      this.optionCardOpen = false;

      // true:是叶子节点
      // 点击叶子节点，如果节点id和上次的id相同，则关闭或开启弹窗;否则显示弹框
      if (node.isLeaf) {
        if (this.treeId == data.id) {
          this.indicatorOpen = !this.indicatorOpen; //关闭或开启弹窗
        } else {
          this.indicatorOpen = true; //显示弹框
          this.treeId = data.id;
        }

        this.indicatorInfo.code = data.id; //指标字典值
        this.indicatorInfo.value = data.label;
      }
    },

    //指标弹框-取消
    handleClose() {
      this.indicatorOpen = false;
      this.selfSelectOpen = false;
      this.$refs["formIndicator"].reset(); //表单重置
      this.$refs["formSelfSelection"].reset();
    },

    //指标弹框-确定
    submit(indicatorDate) {
      let valid = this.$refs["formIndicator"].validate();
      if (valid) {
        this.indicatorInfo.whichYear = new Date(indicatorDate).getFullYear();
        let copy = Object.assign({}, this.indicatorInfo); //指标信息
        // 如已存在, 避免重复添加指标
        if (
          this.dynamicColumns.length > 0 &&
          this.dynamicColumns.some(
            (item) =>
              item.indicatorCode &&
              item.indicatorCode == copy.code &&
              item.date == copy.whichYear
          )
        ) {
          this.$notify({
            title: this.vm.$t("tips.warning"),
            message: `${copy.value} ${this.vm.$t("tips.msg4")}`,
            type: "warning",
            offset: 100,
          });
        } else {
          // 用于判断导出按钮是否可用
          this.countIndicator.push({
            indicatorCode: copy.code,
            date: copy.whichYear,
          });

          // 渲染动态表头
          this.dynamicColumns.push({
            indicatorCode: copy.code,
            date: copy.whichYear,
          });

          this.indicatorSelected.push({
            whichYear: String(copy.whichYear),
            indicator: copy.code,
          });

          this.$nextTick(() => {
            this.$refs.DBList.doLayout();
          });
        }
        this.indicatorOpen = false; //隐藏弹框
        this.$refs["formIndicator"].reset(); //表单重置
      }
    },

    // 11.点击左侧树结构--指数或自选清单
    clickTreeIndex(data, node) {
      this.isShowInstructions = false; //隐藏指示说明
      this.optionCardOpen = false; //隐藏右键菜单

      if (
        node.isLeaf &&
        data.id !== undefined &&
        data.id !== "" &&
        data.id !== null
      ) {
        this.indicatorSelected = []; //清空指标列表
        this.countIndicator = []; //清空指标计数器
        this.resetQueryParams(); //清空参数

        // 点击叶子节点，如果节点id和上次的id相同，则关闭或开启弹窗;否则显示弹框
        if (this.treeId == data.id) {
          return true;
        } else {
          this.loading = true; //显示loading效果
          this.treeId = data.id;
          this.dynamicColumns = []; // 清除动态列

          this.queryParams.pageNum = 1;
          this.queryParams.index = ""; //指数字典值/指数id
          this.queryParams.selfSelectionId = data.id; //自选清单ID
          this.dataBrowserList = []; //清空list
          this.apiFlag = data.type;
          // 每次点击自选清单，让滚动条回到顶部
          this.$nextTick(() => {
            this.$refs.DBList.bodyWrapper.scrollTop = 0;
          });
          this.getResultList(this.apiFlag);
        }
      }
    },
    // 13.删除动态列
    deleteColumn(value) {
      // 动态列(删除表头)
      this.dynamicColumns.map((item, index) => {
        if (
          item.indicatorCode == value.indicatorCode &&
          item.date == value.date
        ) {
          this.dynamicColumns.splice(index, 1);
        }
      });

      //请求参数指标(删除参数项)
      let copy = this.indicatorSelected;
      copy.map((item, index) => {
        if (
          item.indicator == value.indicatorCode &&
          item.whichYear == value.date
        ) {
          copy.splice(index, 1);
        }
      });

      this.indicatorSelected = copy; //参数

      this.queryParams.indicatorList.map((item, index) => {
        //同时把参数中的项删除
        if (
          item.indicator == value.indicatorCode &&
          item.whichYear == value.date
        ) {
          this.queryParams.indicatorList.splice(index, 1);
        }
      });

      // 删除表头的同时,删除列表项
      let list = JSON.parse(JSON.stringify(this.dataBrowserList));
      list.forEach((item) => {
        if (item.indicator) {
          item.indicator.map((o, i) => {
            if (o.code == value.indicatorCode && o.whichYear == value.date) {
              item.indicator.splice(i, 1);
            }
          });
        }
      });

      let i = this.countIndicator.findIndex((item) => {
        return (
          item.indicatorCode === value.indicatorCode && item.date === value.date
        );
      });

      if (i >= 0) this.countIndicator.splice(i, 1); //计数选中的指标个数
      this.dataBrowserList = list;
    },

    //14.删除自选清单-行
    handleDelete(row) {
      let obj = {
        id: "",
      };
      obj.id = row.id || "";
      this.$modal
        .confirm(this.vm.$t("tips.msg6"))
        .then(function () {
          return delSelfSelectionDetail(obj);
        })
        .then(() => {
          // 获取股票list
          this.loading = true;
          this.dataBrowserList = [];
          this.queryParams.pageNum = 1;
          this.getResultList(this.apiFlag);
          this.$modal.msgSuccess(this.vm.$t("tips.selfTip4"));
        })
        .catch((err) => { });
    },

    // 15.保存为自选清单
    saveSelfSelection() {
      this.isShowInstructions = false; //隐藏指示说明
      this.resetSelfForm(); //重置表单
      // 校验是否有股票
      if (!this.dataBrowserList || this.dataBrowserList <= 0) {
        this.$notify({
          title: this.vm.$t("tips.warning"),
          message: this.vm.$t("tips.msg"),
          type: "warning",
          offset: 100,
        });
      } else {
        this.titleSelfSelect = this.vm.$t("selfSelect.dialogTitle");
        // 显示弹出框
        this.selfSelectOpen = true;
      }
    },
    // 16.修改/添加自选清单
    submitSelfSelect(selfForm) {
      let valid = this.$refs["formSelfSelection"].validate();
      if (valid) {
        let obj = {
          id: undefined, //自选清单id
          name: undefined,
          stockList: [], //公司信息
          selfSelectionId: "", //自选清单id
          importId: undefined, //导入id
        };
        obj.name = selfForm.name; //清单名字

        // 重名校验
        if (this.selfLabelArr.some((s) => s.label === selfForm.name)) {
          this.$message.error(this.vm.$t("selfSelectTip.selfTip1"));
        } else {
          if (this.selfForm.id != undefined) {
            obj.id = this.selfForm.id; //自选清单id
            updateSelfSelection(obj).then((response) => {
              this.$modal.msgSuccess(this.vm.$t("selfSelectTip.selfTip2"));
              this.selfSelectOpen = false;
              this.getSelfSelection(); //重新get自选清单list
              this.$refs["formSelfSelection"].reset(); //表单重置
            });
          } else {
            obj.selfSelectionId = this.queryParams.selfSelectionId; //自选清单id
            obj.importId = this.queryParams.importId;
            obj.stockList = this.dataBrowserList || [];

            addSelfSelection(obj).then((response) => {
              this.$modal.msgSuccess(this.vm.$t("selfSelectTip.selfTip3"));
              this.selfSelectOpen = false;
              this.getSelfSelection(); //重新get自选清单list
              this.$refs["formSelfSelection"].reset(); //表单重置
            });
          }
        }
      }
    },

    // 17.右键点击自选清单
    handleRightClick(e, data) {
      this.isShowInstructions = false; //隐藏指示说明
      //子节点
      if (!data.children) {
        this.optionCardOpen = false; //防止多次点击
        this.optionCardX = e.x; //位置
        this.optionCardY = e.y;
        this.selfForm.id = data.id; //清单id
        this.selfForm.name = data.label || "";
        this.optionCardOpen = true; //开启弹窗
      }
    },

    // 删除自选清单
    remove() {
      let data = {
        id: "",
      };
      data.id = this.selfForm.id;

      this.optioncardshow = false;
      this.$modal
        .confirm(this.vm.$t("tips.msg5"))
        .then(function () {
          return delSelfSelection(data);
        })
        .then(() => {
          this.dataBrowserList = []; // 清空结果区
          this.getSelfSelection(); //重新get自选清单list
          this.$modal.msgSuccess(this.vm.$t("tips.selfTip4"));
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //修改自选清单
    rename() {
      this.optionCardOpen = false; //关闭菜单
      this.titleSelfSelect = this.vm.$t("selfSelect.dialogTitle2"); //弹框标题
      this.selfSelectOpen = true; //开启自选清单弹框
    },

    //table隔行变色
    TableRowColor({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  overflow: unset;
}

.app-main {
  min-height: unset;
}

.outer-box {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;

  // 左侧树状结构
  .left-box {
    max-height: calc(100vh - 220px);
    margin-right: 20px;
    margin-top: 50px;

    .c-tree {
      height: calc(100vh - 215px);
      display: flex;
      flex-direction: column;
    }

    .tree-list,
    .tree-last-list {
      flex: 1;
      overflow-y: hidden;
      margin-bottom: 5px;
      padding-bottom: 42px;
    }
  }

  // 底部搜索框
  .tree-bottom-input {
    padding: 10px 0;
    position: fixed;
    bottom: 58px;
    width: 245px;

    .el-input__inner {
      width: 600px;
    }
  }
}

::v-deep .el-table__body-wrapper .is-scrolling-right,
::v-deep .el-table__body-wrapper .is-scrolling-left,
::v-deep .el-table__body-wrapper .is-scrolling-none {
  height: 100% !important;
}

// 右侧版心
.content {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 140px);
  position: relative;
  width: 100%;

  // 滚动条高度
  ::v-deep .el-table--scrollable-y .el-table__body-wrapper {
    // height: calc(100vh - 295px) !important;
    // height: 100%;
  }

  ::v-deep .el-table {
    border-radius: 6px;
    max-width: calc(100vw - 300px);
    max-height: calc(100vh - 175px);
  }

  .out-div {
    height: 100%;
  }

  // 固定列表项
  ::v-deep .el-table__body-wrapper.is-scrolling-none~.el-table__fixed {
    box-shadow: revert;
    border-right: 1px dashed #e7e7e8;
    max-height: calc(100vh - 175px);
  }

  .c-list {
    height: 100%;

    // 表头样式
    ::v-deep .el-table .el-table__header-wrapper th,
    ::v-deep .el-table .el-table__fixed-header-wrapper th,
    ::v-deep .el-table__fixed-right-patch,
    ::v-deep .el-table__header-wrapper {
      background-color: #dfebf9;
    }

    ::v-deep .el-table .el-table__fixed-header-wrapper th,
    ::v-deep .el-table__fixed {
      color: #1f5080;
    }

    ::v-deep .el-table__fixed::before,
    ::v-deep .el-table__fixed-right::before {
      content: unset;
    }

    // 无数据
    ::v-deep .el-table__empty-block {
      width: 100% !important;
    }

    .el-alert--warning.is-light,
    .el-alert--success.is-light {
      background-color: transparent;
      color: #676878;
    }

    //无数据提示语
    .el-alert.lazy-alert {
      padding: 3px 16px;
      font-size: 12px;

      ::v-deep .el-alert__closebtn {
        display: none;
      }
    }

    ::v-deep .el-table--border th.el-table__cell.gutter:last-of-type {
      border-bottom: unset;
      border-bottom-width: unset;
    }

    ::v-deep .el-table tr {
      height: 40px;
    }

    ::v-deep .el-table--medium .el-table__cell,
    ::v-deep .el-table .el-table__cell {
      padding: unset;
    }

    // 动态列
    .dynamic-indicator-code,
    .dynamic-indicator-other {
      display: inline;
    }
  }

  // 表格隔行变色
  ::v-deep .table_DB .warning-row {
    background: #f7f8fb;
  }
}

// 关键词弹出框
.dialog-box {
  .key-world-dialog {
    position: fixed;
    top: unset;
    left: 20px;
    bottom: 137px;
    right: 0;
    width: 400px;
    height: 190px;
    overflow: unset;
    background-color: unset;
  }

  ::v-deep .el-dialog__body {
    padding: 10px;
    overflow-y: auto;
  }

  ::v-deep .el-dialog__header {
    padding: 10px;
  }

  ::v-deep .el-dialog__headerbtn {
    top: 4px;
    right: 10px;
  }

  ::v-deep .el-table--medium .el-table__cell {
    padding: 3px 0;
  }
}

// 导入弹框
.el-dialog__wrapper.dialog_DB {
  top: 50px;
}

.dialog_DB {

  // 导入弹框
  ::v-deep .el-dialog__body {
    padding: 30px 50px;
  }

  ::v-deep .el-message-box__content {
    padding: 10px 25px;
  }

  .dialog-footer {
    width: 360px;
    margin: 0 auto 20px auto;
    text-align: center;

    button {
      width: 170px;

      &.right_btn {
        margin-left: 20px;
      }
    }
  }

  ::v-deep .el-upload-dragger {
    background: linear-gradient(0deg, #f4f9ff 0%, rgba(223, 235, 249, 0) 100%);
  }
}

// 只用于大屏幕
@media screen and (min-width: 1790px) {

  // 右侧版心
  .content {
    max-height: calc(100vh - 180px);

    .c-list {
      ::v-deep .el-table {
        max-width: calc(100vw - 335px);
      }
    }
  }

  // 底部搜索框
  .tree-bottom-input {
    width: 275px;
  }
}

@media screen and (min-width: 1300px) {
  .dialog-box {

    // 关键词弹出框
    .key-world-dialog {
      position: fixed;
      bottom: 162px;
    }
  }
}
</style>