var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.visible,
            "custom-class": "api-edit-drawer",
            size: "90%",
            title: (_vm.apiId ? "修改" : "新增") + "API",
            wrapperClosable: false,
            "append-to-body": true,
          },
          on: { close: _vm.close },
        },
        [
          _c(
            "div",
            { staticClass: "api-content" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.detailInfo,
                    rules: _vm.formRules,
                    disabled: _vm.batchBind,
                    "label-width": "150px",
                  },
                },
                [
                  _c("div", { staticClass: "sub-title" }, [_vm._v("API定义")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "API名称：",
                                prop: "apiMeta.apiName",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                placement: "top",
                                                content:
                                                  "API名称是标识API的重要属性，它和API版本一起，可以标识唯一的API。API 名称建议使用英文输入，但也支持中文（在Postman或APIFox中使用中文时需要设置对中文的编码）",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-question",
                                              }),
                                            ]
                                          ),
                                          _c("span", [_vm._v("API名称：")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.detailInfo.apiMeta.apiId
                                    ? true
                                    : false,
                                  placeholder: "请输入API名称",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.textTrim(
                                      _vm.detailInfo.apiMeta,
                                      "apiName"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.detailInfo.apiMeta.apiName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.detailInfo.apiMeta,
                                      "apiName",
                                      $$v
                                    )
                                  },
                                  expression: "detailInfo.apiMeta.apiName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "API状态：",
                                prop: "apiMeta.apiStatus",
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  _vm.detailInfo.apiMeta.apiStatus === 1
                                    ? _c("el-tag", [_vm._v("开发中")])
                                    : _vm.detailInfo.apiMeta.apiStatus === 2
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("已发布")]
                                      )
                                    : _vm.detailInfo.apiMeta.apiStatus === 3
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("已停用")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "API版本：",
                                prop: "apiMeta.apiVersion",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                placement: "top",
                                                content:
                                                  "API版本是一个自动管理的属性， 是为了解决同名API配置变化而设立的属性。默认值为1。当对发布后的API进行修改时，API版本将自动将其同API名称的最大API版本加1",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-question",
                                              }),
                                            ]
                                          ),
                                          _c("span", [_vm._v("API版本：")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "请输入API版本",
                                },
                                model: {
                                  value: _vm.detailInfo.apiMeta.apiVersion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.detailInfo.apiMeta,
                                      "apiVersion",
                                      $$v
                                    )
                                  },
                                  expression: "detailInfo.apiMeta.apiVersion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "数据库：",
                                prop: "apiCategoryGroup",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择数据库" },
                                  model: {
                                    value:
                                      _vm.detailInfo.apiMeta.apiCategoryGroup,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detailInfo.apiMeta,
                                        "apiCategoryGroup",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "detailInfo.apiMeta.apiCategoryGroup",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "data_platform",
                                      value: "data_platform",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否保存为模板：",
                                prop: "apiAsTemplate",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                placement: "top",
                                                content:
                                                  "“是否保存模板”是API的一个布尔属性。此处配置者需了解关于模板和API的关系，即：我们可以通过模板名称找到对应的API, 但特定的API不一定是业务模板",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-question",
                                              }),
                                            ]
                                          ),
                                          _c("span", [
                                            _vm._v("是否保存为模板："),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.detailInfo.apiMeta.apiAsTemplate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detailInfo.apiMeta,
                                        "apiAsTemplate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "detailInfo.apiMeta.apiAsTemplate",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                              _vm.detailInfo.apiMeta.apiAsTemplate === 1
                                ? _c(
                                    "el-checkbox",
                                    {
                                      staticStyle: { "margin-left": "30px" },
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      on: {
                                        change: _vm.apiAsBusinessTemplateChange,
                                      },
                                      model: {
                                        value:
                                          _vm.detailInfo.apiMeta
                                            .apiAsBusinessTemplate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailInfo.apiMeta,
                                            "apiAsBusinessTemplate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailInfo.apiMeta.apiAsBusinessTemplate",
                                      },
                                    },
                                    [_vm._v("保存为数据模板，输出到数据浏览器")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.detailInfo.apiMeta.apiAsTemplate === 1 &&
                  _vm.detailInfo.apiMeta.apiAsBusinessTemplate === 1
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "日期选择类型：",
                                    prop: "apiMeta.apiDateType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      on: { change: _vm.apiDateTypeChange },
                                      model: {
                                        value:
                                          _vm.detailInfo.apiMeta.apiDateType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailInfo.apiMeta,
                                            "apiDateType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailInfo.apiMeta.apiDateType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dateFilterTypeList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _vm.detailInfo.apiMeta.apiDateType
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.startDateLabel,
                                        prop: "apiMeta.dataBeginDate",
                                      },
                                    },
                                    [
                                      [2].indexOf(
                                        _vm.detailInfo.apiMeta.apiDateType
                                      ) > -1
                                        ? _c("el-date-picker", {
                                            attrs: {
                                              "value-format": "yyyy-MM-dd",
                                              type: "date",
                                              placeholder: "请选择",
                                            },
                                            model: {
                                              value:
                                                _vm.detailInfo.apiMeta
                                                  .dataBeginDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailInfo.apiMeta,
                                                  "dataBeginDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailInfo.apiMeta.dataBeginDate",
                                            },
                                          })
                                        : [4].indexOf(
                                            _vm.detailInfo.apiMeta.apiDateType
                                          ) > -1
                                        ? _c("el-date-picker", {
                                            attrs: {
                                              "value-format": "yyyy",
                                              type: "year",
                                              placeholder: "请选择",
                                            },
                                            model: {
                                              value:
                                                _vm.detailInfo.apiMeta
                                                  .dataBeginDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailInfo.apiMeta,
                                                  "dataBeginDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailInfo.apiMeta.dataBeginDate",
                                            },
                                          })
                                        : [6].indexOf(
                                            _vm.detailInfo.apiMeta.apiDateType
                                          ) > -1
                                        ? _c("el-date-picker", {
                                            attrs: {
                                              "value-format": "yyyy-MM",
                                              type: "month",
                                              placeholder: "请选择",
                                            },
                                            model: {
                                              value:
                                                _vm.detailInfo.apiMeta
                                                  .dataBeginDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailInfo.apiMeta,
                                                  "dataBeginDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailInfo.apiMeta.dataBeginDate",
                                            },
                                          })
                                        : _vm.detailInfo.apiMeta.apiDateType ===
                                          5
                                        ? _c("ElQuarterPicker", {
                                            attrs: { valueFormat: "yyyy-q" },
                                            model: {
                                              value:
                                                _vm.detailInfo.apiMeta
                                                  .dataBeginDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailInfo.apiMeta,
                                                  "dataBeginDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailInfo.apiMeta.dataBeginDate",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detailInfo.apiMeta.apiAsTemplate === 1 &&
                  _vm.detailInfo.apiMeta.apiAsBusinessTemplate === 1
                    ? [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "日期标签文本：",
                                      prop: "apiMeta.apiDateTitle",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value:
                                          _vm.detailInfo.apiMeta.apiDateTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailInfo.apiMeta,
                                            "apiDateTitle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailInfo.apiMeta.apiDateTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否输出变更时间：",
                                      prop: "apiMeta.apiShowUpdate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.detailInfo.apiMeta
                                              .apiShowUpdate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detailInfo.apiMeta,
                                              "apiShowUpdate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailInfo.apiMeta.apiShowUpdate",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否输出交易所：",
                                      prop: "apiMeta.apiShowExchange",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.detailInfo.apiMeta
                                              .apiShowExchange,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detailInfo.apiMeta,
                                              "apiShowExchange",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailInfo.apiMeta.apiShowExchange",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "统计数据输出格式：",
                                      prop: "apiMeta.statisticsFiscalYear",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.detailInfo.apiMeta
                                              .statisticsFiscalYear,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detailInfo.apiMeta,
                                              "statisticsFiscalYear",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailInfo.apiMeta.statisticsFiscalYear",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("EffectiveDate")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("FiscalYear")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "API描述：",
                                prop: "apiDescription",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入API的描述信息",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.textTrim(
                                      _vm.detailInfo.apiMeta,
                                      "apiDescription"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.detailInfo.apiMeta.apiDescription,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.detailInfo.apiMeta,
                                      "apiDescription",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "detailInfo.apiMeta.apiDescription",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v("API行为设定"),
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "API请求方法：",
                                prop: "apiRequestMethod",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value:
                                      _vm.detailInfo.apiMeta.apiRequestMethod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detailInfo.apiMeta,
                                        "apiRequestMethod",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "detailInfo.apiMeta.apiRequestMethod",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "Post", value: "Post" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "Get", value: "Get" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "API操作模式：",
                                prop: "apiOperationType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value:
                                      _vm.detailInfo.apiMeta.apiOperationType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detailInfo.apiMeta,
                                        "apiOperationType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "detailInfo.apiMeta.apiOperationType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "查询数据", value: 0 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "数据是否需要缓存：",
                                prop: "apiSupportCache",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function (val) {
                                      return val === 1
                                        ? (_vm.detailInfo.apiData.apiLimitExp = 0)
                                        : (_vm.detailInfo.apiData.apiLimitExp = 1)
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.detailInfo.apiMeta.apiSupportCache,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detailInfo.apiMeta,
                                        "apiSupportCache",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "detailInfo.apiMeta.apiSupportCache",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("需要"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("不需要"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否过滤掉空数据：",
                                prop: "apiFilterNull",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.detailInfo.apiMeta.apiFilterNull,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detailInfo.apiMeta,
                                        "apiFilterNull",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "detailInfo.apiMeta.apiFilterNull",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("需要"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("不需要"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v("API安全设定"),
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "API访问权限码：",
                                prop: "apiAuthCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入API访问权限码" },
                                on: {
                                  blur: function ($event) {
                                    return _vm.textTrim(
                                      _vm.detailInfo.apiMeta,
                                      "apiAuthCode"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.detailInfo.apiMeta.apiAuthCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.detailInfo.apiMeta,
                                      "apiAuthCode",
                                      $$v
                                    )
                                  },
                                  expression: "detailInfo.apiMeta.apiAuthCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v("API帮助指引"),
                  ]),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-left": "20px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "#",
                                accept: ".pdf",
                                "before-upload": _vm.handleBeforeUpload,
                                "on-success": _vm.handleUploadSuccess,
                                "on-change": _vm.handleUploadChange,
                                "on-remove": _vm.handleRemoveFile,
                                "on-preview": _vm.handlePreview,
                                limit: 1,
                                "auto-upload": false,
                                "file-list": _vm.fileList,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "tip",
                                  fn: function () {
                                    return undefined
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { icon: "plus", text: "" } },
                                    [_vm._v(_vm._s(_vm.$t("上传文件")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-divider"),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v("API数据源设定"),
                  ]),
                  _vm.detailInfo.apiData.apiUseAdvModeForData === 0
                    ? _c("div", { staticClass: "edit-mode" }, [
                        _c("div", { staticClass: "left-content" }, [
                          _c(
                            "div",
                            { staticClass: "tree-data" },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _vm._v("数据表 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "top",
                                        content:
                                          "用户可以在当前数据库中选择业务需求定义的数据表作为API的数据源",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("TreeComponent", {
                                ref: "tree",
                                staticStyle: { height: "calc(100% - 37px)" },
                                attrs: {
                                  treeData: _vm.treeData,
                                  defaultExpandAll: "",
                                  defaultProps: {
                                    label: "TABLE_NAME",
                                    value: "TABLE_NAME",
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var data = ref.data
                                        return _c("span", {}, [
                                          data.children
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "700",
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class": "database",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.TABLE_NAME) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "custom-tree-node",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "node-label",
                                                      attrs: {
                                                        title:
                                                          data.TABLE_NAME +
                                                          "(" +
                                                          (data.TABLE_COMMENT ||
                                                            "") +
                                                          ")",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "node-mark",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              data.TABLE_TYPE ===
                                                                "BASE TABLE"
                                                                ? "T"
                                                                : "V"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.TABLE_NAME
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "node-btn" },
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "text",
                                                          disabled:
                                                            _vm.setIsDisabled(
                                                              data.TABLE_NAME
                                                            ),
                                                          icon: "el-icon-circle-plus-outline",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return (function () {
                                                              return _vm.dataTableClick(
                                                                data
                                                              )
                                                            })($event)
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                        ])
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3945640074
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "tree-data" },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [_vm._v("已有数据模板")]
                              ),
                              _c("TreeComponent", {
                                ref: "templateTree",
                                staticStyle: { height: "calc(100% - 37px)" },
                                attrs: {
                                  treeData: _vm.templateData,
                                  defaultExpandAll: "",
                                  defaultProps: {
                                    children: "children",
                                    label: "templateName",
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var data = ref.data
                                        return _c("span", {}, [
                                          data.children
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "700",
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class": "category",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.templateName
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "custom-tree-node",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "node-label",
                                                      attrs: {
                                                        title:
                                                          data.templateName,
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        attrs: {
                                                          "icon-class":
                                                            "data-table",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.templateName
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "node-btn" },
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "text",
                                                          icon: "el-icon-circle-plus-outline",
                                                          disabled:
                                                            _vm.setTemplateIsDisabled(
                                                              data.apiId
                                                            ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return (function () {
                                                              return _vm.templateClick(
                                                                data
                                                              )
                                                            })($event)
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                        ])
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2032122492
                                ),
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "right-content common-table-style" },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "20px" } },
                              [
                                _vm._v(" 数据源 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "top",
                                      content:
                                        "数据源 -- API 要输出数据的来源，可以是直接选定的数据表，也可以是某个定义为业务模板的API",
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-question" })]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  data: _vm.detailInfo.dataSourceMetaList.filter(
                                    function (e) {
                                      return e.deleted !== 1
                                    }
                                  ),
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    type: "index",
                                    label: "序号",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "数据库名",
                                    prop: "databaseName",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "数据表名",
                                    prop: "tableName",
                                    "min-width": "200",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "数据表别名",
                                    prop: "tableAlias",
                                    width: "200",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input", {
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.textTrim(
                                                    row,
                                                    "tableAlias"
                                                  )
                                                },
                                                change: function ($event) {
                                                  return _vm.tableAliasChange(
                                                    row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row.tableAlias,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "tableAlias",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.tableAlias",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3768416196
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "来源于模板",
                                    prop: "templateApiId",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getApiTemplateName(row)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3639645833
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "操作" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          var $index = ref.$index
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteDataSource(
                                                      row,
                                                      $index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3087046159
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "third-title" }, [
                              _c("span", [
                                _c(
                                  "span",
                                  [
                                    _vm._v(" 定义输出字段 "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "top",
                                          content:
                                            "您可以在“定义输出字段”区域定义选用要输出的API字段，设定其输出名称和输出的排序顺序。其中输出名称不填写的话即为该字段在数据表中的名称。输出顺序不填的话，默认为0， API中将以字段在表中的原有顺序进行输出。",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-question",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "24px" } },
                                  [
                                    _vm._v("已选"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#01579b" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.detailInfo.dataPointMetaList.filter(
                                                function (e) {
                                                  return (
                                                    e.chooseColumn === 1 &&
                                                    e.deleted !== 1
                                                  )
                                                }
                                              ).length
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v("条"),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-right": "4px" },
                                      attrs: {
                                        icon: "el-icon-plus",
                                        size: "small",
                                      },
                                      on: { click: _vm.addField },
                                    },
                                    [_vm._v("添加自定义字段")]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "top",
                                        content:
                                          "“自定义字段” 是为了提供对现有数据库字段进行补充，实现函数计算和自定义计算",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-table",
                              {
                                staticClass: "table-form",
                                attrs: {
                                  data: _vm.detailInfo.dataPointMetaList.filter(
                                    function (e) {
                                      return e.deleted !== 1
                                    }
                                  ),
                                  border: "",
                                  "max-height": "600px",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    type: "index",
                                    label: "序号",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "选用输出", width: "120px" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": 1,
                                                  "false-label": 0,
                                                },
                                                on: {
                                                  change: _vm.allCheckChange,
                                                },
                                                model: {
                                                  value: _vm.allCheck,
                                                  callback: function ($$v) {
                                                    _vm.allCheck = $$v
                                                  },
                                                  expression: "allCheck",
                                                },
                                              },
                                              [_vm._v("选用输出")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  "true-label": 1,
                                                  "false-label": 0,
                                                },
                                                model: {
                                                  value: row.chooseColumn,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "chooseColumn",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.chooseColumn",
                                                },
                                              },
                                              [_vm._v("选用")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2046070468
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "数据库名",
                                    prop: "databaseName",
                                    "show-overflow-tooltip": "",
                                    width: "120px",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "表名",
                                    prop: "tableName",
                                    width: "200",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "列名",
                                    prop: "columnName",
                                    "show-overflow-tooltip": "",
                                    width: "150",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "输出字段",
                                    prop: "columnAlias",
                                    width: "150",
                                    sortable: "",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled:
                                                  row.isApplyFuncColumn ||
                                                  row.isCalculationColumn,
                                                title: row.columnAlias,
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.textTrim(
                                                    row,
                                                    "columnAlias"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row.columnAlias,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "columnAlias",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.columnAlias",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1951333450
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "输出名称",
                                    prop: "columnOutputTitle",
                                    "min-width": "200",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          var $index = ref.$index
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass:
                                                  "columnOutputTitle-item",
                                                attrs: {
                                                  prop:
                                                    "dataPointMetaList." +
                                                    $index +
                                                    ".columnOutputTitle",
                                                  rules: [
                                                    {
                                                      required:
                                                        row.chooseColumn === 1
                                                          ? true
                                                          : false,
                                                      message:
                                                        "输出名称不能为空",
                                                      trigger: "blur",
                                                    },
                                                  ],
                                                  "label-width": "0px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      row.isApplyFuncColumn ||
                                                      row.isCalculationColumn,
                                                    title:
                                                      row.columnOutputTitle,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.textTrim(
                                                        row,
                                                        "columnOutputTitle"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      row.columnOutputTitle,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "columnOutputTitle",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.columnOutputTitle",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2432952847
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "自定义表达式",
                                    prop: "expressionContent",
                                    "show-overflow-tooltip": "",
                                    width: "150",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "输出顺序",
                                    prop: "finalOrder",
                                    sortable: "",
                                    width: "100",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input-number", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                precision: 0,
                                                controls: false,
                                                disabled:
                                                  row.isApplyFuncColumn ||
                                                  row.isCalculationColumn,
                                              },
                                              model: {
                                                value: row.finalOrder,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "finalOrder",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.finalOrder",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    71053350
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "列数据类型",
                                    prop: "dataType",
                                    "show-overflow-tooltip": "",
                                    width: "120",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "字段顺序",
                                    prop: "ordinalPosition",
                                    width: "80",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "是否锁定",
                                    prop: "fixedColumn",
                                    width: "80",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-checkbox", {
                                              attrs: {
                                                "true-label": 1,
                                                "false-label": 0,
                                              },
                                              on: {
                                                change: function (val) {
                                                  return _vm.fixedChange(
                                                    row,
                                                    val
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row.fixedColumn,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "fixedColumn",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.fixedColumn",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    434263840
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "操作",
                                    fixed: "right",
                                    width: "90",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          var $index = ref.$index
                                          return [
                                            row.isApplyFuncColumn ||
                                            row.isCalculationColumn
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editField(
                                                          row,
                                                          $index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("编辑")]
                                                )
                                              : _vm._e(),
                                            row.isApplyFuncColumn ||
                                            row.isCalculationColumn
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteField(
                                                          row,
                                                          $index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1288148610
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "third-title" },
                              [
                                _c(
                                  "span",
                                  [
                                    _vm._v("请求参数 "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "top",
                                          content:
                                            "“请求参数” 定义API对外暴露给客户调用时使用的其他非默认参数。请求参数作为数据的过滤条件，要求在“过滤条件设定”区块中同步定义",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-question",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-plus",
                                      size: "small",
                                    },
                                    on: { click: _vm.addParams },
                                  },
                                  [_vm._v("添加")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-table",
                              {
                                staticClass: "table-form",
                                attrs: {
                                  data: _vm.detailInfo.requestParameterList.filter(
                                    function (e) {
                                      return e.deleted !== 1
                                    }
                                  ),
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "参数名称",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "requestParameterList." +
                                                    _vm.detailInfo.requestParameterList.findIndex(
                                                      function (e) {
                                                        return e.key === row.key
                                                      }
                                                    ) +
                                                    ".parameterName",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        "参数名称不能为空",
                                                      trigger: "blur",
                                                    },
                                                  ],
                                                  "label-width": "0px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.textTrim(
                                                        row,
                                                        "parameterName"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.parameterName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "parameterName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.parameterName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2109567539
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "参数类型",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value: row.paraType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "paraType",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.paraType",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "Params",
                                                    value: 0,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "Body",
                                                    value: 1,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1676816889
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "子类型",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value: row.paraSubType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "paraSubType",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.paraSubType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.paraSubTypeList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3166690418
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "参数占位符",
                                    width: "220",
                                    "show-overflow-tooltip": "",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "requestParameterList." +
                                                    _vm.detailInfo.requestParameterList.findIndex(
                                                      function (e) {
                                                        return e.key === row.key
                                                      }
                                                    ) +
                                                    ".bindQueryConditionExpressionPattern",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        "参数占位符不能为空",
                                                      trigger: "blur",
                                                    },
                                                  ],
                                                  "label-width": "0px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请将值输入在{}中，如{xxx}",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.textTrim(
                                                        row,
                                                        "bindQueryConditionExpressionPattern"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      row.bindQueryConditionExpressionPattern,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "bindQueryConditionExpressionPattern",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.bindQueryConditionExpressionPattern",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1582503208
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "参数数据类型",
                                    "show-overflow-tooltip": "",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value: row.parameterDataType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "parameterDataType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.parameterDataType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.dataTypeList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4097949339
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "是否有效" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": 1,
                                                "inactive-value": 0,
                                                "active-text": "有效",
                                                "inactive-text": "无效",
                                              },
                                              model: {
                                                value: row.parameterStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "parameterStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.parameterStatus",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    400371270
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "顺序" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input-number", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                precision: 0,
                                                controls: false,
                                              },
                                              model: {
                                                value: row.parameterOrder,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "parameterOrder",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.parameterOrder",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3609580006
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "描述" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input", {
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.textTrim(
                                                    row,
                                                    "parameterDescription"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row.parameterDescription,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "parameterDescription",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.parameterDescription",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1846829590
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "操作" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          var $index = ref.$index
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteParams(
                                                      row,
                                                      $index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    592954430
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "third-title" },
                              [
                                _c(
                                  "span",
                                  [
                                    _vm._v("过滤条件设定 "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "top",
                                          content:
                                            "“过滤条件设定”定义了查询数据的过滤条件。这些条件来源于“请求参数” 或 额外的固化的查询条件。",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-question",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-plus",
                                      size: "small",
                                    },
                                    on: { click: _vm.addCondition },
                                  },
                                  [_vm._v("添加")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-table",
                              {
                                staticClass: "table-form",
                                attrs: {
                                  data: _vm.detailInfo.whereConditionList.filter(
                                    function (e) {
                                      return e.deleted !== 1
                                    }
                                  ),
                                  border: "",
                                  "max-height": "600px",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    type: "index",
                                    label: "序号",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "输出字段",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "whereConditionList." +
                                                    _vm.detailInfo.whereConditionList.findIndex(
                                                      function (e) {
                                                        return e.key === row.key
                                                      }
                                                    ) +
                                                    ".selectId",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message:
                                                        "输出字段不能为空",
                                                    },
                                                  ],
                                                  "label-width": "0px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: { filterable: "" },
                                                    on: {
                                                      change: function (val) {
                                                        return _vm.outputFieldChange(
                                                          row,
                                                          val
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: row.selectId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "selectId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.selectId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.fieldList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          value: item.value,
                                                          label: item.label,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3335867787
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "操作符",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          var $index = ref.$index
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "whereConditionList." +
                                                    _vm.detailInfo.whereConditionList.findIndex(
                                                      function (e) {
                                                        return e.key === row.key
                                                      }
                                                    ) +
                                                    ".queryConditionOperator",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "操作符不能为空",
                                                      trigger: "change",
                                                    },
                                                  ],
                                                  "label-width": "0px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: { filterable: "" },
                                                    on: {
                                                      change: function (val) {
                                                        return _vm.queryOperatorChange(
                                                          row,
                                                          val,
                                                          $index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        row.queryConditionOperator,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "queryConditionOperator",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.queryConditionOperator",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.operatorList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item,
                                                        attrs: {
                                                          value: item,
                                                          label: item,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1434864962
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "值1",
                                    width: "300",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "whereConditionList." +
                                                    _vm.detailInfo.whereConditionList.findIndex(
                                                      function (e) {
                                                        return e.key === row.key
                                                      }
                                                    ) +
                                                    ".queryConditionValue1ExpPattern",
                                                  rules: [
                                                    {
                                                      required:
                                                        [
                                                          "is null",
                                                          "is not null",
                                                        ].indexOf(
                                                          row.queryConditionOperator
                                                        ) === -1,
                                                      message: "值1不能为空",
                                                      trigger: "blur",
                                                    },
                                                  ],
                                                  "label-width": "0px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      [
                                                        "is null",
                                                        "is not null",
                                                      ].indexOf(
                                                        row.queryConditionOperator
                                                      ) !== -1,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.textTrim(
                                                        row,
                                                        "queryConditionValue1ExpPattern"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      row.queryConditionValue1ExpPattern,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "queryConditionValue1ExpPattern",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.queryConditionValue1ExpPattern",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1975983294
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "值2",
                                    "class-name": "cell-overflow",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "whereConditionList." +
                                                    _vm.detailInfo.whereConditionList.findIndex(
                                                      function (e) {
                                                        return e.key === row.key
                                                      }
                                                    ) +
                                                    ".queryConditionValue2ExpPattern",
                                                  "label-width": "0px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      ["between"].indexOf(
                                                        row.queryConditionOperator
                                                      ) === -1,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.textTrim(
                                                        row,
                                                        "queryConditionValue2ExpPattern"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      row.queryConditionValue2ExpPattern,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "queryConditionValue2ExpPattern",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.queryConditionValue2ExpPattern",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1480392609
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "条件顺序" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input-number", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                precision: 0,
                                                controls: false,
                                              },
                                              model: {
                                                value: row.queryConditionOrder,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "queryConditionOrder",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.queryConditionOrder",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    436355638
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "条件组合" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value:
                                                    row.queryConditionGroupOperator,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "queryConditionGroupOperator",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.queryConditionGroupOperator",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "and",
                                                    value: "and",
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "or",
                                                    value: "or",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1037797576
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "操作", fixed: "right" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          var $index = ref.$index
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteQueryCondition(
                                                      row,
                                                      $index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2140026203
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("div", { staticClass: "third-title" }, [
                                  _c("span", [_vm._v("脚本表达式")]),
                                ]),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "From 表达式：",
                                      prop: "apiData.apiTableJoinExp",
                                      "label-width": "180px",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        placement: "top",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "content",
                                                            fn: function () {
                                                              return [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "配置者可以定义API操作的数据表名或多表关联的On语句。例如："
                                                                  ),
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "对于多表，可以设定为 “ tablename_A as alias_a inner join tablename_B as alias_b on a.x=b.x”，"
                                                                  ),
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "对于单表，可以设定为“tablename as alias”，"
                                                                  ),
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "请注意，由于系统开放了灵活性， 配置者需要保证表的别名需要和数据源区块定义的别名一致。"
                                                                  ),
                                                                ]),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1518196949
                                                      ),
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                      }),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v("From 表达式："),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2083286518
                                    ),
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "textarea", rows: "4" },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.textTrim(
                                            _vm.detailInfo.apiData,
                                            "apiTableJoinExp"
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.detailInfo.apiData
                                            .apiTableJoinExp,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailInfo.apiData,
                                            "apiTableJoinExp",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailInfo.apiData.apiTableJoinExp",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Group/Having 表达式：",
                                      "label-width": "180px",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        placement: "top",
                                                        content:
                                                          "系统支持对数据表进行group by/having 条件的设定， 配置者可以按需设定",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                      }),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Group/Having 表达式："
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1841369331
                                    ),
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "textarea", rows: "4" },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.textTrim(
                                            _vm.detailInfo.apiData,
                                            "apiGroupHavingExp"
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.detailInfo.apiData
                                            .apiGroupHavingExp,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailInfo.apiData,
                                            "apiGroupHavingExp",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailInfo.apiData.apiGroupHavingExp",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "OrderBy 表达式：",
                                      "label-width": "180px",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        placement: "top",
                                                        content:
                                                          "系统支持对数据表进行排序设定， 配置者可以设定API数据输出的顺序，例如： order by alias.column_a asc, alias.column_b desc请注意，不要写语句结尾的分号",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                      }),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v("OrderBy 表达式："),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      667659000
                                    ),
                                  },
                                  [
                                    _c("el-input", {
                                      on: {
                                        blur: function ($event) {
                                          return _vm.textTrim(
                                            _vm.detailInfo.apiData,
                                            "apiOrderByExp"
                                          )
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.detailInfo.apiData.apiOrderByExp,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detailInfo.apiData,
                                            "apiOrderByExp",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailInfo.apiData.apiOrderByExp",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否使用limit分页：",
                                      "label-width": "180px",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        placement: "top",
                                                        content:
                                                          "系统支持limit 语句对数据进行分页输出，该功能需结合pageIndex 和pageSize两个参数使用",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                      }),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      "是否使用limit分页："
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1716162352
                                    ),
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: {
                                          change: function (val) {
                                            return val === 1
                                              ? (_vm.detailInfo.apiMeta.apiSupportCache = 0)
                                              : (_vm.detailInfo.apiMeta.apiSupportCache = 1)
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.detailInfo.apiData.apiLimitExp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detailInfo.apiData,
                                              "apiLimitExp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailInfo.apiData.apiLimitExp",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-top": "16px" },
                              attrs: {
                                label: "设置SQL：",
                                prop: "apiData.apiAdvModeDataScript",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: "8",
                                  placeholder: "直接输入SQL脚本",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.textTrim(
                                      _vm.detailInfo.apiData,
                                      "apiAdvModeDataScript"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.detailInfo.apiData.apiAdvModeDataScript,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.detailInfo.apiData,
                                      "apiAdvModeDataScript",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "detailInfo.apiData.apiAdvModeDataScript",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "设置countSQL：",
                                prop: "apiData.apiAdvModeCountScript",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: "8",
                                  placeholder: "直接输入countSQL脚本",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.textTrim(
                                      _vm.detailInfo.apiData,
                                      "apiAdvModeCountScript"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.detailInfo.apiData
                                      .apiAdvModeCountScript,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.detailInfo.apiData,
                                      "apiAdvModeCountScript",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "detailInfo.apiData.apiAdvModeCountScript",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                2
              ),
              _c("bindingUser", {
                ref: "bindingUser",
                attrs: {
                  apiName: _vm.detailInfo.apiMeta.apiName,
                  apiVersion: _vm.detailInfo.apiMeta.apiVersion,
                  userApiBindingList: _vm.detailInfo.userApiBindingList,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer-btn" },
            [
              _vm.batchBind
                ? _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.close } },
                    [_vm._v("关 闭")]
                  )
                : [
                    _c(
                      "el-button",
                      { attrs: { size: "small" }, on: { click: _vm.close } },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.handleSave },
                      },
                      [_vm._v("保 存")]
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
      _vm.addFieldDialogVisible
        ? _c("addFieldDialog", {
            attrs: {
              visible: _vm.addFieldDialogVisible,
              fieldInfo: _vm.currentFieldInfo,
            },
            on: {
              saveField: _vm.saveField,
              close: function ($event) {
                _vm.addFieldDialogVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }