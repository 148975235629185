import request from '@/utils/request'

const cacheHeaders = {
  "cache-control": `max-age=${24 * 60 * 60 * 1000}`,
};

// 获取上传excel后的表格列表
export function getList(params) {
  return request({
    url: '/carbon-data/fmcc/getList',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 计算结果-fund name列表
export function getCalculateResultList(params) {
  return request({
    url: '/carbon-data/fmcc/getCalculateResultList',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 计算结果-watch list
export function getWatchList(params) {
  return request({
    url: '/carbon-data/fmcc/getWatchList',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 计算结果-风险评估
export function calculate(data) {
  return request({
    url: '/carbon-data/fmcc/calculate',
    method: 'post',
    data
  })
}

// 计算结果-投资组合碳足迹
export function portfolioCarbonFootprint(params) {
  return request({
    url: '/carbon-data/fmcc/portfolioCarbonFootprint',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 获取投资组合coverage
export function getCoverage(params) {
  return request({
    url: '/carbon-data/fmcc/getCoverage',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 报告导出--FMCC
export function downloadReport(data) {
  return request({
    url: '/carbon-data/fmcc/downloadReport',
    method: 'post',
    responseType: 'blob',
    timeout: 30000,
    data
  })
}

// 报告导出--footPrint
export function downloadReportFootPrint(data) {
  return request({
    url: '/carbon-data/fmcc/downloadFooterReport',
    method: 'post',
    responseType: 'blob',
    timeout: 30000,
    data
  })
}


export function sendMail(data) {
  return request({
    url: '/carbon-data/fmcc/sendMail',
    method: 'post',
    data
  })
}

/**
 * 下载文件模板模板
 * @param lang
 * @return Promise
 * */
export function requestFileTemplate(params) {
  return request({
    url: '/carbon-data/files/downloadTemplate',
    method: 'get',
    responseType: 'blob',
    headers: {
      ...cacheHeaders,
    },
    params: {
      ...params
    }
  })
}