var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instructions" }, [
    _c("div", { staticClass: "header" }, [
      _c("span", { staticClass: "header-title" }, [_vm._v("Instructions")]),
      _c("i", {
        staticClass: "el-icon-close flex-center",
        on: {
          click: function ($event) {
            return _vm.$emit("showHelp", false)
          },
        },
      }),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "row flex-start" }, [
        _c("div", { staticClass: "left flex-center border" }, [
          _c("span", [_vm._v("Steps")]),
        ]),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "info-row" }, [
            _c("div", { staticClass: "number flex-center border" }, [
              _vm._v("1"),
            ]),
            _c("div", { staticClass: "text flex-start border" }, [
              _vm._v(" Upload Portfolio first "),
            ]),
          ]),
          _c("div", { staticClass: "info-row grey" }, [
            _c("div", { staticClass: "number flex-center border" }, [
              _vm._v("2"),
            ]),
            _c("div", { staticClass: "text flex-start border" }, [
              _vm._v(' Preview "Mandatory PAI" '),
            ]),
          ]),
          _c("div", { staticClass: "info-row" }, [
            _c("div", { staticClass: "number flex-center border" }, [
              _vm._v("3"),
            ]),
            _c("div", { staticClass: "text flex-start border" }, [
              _vm._v(
                " Then you may go to the “Additional PAI” section for choosing additional indicators "
              ),
            ]),
          ]),
          _c("div", { staticClass: "info-row grey" }, [
            _c("div", { staticClass: "number flex-center border" }, [
              _vm._v("4"),
            ]),
            _c("div", { staticClass: "text flex-start border" }, [
              _vm._v(
                ' Click the calculated value, you may review the underlying data for both “Mandatory PAI" and "Additional PAI" '
              ),
            ]),
          ]),
          _c("div", { staticClass: "info-row" }, [
            _c("div", { staticClass: "number flex-center border" }, [
              _vm._v("5"),
            ]),
            _c("div", { staticClass: "text flex-start border" }, [
              _vm._v(" Generate and download the PAI data "),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }