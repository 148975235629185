<template>
  <footer class="app-footer">
    <!-- 分割线 -->
    <el-divider class="nav-line"></el-divider>
    <!-- 底部信息 -->
    <div class="extra">
      <div class="container">
        <!-- 版权信息 -->
        <div class="copyright">
          <p>CopyRight © 鼎力可持续数字科技（深圳）有限公司</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped lang='scss'>
.app-footer {
  overflow: hidden;
  background-color: #f5f5f5;
  .extra {
    background-color: #01579B
  }

  .copyright {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    p {
      margin-top: 0;
    }
  }
}
</style>
