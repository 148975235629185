export default {
  // 语言
  language: {
    name: '中文'
  },
  // 登录页面
  login: {
    title: '鼎力可持续数据平台',
    label: '账号登录',
    username: '账号',
    password: '密码',
    verifyCode: '验证码',
    logIn: '登录',
    status: '登 录 中...',
    rememberPsw: '记住密码',
    slogan: '可持续发展战略',
    vision1: '影响和推动企业可持续健康发展',
    vision2: '帮助金融机构做出更好的分析决策',
    advant1: '创新',
    advant2: '研究+数据',
    advant3: '风险评估',
    advant4: '管理软件',
    advant5: '评价方法',
    advant6: '优化',
    advant7: '技术+模型',
    advant8: '风险管理',
    advant9: 'SaaS平台',
    advant10: '指标体系',
    connect: '客户服务邮箱：clientsupport@governance-solutions.com',
    company: 'Copyright by © 2022 鼎力可持续数字科技（深圳）有限公司',
    rulesCode: '请输入验证码',
    rulesAccount: '请输入您的账号',
    rulesPws: '请输入您的密码'

  },
  // 标签页面
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  // 设置页面
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo',
    accountMenu1: '个人中心',
    accountMenu2: '退出登录',
  },
  // 按钮
  elbtn: {
    import: '导入',
    export: '导出',
    extract: '提取',
    instructions: '指示说明',
    delete: '删除',
    add: '添加',
    cancel: '取 消',
    apply: '应 用',
    confirm: '确 认',
    download: '下载模板',
    saveSelf: '保存为自选清单',
    help: '帮助支持',
    deleteSelf: '删除自选清单',
    rename: '重命名',
    operate: '操作',
    sysTips: '系统提示',
    tips: '提示',
  },
  // 下载模板提示
  downloadTemplate: {
    tips: `您已经下载了<strong>模板文件</strong>。请检查浏览器的下载列表。`
  }
}
