import { render, staticRenderFns } from "./year-table.vue?vue&type=template&id=9ecb2a48"
import script from "./year-table.vue?vue&type=script&lang=js"
export * from "./year-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/data-platform-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9ecb2a48')) {
      api.createRecord('9ecb2a48', component.options)
    } else {
      api.reload('9ecb2a48', component.options)
    }
    module.hot.accept("./year-table.vue?vue&type=template&id=9ecb2a48", function () {
      api.rerender('9ecb2a48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/date-picker/src/basic/year-table.vue"
export default component.exports