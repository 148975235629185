var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company-detail" },
    [
      _c("h2", { staticClass: "font-32" }, [
        _vm._v(_vm._s(_vm.part1Data.name)),
      ]),
      _c("h2", { staticClass: "font-26" }, [_vm._v(_vm._s(_vm.part1Data.id))]),
      _c("el-divider"),
      _c("div", [
        _c("span", {
          staticClass: "font-24",
          domProps: {
            innerHTML: _vm._s("行业名称(申万一级2014)&nbsp;&nbsp;&nbsp;&nbsp;"),
          },
        }),
        _vm._v(_vm._s(_vm.part1Data.swName)),
      ]),
      _c("div", [
        _c("span", {
          staticClass: "font-24",
          domProps: { innerHTML: _vm._s("经营范围&nbsp;&nbsp;&nbsp;&nbsp;") },
        }),
        _vm._v(" " + _vm._s(_vm.part1Data.businessScope) + " "),
      ]),
      _c("el-divider"),
      _c("h1", { staticClass: "font-24" }, [_vm._v("FLOP财报异常指数 ")]),
      _c("FlopAnomalyIndex", {
        attrs: {
          flopAnomalyEchartsData: _vm.renderData.part2,
          echartsTitle: _vm.part1Data.name,
        },
      }),
      _vm.companyAbnormal
        ? _c(
            "div",
            [
              _c("h3", { staticClass: "font-24" }, [_vm._v("公司情况汇总")]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.renderData.part3 },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "Date", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.formatDate(scope.row.date))),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2492010660
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "frdProbPmm",
                      label: "异常指数",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "isFrdPrePmm",
                      label: "模型认定的违规",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  style:
                                    scope.row.isFrdPrePmm === 1
                                      ? "color: red"
                                      : "",
                                },
                                [_vm._v(_vm._s(scope.row.isFrdPrePmm))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4007909885
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "isFrd127",
                      label: "监管认定违规",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  style:
                                    scope.row.isFrd127 === 1
                                      ? "color: red"
                                      : "",
                                },
                                [_vm._v(_vm._s(scope.row.isFrd127))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1986087997
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "违规类型", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.violationType
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { slot: "reference" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openActicityDialog(
                                            scope.row.activity
                                          )
                                        },
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.handleViolationType(
                                            scope.row.violationType
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
              _c("h3", { staticClass: "font-24" }, [_vm._v("FLOP异常指标")]),
              _c("FlopAbnormalTarget", {
                attrs: {
                  abnormalTargetData: _vm.renderData.part4,
                  unitId: _vm.part1Data.id,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-box",
          attrs: { title: "违规内容", visible: _vm.open, width: "45%" },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c("p", { staticClass: "dialog-text" }, [
            _vm._v(_vm._s(_vm.violationActivity)),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.open = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }