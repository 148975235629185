<template>
    <div class="portfolio-data-wrapper">
        <el-table
            ref="table"
            :data="data"
            size="mini"
            class="portfolio-data-table"
            :header-cell-style="getHeaderCellStyle"
            height="calc(100%)"
            width="100%"
            :border="true"
            :row-class-name="addRowClass"
            :cell-style="getCellStyle"
            :lazy="true"
        >
            <el-table-column prop="orderNumber" label="Number" width="90" />
            <el-table-column prop="isin" label="ISIN" />
            <el-table-column prop="ticker" label="Ticker" />
            <el-table-column
                prop="securityName"
                label="Security Name"
                min-width="200"
            >
                <template slot-scope="{ row }">
                    {{ row.securityName }}
                    <el-tooltip
                        content="The weighting of your uploaded portfolio is less than 100%. As per our methology, we will make up the shortfall with CASH."
                        placement="bottom"
                        v-if="row.isCashItem"
                    >
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column prop="shares" label="Shares" min-width="100" />
            <el-table-column
                prop="cvForOrigin"
                label="Current Value - Uploaded"
                min-width="150"
            />
            <el-table-column
                prop="cvForLocalCurrency"
                :label="`Current Value - SFDR (${
                    currency ? currency : 'Local Currency'
                })`"
                min-width="200"
            >
                <template slot="header" slot-scope="{ column }">
                    {{ column.label }}
                    <el-tooltip
                        content="This Current Value is calculated as per the methodology from the RTS. The ESAs are aware that there is a potential misalignment between the timing of the (at least) quarterly calculations of the adverse impacts under Section II and Annex I of the Delegated Regulation. The quarterly impacts should be based on the current value of the investment derived from the valuation the individual investment (e.g. share) price valued at fiscal year-end multiplied by the quantity of investments (e.g. shares) held at the end of each quarter. In such manner the composition of the investments at the end of each quarter is taken into account, but the valuation reflects the fiscal-year end point in time."
                        placement="bottom"
                    >
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="cvForEur"
                label="Current Value - SFDR (EUR)"
                min-width="150"
            >
                <template slot="header" slot-scope="{ column }">
                    {{ column.label }}
                    <el-tooltip
                        content="The Current Value - SFDR(EUR) is converted from the currency uploaded to EUR."
                        placement="bottom"
                    >
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="weightingForOrigin"
                label="Weighting - Uploaded"
                min-width="120"
            />
            <el-table-column
                prop="weightingForEur"
                label="Weighting - SFDR"
                min-width="103"
            >
                <template slot="header" slot-scope="{ column }">
                    {{ column.label }}
                    <el-tooltip
                        content="The weighting - SFDR is calculated as Current Value - SFDR (EUR) of the security / Total Current Value - SFDR (EUR) of the portfolio."
                        placement="bottom"
                        :transition="null"
                    >
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
            <template slot="empty">
                <EmptyBlock @showUploadDialog="showDialog"></EmptyBlock>
            </template>
        </el-table>
    </div>
</template>

<script>
import UploadDialog from './UploadDialog.vue'
import EmptyBlock from './EmptyBlock.vue'

export default {
  components: {
    UploadDialog,
    EmptyBlock
  },
  props: {
    data: {
      type: Array,
      default: null
    },
    currency: {
      type: String,
      default: null
    }
  },
  hasDownloadTemplate: false,
  data() {
    return {}
  },
  methods: {
    getHeaderCellStyle({ row, column, rowIndex, columnIndex }) {
      const style = {
        color: '#2f5584',
        'background-color': '#e0ecfa',
        'font-weight': 400,
        'line-height': '16px !important'
      }
      if ([4, 5, 6, 7, 8, 9].indexOf(columnIndex) > -1) {
        style['text-align'] = 'right'
      }
      if (columnIndex === 0) {
        style['text-align'] = 'center'
      }
      return style
    },
    addRowClass({ row, rowIndex }) {
      if (row.isTotal) {
        return 'sfdr-portfolio-table-total-row'
      }
    },
    getCellStyle({ row, column, rowIndex, columnIndex }) {
      const style = {}
      if ([4, 5, 6, 7, 8, 9].indexOf(columnIndex) > -1) {
        style['text-align'] = 'right'
      }
      if (columnIndex === 0) {
        style['text-align'] = 'center'
      }
      if ([6, 7, 9].indexOf(columnIndex) > -1) {
        style['background'] = '#EEF2F8'
        style['color'] = '#01579B'
      }
      if (row.isCashItem && [2, 3, 9].indexOf(columnIndex) > -1) {
        style['color'] = '#FFB900'
        style['background'] = 'rgba(237,174,12,0.1)'
      }
      return style
    },
    showDialog() {
      this.$emit('showUploadDialog')
    },
    getSummaries(params) {
      console.log(params)
      const { columns, data } = params
      const sums = []
      const emptyIndexes = [0, 1, 2]
      columns.forEach((column, index) => {
        if (emptyIndexes.indexOf(index) > -1) {
          sums[index] = ''
          return
        }
        if (index === 3) {
          sums[index] = 'Total'
          return
        }
        const values = data?.map((item) => Number(item[column.property]))
        if (!values?.every((value) => isNaN(value))) {
          sums[index] = values?.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-data-wrapper {
    font-family: PingFang SC-Regular;
    height: calc(100%);
    // height: calc(100% - 113px);

    width: 100%;

    .portfolio-data-table {
        height: calc(100%);
        overflow: hidden;
        border-top: 1px solid #5998f0;

        .header-cell {
            color: #215796;
            background-color: #c6dcf8;
        }
    }
}
</style>
<style lang="scss">
.portfolio-data-table {
    width: 100%;

    .el-table__body-wrapper {
        overflow-x: hidden !important;
    }
    .sfdr-portfolio-table-total-row {
        font-weight: 600;
        color: #01579b;
    }
    .sfdr-portfolio-table-cash-row {
        background: #f8f8f8;
    }
}
</style>