var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "app-footer" },
    [_c("el-divider", { staticClass: "nav-line" }), _vm._m(0)],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extra" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "copyright" }, [
          _c("p", [_vm._v("CopyRight © 鼎力可持续数字科技（深圳）有限公司")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }