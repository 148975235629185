var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-show-vue" },
    [
      _c(
        "el-row",
        { staticStyle: { height: "100%" }, attrs: { type: "border-card" } },
        [
          _c(
            "el-col",
            { attrs: { sm: 4, md: 4, lg: 6, xl: 6 } },
            [
              _c("Navigation", {
                ref: "navigation",
                attrs: { heading: _vm.navigationList },
              }),
            ],
            1
          ),
          _c("el-col", { attrs: { sm: 20, md: 20, lg: 18, xl: 18 } }, [
            _c(
              "div",
              {
                staticStyle: { "max-width": "1200px", "padding-left": "10px" },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    ref: "wikiTitle",
                    staticClass: "wiki-title",
                  },
                  [_vm._v(" " + _vm._s(_vm.wikiPage.title) + " ")]
                ),
                _c(
                  "div",
                  { ref: "pageContent", staticClass: "dl-page-content" },
                  [
                    _c("div", {
                      staticClass: "markdown-body",
                      domProps: { innerHTML: _vm._s(_vm.pageShowDetail) },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { ref: "imagePreview" },
        [
          _vm.showImagePreview
            ? _c("el-image-viewer", {
                attrs: {
                  "url-list": _vm.showImagePreviewList,
                  "on-close": _vm.closeImagePreview,
                  "initial-index": _vm.previewInitialIndex,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }