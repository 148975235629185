<template>
  <el-dialog :visible="visible" title="绑定用户" width="1600px" custom-class="bindingUserTreeDialog" @close="dialogClose">
    <div style="overflow: hidden;height: 100%;">
      <div class="left-content">
        <el-form :model="formData" inline label-width="40px">
          <el-form-item label="用户">
            <el-select v-model="formData.selectedUserId" filterable clearable style="width: 160px;" @change="selectedUserIdChange">
              <el-option v-for="item of userList.filter((e) => e.status === '0')" :key="item.userId" :value="item.userId" :label="`${item.nickName}(${item.userName})`"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序">
            <el-select v-model="formData.sortType"  clearable style="width: 160px;">
              <el-option label="按名称" value="1"></el-option>
              <el-option label="按类型" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <TreeComponent
          class="tree-component"
          ref="tree"
          :treeData="treeData"
          defaultExpandAll
          @node-click="nodeClick"
        >
          <span slot-scope="{ data }" style="width: 100%">
            <div class="node-content">
              <div :title="data.label" class="node-label">{{ data.label }}</div>
            </div>
          </span>
        </TreeComponent>
        <!-- <el-tree
          class="tree"
          ref="tree"
          :data="treeData"
          default-expand-all
          highlight-current
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          @node-click="nodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <div class="node-content">
              <div v-if="data.apiTemplateName" :title="data.apiTemplateName" class="template-mark">T</div>
              <div :title="node.label" class="node-label" :style="{width: data.apiTemplateName ? 'calc(100% - 20px)' : '100%'}">{{ node.label }}</div>
            </div>
          </span>
        </el-tree> -->
      </div>
      <div class="right-content">
        <bindingUser v-bind="apiInfo" :batchBind="true" @getUserList="getUserList" @saveSuccess="saveSuccess" style="height: 100%;" />
      </div>
    </div>
    <span slot="footer">
      <el-button size="small" @click="dialogClose">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getConfigAPIMetaListByUserId, getApiMetaList, getApiUserBindingByApiId } from "@/api/data-platform/admin-center.js";
import bindingUser from './bindingUser.vue';
import TreeComponent from "@/components/TreeComponent";
export default {
  components: {
    bindingUser,
    TreeComponent
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      keyword: '',
      allAPIData: [],
      userAPITreeData: [],
      apiInfo: {
        apiName: '',
        apiVersion: '',
        apiTemplateName: '',
        apiId: '',
        userApiBindingList: []
      },
      currentNodeKey: '',
      userList: [],
      formData: {
        selectedUserId: '',
        sortType: ''
      },
      treeData: []
    }
  },
  created () {
    this.getTreeData();
  },
  watch: {
    keyword (val) {
      this.$refs.tree.filter(val);
    },
    'formData.sortType': {
      handler (val) {
        this.setTreeData();
      }
    }
  },
  computed: {

  },
  methods: {
    setTreeData () {
      let data = this.formData.selectedUserId ? JSON.parse(JSON.stringify(this.userAPITreeData)) : JSON.parse(JSON.stringify(this.allAPIData));
      if (this.formData.sortType === '1') {
        data = data.sort((a, b) => a.label.localeCompare(b.label));
      } else if (this.formData.sortType === '2') {
        const arr1 = data.filter((e) => e.apiTemplateName);
        const arr2 = data.filter((e) => !e.apiTemplateName);
        data = arr1.concat(arr2);
      }
      this.treeData = data;
    },
    // 切换用户
    selectedUserIdChange (val) {
      if (val) {
        this.userAPITreeData = [];
        getConfigAPIMetaListByUserId({userId: val}).then((res) => {
          const data = res.data || [];
          for (const item of data) {
            item.label = `${item.apiName}(version:${item.apiVersion})`;
            item.value = item.apiId;
          }
          this.userAPITreeData = data;
          this.setTreeData();
          this.filterNode();
        })
      } else {
        this.setTreeData();
        this.filterNode();
      }
    },
    getUserList (list) {
      this.userList = list;
    },
    nodeClick (val) {
      this.apiInfo.apiName = val.apiName;
      this.apiInfo.apiVersion = val.apiVersion;
      this.apiInfo.apiTemplateName = val.apiTemplateName;
      this.apiInfo.apiId = val.apiId;
      this.getUserApiBindingList();
    },
    filterNode () {
      this.$nextTick(() => {
        this.$refs.tree.filter();
      })
    },
    saveSuccess () {
      this.getUserApiBindingList();
    },
    // 查询绑定用户
    getUserApiBindingList () {
      getApiUserBindingByApiId({apiId: this.apiInfo.apiId}).then((res) => {
        if (res.code === 200) {
          this.apiInfo.userApiBindingList = res.data || [];
        }
      })
    },
    getTreeData () {
      getApiMetaList().then((res) => {
        const data = res.data || [];
        for (const item of data) {
          item.label = `${item.apiName}(version:${item.apiVersion})`;
          item.value = item.apiId;
        }
        this.allAPIData = data;
        this.setTreeData();
      })
    },
    dialogClose () {
      this.$emit('dialogClose');
    }
  }
}
</script>
<style lang="scss" scoped>
.left-content {
  width: 420px;
  float: left;
  height: 100%;
  .tree-component {
    height: calc(100% - 54px);
  }
  .node-content {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
  }
  .node-label {
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .template-mark {
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    margin-right: 4px;
    background-color: #01579b;
    color: #FFFFFF;
  }
}
.right-content {
  background-color: #FFFFFF;
  width: calc(100% - 420px);
  height: 100%;
  float: right;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}
</style>
<style lang="scss">
.bindingUserTreeDialog {
  height: 80%;
  .el-dialog__body {
    height: calc(100% - 116px);
  }
}
</style>