<template>
  <div class="tree-container">
    <p class="tree-top-title">{{ treeTitle }}</p>

    <div class="tree-content">
      <!-- 树状图列表 -->
      <div v-for="(item, index) in treeList" :key="index">
        <div class="head-container tree-box">
          <el-tree
            class="tree-d"
            :data="item.arr"
            node-key="id"
            :props="defaultProps"
            :expand-on-click-node="true"
            :filter-node-method="filterNode"
            ref="tree"
            default-expand-all
            accordio
            v-loading="isLazy ? loading : ''"
            highlight-current
            @node-click="handleNodeClick"
            @node-contextmenu="rightClick"
          >
            <span slot-scope="{ node, data }" class="span__">
              <div class="children-list">
                <!-- 二级菜单-->
                <span
                  v-if="!data.children"
                  style="margin-left: 10px"
                  :title="node.label"
                >
                  <!-- <el-tooltip placement="right"> -->

                  <!-- 二级菜单图标 -->
                  <div class="el-icon-second">
                    <svg-icon slot="prefix" icon-class="stock-market" />
                  </div>

                  <span>{{ node.label }}</span>
                  <!-- </el-tooltip> -->
                </span>

                <!-- 一级菜单名字:自选清单 -->
                <span
                  v-else-if="data.flag === 'selfSelect'"
                  class="tree-node-span"
                >
                  <!-- 小图标 -->
                  <div class="el-icon-indicator">
                    <svg-icon slot="prefix" icon-class="subtract" />
                  </div>

                  <span>{{ $t("selfSelect.label") }}</span>
                  <!-- <span>{{ node.label }} </span> -->

                  <!-- 小图标 -->
                  <div class="el-icon-arrow-blue">
                    <svg-icon slot="prefix" icon-class="arrow" />
                  </div>
                </span>

                <!-- 一级菜单名字:指标 -->
                <span v-else class="tree-node-span">
                  <!-- 小图标 -->
                  <div class="el-icon-indicator" v-if="data.type === 'carbon'">
                    <svg-icon slot="prefix" icon-class="carbon" />
                  </div>
                  <div
                    class="el-icon-indicator"
                    v-else-if="data.type === 'climate'"
                  >
                    <svg-icon slot="prefix" icon-class="climate" />
                  </div>
                  <span>{{ node.label }} </span>
                  <!-- 小图标 -->
                  <div class="el-icon-arrow-blue">
                    <svg-icon slot="prefix" icon-class="arrow" />
                  </div>
                </span>
              </div>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TreeDL",
  props: {
    // 树状图列表
    treeList: {
      type: Array,
      required: true,
    },
    // 顶部标题
    treeTitle: {
      type: String,
      default: "待选项",
    },

    // 是否懒加载
    isLazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true, // 遮罩层
      // 树状结构
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  watch: {
    treeList() {
      console.log(this.treeList)
    }
  },
  methods: {
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    // 节点单击事件,调接口,搜索数据
    handleNodeClick(data, node, obj) {
      this.$emit("handleTreeNodeClick", data, node);
    },

    // 关闭loading
    hideLoading() {
      this.loading = false;
    },
    // 开启loading
    openLoading() {
      this.loading = true;
    },

    // 右键点击-子节点
    rightClick(data, node, obj) {
      this.$emit("rightClick", data, node);
    },
    // 获取提示信息
  },
};
</script>
<style lang="scss" scoped>
.tree-container {
  font-size: 14px;
  // border: 1px solid #f3f4f8;
  border-radius: 6px;
  min-width: 225px;
  background-color: #fff;
  color: #01579b;

  .tree-top-title {
    border-radius: 6px 6px 0 0;
    line-height: 40px;
    padding-left: 10px;
    font-weight: 600;
    background-color: #dfebf9;
  }
  .tree-box {
    padding-top: 3px;
  }
  .tree-content {
    // max-height: 130px;
    height: 100%;
    overflow-y: auto;
  }
  .el-tree {
    font-size: 13px;
    color: #01579b;
  }
  // 一级标题
  .tree-node-span {
    font-weight: 700;
  }
  // 隐藏小图标
  ::v-deep .el-icon-caret-right {
    display: none;
  }
  // 一级标题图标
  .el-icon-indicator,
  .el-icon-arrow-blue {
    margin-right: 10px;
  }
  // 旋转图标
  .el-icon-arrow-blue {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    .svg-icon {
      width: 0.7em;
    }
  }
  // 二级标题
  .el-icon-second {
    margin-right: 4px;
  }
}

// 旋转图标
.el-tree-node.is-expanded {
  .el-icon-arrow-blue {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
// ::v-deep
//   .el-tree--highlight-current
//   .el-tree-node.is-current
//   > .el-tree-node__content {
//   background-color: unset;
// }
// 背景色
::v-deep .el-tree-node__children {
  padding: 0 10px;
  .el-tree-node__content {
    &:hover {
      background-color: #dfebf9 !important;
    }
  }
}

::v-deep .el-tree-node__content {
  padding: 0px 5px !important;
  height: 22px;
  line-height: 19px;
}
.children-list {
  // 省略号
  max-width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

// 设置下拉图标位置
// ::v-deep.el-tree-node__expand-icon {
//   position: absolute;
//   right: 1%;
//   color: #01579b;
// }
// 不使用slot自定义列表时label样式控制
::v-deep.el-tree-node__label {
  padding-left: 15px;
}

// 只用于大屏幕
@media screen and (min-width: 1790px) {
  .tree-container {
    .tree-content {
      // max-height: 370px;
    }
    .children-list {
      // 省略号
      max-width: 240px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }
  }
}
</style>