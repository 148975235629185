<template>
  <div class="world-map-login">
    <div class="main-content">
      <!-- <div class="logo"></div> -->
      <div class="title">物理风险交互式地图集</div>
      <div class="login-box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="手机号" name="phone">
            <el-form ref="formRef" :model="formData" :rules="formRules" class="login-form">
              <el-form-item prop="mobile">
                <el-input v-model="formData.mobile" size="large" autocomplete="off" placeholder="请输入手机号" />
              </el-form-item>
              <el-form-item prop="isVerifyPass">
                  <!-- 滑块解锁 -->
                  <SilderVerify :isVerifyPass.sync="formData.isVerifyPass" />
              </el-form-item>

              <el-form-item prop="validateCode">
                  <el-input v-model.number="formData.validateCode" size="large" autocomplete="off" placeholder="请输入6位数字验证码"
                      :validate-event="false">
                      <template #suffix>
                          <el-button type="text" @click.prevent="getMobilePhoneCode" :disabled="getCodeBtnDisabled">{{ phoneCodeTxt }}</el-button>
                      </template>
                  </el-input>
              </el-form-item>
              <el-form-item style="width: 100%;" class="submit">
                  <el-button :disabled="!formData.mobile || !formData.validateCode" :loading="loading" size="large"
                      type="primary" style="width: 100%;margin-top:16px" @click.prevent="handleLogin">
                      <span>确认</span>
                  </el-button>
              </el-form-item>
            </el-form>       
          </el-tab-pane>
          <!-- <el-tab-pane label="邮箱" name="email"></el-tab-pane> -->
        </el-tabs>
      </div>
      <div class="tip-text">
        请先验证身份再查看物理风险地图
      </div>
    </div>
  </div>
</template>
<script>
import SilderVerify from "./components/login/SilderVerify.vue";
import { validatePhoneNumberRule } from "@/utils/validate.js";
import { sendMessage, loginWorldMap } from "@/api/login.js";

//校验规则:6位数字验证码
const validatePhoneCodeRule = (rule, value, callback) => {
  const regPhoneCode = /^\d{6}$/;
  if (!value) {
      callback(new Error("请输入验证码"));
  } else {
      if (regPhoneCode.test(value)) {
          callback();
      } else {
          callback(new Error("验证码错误，请重新输入"));
      }
  }
}
const validateDragPassRule = (rule, value, callback) => {
    if (!value) {
        callback(new Error("请先拖动滑块进行安全验证"));
    } else {
        callback();
    }
}
export default {
  components: {
    SilderVerify
  },
  data () {
    return {
      activeName: 'phone',
      phoneCodeTxt: '发送验证码',
      loading: false,
      getCodeBtnDisabled: false,
      waitTime: 60, // 倒计时
      formData: {
        mobile: '',
        validateCode: '',
        isVerifyPass: false
      },
      formRules: {
        mobile: [{ required: true, validator: validatePhoneNumberRule, trigger: 'blur' }],
        validateCode: [{ validator: validatePhoneCodeRule }],
        isVerifyPass: [{ validator: validateDragPassRule }]
      }
    }
  },
  methods: {
    // 发送验证码
    async getMobilePhoneCode() {
      console.log(this.formData)
      let isPass = true;
      await this.$refs.formRef.validateField(['mobile', 'isVerifyPass'], function (res) {
        if (res) {
          isPass = false
        }
      })
      if (isPass) {
        sendMessage({mobile: this.formData.mobile}).then((data) => {
          this.$modal.msgSuccess("发送验证码成功");
          this.waitTime--;
          this.getCodeBtnDisabled = true;
          this.phoneCodeTxt = `${this.waitTime}s 后重新发送`;

          // 倒计时
          let timer = setInterval(() => {
              if (this.waitTime > 1) {
                this.waitTime--;
                this.phoneCodeTxt = `${this.waitTime}s 后重新发送`;
              } else {
                  clearInterval(timer)
                  this.waitTime = 60
                  this.getCodeBtnDisabled = false;
                  this.phoneCodeTxt = '发送验证码';
              }
          }, 1000)
        }).catch(err => {
            console.log(err)
        })
      }
    },
    // 登录/注册
    handleLogin() {
      this.$refs.formRef.clearValidate("isVerifyPass");//清除校验规则
      // 校验手机号,短信验证码
      this.$refs.formRef.validate((vaild) => {
        if (vaild) {
          const params = {
            ...this.formData
          };
          this.loading = true;
          loginWorldMap(params).then((res) => {
            sessionStorage.setItem('mapPhone', this.formData.mobile);
            this.$router.push({
              path: '/worldmap-board',
            })
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
          });
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.world-map-login {
  height: 100%;
  width: 100%;
  background-image: url("../assets/images/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 0;
  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
    .logo {
      width: 272px;
      height: 44px;
      background-image: url("../assets/images/dl-logo.png");
      background-size: cover;
    }
    .title {
      color: #FFFFFF;
      font-size: 18px;
      margin: 12px 0 40px 0;
    }
    .login-box {
      width: 460px;
      height: 382px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      padding: 32px;
      padding-top: 20px;
    }
    .tip-text {
      color: #FFFFFF;
      font-size: 14px;
      margin-top: 16px;
    }
  }
  ::v-deep.el-tabs__header {
    margin-bottom: 24px;
  }
  ::v-deep.el-button--text {
    color: #008FBE;
    height: 100%;
  }
  ::v-deep.el-button--primary {
    background-color: #008FBE;
    border-color: #008FBE;
  }
  ::v-deep.el-button--primary.is-disabled {
    background-color: #A6D3E2;
    border-color: #A6D3E2;
  }
  ::v-deep.el-tabs__item.is-active {
    color: #008FBE;
  }
  ::v-deep.el-tabs__active-bar {
    background-color: #008FBE;
  }
  ::v-deep.el-form-item {
    margin-bottom: 18px;
  }
}
</style>
