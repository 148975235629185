import request from '@/utils/request'
// import requestCache from '@/utils/requestCache'
// import cache from "@/plugins/cache";

const cacheHeaders = {
  "cache-control": `max-age=${24 * 60 * 60 * 1000}`,
};

/**
 * 1.获取上传的 Portfolio 列表数据
 * @param operationId 上传文件，并解析成功后，返回的operation
 * @return Promise
 * */
export function getPortfolioList(operationId) {
  return request({
    url: `/sfdr/getPortfolioListsByOperationId/${operationId}`,
    method: 'get',
  })
}

/**
 * 2.获取上传的 Portfolio 详细数据
 * @param operationId 上传文件，并解析成功后，返回的operation
 * @return Promise
 * */
export function getPortfolioDetails(operationId) {
  return request({
    url: `/sfdr/getPortfolioDetailsByOperationId/${operationId}`,
    method: 'get'
  })
}

/**
 * 3.删除上传的 Portfolio 列表数据
 * @param operationId 上传文件，并解析成功后，返回的operation
 * @return Promise
 * */
export function deletePortfolioList(operationId) {
  return request({
    url: `/sfdr/deletePortfolioListsByOperationId/${operationId}`,
    method: 'delete'
  })
}


/**
 * 4.删除上传的 Portfolio 详细数据
 * @param operationId 上传文件，并解析成功后，返回的operation
 * @return Promise
 * */
export function deletePortfolioDetail(operationId) {
  return request({
    url: `/sfdr/deletePortfolioDetailsByOperationId/${operationId}`,
    method: 'delete'
  })
}

/**
 * 5.下载文件模板模板
 * @param operationId 上传文件，并解析成功后，返回的operation
 * @return Promise
 * */
export function requestFileTemplate() {
  return request({
    url: `/sfdr/downloadTemplate`,
    method: 'get',
    responseType: 'blob',
    headers: {
      ...cacheHeaders,
    }
  })
}

/**
 * 6. 获取所有的AdverseSustainabilityImpact
 */

export async function getAllAdverseSustainabilityImpacts() {

  return request({
    url: `/sfdr/loadAdverseSustainabilityImpactList`,
    method: "get",
  })

  // const cacheKey = _cacheKey || params.cacheKey;
  // return requestCache({
  //   url: `/sfdr/loadAdverseSustainabilityImpactList`,
  //   method: "get",
  //   headers: {
  //     ...cacheHeaders,
  //   },
  //   cacheKey: "ALL_IMPACTS",
  // });
}

/**
 * 7. 获取子节点PAI
 * @param impactName? 可以按照impactName进行筛选
 */
export async function getPAIList(impactName) {
  const url = impactName ? `/sfdr/loadPAIListByAdverseSustainabilityImpact/${impactName}` : `/sfdr/loadAllPAIDefinition`;
  return request({
    url,
    method: "get",
  })

  // return requestCache({
  //   url,
  //   method: "get",
  //   headers: {
  //     ...cacheHeaders,
  //   },
  //   cacheKey: "ALL_PAILIST",
  // });
}

/**
 * 8. 计算相关PAI的结果
 * @param inputPaiIdList 可以按照impactName进行筛选
 */
export function calculatePAIData(operationId, inputPaiIdList) {
  const paiIdListStr = inputPaiIdList.join(',');
  const url = `/sfdr/postPortfolioCalc/${operationId}?inputPaiIdList=${paiIdListStr}`;
  return request({
    url,
    method: "post",
  });
}

/**
 * 9.获取PAI计算结果
 *
 */
export function getPAICalculationData(operationId) {
  return request({
    url: `/sfdr/getPortfolioCalcResult/${operationId}`,
    method: "get",
    headers: {
      ...cacheHeaders,
    }
  });
}

/**
 * 10.获取PAI计算结果
 * @param inputPaiIdList 可以按照impactName进行筛选
 */
export function deletePAICalculationData(operationId, inputPaiIdList) {
  const paiIdListStr = inputPaiIdList.join(',');
  return request({
    url: `/sfdr/deletePortfolioCalcByOperationIdAndPAIIds/${operationId}?PAIIdList=${paiIdListStr}`,
    method: "delete",
  });
}

/**
 * 11. 导出原始数据
 * @param operationId 上传文件，并解析成功后，返回的operation
 * @return Promise
 * */
export function requestUnderLyingData(operationId) {
  return request({
    url: `/sfdr/exportPortfolioCalcResult/${operationId}`,
    method: 'post',
    responseType: 'blob',
    headers: {
      ...cacheHeaders,
    }
  })
}

/**
 * 12. 获取DrillDown数据（类似于Holdings的PAI数据）
 * @param operationId 上传文件，并解析成功后，返回的operationId
 * @param metricId 
 * @param quarter
 * @return Promise
 * */
export function requestDrillDownData(operationId, metricId, quarter) {
  return request({
    url: `/sfdr/getDrillDown/${operationId}?metricId=${metricId}&quarter=${quarter}`,
    method: 'get',
    headers: {
      ...cacheHeaders,
    }
  })
}

/**
 * 13. 获取DrillDown数据权限
 * @return Promise
 * */
export function requestDrillDownPermission() {
  return request({
    url: `/sfdr/judgeDrillDownPermission`,
    method: 'get',
  })
}

/**
 * 14. 获取系统是否在维护时间内
 * @return Promise
 * */
export function requestSystemNotification() {
  return request({
    url: `/sfdr/getNotification`,
    method: 'get',
  })
}
