var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "risk_container",
    },
    [
      _c("div", { staticClass: "footprint_title title_bg" }, [
        _vm._v(" " + _vm._s(_vm.$t("risk.title1")) + " "),
      ]),
      _c("div", { staticClass: "text" }, [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.$t("risk.text1_1")) } }),
        _c("p", [_vm._v(" " + _vm._s(_vm.$t("risk.text1_2")) + " ")]),
      ]),
      _c("div", { staticClass: "footprint_title title_mid" }, [
        _vm._v(" " + _vm._s(_vm.$t("risk.title2")) + " "),
      ]),
      _vm.renderData.climateRisk
        ? _c(
            "table",
            { staticClass: "table", attrs: { border: "0", cellspacing: "0" } },
            [
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_1")))]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _vm._v(
                    _vm._s(
                      Number(
                        _vm.renderData.climateRisk.threshold * 100
                      ).toFixed(2) + "%"
                    )
                  ),
                ]),
              ]),
              _c("tr", { staticClass: "bg" }, [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_2")))]),
                _c("td", [_vm._v(" " + _vm._s(_vm.$t("risk.table1_3")) + " ")]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_4")))]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_11")))]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_5")))]),
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_6")))]),
                _c("td", [
                  _c("div", { class: _vm.isTable1Line1tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable1Line1tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRisk.physicalRisk * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable1Line1tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable1Line1tag ? "*" : "") +
                        _vm._s(
                          _vm.renderData.climateRisk.physicalRiskRelevance
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable1Line1tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable1Line1tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRisk.physicalRiskWeighting *
                              100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [_vm._v(" " + _vm._s(_vm.$t("risk.table1_7")) + " ")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_8")))]),
                _c("td", [
                  _c("div", { class: _vm.isTable1Line2tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable1Line2tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRisk.transitionRisk * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable1Line2tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable1Line2tag ? "*" : "") +
                        _vm._s(
                          _vm.renderData.climateRisk.transitionRiskRelevance
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable1Line2tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable1Line2tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRisk.transitionRiskWeighting *
                              100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [_vm._v(" " + _vm._s(_vm.$t("risk.table1_9")) + " ")]),
              ]),
              _vm.isTable1Note
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "5" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("risk.table1_10")) + " "),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "footprint_title title_bg" }, [
        _vm._v(" " + _vm._s(_vm.$t("risk.title3")) + " "),
      ]),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.renderData.statsOfSASB, stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "sasb",
              label: _vm.$t("risk.table2_1"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "amount",
              label: _vm.$t("risk.table2_2"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "value",
              label: _vm.$t("risk.table2_3"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "weightings",
              label: _vm.$t("risk.table2_4"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "scope12Emission",
              label: _vm.$t("risk.table2_5"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "emissionShares",
              label: _vm.$t("risk.table2_6"),
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "footprint_title title_bg" }, [
        _vm._v(" " + _vm._s(_vm.$t("risk.title4")) + " "),
      ]),
      _c("div", { attrs: { id: "bar_box1" } }),
      _c("div", { staticClass: "footprint_title title_bg" }, [
        _vm._v(" " + _vm._s(_vm.$t("risk.title5")) + " "),
      ]),
      _c("div", { staticClass: "text text_mid" }, [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.$t("risk.text2")) } }),
      ]),
      _c("div", { staticClass: "footprint_title title_mid" }, [
        _vm._v(" " + _vm._s(_vm.$t("risk.table3")) + " "),
      ]),
      _vm.renderData.climateRiskMateriality1
        ? _c(
            "table",
            { staticClass: "table", attrs: { border: "0", cellspacing: "0" } },
            [
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_1")))]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _vm._v(
                    _vm._s(
                      Number(
                        _vm.renderData.climateRiskMateriality1.threshold * 100
                      ).toFixed(2) + "%"
                    )
                  ),
                ]),
              ]),
              _c("tr", { staticClass: "bg" }, [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_2")))]),
                _c("td", [_vm._v(" " + _vm._s(_vm.$t("risk.table1_3")) + " ")]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.Materiality")))]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_11")))]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_5")))]),
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_6")))]),
                _c("td", [
                  _c("div", { class: _vm.isTable2Line1tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable2Line1tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRiskMateriality1
                              .physicalRisk * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable2Line1tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable2Line1tag ? "*" : "") +
                        _vm._s(
                          _vm.renderData.climateRiskMateriality1
                            .physicalRiskMaterial
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable2Line1tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable2Line1tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRiskMateriality1
                              .physicalRiskWeighting * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [_vm._v(" " + _vm._s(_vm.$t("risk.table1_7")) + " ")]),
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_8")))]),
                _c("td", [
                  _c("div", { class: _vm.isTable2Line2tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable2Line2tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRiskMateriality1
                              .transitionRisk * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable2Line2tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable2Line2tag ? "*" : "") +
                        _vm._s(
                          _vm.renderData.climateRiskMateriality1
                            .transitionRiskMaterial
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable2Line2tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable2Line2tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRiskMateriality1
                              .transitionRiskWeighting * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [_vm._v(" " + _vm._s(_vm.$t("risk.table1_9")) + " ")]),
              ]),
              _vm.isTable2Note
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "5" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("risk.table1_10")) + " "),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "footprint_title title_bg" }, [
        _vm._v(" " + _vm._s(_vm.$t("risk.title6")) + " "),
      ]),
      _c("div", { staticClass: "text text_mid" }, [
        _c("p", [_vm._v(" " + _vm._s(_vm.$t("risk.text3_3")) + " ")]),
      ]),
      _vm.baseFormData && _vm.renderData.climateRiskMateriality2
        ? _c(
            "table",
            { staticClass: "table", attrs: { border: "0", cellspacing: "0" } },
            [
              _c("tr", { staticClass: "bg" }, [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table3_1")))]),
                _c("td", [_vm._v(" " + _vm._s(_vm.$t("risk.table3_2")) + " ")]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("risk.table3_3", [
                          _vm.baseFormData.shadowDevideEbitdaOption2,
                        ])
                      ) +
                      " "
                  ),
                ]),
                _c("td", { attrs: { colspan: "3" } }),
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table3_4")))]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        Number(
                          _vm.renderData.climateRiskMateriality2
                            .thresholdOfPhysical * 100
                        ).toFixed(2) + "%"
                      ) +
                      " "
                  ),
                ]),
                _c("td", [_vm._v("/")]),
                _c("td", { attrs: { colspan: "3" } }),
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table3_5")))]),
                _c("td", [_vm._v("/")]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        Number(
                          _vm.renderData.climateRiskMateriality2
                            .materialityThresholdOfShadow * 100
                        ).toFixed(2) + "%"
                      ) +
                      " "
                  ),
                ]),
                _c("td", { attrs: { colspan: "3" } }),
              ]),
              _c("tr", { staticClass: "bg" }, [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_2")))]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table3_2")))]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("risk.table3_3", [
                        _vm.baseFormData.shadowDevideEbitdaOption2,
                      ])
                    )
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.Materiality")))]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_11")))]),
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_5")))]),
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_6")))]),
                _c("td", [
                  _c("div", { class: _vm.isTable3Line1tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable3Line1tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRiskMateriality2
                              .physicalRisk * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [_vm._v("/")]),
                _c("td", [
                  _c("div", { class: _vm.isTable3Line1tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable3Line1tag ? "*" : "") +
                        _vm._s(
                          _vm.renderData.climateRiskMateriality2
                            .physicalRiskMaterial
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable3Line1tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable3Line1tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRiskMateriality2
                              .physicalRiskWeighting * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.$t("risk.table3_node1")) + " "),
                ]),
              ]),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("risk.table1_8")))]),
                _c("td", [_vm._v("/")]),
                _c("td", [
                  _c("div", { class: _vm.isTable3Line2tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable3Line2tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRiskMateriality2
                              .transitionRiskOfShadow * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable3Line2tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable3Line2tag ? "*" : "") +
                        _vm._s(
                          _vm.renderData.climateRiskMateriality2
                            .transitionRiskMaterial
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _c("div", { class: _vm.isTable3Line2tag ? "red" : "" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isTable3Line2tag ? "*" : "") +
                        _vm._s(
                          Number(
                            _vm.renderData.climateRiskMateriality2
                              .transitionRiskWeighting * 100
                          ).toFixed(2) + "%"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.$t("risk.table3_node2")) + " "),
                ]),
              ]),
              _vm.isTable3Note
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "6" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("risk.table1_10")) + " "),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "text text_bottom" }, [
        _vm._v(" " + _vm._s(_vm.$t("risk.text4")) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }