<template>
  <!-- 展示 状态-->
  <div class="page-show-vue">
    <el-row type="border-card" style="height: 100%">
      <!-- 左侧导航菜单   -->
      <el-col :sm="4" :md="4" :lg="6" :xl="6">
        <Navigation ref="navigation" :heading="navigationList"></Navigation>
      </el-col>

      <!-- 内容部分 -->
      <el-col :sm="20" :md="20" :lg="18" :xl="18">
        <div style="max-width: 1200px; padding-left: 10px">
          <!-- 暂不显示题目 -->
          <div class="wiki-title" ref="wikiTitle" v-show="false">
            {{ wikiPage.title }}
          </div>

          <div ref="pageContent" class="dl-page-content">
            <div v-html="pageShowDetail" class="markdown-body"></div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 图片预览 -->
    <div ref="imagePreview">
      <el-image-viewer
        v-if="showImagePreview"
        :url-list="showImagePreviewList"
        :on-close="closeImagePreview"
        :initial-index="previewInitialIndex"
      />
    </div>
  </div>
</template>

<script>
import htmlUtil from "@/utils/data-platform/HtmlUtil.js";
import Navigation from "./Navigation.vue";
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/markdown/github-markdown.min.css";
import "mavon-editor/dist/css/index.css";
import { getApiText } from "@/api/system/config.js";
export default {
  components: { mavonEditor, Navigation },
  data() {
    return {
      loading: true,
      // 页面展示相关
      wikiPage: {},
      pageContent: {},
      pageShowDetail: "",
      // 左侧导航菜单
      navigationList: [],
      // 大图预览
      previewInitialIndex: 0,
      showImagePreview: false,
      showImagePreviewList: [],
    };
  },
  mounted: function () {
    this.loadPageDetail();
  },
  methods: {
    //计算导航宽度
    computeNavigationWidth() {
      setTimeout(() => {
        if (this.$refs.navigation) {
          this.$refs.navigation.computeNavigationWidth();
        }
      }, 100);
    },

    // 获取接口描述
    loadPageDetail(pageId) {
      let id = 1;
      getApiText({idstr: id})
        .then(({ data }) => {
          // 标题
          let wikiPage = data || {};
          this.wikiPage = wikiPage;
          this.pageContent = data || {};
          //解析
          this.pageContent.text = mavonEditor
            .getMarkdownIt()
            .render(this.pageContent.text);

          this.pageShowDetail = this.pageContent.text;
        })
        .catch((err) => {
          console.log(err);
        });

      setTimeout(() => {
        //加载左侧导航
        this.createNavigationHeading();
      }, 500);
    },
    // 导航栏标题获取
    createNavigationHeading() {
      let navigationList = htmlUtil.createNavigationHeading();
      this.$refs.navigation.loading = false;
      // 标题加到导航里面去
      if (navigationList.length > 0) {
        // let titleDL = this.wikiPage.title || "API库展示";
        navigationList.unshift({
          level: 1,
          node: this.$refs.wikiTitle,
          text: "",
        });
      }
      this.navigationList = navigationList;
    },
    // 图片预览
    closeImagePreview() {
      this.showImagePreview = false;
    },
    previewPageImage() {
      const imgArr = [];
      const imgSelector = this.$refs.pageContent.querySelectorAll("img");
      imgSelector.forEach((item, index) => {
        imgArr.push(item.src);
        item.onclick = () => {
          this.previewInitialIndex = index;
          this.showImagePreviewList = imgArr;
          this.showImagePreview = true;
          setTimeout(() => this.initImageViewerMask(), 0);
        };
      });
    },

    initImageViewerMask() {
      // 图片预览遮罩点击隐藏预览框
      let imageViewerMask = this.$refs.imagePreview.querySelectorAll(
        ".el-image-viewer__mask"
      );
      imageViewerMask.forEach((item) => {
        item.onclick = () => {
          this.showImagePreview = false;
        };
      });
    },
  },
};
</script>
<style scoped lang="scss">
/*覆盖样式*/
.page-show-vue {
  height: 100%;
  .markdown-body {
    height: calc(100vh - 270px);
    overflow-y: auto;
  }
}
// 只用于大屏幕
@media screen and (min-width: 1770px) {
  .page-show-vue {
    .markdown-body {
      height: calc(100vh - 400px);
    }
  }
}
.page-show-vue .icon-collapse {
  float: left;
  font-size: 25px;
  color: #aaa;
  margin-top: 8px;
  cursor: pointer;
}
.page-show-vue .icon-collapse:hover {
  color: #eee;
}
.page-show-vue .wiki-title {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.page-show-vue .dl-page-content img {
  cursor: pointer;
  max-width: 100%;
}
.page-show-vue .dl-page-content img:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.page-show-vue .upload-page-file .el-upload-list {
  display: none;
}
.page-show-vue .is-link {
  color: #1e88e5;
  cursor: pointer;
}
/*编辑框高度*/
// .page-show-vue #newPageContentDiv .w-e-text-container {
//   height: 600px !important;
// }
</style>

