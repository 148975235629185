<template>
    <div class="additional-page">
        <div class="additional-header" v-if="!showDrillDownTable">
            <div class="additional-pai-select-wrapper">
                <span
                    class="additional-pai-select"
                    @click="showPaiSelect = true"
                    >Select additional indicators</span
                >
                <el-tooltip class="item" effect="dark" placement="right">
                    <i class="el-icon-question"></i>
                    <div slot="content">
                        <p>Notes:</p>
                        <p>
                            (a) At least one additional indicator related to
                            principal adverse impacts on a climate or other
                        </p>
                        <p>environment related sustainability factor;</p>
                        <p>
                            (b) At least one additional indicator related to
                            principal adverse impacts on a social, employee,
                        </p>
                        <p>
                            human rights, anti-corruption or anti-bribery
                            sustainability factor.
                        </p>
                    </div>
                </el-tooltip>
            </div>
            <div class="display-select-wrapper" v-show="!showPaiSelect">
                <el-radio v-model="currentDisplayType" label="full"
                    >Full Display</el-radio
                >
                <el-radio v-model="currentDisplayType" label="simple"
                    >Summary Display</el-radio
                >
            </div>
        </div>
        <mandatory-table
            v-show="!showPaiSelect"
            v-if="!showDrillDownTable"
            :data="tableData"
            :columns="tableColumn"
            :displayType="currentDisplayType"
            :hasDrillDownPermission="hasDrillDownPermission"
            @cellClick="cellClickHandler"
            v-loading="loading"
        ></mandatory-table>
        <DrillDownTable
            v-if="showDrillDownTable"
            :data="drillDownData"
            :columns="drillDownColumns"
            @cellClick="cellClickHandler"
        ></DrillDownTable>
        <AdditionalPaiSelect
            :paiGroup="paiGroup"
            v-show="showPaiSelect"
            @updatePaiIdList="paiIdListHandler"
        ></AdditionalPaiSelect>
        <div class="footer">
            <div class="btn-container" v-show="!showPaiSelect">
                <el-button @click="backStep" v-if="!showDrillDownTable"
                    >Back</el-button
                >
                <el-button
                    class="primary"
                    type="primary"
                    @click="nextStep"
                    v-if="!showDrillDownTable"
                    >Review full report</el-button
                >
                <el-button
                    class="primary"
                    type="primary"
                    @click="showDrillDownTable = false"
                    v-if="showDrillDownTable"
                    >Close</el-button
                >
            </div>
            <div class="btn-container" v-show="showPaiSelect">
                <el-button @click="cancelHandler">Cancel</el-button>
                <el-button
                    class="primary"
                    type="primary"
                    @click="calculationHandler"
                    >Calculation</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import MandatoryTable from '../components/mandatory-pai/MandatoryTable.vue'
import DrillDownTable from '../components/mandatory-pai/DrillDownTable.vue'
import AdditionalPaiSelect from '../components/additional-pai/AdditionalPaiSelect.vue'
import messageMixin from '../mixin/messageMixin'
import tableDataHandleMixin from '../mixin/tableDataHandleMixin'
import {
  calculatePAIData,
  getPAICalculationData
} from '@/api/data-platform/SFDR.js'

export default {
  components: {
    MandatoryTable,
    DrillDownTable,
    AdditionalPaiSelect
  },
  mixins: [messageMixin, tableDataHandleMixin],
  data() {
    return {
      showPaiSelect: true,
      currentDisplayType: 'full', // full,simple
      loading: false,
      originalData: [],
      tableData: [],
      tableColumn: [],
      categoryIdGroup: {
        1: [],
        2: []
      },
      paiIDList: []
    }
  },
  methods: {
    paiIdListHandler(categoryIdGroup) {
      console.log('categoryIdGroup:', categoryIdGroup)
      this.categoryIdGroup = categoryIdGroup
    },
    cancelHandler() {
      if (this.paiIDList.length) {
        this.showPaiSelect = false
      } else {
        this.backStep()
      }
    },
    calculationHandler() {
      if (!this.categoryIdGroup[1].length) {
        this.popWarning(
          'Select at least one on <strong>Climate and other environment-related indicators</strong>.',
          2000
        )
        return
      }
      if (!this.categoryIdGroup[2].length) {
        this.popWarning(
          'Select at least one on <strong>Social and employee, respect for human rights, anti-corruption and anti-bribery matters</strong>.',
          2000
        )
        return
      }

      this.showPaiSelect = false
      const newIdList = [...this.categoryIdGroup[1], ...this.categoryIdGroup[2]]

      this.paiIDList = newIdList

      this.requestCalculatePaiData()
    },
    async requestCalculatePaiData() {
      if (!this.paiIDList.length || !this.operationId) {
        return
      }
      this.loading = true

      const sendPaiList = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17
      ].concat(this.paiIDList)

      const calcResp = await calculatePAIData(this.operationId, sendPaiList)

      if (calcResp.code !== 200) {
        this.popError('Calculate PAIs data failed')
        return
      }

      const tableDataResp = await getPAICalculationData(this.operationId)
      if (tableDataResp.code !== 200) {
        this.popError('Get PAIs calculation data failed')
      }
      this.originalData = tableDataResp.data || []
      this.handleResponse()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/footer.scss";

.footer {
    padding: 16px 0;
}

.additional-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100% - 214px);

    ::v-deep div p {
        word-break: break-word;
    }
    .additional-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        .additional-pai-select-wrapper {
            ::v-deep .el-tooltip {
                color: #bfbfbf;
            }
            span {
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 20px;
                margin-right: 8px;
                cursor: pointer;
            }
            span:hover {
                color: #5998f0;
            }
            .impact-select {
                width: 250px;
                border-color: transparent;

                ::v-deep .el-input__inner {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    user-select: none;
                }
            }
        }

        .display-select-wrapper {
            ::v-deep .el-radio.is-checked {
                .el-radio__inner {
                    background: #ffffff;
                }
                .el-radio__inner::after {
                    width: 6px;
                    height: 6px;
                    background: #1890ff;
                }
                .el-radio__label {
                    color: #606266;
                }
            }
            .el-radio:first-child {
                margin-right: 22px;
            }
        }
    }
}
</style>
<style lang="scss">
.el-select-dropdown__item {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #323233;
}
.el-select-dropdown__item:hover {
    background: #e1ebf8;
}

.el-select-dropdown {
    margin-top: 2px !important;
    .popper__arrow {
        display: none;
    }
}
</style>
