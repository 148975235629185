<template>
  <div>
    <el-table
      class="fileTable"
      :data="rows"
      style="width: 100%"
      stripe
      :header-row-class-name="tableHead"
      v-loading="loading"
    >
      <el-table-column
        align="center"
        prop="portfolioDate"
        :label="$t('watchList.portfolioDate')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="fundId"
        :label="$t('watchList.fundId')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="fundName"
        :label="$t('watchList.fundName')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="isin"
        :label="$t('watchList.isin')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="ticker"
        :label="$t('watchList.ticker')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="securityName"
        :label="$t('watchList.securityName')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="sasbIndustry"
        :label="$t('watchList.sasbIndustry')"
      >
      </el-table-column>
    </el-table>
    <div class="text">
      <p>
        {{$t('watchList.text1')}}
      </p>
    </div>
    <el-pagination
      v-if="renderData"
      class="pagination"
      background
      layout="prev, pager, next"
      :total="renderData.total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

</template>

<script>
import { getWatchList } from '@/api/data-platform/FMCC'

export default {
  name: 'fileTable',
  props: ['id', 'baseFormData'],
  data() {
    return {
      renderData: null,
      val: 1,
      loading: false
    }
  },
  computed: {
    lang() {
      return this.$store.getters.language
    },
    // 提取渲染数据
    rows() {
      let res = []
      if(this.renderData && this.renderData.rows) {
        res = this.renderData.rows
      }
      return res
    }
  },
  watch: {
    lang(newVal, old) {
      if(this.id && this.baseFormData) {
        this.getWatchList()
      }
    }
  },
  // mounted() {
  //   this.getWatchList()
  // },
  methods: {
    // 样式
    tableHead() {
      return 'tableHead'
    },
    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.val = val
      this.getWatchList()
    },
    // 获取数据
    getWatchList() {
      this.loading = true
      getWatchList({
        id: this.id,
        pageNum: this.val,
        ...this.baseFormData,
        lang: this.lang
      }).then(res => {
        this.renderData = res
        this.loading = false
      }).catch((err)=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
@mixin fontStyle {
  font-family: 'PingFang SC';
  font-weight: 900;
}

.fileTable {

  ::v-deep .tableHead th {
    @include fontStyle;
    height: 40px;
    font-size: 14px;
    color: #1F5080;
    background: #DFEBF9;
  }

  ::v-deep .cell {
    font-family: 'PingFang SC';
    font-size: 14px;
    color: #1F5080;
  }
}

.text {
  padding: 36px 40px 16px 40px;
  font-family: 'PingFang SC';
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1F5080;;
}
.pagination {
  margin: 10px 10px 30px 0;
  text-align: right;
}
::v-deep .el-pager li {
  font-weight: 400;
}
</style>
