var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "绑定用户",
        width: "1600px",
        "custom-class": "bindingUserTreeDialog",
      },
      on: { close: _vm.dialogClose },
    },
    [
      _c("div", { staticStyle: { overflow: "hidden", height: "100%" } }, [
        _c(
          "div",
          { staticClass: "left-content" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.formData,
                  inline: "",
                  "label-width": "40px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "用户" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "160px" },
                        attrs: { filterable: "", clearable: "" },
                        on: { change: _vm.selectedUserIdChange },
                        model: {
                          value: _vm.formData.selectedUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "selectedUserId", $$v)
                          },
                          expression: "formData.selectedUserId",
                        },
                      },
                      _vm._l(
                        _vm.userList.filter(function (e) {
                          return e.status === "0"
                        }),
                        function (item) {
                          return _c("el-option", {
                            key: item.userId,
                            attrs: {
                              value: item.userId,
                              label: item.nickName + "(" + item.userName + ")",
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "排序" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "160px" },
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.formData.sortType,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sortType", $$v)
                          },
                          expression: "formData.sortType",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "按名称", value: "1" },
                        }),
                        _c("el-option", {
                          attrs: { label: "按类型", value: "2" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("TreeComponent", {
              ref: "tree",
              staticClass: "tree-component",
              attrs: { treeData: _vm.treeData, defaultExpandAll: "" },
              on: { "node-click": _vm.nodeClick },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var data = ref.data
                    return _c("span", { staticStyle: { width: "100%" } }, [
                      _c("div", { staticClass: "node-content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "node-label",
                            attrs: { title: data.label },
                          },
                          [_vm._v(_vm._s(data.label))]
                        ),
                      ]),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-content" },
          [
            _c(
              "bindingUser",
              _vm._b(
                {
                  staticStyle: { height: "100%" },
                  attrs: { batchBind: true },
                  on: {
                    getUserList: _vm.getUserList,
                    saveSuccess: _vm.saveSuccess,
                  },
                },
                "bindingUser",
                _vm.apiInfo,
                false
              )
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.dialogClose } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }