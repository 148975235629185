<template>
  <div class="company-container">
    <p class="company-top-title">{{ companyTitle }}</p>
    <div class="company-table">
      <el-collapse v-model="activeNames" @change="handleChange" class="company-collapse">
        <el-collapse-item name="1">
          <template slot="title">
            <div style="width: 100%;text-align: center;background-color: rgba(249, 194, 194, 1);">监管认定异常公司</div>
          </template>
          <div style="padding: 0 10px;">
            <!-- <el-input  placeholder="请输入" prefix-icon="el-icon-search" @keyup="handleSearch()" style="margin: 10px 0;" /> -->
            <el-autocomplete
              v-model="searchStr1"
              :fetch-suggestions="querySearch"
              placeholder="请输入搜索条件"
              :trigger-on-focus="false"
              @select="handleSelect"
              style="margin: 10px 0;"
            >
            </el-autocomplete>
            <div style="height: 400px;overflow-y: auto;margin-bottom: 10px;">
              <el-table v-loading="loading" height="400" :data="abnormalUtilList">
                <el-table-column prop="id" label="ID" align="center" width="100">
                  <template slot-scope="scope">
                    <span @click="handleCellClick(scope.row.id, 10)" class="cursor-btn">{{ scope.row.id }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="Name" align="center" width="100">
                  <template slot-scope="scope">
                    <span @click="handleCellClick(scope.row.id, 10)" class="cursor-btn">{{scope.row.name}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <div style="width: 100%;text-align: center;background-color: rgba(184, 240, 217, 1);">其他公司</div>
          </template>
          <div style="padding: 0 10px;">
            <!-- <el-input placeholder="请输入搜索条件" prefix-icon="el-icon-search" @keyup="handleSearch()" style="margin: 10px 0;"/> -->
            <el-autocomplete
              v-model="searchStr2"
              :fetch-suggestions="querySearch2"
              placeholder="请输入搜索条件"
              :trigger-on-focus="false"
              @select="handleSelect2"
              style="margin: 10px 0;"
            >
            </el-autocomplete>
            <div style="height: 400px;overflow-y: auto;margin-bottom: 10px;">
              <el-table v-loading="loading" height="400" :data="noneAbnormalList">
                <el-table-column prop="id" label="ID" align="center" width="100">
                  <template slot-scope="scope">
                    <span @click="handleCellClick(scope.row.id, 0)" class="cursor-btn">{{scope.row.id}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="Name" align="center" width="100">
                  <template slot-scope="scope">
                    <span @click="handleCellClick(scope.row.id, 0)" class="cursor-btn">{{scope.row.name}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { getFlopUtilList } from '@/api/data-platform/FLOP.js'
export default {
  props: {
    companyTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      activeNames: ['1','2'],
      abnormalUtilList: [],
      noneAbnormalList: [],
      queryParams: {},
      searchStr1: '',
      searchStr2: '',
    }
  },
  created() {
    this.getUitlList()
  },
  methods: {
    // 获取公司列表
    getUitlList() {
      this.loading = true
      getFlopUtilList(10).then(res => {
        this.abnormalUtilList = res.data
      })
      getFlopUtilList(0).then(res => {
        this.noneAbnormalList = res.data
        this.loading = false
      })
    },
    handleChange() {},
    // 点击id/name事件 获取右侧公司详情
    handleCellClick(id,num) {
      const param = {
        id: id,
        type: num
      }
      this.$emit("handleCellClick", param)
    },
    // 异常公司查询
    querySearch(queryString, cb) {
      var abnormalUtilList = this.abnormalUtilList;
      var results = queryString ? abnormalUtilList.filter(this.createFilter(queryString)) : abnormalUtilList;
      var result = []
      if(results.length > 0){
        results.map(item => {
          result.push({value: item.id + item.name})
        })
      }
      // 调用 callback 返回建议列表的数据
      cb(result);
    },
    handleSelect(item) {
      const arr = []
      item.value.split('').map(item => {
        if(!isNaN(parseFloat(item))){
          arr.push(item)
        }
      })
      this.handleCellClick(arr.join('').slice(0,6),10)
    },
    createFilter(queryString) {
      return (abnormalUtilItem) => {
        return (abnormalUtilItem.id.indexOf(queryString) >= 0 || abnormalUtilItem.name.indexOf(queryString) >= 0);
      };
    },
    // 无异常公司查询
    querySearch2(queryString, cb) {
      var abnormalUtilList = this.noneAbnormalList;
      var results = queryString ? abnormalUtilList.filter(this.createFilter(queryString)) : abnormalUtilList;
      var result = []
      if(results.length > 0){
        results.map(item => {
          result.push({value: item.id + item.name})
        })
      }
      // 调用 callback 返回建议列表的数据
      cb(result);
    },
    handleSelect2(item) {
      this.handleCellClick(item.value.slice(0,6),0)
    },
  }
}
</script>

<style lang="scss" scoped>
.company-container{
  font-size: 14px;
  border-radius: 6px;
  // min-width: 200px;
  background-color: #fff;
  color: #01579b;
  .company-top-title{
    border-radius: 6px 6px 0 0;
    line-height: 40px;
    padding-left: 10px;
    font-weight: 600;
    background-color: #dfebf9;
  }
  .company-collapse{
    // padding: 10px;
    ::v-deep .el-collapse-item__content{
      padding-bottom: 0px;
    }
    ::v-deep .el-collapse-item__arrow{
      display: none;
    }
  }
  .company-table{
    height: 100%;
    overflow-y: auto;
    .cursor-btn{
      cursor: pointer;
    }
  } 
}
</style>