<template>
  <div class="footprint_container" v-loading="loading">
    <div class="footprint_title title_bg" v-if="!isHidden">
      {{ $t("footprint.title1") }}
    </div>

    <el-table
      v-if="renderData && !isHidden"
      :data="renderData.carbonFootprintAssessment"
      stripe
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="indicator"
        :label="$t('footprint.table1Th1')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="unit"
        :label="$t('footprint.table1Th2')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="value"
        :label="$t('footprint.table1Th3')"
      >
      </el-table-column>
    </el-table>
    <div class="text" v-if="!isHidden">
      <p>
        {{ $t("footprint.text1") }}
      </p>
      <p>
        {{ $t("footprint.text2") }}
      </p>
    </div>

    <!-- 基于SASB行业分类的数据统计 -->
    <div
      class="footprint_title title_bg"
      v-if="!isHidden && flag !== 'FootPrint'"
    >
      {{ $t("footprint.title2") }}
    </div>
    <el-table
      v-if="renderData && !isHidden && flag !== 'FootPrint'"
      :data="renderData.sasbClassification"
      stripe
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="sasb"
        :label="$t('footprint.table2Th1')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="amount"
        :label="$t('footprint.table2Th2')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="value"
        :label="$t('footprint.table2Th3')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="weightings"
        :label="$t('footprint.table2Th4')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="scope12Emission"
        :label="$t('footprint.table2Th5')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="emissionShares"
        :label="$t('footprint.table2Th6')"
      >
      </el-table-column>
    </el-table>

    <!-- echarts -->
    <div
      class="footprint_title title_bg"
      v-if="!isHidden && flag !== 'FootPrint'"
    >
      {{ $t("footprint.title3") }}
    </div>
    <div id="bar_box" ref="imageDom" v-show="flag !== 'FootPrint'"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { portfolioCarbonFootprint } from "@/api/data-platform/FMCC";

export default {
  name: "FootprintTab",
  props: ["baseFormData", "id", "isHidden", "flag"],
  data() {
    return {
      portfolioCarbonFootprint: null,
      loading: false,
    };
  },
  computed: {
    // 数据处理
    renderData() {
      let res = {};
      if (
        this.portfolioCarbonFootprint &&
        this.portfolioCarbonFootprint.carbonFootprintAssessment
      ) {
        res.carbonFootprintAssessment =
          this.portfolioCarbonFootprint.carbonFootprintAssessment.map(
            (item) => {
              if (item.unit == "/") {
                return {
                  ...item,
                  value: Number(item.value * 100).toFixed(2) + "%",
                };
              } else if (item.unit == "tCO₂e" || item.indicator.indexOf('WACI')>-1) {
                return {
                  ...item,
                  value: Number(item.value).toFixed(2)
                };
              } else {
                return item;
              }
            }
          );

        res.sasbClassification =
          this.portfolioCarbonFootprint.sasbClassification.map((item) => {
            return {
              ...item,
              weightings: Number(item.weightings * 100).toFixed(2) + "%",
              emissionShares:
                Number(item.emissionShares * 100).toFixed(2) + "%",
            };
          });

        this.$nextTick(() => {
          this.init();
        });
      }
      return res;
    },
    // echarts数据处理
    weightings() {
      let res = [];
      if (
        this.portfolioCarbonFootprint &&
        this.portfolioCarbonFootprint.sasbClassification
      ) {
        this.portfolioCarbonFootprint.sasbClassification.forEach((item) => {
          res.push(item.weightings);
        });
      }
      return res;
    },
    // echarts数据处理
    emissionShares() {
      let res = [];
      if (
        this.portfolioCarbonFootprint &&
        this.portfolioCarbonFootprint.sasbClassification
      ) {
        this.portfolioCarbonFootprint.sasbClassification.forEach((item) => {
          res.push(item.emissionShares);
        });
      }
      return res;
    },
    // echarts数据处理
    sasb() {
      let res = [];
      if (
        this.portfolioCarbonFootprint &&
        this.portfolioCarbonFootprint.sasbClassification
      ) {
        this.portfolioCarbonFootprint.sasbClassification.forEach((item) => {
          res.push(item.sasb);
        });
      }
      return res;
    },
    lang() {
      return this.$store.getters.language;
    },
  },
  watch: {
    // 语言切换重新请求数据
    lang(newVal, old) {
      if (this.id && this.baseFormData) {
        this.getFootprint();
      }
    },
  },
  methods: {
    // 请求数据
    getFootprint() {
      this.loading = true;
      return new Promise((resolve) => {
        portfolioCarbonFootprint({
          id: this.id,
          ...this.baseFormData,
          lang: this.lang,
        }).then((res) => {
          this.portfolioCarbonFootprint = res.data;
          this.loading = false;
          resolve();
        });
      });
    },
    // 生成柱状图
    init() {
      let chartDom = document.getElementById("bar_box");
      let myChart = echarts.init(chartDom);
      myChart.resize({ height: this.sasb.length * 14 + 120 });
      let option;

      option = {
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'shadow'
        //   }
        // },
        animation: false,
        legend: {
          bottom: 20,
        },
        grid: {
          top: 40,
          left: "8%",
          right: "4%",
          bottom: "50",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisLabel: {
            formatter: function (value) {
              return Number(value * 100).toFixed(2) + "%";
            },
          },
        },
        yAxis: {
          type: "category",
          data: this.sasb,
          axisLabel: {
            interval: 0,
          },
        },
        series: [
          {
            name: this.lang === "en" ? "Weightings" : "占基金资产净值比例",
            type: "bar",
            data: this.weightings,
          },
          {
            name: this.lang === "en" ? "Emission shares" : "排放量分配占比",
            type: "bar",
            data: this.emissionShares,
          },
        ],
      };
      option && myChart.setOption(option, true);
    },
  },
};
</script>

<style scoped lang="scss">
$text_color: #1f5080;
@mixin fontStyle {
  font-family: "PingFang SC";
  font-weight: 900;
}

.footprint_container {
  .footprint_title {
    height: 40px;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: $text_color;
    @include fontStyle;
  }

  ::v-deep .cell {
    font-family: "PingFang SC";
    font-size: 14px;
    color: #1f5080;
  }

  .title_bg {
    height: 40px;
    background-color: #dfebf9;
    line-height: 40px;
  }

  .text {
    padding: 20px 16px 20px 30px;
    font-family: "PingFang SC";
    font-size: 14px;
    line-height: 20px;
    color: $text_color;
  }

  #bar_box {
    width: 100%;
    min-height: 120px;
  }
}
</style>
