var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.refresh
    ? _c("div", { staticClass: "additional-pai-select-wrapper" }, [
        _c("div", { staticClass: "category-section" }, [
          _c(
            "div",
            { staticClass: "category-header" },
            [
              _vm._m(0),
              _c(
                "el-checkbox",
                {
                  staticClass: "select-all",
                  model: {
                    value: _vm.part1SelectAll,
                    callback: function ($$v) {
                      _vm.part1SelectAll = $$v
                    },
                    expression: "part1SelectAll",
                  },
                },
                [_vm._v("Select All")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "impacts-section" }, [
            _c("p", { staticClass: "impacts-title" }, [_vm._v("Emissions")]),
            _c(
              "div",
              _vm._l(_vm.impactFactorsMapping["Emissions"], function (factor) {
                return _c("SelectItem", {
                  key: factor,
                  attrs: {
                    selected: !!_vm.selectItemObj[factor],
                    id: factor,
                    name: factor,
                    impact: "Emissions",
                    categoryId: 1,
                  },
                  on: {
                    paiSelected: _vm.handlePaiItemSelect,
                    paiUnSelected: _vm.handlePaiItemUnSelect,
                  },
                })
              }),
              1
            ),
          ]),
          _c("div", { staticClass: "impacts-section" }, [
            _c("p", { staticClass: "impacts-title" }, [
              _vm._v("Energy performance"),
            ]),
            _c(
              "div",
              _vm._l(
                _vm.impactFactorsMapping["Energy performance"],
                function (factor) {
                  return _c("SelectItem", {
                    key: factor,
                    attrs: {
                      selected: !!_vm.selectItemObj[factor],
                      id: factor,
                      name: factor,
                      impact: "Energy performance",
                      categoryId: 1,
                    },
                    on: {
                      paiSelected: _vm.handlePaiItemSelect,
                      paiUnSelected: _vm.handlePaiItemUnSelect,
                    },
                  })
                }
              ),
              1
            ),
          ]),
          _c("div", { staticClass: "impacts-section" }, [
            _c("p", { staticClass: "impacts-title" }, [
              _vm._v("Water, waste and material emissions"),
            ]),
            _c(
              "div",
              _vm._l(
                _vm.impactFactorsMapping["Water, waste and material emissions"],
                function (factor) {
                  return _c("SelectItem", {
                    key: factor,
                    attrs: {
                      selected: !!_vm.selectItemObj[factor],
                      id: factor,
                      name: factor,
                      impact: "Water, waste and material emissions",
                      categoryId: 1,
                    },
                    on: {
                      paiSelected: _vm.handlePaiItemSelect,
                      paiUnSelected: _vm.handlePaiItemUnSelect,
                    },
                  })
                }
              ),
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "border-split" }),
        _c("div", { staticClass: "category-section" }, [
          _c(
            "div",
            { staticClass: "category-header" },
            [
              _vm._m(1),
              _c(
                "el-checkbox",
                {
                  staticClass: "select-all",
                  model: {
                    value: _vm.part2SelectAll,
                    callback: function ($$v) {
                      _vm.part2SelectAll = $$v
                    },
                    expression: "part2SelectAll",
                  },
                },
                [_vm._v("Select All")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "impacts-section" }, [
            _c("p", { staticClass: "impacts-title" }, [
              _vm._v("Social and employee matters"),
            ]),
            _c(
              "div",
              _vm._l(
                _vm.impactFactorsMapping["Social and employee matters"],
                function (factor) {
                  return _c("SelectItem", {
                    key: factor,
                    attrs: {
                      selected: !!_vm.selectItemObj[factor],
                      id: factor,
                      name: factor,
                      impact: "Social and employee matters",
                      categoryId: 2,
                    },
                    on: {
                      paiSelected: _vm.handlePaiItemSelect,
                      paiUnSelected: _vm.handlePaiItemUnSelect,
                    },
                  })
                }
              ),
              1
            ),
          ]),
          _c("div", { staticClass: "impacts-section" }, [
            _c("p", { staticClass: "impacts-title" }, [_vm._v("Human Rights")]),
            _c(
              "div",
              _vm._l(
                _vm.impactFactorsMapping["Human Rights"],
                function (factor) {
                  return _c("SelectItem", {
                    key: factor,
                    attrs: {
                      selected: !!_vm.selectItemObj[factor],
                      id: factor,
                      name: factor,
                      impact: "Human Rights",
                      categoryId: 2,
                    },
                    on: {
                      paiSelected: _vm.handlePaiItemSelect,
                      paiUnSelected: _vm.handlePaiItemUnSelect,
                    },
                  })
                }
              ),
              1
            ),
          ]),
          _c("div", { staticClass: "impacts-section" }, [
            _c("p", { staticClass: "impacts-title" }, [
              _vm._v("Anti-corruption and anti-bribery"),
            ]),
            _c(
              "div",
              _vm._l(
                _vm.impactFactorsMapping["Anti-corruption and anti-bribery"],
                function (factor) {
                  return _c("SelectItem", {
                    key: factor,
                    attrs: {
                      selected: !!_vm.selectItemObj[factor],
                      id: factor,
                      name: factor,
                      impact: "Anti-corruption and anti-bribery",
                      categoryId: 2,
                    },
                    on: {
                      paiSelected: _vm.handlePaiItemSelect,
                      paiUnSelected: _vm.handlePaiItemUnSelect,
                    },
                  })
                }
              ),
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "category-title" }, [
      _c("img", {
        staticClass: "point-icon",
        attrs: { src: require("@/assets/sfdr/images/A.png"), alt: "" },
      }),
      _vm._v(
        " Climate and other environment-related indicators (Select at least one ) "
      ),
      _c("span", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "category-title" }, [
      _c("img", {
        staticClass: "point-icon",
        attrs: { src: require("@/assets/sfdr/images/B.png"), alt: "" },
      }),
      _vm._v(
        " Social and employee, respect for human rights, anti-corruption and anti-bribery matters (Select at least one) "
      ),
      _c("span", [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }