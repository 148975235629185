var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "international",
      attrs: { trigger: "click" },
      on: { command: _vm.handleSetLanguage },
    },
    [
      _c("el-button", { ref: "btn1", attrs: { type: "primary" } }, [
        _vm._v(
          " " +
            _vm._s(_vm.$store.getters.language === "zh" ? "中文" : "English") +
            " "
        ),
        _c(
          "div",
          { staticClass: "el-arrow-white" },
          [
            _c("svg-icon", {
              attrs: { slot: "prefix", "icon-class": "arrow-white" },
              slot: "prefix",
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dropdown-menu",
        {
          staticClass: "lang-select",
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        [
          _c("el-dropdown-item", { attrs: { command: "en" } }, [
            _vm._v("English"),
          ]),
          _c("el-dropdown-item", { attrs: { command: "zh" } }, [
            _vm._v("中文"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }