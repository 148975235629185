var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: (_vm.id ? "修改" : "新增") + "自选清单",
        width: "800px",
      },
      on: { close: _vm.dialogClose },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.formRules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "清单名称：", prop: "secCodeListName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.formData.secCodeListName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "secCodeListName", $$v)
                  },
                  expression: "formData.secCodeListName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "清单内容：", prop: "secCodeListContent" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("手动输入"),
                  ]),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("导入")]),
                ],
                1
              ),
              _vm.formData.type === "1"
                ? _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "4",
                      placeholder: "请输入,如:000001,000002",
                    },
                    on: { blur: _vm.blur },
                    model: {
                      value: _vm.formData.secCodeListContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "secCodeListContent", $$v)
                      },
                      expression: "formData.secCodeListContent",
                    },
                  })
                : _c(
                    "div",
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          attrs: {
                            action: "/",
                            "show-file-list": false,
                            "on-change": _vm.importExcel,
                            "auto-upload": false,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "d2-mt-10 d2-mb-10",
                              attrs: {
                                slot: "trigger",
                                icon: "el-icon-upload",
                                size: "small",
                              },
                              slot: "trigger",
                            },
                            [_vm._v(" 上传文件 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { type: "text", size: "small" },
                              on: { click: _vm.downloadTemplate },
                            },
                            [_vm._v("下载模板")]
                          ),
                        ],
                        1
                      ),
                      _vm.tableData.length
                        ? _c(
                            "el-table",
                            {
                              staticStyle: { "margin-top": "16px" },
                              attrs: {
                                data: _vm.tableData,
                                border: "",
                                "max-height": "600px",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "secCode", prop: "secCode" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.dialogClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }