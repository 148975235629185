export default {
    //帮助支持
    help: {
        h2Title1: '快速开始：',
        articleText1: `<p>欢迎使用鼎力数据平台！</p> 
        <p>如果您是第一次进入我们的平台，不妨从“数据浏览器”出发开启了解鼎力数据平台的旅程。数据驱动的功能设计以及定制化的专业服务将满足您的需求。</p>
        <p>我们的数据能力将在这个平台被充分释放以辅助您的各种决策。</p>`,

        h1Title1: 'Data Browser',
        articleText2: `<p>首次使用数据浏览器，您需要使用“导入”功能，将您的投资组合或者您想要查询的证券上传到该系统中，上传列表需要提供的证券信息可以参照导入的模板来填写。</p>`,
        articleText6: `<p>成功导入您的证券列表后，可以按照自己的需要将列表保存为“自选清单”，以供后续选择使用。特定证券的增加可以在页面左下角的查询框中搜索添加。</br>
        确定证券列表后，您就可以开始选择需要的数据点。“待选指标区”内的指标通过点击添加，您可以根据需要选择日期。不需要的数据点也可以通过列表中指标旁边的删除按钮去掉该数据列。</br>
        上述选择完成后，您就可以开始提取您想要的数据了。点击“提取”，系统会按照您提供的证券信息以及选定的数据点进行读取，并将输出的结果展示在页面中。您可以在页面中浏览取数结果，也可以通过“导出”功能，以Excel的格式导出查询的内容，保存到本地。</p>`,

        h1Title2: 'FMCC Climate Risk Analyze',
        articleText3: `<p>开始使用 <strong>FMCC气候风险分析模块</strong>，首先您需要上传您的投资组合。需要提供的证券信息可以在“上传投资组合“按钮的弹窗中下载模板查看。
        成功上传投资组合后，您就可以开始“计算”了,  计算的结果包括：</br>
        <strong>基础数据：</strong>根据您上传的投资组合提取和计算的各证券的碳数据和SASB行业分析结果；</br>
        <strong>气候相关风险评估：</strong>根据计算的结果进行气候相关风险的关联性和重大性分析。您可以通过“高级选项”功能设置您想要的关联性和重大性阈值，该表格会跟随您的设置自动计算结果并展示，方便您做研究判断；</br>
        <strong>投资组合碳足迹：</strong>从组合水平计算整体的碳足迹数据；</br>
        <strong>观察名单：</strong>基于您设置的影子碳成本/税息折旧及摊销前利润（EBITDA）的阈值生成的列表，以供您做后续的分析。</br>
        如果您需要将结果保存到本地，通过“下载报告”即可将页面的内容以excel的形式下载到本地。</br>
        </p>`,

        h1Title3: '联系支持',
        articleText4: `<p>如您是第一次接触我们的平台，并想针对我们提供的产品和服务作进一步了解及问询，欢迎随时联系我们，我们将随时为您解答。</br>
        客户服务邮箱：clientsupport@governance-solutions.com</p>`,
        articleText5: `<p>鼎力可持续数字科技（深圳）有限公司</p>`,
    },
     // FMCC服务模块介绍
    methodIntroduc:{
       articleText1: `<p>2021年8月21日，香港证监会 (SFC) 发布《基金经理对气候相关风险的管理和披露》，并将透过修订《基金经理操守准则》(FMCC)，规定基金经理在投资及风险管理流程中考虑气候相关风险，同时履行披露义务。相关规定最早将于2022年8月20日后生效。高质量碳相关数据在FMCC有关气候相关风险关联性和重大性评估中，对气候相关风险评估结果至关重要，这一结果将对后续风险及投资管理产生影响。数据可得性是有效管理气候相关风险并进行披露的关键。</p></br>
       <p>该模块可帮助基金经理满足FMCC对于气候相关风险的合规要求，基于提供的投资组合持仓信息，量化评估气候相关风险的关联性及重大性，辅助投资决策。同时，该模块可进一步支持FMCC中的气候情境分析、碳足迹测算等需求，按照FMCC所要求的计算方法对投资组合层面的碳足迹展开测算，用于内部留存和对外披露。我们还将结合风险测算的结果为您提供watchlist用于事前风险预警，有效管理气候相关风险。</p></br>
       <p>该模块底层数据由鼎力碳策™数据库提供。基于前瞻视角，鼎力与生态环境部国家应对气候变化战略研究和国际合作中心（NCSC）合作开发的鼎力碳策™是鼎力打造出的投资组合碳核算基础数据库和测算工具。该工具依据业内权威的碳会计金融合作伙伴关系的《金融业适用的全球温室气体的核算及报告标准》(Global GHG Accounting & Reporting Standards of the Partnership for Carbon Accounting Financials, PCAF Standards) 方法学进行构建（SFC FMCC所推荐得碳足迹测算方法学）。我们对基金经理所管理的投资组合中的标的进行深度剖析，基于其所处行业特性，运营所在地、相关财务指标等多维度信息综合判断所持标的物理风险和转型风险的实质性情况。</p></br>
       <p>充分参考FMCC对于评估气候相关风险重大性程度时提到的国际组织所给出的建议，并结合永续会计准则委员会 (Sustainability Accounting Standards Board, SASB）、气候相关财务信息披露工作组 (Task Force on Climate-Related Financial Disclosures, TCFD)等给出的指引和方法，鼎力形成气候相关风险测算的方法可帮助您满足合规要求的同时助力更有效的气候相关风险管理。</p>`,
       
       articleText2: `<ul style='list-style-type: disc;'>
       <li>气候相关风险关联性分析</li>
       <li>气候相关风险重大性分析</li>
       <li>投资组合碳足迹测算</li>
       <li>Watchlist 事前气候相关风险预警</li></ul>`,
   
       h1Title1: '方法学介绍',
       h2Title1: '关联性分析',
       articleText3: `<p>遵循SFC FMCC推荐的国际方法学，参考SASB方法学在行业维度上对物理风险及转型风险敞口的评估和逻辑，结合组成测算基金的不同标的在各个行业的分布情况，通过投资组合中不同标的所占净值比例量化每只基金分别对物理风险和转型风险的暴露程度。</p></br>
       <p>在此基础上，基金经理可根据自身风险偏好设定临界值作为气候相关风险关联性的判断标准，低于临界值判定为不关联，高于临界值判定为关联。</p>`,
   
       h2Title2: '重大性分析Option1',
       articleText4: `<p>遵循SFC FMCC推荐的国际方法学，参考SASB方法学在行业维度上对物理风险及转型风险敞口的评估和逻辑，结合组成测算基金的不同标的在各个行业的分布情况，通过投资组合中不同标的所占净值比例量化每只基金分别对物理风险和转型风险的暴露程度。</p></br>
       <p>在此基础上，基金经理可根据自身风险偏好设定临界值作为气候相关风险重大性的判断标准，低于临界值判定为不重大，高于临界值判定为重大。</p>`,
        
       h2Title3: '判断准则：',
       articleText5: `<p>物理风险分数> 50%--> 物理风险判定为具有重大性</p>
       <p>转型风险分数> 50%--> 转型风险判定为具有重大性</p>`,

       h2Title4: '结合影子碳价的转型风险重大性分析 Option2',
       articleText6: `<p>遵循SFC FMCC推荐的国际方法学，参考SASB方法学在行业维度上对物理风险敞口的评估和逻辑，结合组成测算基金的不同标的在各个行业的分布情况，通过投资组合中不同标的所占净值比例量化每只基金分别对物理风险的暴露程度。</p></br>
       <p>针对转型风险，考虑到目前市场上数据可得性及更高数据颗粒度情况，我们可以从另一个视角量化评估转型风险重大性。</p></br>
       <p>将影子碳成本与基金中每个标的的碳排放情况纳入评估中。利用碳价对EBITDA产生的影响（Shadow carbon cost/EBITDA）作为表征转型风险的一种方式，设定该影响比例的临界值（当前设定为5%），以用于识别并筛选出对于碳价相对敏感的标的，对经过计算后占比超过临界值的标的所对应净值占比进行求和。针对求和后得到的占比，进一步设定转型风险重大性判断的临界值。</p></br>
       <p>若通过该测算得到对于碳价影响相对敏感的标的占被评估基金的比例超过某一临界值（如10%），则判定该基金面临重大转型风险；否则，则无重大性转型风险。</p>`,
       articleText7: `<p>其中，经评估后影子碳成本对EBITDA影响超过5%的标的对应的资产净值总和，即当Shadow carbon cost/EBITDA>0.05时，对相应资产的净值比例进行加总。</p>`,

    //    h2Title5: '判断准则：',
       articleText8: `<p>物理风险分数> 50%--> 物理风险判定为具有重大性</p>
       <p>转型风险分数> 10%--> 转型风险判定为具有重大性</p>`,
    }
    
}
