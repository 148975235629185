<template>
  <div>
    <!-- 多语言切换按钮-适用于登录页面 -->
    <el-dropdown
      class="set-lang"
      @click.native="handleDropdown"
      @command="handleSetLang"
    >
      <el-button type="primary" ref="btn1">
        {{ $store.getters.language === "zh" ? "中文" : "English" }}

        <!-- 小图标 -->
        <div class="el-arrow-dl">
          <svg-icon slot="prefix" icon-class="arrow-purple" />
        </div>
      </el-button>
      <el-dropdown-menu
        slot="dropdown"
        class="lang-dropdown"
        :append-to-body="false"
        ref="downdiv"
        v-show="show"
      >
        <el-dropdown-item command="en">English</el-dropdown-item>
        <el-dropdown-item command="zh">中文</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div ref="newSpace"></div>
  </div>
</template>
<script>
export default {
  name: "LangSelect",
  data() {
    return {
      urlPath: window.location.href,
      show: true,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  mounted() {
    // this.$refs.newSpace.appendChild(this.$refs.downdiv.popperElm);
  },
  methods: {
    handleSetLang(lang) {
      this.$i18n.locale = lang;
      this.urlPath = window.location.pathname;
      this.$store.dispatch("app/setLanguage", lang);

      // 如果url包含语言,就刷新页面
      if (this.urlPath.indexOf("zh") > -1 || this.urlPath.indexOf("en") > -1) {
        // 如果url包含语言，但不是当前语言
        if (this.urlPath.indexOf(lang) == -1) {
          let pathnameNew = this.sliceURL(this.urlPath, lang).join("/");
          let urlNew =
            window.location.protocol +
            "//" +
            window.location.host +
            "" +
            pathnameNew;

          window.location.replace(urlNew); //刷新页面
        }
      }
      this.$message({
        message: this.$t("tips.langTip"),
        type: "success",
      });
    },

    sliceURL(url, lang) {
      let arr = url.split("/");
      if (!arr.includes(lang)) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === "zh" || arr[i] === "en") {
            arr[i] = lang;
          }
        }
      }
      return arr;
    },

    handleDropdown() {
      this.show = true; //显示下拉框
    },
  },
};
</script>
<style lang="scss" scoped>
// 隐藏小三角
::v-deep .popper__arrow {
  display: none !important;
}
.set-lang {
  // 按钮
  ::v-deep .el-button--primary {
    background-color: transparent;
    // border: 2px solid #384fbe;
    border: 1px solid transparent;
    border-radius: 6px;
    color: #384fbe;
    height: 30px;
    width: 82px;
    padding: 5px 10px;
  }
  // 按钮
  .el-button--primary:hover, .el-button--primary:focus{
    border: 1px solid #384fbe;
  }
  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    width: 100%;
    background-color: #dfebf9;
    color: #14487b !important;
  }
  ::v-deep .el-dropdown-menu--medium {
    padding: 0px;
  }

  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .el-dropdown-menu__item {
    color: #01579b;
  }
  // 更换的小图标
  .el-arrow-dl {
    margin-left: 3px;
    display: inline-block;
    .svg-icon {
      width: 0.7em;
    }
  }
}
.lang-dropdown {
  display: block;
  width: 82px !important;
  top: 20px !important;
  left: 0px !important;
  border-radius: 6px;
}
</style>