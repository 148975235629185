<template>
  <div class="tabs_container">
    <svg-icon v-show="activeName === 'third'" class="tip" icon-class="iconfont-wenhao" @click="dialogVisible = true"/>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs">
      <el-tab-pane :label="$t('tabs.tab1')" name="first">
        <ProcessedData ref="processed" :baseFormData="baseFormData" :id="id" :note="note"></ProcessedData>
      </el-tab-pane>
      <el-tab-pane :label="$t('tabs.tab2')" name="second">
        <RiskAssessment ref="risk" :baseFormData="baseFormData" :id="id"></RiskAssessment>
      </el-tab-pane>
      <el-tab-pane :label="$t('tabs.tab3')" name="third">
        <FootprintTab ref="footprint" :baseFormData="baseFormData" :id="id"></FootprintTab>
      </el-tab-pane>
      <el-tab-pane :label="$t('tabs.tab4')" name="four">
        <WatchList ref="watchlist" :baseFormData="baseFormData" :id="id"></WatchList>
      </el-tab-pane>
    </el-tabs>
    <!--    第三个tab提示弹窗-->
    <el-dialog
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <BaseTip></BaseTip>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.stop="dialogVisible = false">{{ $t('FMCCTop.confirmBtn') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ProcessedData from './processedData'
import FootprintTab from './footprintTab'
import WatchList from './watchList'
import RiskAssessment from './riskAssessment'
import BaseTip from './baseTip'
import {
  getList,
  getCalculateResultList,
  getWatchList,
  calculate,
  portfolioCarbonFootprint,
  getCoverage
} from '@/api/data-platform/FMCC'

export default {
  props: ['baseFormData', 'id', 'note'],
  name: 'tabs',
  components: {
    ProcessedData,
    FootprintTab,
    WatchList,
    RiskAssessment,
    BaseTip
  },
  data() {
    return {
      activeName: 'first',
      dialogVisible: false
    }
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false
    },
    getCalculateResultList(data) {
      this.$refs.processed.getCalculate(data)
    },
    // 切换tab请求数据
    handleClick(tab, event) {
      switch (tab.index) {
        case '0':
          this.getCalculateResultList()
          break
        case '1':
          this.$refs.risk.getRisk()
          break
        case '2':
          this.$refs.footprint.getFootprint()
          break
        case '3':
          this.$refs.watchlist.getWatchList()
          break
      }
    },
    reload() {
      this.activeName = 'first'
    }
  }
}
</script>

<style scoped lang="scss">
@mixin fontStyle {
  font-family: 'PingFang SC';
  font-weight: 900;
}

.tabs_container {
  position: relative;
  background: #f3f4f7;
  border-radius: 6px;

  ::v-deep .el-tabs__header {
    margin: 0;
  }

  ::v-deep .el-tabs__content {
    background-color: #FFFFFF;
    padding-top: 34px;
    margin-bottom: 30px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  ::v-deep .el-tabs__item {
    width: 200px;
    height: 40px;
    padding: 0;
    margin-right: 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;
    background: #DFEBF9;
    color: #01579B;
    font-size: 16px;
    line-height: 40px;
    @include fontStyle;

    &:nth-child(4) {
      //font-size: 14px;
      margin-bottom: 1px;
      width: 240px;
    }

    &.is-active {
      background: #FFFFFF;
    }
  }

  ::v-deep .el-tabs__nav-wrap::after {
    position: static;
  }

  ::v-deep .el-tabs__active-bar {
    width: 0 !important;
    height: 0 !important;
  }

  .tip {
    position: absolute;
    right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 999;
  }
}
</style>
