<template>
  <p>welcome home</p>
</template>
<script>
let arr = [
  "sfdr:portfolio:upload",
  "sfdr:portfolio:drilldown",
  "sfdr:portfolio:admin",
];

export default {
  created() {
    this.redirect();
  },
//   computed: {
//     permissionList() {
//       return this.$store.getters.permissions;
//     },
//   },
  methods: {
    redirect() {
      let pList = this.$store.getters.permissions;
      this.$nextTick(() => {
        this.$router.replace({
          path: "/data-platform/dl-databrowser",
        });
      });
      // if (
      //   pList.includes(arr[0]) ||
      //   pList.includes(arr[1]) ||
      //   pList.includes(arr[2])
      // ) {
      //   this.$nextTick(() => {
      //     this.$router.replace({
      //       path: "/data-platform/sfdr",
      //     });
      //   });
      // } else {
      //   this.$nextTick(() => {
      //     this.$router.replace({
      //       path: "/data-platform/carbon",
      //     });
      //   });
      // }
    },
  },
};
</script>