<template>
    <div class="sfdr" v-loading="loading" v-if="render">
        <!-- 顶部面包屑菜单 -->
        <el-breadcrumb class="menu" separator="/">
            <!-- <el-breadcrumb-item
                :to="{ path: '/data-platform/carbon/dataBrowser' }"
                >{{ $t("sfdr.nav.home") }}</el-breadcrumb-item
            > -->
            <el-breadcrumb-item>{{ $t("sfdr.nav.home") }}</el-breadcrumb-item>
            <el-breadcrumb-item @click="reset">{{
                $t("sfdr.nav.sfdr")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="step === 'upload' && !showWelcome">{{
                $t("sfdr.nav.upload")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="step === 'mandatory'">{{
                $t("sfdr.nav.mandatory")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="step === 'additional'">{{
                $t("sfdr.nav.portfolioCalculation")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="step === 'additional'">{{
                $t("sfdr.nav.additional")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="step === 'review'">{{
                $t("sfdr.nav.review")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="showMetricCrumb">{{
                metricName
            }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button class="help-button" @click="openNotification">
            Instructions
        </el-button>
        <!-- 渲染的子页面 -->
        <UploadPage
            v-show="step === 'upload'"
            @nextStep="nextStep"
            @updateOperationId="updateOperationId"
        ></UploadPage>
        <MandatoryPaiPage
            v-if="step === 'mandatory'"
            :impactList="mandatoryImpactList"
            :paiGroup="mandatoryPaiGroup"
            :operationId="operationId"
            :hasDrillDownPermission="hasDrillDownPermission"
            @updateColumns="handleColumns"
            @updateData="handleMainData"
            @clickDrillDown="clickDrillDownHandler"
            @nextStep="nextStep"
            @backStep="backStep"
        ></MandatoryPaiPage>
        <AdditionalPaiPage
            v-if="step === 'additional'"
            :operationId="operationId"
            :paiGroup="additionalPaiGroup"
            :impactList="additionalImpactList"
            :hasDrillDownPermission="hasDrillDownPermission"
            @updateData="handleAdditionalData"
            @clickDrillDown="clickDrillDownHandler"
            @nextStep="nextStep"
            @backStep="backStep"
        ></AdditionalPaiPage>
        <ReviewPage
            v-if="step === 'review'"
            :operationId="operationId"
            :mandatoryData="mandatoryData"
            :additionalData="additionalData"
            :columns="columns"
            @reset="reset"
        ></ReviewPage>
        <transition name="top">
            <Help v-show="showInstructions" @showHelp="openNotification"></Help>
        </transition>
        <transition name="fade">
            <Welcome
                v-if="showWelcome"
                @showWelcome="enterHome"
                :notificationData="notificationData"
            ></Welcome>
        </transition>
        <el-dialog title="Reminder" :visible.sync="dialogVisible">
            <span
                >If you leave the SFDR page, the data will not be retained. Are
                you sure you want to leave?</span
            >
            <span slot="footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="next()">Confirm</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Welcome from './components/common/welcome/Welcome.vue'
import Help from './components/common/help/SFDRHelp.vue'
import UploadPage from './pages/UploadPage.vue'
import MandatoryPaiPage from './pages/MandatoryPaiPage.vue'
import AdditionalPaiPage from './pages/AdditionalPaiPage.vue'
import ReviewPage from './pages/ReviewPage.vue'
import {
  getAllAdverseSustainabilityImpacts,
  getPAIList,
  deletePortfolioList,
  deletePortfolioDetail,
  requestSystemNotification,
  requestDrillDownPermission
} from '@/api/data-platform/SFDR.js'

export default {
  components: {
    UploadPage,
    MandatoryPaiPage,
    AdditionalPaiPage,
    ReviewPage,
    Help,
    Welcome
  },
  data() {
    return {
      // 每次上传一个文件，生成一个id，类似于文件操作的会话ID
      operationId: null,
      // operationId: '1a2fa396-a948-4a16-8d20-4da79f8b9f94',

      step: 'upload',
      // step: 'mandatory',
      stepList: ['upload', 'mandatory', 'additional', 'review'],
      mandatoryImpactList: [],
      additionalImpactList: [],
      mandatoryPaiGroup: {},
      additionalPaiGroup: {},
      mandatoryData: [],
      additionalData: [],
      columns: [],
      loading: false,
      render: true,
      showMetricCrumb: false,
      metricName: '',
      showInstructions: false,
      showWelcome: true,
      notificationData: {},
      hasDrillDownPermission: false,
      dialogVisible: false
    }
  },

  async created() {
    this.loading = true
    const notification = await requestSystemNotification()
    this.notificationData = notification.data || {}

    if (this.notificationData.checkStatus === 1) {
      this.loading = false
      return
    }

    const data = await this.requestInitData()

    this.initImpactList(data.impactGroup)

    this.paiList = data.paiList
    this.initPaiGroup(data.paiList)

    this.hasDrillDownPermission = data.drillDownPermission

    this.loading = false
    // this.openNotification()
  },
  // mounted() {
  //   window.addEventListener('resize', this.reload)
  // },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.reload)
  // },
  beforeRouteLeave(to, from, next) {
    if (this.operationId) {
      this.next = next
      this.dialogVisible = true
    } else {
      next()
    }
  },
  methods: {
    clickDrillDownHandler(metricId) {
      if (!metricId) {
        this.showMetricCrumb = false
        return
      }
      const pai = this.paiList.find((item) => item.metricId === metricId)
      // this.metricName = `Detailed Data - ${pai.adverseImpactOnSustainabilityFactors} - ${pai.metric}`
      this.metricName = `Detailed Data - ${pai.simpleMetric}`

      this.showMetricCrumb = true
    },
    reload() {
      // 重新渲染
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
    },
    async requestInitData() {
      const [impactResp, paiResp, drillDownPermissionResp] = await Promise.all([
        getAllAdverseSustainabilityImpacts(),
        getPAIList(),
        requestDrillDownPermission()
      ])
      return {
        impactGroup: impactResp?.data,
        paiList: paiResp?.data,
        drillDownPermission: drillDownPermissionResp.data
      }
    },
    initImpactList(impactGroup) {
      this.mandatoryImpactList = impactGroup?.mandatoryPaiList
      this.additionalImpactList = impactGroup?.additionalPaiList
    },
    initPaiGroup(paiList) {
      const mandatoryPaiGroup = {}
      const additionalPaiGroup = {}

      this.mandatoryImpactList?.forEach((impactName) => {
        mandatoryPaiGroup[impactName] = []
      })
      this.additionalImpactList?.forEach((impactName) => {
        additionalPaiGroup[impactName] = []
      })

      const mandatoryPaiList = paiList?.filter((item) => item.required)
      const additionalPaiList = paiList?.filter((item) => !item.required)

      mandatoryPaiList?.forEach((item) => {
        const impactName = item.adverseSustainabilityImpact
        mandatoryPaiGroup[impactName].push(item)
      })
      additionalPaiList?.forEach((item) => {
        const impactName = item.adverseSustainabilityImpact
        additionalPaiGroup[impactName].push(item)
      })

      mandatoryPaiGroup.All = mandatoryPaiList
      additionalPaiGroup.All = additionalPaiList

      this.mandatoryPaiGroup = mandatoryPaiGroup
      this.additionalPaiGroup = additionalPaiGroup
    },
    nextStep() {
      const stepIndex = this.stepList.indexOf(this.step)
      if (stepIndex < 0 || stepIndex === this.stepList - 1) {
        return
      }
      this.step = this.stepList[stepIndex + 1]
    },
    backStep() {
      const stepIndex = this.stepList.indexOf(this.step)
      if (stepIndex < 0 || stepIndex === 0) {
        return
      }
      this.step = this.stepList[stepIndex - 1]
    },
    reset() {
      this.step = 'upload'
      this.reload()
    },
    updateOperationId(operationId) {
      this.clearBackendData(this.operationId)
      this.operationId = operationId
    },
    handleMainData(data) {
      if (data?.filter((o) => !o.isTitle).length < 17) {
        return
      }
      this.mandatoryData = data
    },
    handleAdditionalData(data) {
      const copiedData = [].concat(data)
      this.handleTitleRows(copiedData)
      this.additionalData = copiedData
    },
    handleTitleRows(result) {
      // if (this.currentDisplayType !== 'full') {
      //   return
      // }
      // 給两个大标题添加前綴
      for (let i = 0; i < result.length; i++) {
        // parOrder为13的PAI名字为 Social and employee matters
        if (result[i].isTitle && result[i].isClimate) {
          // result[i].metric =
          //   'ADDITIONAL CLIMATE AND OTHER ENVIRONMENT-RELATED INDICATORS'
          // result[i].simpleMetric =
          //   'ADDITIONAL CLIMATE AND OTHER ENVIRONMENT-RELATED INDICATORS'
          result.splice(i, 1, {
            metric:
              'ADDITIONAL CLIMATE AND OTHER ENVIRONMENT-RELATED INDICATORS',
            simpleMetric:
              'ADDITIONAL CLIMATE AND OTHER ENVIRONMENT-RELATED INDICATORS',
            isTitle: true,
            isClimate: true
          })
          break
        }
      }
      for (let i = 0; i < result.length; i++) {
        // parOrder为13的PAI名字为 Social and employee matters
        if (result[i].isTitle && result[i].isSocial) {
          // result[i].metric =
          //   'ADDITIONAL INDICATORS FOR SOCIAL AND EMPLOYEE, RESPECT FOR HUMAN RIGHTS, ANTI-CORRUPTION AND ANTI-BRIBERY MATTERS'
          // result[i].simpleMetric =
          //   'ADDITIONAL INDICATORS FOR SOCIAL AND EMPLOYEE, RESPECT FOR HUMAN RIGHTS, ANTI-CORRUPTION AND ANTI-BRIBERY MATTERS'

          result.splice(i, 1, {
            metric:
              'ADDITIONAL INDICATORS FOR SOCIAL AND EMPLOYEE, RESPECT FOR HUMAN RIGHTS, ANTI-CORRUPTION AND ANTI-BRIBERY MATTERS',
            simpleMetric:
              'ADDITIONAL INDICATORS FOR SOCIAL AND EMPLOYEE, RESPECT FOR HUMAN RIGHTS, ANTI-CORRUPTION AND ANTI-BRIBERY MATTERS',
            isTitle: true,
            isSocial: true
          })

          break
        }
      }
    },
    handleColumns(cols) {
      this.columns = cols
    },
    clearBackendData(operationId) {
      if (!operationId) {
        return
      }
      deletePortfolioList(operationId)
      deletePortfolioDetail(operationId)
    },
    openNotification(isShow) {
      this.showInstructions = isShow
    },
    enterHome(isShow) {
      this.showWelcome = isShow
    }
  }
}
</script>

<style lang="scss" scoped>
.sfdr {
    padding: 20px 16px 0;
    height: calc(100%);
    display: flex;
    flex-direction: column;
    position: relative;

    font-family: PingFang SC-Regular;
    font-size: 13px !important;

    .menu {
        margin-bottom: 20px;
        font-size: 13px !important;
    }

    .page-wrapper {
        height: calc(100%);

        flex: 1;
    }

    ::v-deep .el-button--primary {
        background: #5998f0;
    }

    ::v-deep .el-button--primary:hover {
        background: #5998f0;
        opacity: 0.6;
        border-color: rgba(#5998f0, 0.6);
    }

    ::v-deep .el-button--default:hover {
        background: #fafafa;
    }

    ::v-deep .help-button {
        position: absolute;
        top: 15px;
        right: 16px;

        width: 102px;
        height: 30px;
        padding: 5px 14px;
        // border-radius: 4px 4px 4px 4px;
        // opacity: 1;

        // ::v-deep i {
        //     font-size: 16px;
        //     position: relative;
        //     top: 1px;
        // }

        ::v-deep span {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
        }
    }
    ::v-deep .help-button:hover {
        background: #5998f0;
        color: #fff;
    }

    .fade-leave {
        opacity: 1;
    }
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        transition: opacity 0.5s;
    }
    .fade-enter-to {
        opacity: 1;
    }

    .top-enter-active,
    .top-leave-active {
        transition: opacity 0.2s;
        transition: left 0.2s;
    }
    .top-leave {
        opacity: 1;
        left: 16px;
    }

    .top-enter,
    .top-leave-to {
        opacity: 0;
        left: 90%;
    }

    ::v-deep .el-dialog:not(.is-fullscreen) {
        margin-top: 15vh !important;
    }
    ::v-deep .el-dialog__body {
        word-break: break-word;
    }
    ::v-deep .sfdr-portfolio-table-total-cell {
        background: #f5f5f5;
    }
}
</style>
<style lang="scss">
.sfdr-notification-download {
    top: 75px !important;
    font-family: PingFang SC-Regular, Arial, sans-serif;
    word-spacing: 0;
    line-height: 20px;
    font-size: 14px;
}

.el-table__body-wrapper {
    &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
    }

    // &::-webkit-scrollbar-track {
    //     // 轨道颜色
    //     background-color: #fff;
    // }
    // &::-webkit-scrollbar-thumb {
    //     // 滚动块颜色
    //     background-color: #e6e9ed;
    //     // border-radius: 5px;
    // }
    // &::-webkit-scrollbar-thumb:hover {
    //     // hover高亮
    //     background-color: #d5d8db;
    // }
}

// 解决表格固定列问题
.el-table__fixed,
.el-table__fixed-right {
    height: calc(100% - 7px) !important;
    box-shadow: -5px -2px 10px rgba(0, 0, 0, 0.12) !important;

    .el-table__fixed-body-wrapper {
        // height: calc(100% - 36px) !important;
    }
}

// 当表格没有滚动条时
.el-table__body-wrapper.is-scrolling-none ~ .el-table__fixed-right {
    height: 100% !important;
    bottom: 0 !important;
    box-shadow: none !important;
}

// 当表格有纵向滚动条时
.el-table--scrollable-y .el-table__fixed-right {
    right: 7px !important;
}

// 当表格只有横向滚动条，没有纵向滚动条时
.el-table--scrollable-x:not(.el-table--scrollable-y) .el-table__fixed-right {
    right: 0 !important;
}

.el-table th .cell {
    line-height: 16px !important;
    word-break: break-word;
}

.el-tooltip {
    // margin-left: 3px;
    // color: rgba(#606266, 0.5);
    color: #a5a7aa;
    font-size: 14px;
}
.el-tooltip__popper {
    max-width: 580px;
    background: #606266 !important;
    font-size: 13px;
    font-weight: 400;
    color: #ebf3ff;
}
</style>
