<template>
  <div class="template-category">
    <div style="margin-bottom: 20px">
      <div>
        <el-button type="primary" size="small" @click="handleAdd">新增</el-button>
      </div>
    </div>
    <div class="table-area common-table-style">
      <el-table
        :data="tableData"
        height="100%"
        row-key="templateId"
        :default-expand-all="true"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        stripe>
        <el-table-column label="模板名称" prop="templateName"></el-table-column>
        <el-table-column label="绑定API名称" prop="apiName"></el-table-column>
        <el-table-column label="排序" prop="orderNum"></el-table-column>
        <el-table-column label="创建时间" prop="createTime">
        <template slot-scope="scope">
            <span>{{ parseTime(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" class-name="small-padding fixed-width">
          <template slot-scope="scope" v-if="scope.row.templateName!=='未分组'">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
            >修改</el-button>
            <el-button
              v-if="!scope.row.apiName"
              size="mini"
              type="text"
              icon="el-icon-plus"
              @click="handleAdd(scope.row)"
            >新增</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" :close-on-click-modal="false" width="680px" append-to-body>
      <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
        <el-form-item label="上级分类" prop="parentId">
          <treeselect
            v-model="formData.parentId"
            :options="menuOptions"
            :normalizer="normalizer"
            :show-count="true"
            placeholder="选择上级分类"
          />
        </el-form-item>
        <el-form-item label="模板名称：" prop="templateName">
          <el-input v-model="formData.templateName" maxlength="200" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="显示顺序：" prop="orderNum">
          <el-input-number v-model="formData.orderNum" placeholder="请输入"></el-input-number>
        </el-form-item>
        <el-form-item v-if="!formData.children || formData.children.length === 0" label="绑定API：" prop="apiName">
          <el-select v-model="formData.apiName" filterable clearable placeholder="请选择">
            <el-option v-for="item in apiList" :key="item.apiName" :value="item.apiName" :label="item.apiName"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { listTemplateTree, getCanBindApiList, updateApiTemplate, deleteTemplate } from "@/api/data-platform/template-category.js";
import treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    treeselect
  },
  data () {
    return {
      tableData: [],
      apiList: [],
      formData: {
        parentId: '',
        templateName: '',
        orderNum: null,
        apiName: '',
      },
      rules: {
        templateName: [{ required: true, message: '模板名称不能为空', trigger: 'blur'}],
        orderNum: [{ required: true, message: '显示顺序不能为空', trigger: 'blur'}],
      },
      title: '新增模板',
      dialogVisible: false,
      menuOptions: []
    }
  },
  created () {
    this.getTemplateTreeData();
    this.getCanBindApiList();
  },
  methods: {
    // 获取树形表格数据
    getTemplateTreeData () {
      listTemplateTree().then((res) => {
        this.tableData = res.data || [];
        this.menuOptions = [{ templateId: 0, templateName: '主类目', children: this.tableData }];
      })
    },
    // 获取可绑定api列表
    getCanBindApiList () {
      getCanBindApiList().then((res) => {
        this.apiList = res.data || [];
      })
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (!node.children || !node.children.length) {
        delete node.children;
      }
      return {
        id: node.templateId,
        label: node.templateName,
        children: node.children,
        isDisabled: node.apiName ? true : false
      };
    },
    handleUpdate (row) {
      this.title = '修改模板';
      this.formData = row;
      this.dialogVisible = true;
      this.$refs.form && this.$refs.form.resetFields();
    },
    handleAdd (row = {}) {
      this.title = '新增模板';
      this.formData = {
        parentId: row.templateId || 0,
        templateName: '',
        orderNum: null,
        apiName: '',
      },
      this.dialogVisible = true;
      this.$refs.form && this.$refs.form.resetFields();
    },
    handleDelete (row) {
      this.$modal.confirm('是否确认删除名称为"' + row.templateName + '"的模板？').then(function() {
        const params = {
          templateIds: row.templateId
        }
        return deleteTemplate(params);
      }).then(() => {
        this.getTemplateTreeData();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    },
    // 确定
    submitForm () {
      this.$refs.form.validate((vaild) => {
        if (vaild) {
          const params = {
            ...this.formData
          }
          updateApiTemplate(params).then((res) => {
            if (res.code === 200) {
              if (params.templateId) {
                this.$modal.msgSuccess('修改成功');
              } else {
                this.$modal.msgSuccess('新增成功');
              }
              this.dialogVisible = false;
              this.getTemplateTreeData();
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.template-category {
  padding: 20px;
  background-color: #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  .table-area {
    flex: 1;
    height: 0;
  }
}
::v-deep .vue-treeselect--has-value .vue-treeselect__input {
  vertical-align: middle;
}
</style>
