<template>
  <!-- 修改/添加自选清单模态框 -->
  <el-dialog
    :title="titleSelfSelect"
    :visible.sync="visible"
    :before-close="handleClose"
    class="dialog_DB"
    width="460px"
  >
    <el-form
      :model="form"
      :rules="selfRules"
      label-width="170px"
      ref="form"
      class="dialog_DB_form"
    >
      <el-form-item :label="$t('selfSelect.formLabel')" prop="name">
        <el-input
          v-model="sName"
          @blur="form.name = $event.target.value.trim()"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">{{ $t("elbtn.cancel") }}</el-button>
      <el-button type="primary" @click="submit">{{
        $t("elbtn.apply")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "SelfSelectDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    titleSelfSelect: {
      type: String,
      default: "",
    },
    selfSelName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sName: this.selfSelName,
      form: {
        name: "", //自选清单名称
        id: undefined, //清单id
      },
      selfRules: {
        name: [
          {
            required: true,
            message: vm.$t("selfSelect.nameTip1"),
            trigger: "blur",
          },
          {
            pattern: /^.{1,20}$/,
            message: vm.$t("selfSelect.nameTip2"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    selfSelName() {
      this.sName = this.selfSelName;
    },
  },

  methods: {
    //确定按钮
    submit() {
      this.form.name = this.sName;
      this.$emit("submit", this.form);
    },
    // 取消按钮
    handleClose() {
      this.$emit("handleClose");
    },
    //校验表单
    validate() {
      let flag = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          flag = true;
        } else {
          flag = false;
        }
      });
      return flag;
    },
    // 表单重置
    reset() {
      this.form = {
        name: "", //自选清单名称
        id: undefined, //清单id
      };
      this.resetForm("form");
    },
  },
};
</script>