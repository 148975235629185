<template>
  <!-- 顶部二级菜单 -->
  <el-menu
    class="sub-el-menu"
    :default-active="activeSubMenu"
    mode="horizontal"
    @select="handleSubSelect"
    active-text-color="#ffd04b"
  >
    <template v-for="(item, index) in topSubMenus">
      <el-menu-item :index="item.path" :key="index">
        <!-- {{ item.path }} -->
        {{ item.meta.title }}
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script>
import { constantRoutes } from "@/router";
// 隐藏侧边栏路由
const hideList = ["/index", "/user/profile"];
export default {
  // 接收祖先组件传来的值
  // inject: ["parentPath"],
  data() {
    return {
      // 顶部栏初始数
      visibleNumber: 4,
      // 当前激活菜单的 index
      currentIndex: undefined,
      isShowSub: true, //手否显示二级菜单
    };
  },
  computed: {
    // 所有的路由信息
    routers() {
      return this.$store.state.permission.sidebarRouters;
    },
    // 一级路由
    firstLevelRoute() {
      return this.$store.getters.currentRouter || [];
    },
    // 获取二级菜单的子菜单项
    topSubMenus() {
      let topSubMenus = [];
      let pathArr = this.firstLevelRoute.split("/") || [];
      this.routers.map((menu) => {
        if (menu.hidden !== true) {
          let parent = menu.path.substring(menu.path.lastIndexOf("/") + 1);
          //判断当前路由是否是父路由
          if (pathArr.includes(parent)) {
            if (menu.children && menu.children.length > 0) {
              menu.children.forEach((item) => {
                topSubMenus.push(item);
              });
            }
          }
        }
      });
      return topSubMenus;
    },

    // 默认激活的菜单
    activeSubMenu() {
      const path = this.$route.path;
      let activePath = path;
      if (
        path !== undefined &&
        path.lastIndexOf("/") > 0 &&
        hideList.indexOf(path) === -1
      ) {
        const tmpPath = path.substring(1, path.length);
        if (tmpPath.substring(0, tmpPath.indexOf("/")) == "data-platform") {
          activePath = "/" + tmpPath.substring(0);
        } else {
          activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
        }
      } else if (!this.$route.children) {
        activePath = path;
      }

      this.activeRoutes(activePath);
      return activePath;
    },
    // 设置子路由
    childrenMenus() {
      var childrenMenus = [];
      this.routers.map((router) => {
        for (var item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === "/") {
              router.children[item].path = "/" + router.children[item].path;
            } else {
              if (!this.ishttp(router.children[item].path)) {
                router.children[item].path =
                  router.path + "/" + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    },
  },
  methods: {
    // 当前激活的路由
    activeRoutes(key) {
      var routes = [];
      const path = this.$route.path;
      // let pathArr = this.firstLevelRoute.split("/");
      let parent = path.substring(0, path.lastIndexOf("/"));

      if (this.childrenMenus && this.childrenMenus.length > 0) {
        this.childrenMenus.map((item) => {
          // console.log(item.parentPath, parent);
          // if (item.parentPath === parent) {
          //   routes.push(item);
          // }
        });
      }

      // if (routes.length > 0) {
      //   this.$store.commit("SET_SIDEBAR_SUB_ROUTERS", routes);
      // }
      // this.$emit("currentPathChildren", routes);
      // console.log(routes, "5555");
    },

    // 菜单选择事件
    handleSubSelect(key, keyPath) {
      this.currentIndex = key;
      const route = this.routers.find((item) => item.path === key);

      if (this.ishttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (!route || !route.children) {
        // 没有子路由路径内部打开
        this.$router.push({ path: key, query: { name: value } });
      } else {
        // 显示左侧联动菜单
        this.activeRoutes(key);
      }
    },
    ishttp(url) {
      return url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1;
    },
  },
};
</script>
<style lang="scss">
.sub-el-menu {
  background-color: #fff;
}
</style>