<template>
  <div class="nav-outer-box">
    <!-- 顶部header -->
    <div class="navbar">
      <!-- 左侧logo -->
      <div class="logo">
        <router-link to="/">
            <svg-icon icon-class="left-top-logo" />
        </router-link>
      </div>

      <div class="container">
        <!-- 顶部一级菜单 -->
        <top-nav id="topmenu-container" class="topmenu-container" />
      </div>

      <!-- 帮助支持 -->
      <!-- <div>
        <Help />
      </div> -->
      <!-- 中英文切换 -->
      <!-- <div class="lang-nav">
        <lang-select class="set-language" />
      </div> -->

      <!-- 右侧账户信息 -->
      <div class="right-menu">
        <el-dropdown
          class="avatar-container right-menu-item hover-effect"
          trigger="click"
        >
          <div class="avatar-wrapper">
            <svg-icon slot="prefix" icon-class="personal-center" />
          </div>
          <el-dropdown-menu
            slot="dropdown"
            class="dropdown-menu2"
            style="z-index: 99999 !important"
          >
            <!-- 个人中心 -->
            <router-link
              v-if="name === 'admin'"
              :to="{
                path: '/user/profile/' + language,
              }"
            >
              <!-- <router-link to="/user/profile"> -->
              <el-dropdown-item>
                {{ $t("settings.accountMenu1") }}</el-dropdown-item
              >
            </router-link>
            <!-- 退出登录 -->
            <el-dropdown-item divided @click.native="logout">
              <span>{{ $t("settings.accountMenu2") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 分割线 -->
    <el-divider class="nav-line"></el-divider>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopNav from "@/components/TopNavDL";
import Help from "@/components/Help";
export default {
  components: {
    TopNav,
    Help,
  },
  data() {
    return {
      // parentPath: "", //路由信息
    };
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "device", "name"]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val,
        });
      },
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav;
      },
    },
    language() {
      return this.$i18n.locale
        ? this.$i18n.locale
        : this.$store.getters.language;
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    // 退出登录
    async logout() {
      // 确定注销并退出系统吗
      this.$confirm(this.$t("tips.masg10"), this.$t("elbtn.tips"), {
        confirmButtonText: this.$t("elbtn.confirm"),
        cancelButtonText:  this.$t("elbtn.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("LogOut").then(() => {
            location.href = "/";
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-outer-box {
  top: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
  .container {
    margin: 0 auto;
    position: relative;
  }
}
.navbar {
  height: 50px;
  padding: 0 25px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #01579b;
  width: 100%;
  // 左侧logo
  .logo {
    // min-width: 200px;
    display: flex;
    align-items: center;
    a {
      margin-right: 20px;
      display: block;
      height: 50px;
      line-height: 60px;
      width: 100%;
     .svg-icon{
       height: 50px;
       width:116px;
     }
      // text-indent: -9999px;
      // background: url("~@/assets/images/top-logo@1.png") no-repeat center;
      // background-size: 100% 88%;
    }
  }
  .topmenu-container {
    margin-top: unset;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  // 右侧账户图标
  .right-menu {
    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      // padding: 0 8px;
      margin-left: 8px;
      height: 100%;
      font-size: 21px;
      color: #fff;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;
      }
    }

    .avatar-container {
      // margin-right: 10px;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
      }
    }
  }
}
// 只用于大屏幕
@media screen and (min-width: 1790px) {
  .navbar {
    padding: 0 20px;
  }
}
// 小于798
@media (max-width: 769px) {
  .navbar {
    .logo {
      min-width: 100px;
      a {
        background-size: 100% 80%;
      }
    }
  }
}
</style>
