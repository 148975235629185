var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "key-box" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "130px",
                "label-position": "center",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "APP KEY:" } },
                [
                  _c("el-input", {
                    staticClass: "app-input",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.form.appKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appKey", $$v)
                      },
                      expression: "form.appKey",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.form.appKey || "",
                          expression: "form.appKey || ''",
                          arg: "copy",
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopySuccess,
                          expression: "onCopySuccess",
                          arg: "success",
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:error",
                          value: _vm.onCopyError,
                          expression: "onCopyError",
                          arg: "error",
                        },
                      ],
                      staticClass: "btn",
                    },
                    [_vm._v(_vm._s(_vm.$t("apiRepository.copyBtn")))]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APP SECRUITY:" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "app-input",
                      attrs: {
                        readonly: "",
                        type: _vm.flag ? "text" : "password",
                        autocomplete: "new-password",
                      },
                      model: {
                        value: _vm.form.appSecurity,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "appSecurity", $$v)
                        },
                        expression: "form.appSecurity",
                      },
                    },
                    [
                      _c("i", {
                        class: [
                          _vm.flag ? "iconfont icon-eye" : "iconfont icon-eye1",
                        ],
                        staticStyle: {
                          "margin-top": "8px",
                          "font-size": "18px",
                        },
                        attrs: { slot: "suffix" },
                        on: {
                          click: function ($event) {
                            _vm.flag = !_vm.flag
                          },
                        },
                        slot: "suffix",
                      }),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.form.appSecurity || "",
                          expression: "form.appSecurity || ''",
                          arg: "copy",
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopySuccess,
                          expression: "onCopySuccess",
                          arg: "success",
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:error",
                          value: _vm.onCopyError,
                          expression: "onCopyError",
                          arg: "error",
                        },
                      ],
                      staticClass: "btn",
                    },
                    [_vm._v(_vm._s(_vm.$t("apiRepository.copyBtn")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }