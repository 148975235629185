var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "fileTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.rows,
            stripe: "",
            "header-row-class-name": _vm.tableHead,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "portfolioDate",
              label: _vm.$t("watchList.portfolioDate"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "fundId",
              label: _vm.$t("watchList.fundId"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "fundName",
              label: _vm.$t("watchList.fundName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "isin",
              label: _vm.$t("watchList.isin"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "ticker",
              label: _vm.$t("watchList.ticker"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "securityName",
              label: _vm.$t("watchList.securityName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "sasbIndustry",
              label: _vm.$t("watchList.sasbIndustry"),
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "text" }, [
        _c("p", [_vm._v(" " + _vm._s(_vm.$t("watchList.text1")) + " ")]),
      ]),
      _vm.renderData
        ? _c("el-pagination", {
            staticClass: "pagination",
            attrs: {
              background: "",
              layout: "prev, pager, next",
              total: _vm.renderData.total,
            },
            on: { "current-change": _vm.handleCurrentChange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }