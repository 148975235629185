<template>
<!-- 中文-指标说明 -->
  <div class="instructions-box">
    <el-table
      :data="tableData"
      :span-method="objectSpanMethod"
      :row-class-name="tableRowClassName"
      :cell-class-name="addItemClass"
      style="width: 100%"
    >
      <el-table-column
        prop="type"
        :label="$t('instructions.title1')"
        width="170"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        :label="$t('instructions.title2')"
        width="210"
      >
      </el-table-column>
      <el-table-column prop="desc" :label="$t('instructions.title3')">
      </el-table-column>
      <!-- 关闭按钮 -->
      <el-table-column width="20" align="left">
        <template slot="header">
          <div class="el-icon-ins" @click="close">
            <svg-icon slot="prefix" icon-class="instructions-close" />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { instructionsTableData } from "@/lang/dataBrowserZh.js";
let num = 0;
export default {
  name: "InstructionsEn",
  props: {},
  data() {
    return {
      tableData: instructionsTableData,
    };
  },
  created() {
    num = this.getSameRow(this.tableData, "span1");
  },
  methods: {
    // 隔行变色
    tableRowClassName({ row, rowIndex }) {
      // 偶数行且不包括第一列
      if (rowIndex % 2 !== 0 && !row.type) {
        return "warning-row";
      }
    },
    // 获取何以合并的行，返回数组
    getSameRow(data, key) {
      let arr = [];
      arr = data.filter((o) => o.code === key);
      const l = arr.length;
      return l;
    },

    // 合并行
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 第一列
      if (columnIndex === 0) {
        if (rowIndex === 0 && row.code === "span1") {
          return {
            rowspan: num, //合并num行
            colspan: 1,
          };
        } else if (rowIndex === num && row.code === "span2") {
          return {
            rowspan: this.getSameRow(this.tableData, "span2"),
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0, //删除行和列
            colspan: 0,
          };
        }
      }
    },

    // 改变表格某一列或某一行边框样式
    addItemClass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0 || columnIndex == 1) {
        if (rowIndex == num) {
          return "dashe_row dashed_column";
        } else {
          return "dashed_column";
        }
      }
      if (rowIndex == num) {
        return "dashe_row";
      }
    },
    // 隐藏指标说明
    close() {
      this.$emit("handleClose");
    },
  },
};
</script>
<style lang="scss" scoped>
.instructions-box {
  // 隔行变色
  ::v-deep .el-table .warning-row {
    background: #f7f8fb;
  }
  // 去掉表格的边框
  ::v-deep .el-table th.el-table__cell.is-leaf,
  ::v-deep .el-table td.el-table__cell {
    border: none;
  }
  // 添加滚动条
  ::v-deep .el-table .el-table__body-wrapper {
    height: calc(100vh - 260px);
    width: 100%;
    overflow: auto;
  }
  ::v-deep .el-table__body {
    height: 100%;
  }
  //个性化边框
  ::v-deep .el-table td.el-table__cell.dashed_column {
    border-right: 1px dashed #e7e7e8;
  }
  ::v-deep .el-table td.el-table__cell.dashe_row {
    border-top: 1px dashed #e7e7e8;
  }
  // 最后一列
  ::v-deep .el-table th:nth-last-child(2) {
    .cell {
      padding-left: 0;
      padding-right: 0;
    }
  }
  // 图标
  .el-icon-ins {
    .svg-icon {
      width: 0.8em;
    }
  }
}
</style>