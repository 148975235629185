import request from '@/utils/request'

// 根据api名称获取API Schema的字段结构
export function getApiSchemaByApiName(params) {
  return request({
    url: '/DingLiApi/DataBrowser/getApiSchemaByApiName',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 载入系统的SecCode模板列表
export function loadSecCodeTemplateList(params) {
  return request({
    url: '/DingLiApi/DataBrowser/loadSecCodeTemplateList',
    method: 'post',
    data: params
  })
}

// 载入自定义的SecCode模板列表
export function loadSecCodeCustomList(params) {
  return request({
    url: '/DingLiApi/DataBrowser/loadSecCodeCustomList',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 保存用户自定义的SecCode清单
export function saveSecCodeCustomListDef(params) {
  return request({
    url: '/DingLiApi/DataBrowser/saveSecCodeCustomListDef',
    method: 'post',
    data: params
  })
}

// 保存系统的SecCode清单
export function saveSecCodeTemplateListDef(params) {
  return request({
    url: '/DingLiApi/DataBrowser/saveSecCodeTemplateListDef',
    method: 'post',
    data: params
  })
}

// 按Id载入SecCode模板实例
export function loadSecCodeListById(params) {
  return request({
    url: '/DingLiApi/DataBrowser/loadSecCodeListById',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 删除
export function deleteSecCodeListDef(id) {
  return request({
    url: `/DingLiApi/DataBrowser/deleteSecCodeListDef/${id}`,
    method: 'delete',
  })
}

// 根据secCode搜索
export function getCompanySecurityList(params) {
  return request({
    url: '/DingLiApi/DataBrowser/getCompanySecurityList',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 获取交易所列表
export function getExchangeList(params) {
  return request({
    url: '/DingLiApi/DataBrowser/getExchangeList',
    method: 'get',
    params: {
      ...params
    }
  })
}


// 获取分类存在api的模板树数据
export function listAuthTemplateTree(params) {
  return request({
    url: '/DingLiApi/DataBrowser/listAuthTemplateTree',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 获取待选范围树数据
export function listSecCodeChooseScopeTree(params) {
  return request({
    url: '/DingLiApi/DataBrowser/listSecCodeChooseScopeTree',
    method: 'get',
    params: {
      ...params
    }
  })
}