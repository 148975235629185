
import { getDicts as getDicts } from '@/api/system/dict/data'
import { Loading } from 'element-ui'
// import { getToken } from '@/utils/auth'
// import store from '@/store'

let treeLoadingInstance;
// 打开遮罩层
export function loading(content) {
    treeLoadingInstance = Loading.service({
        lock: true,
        text: content,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
    })
}
// 关闭遮罩层
export function closeLoading() {
    treeLoadingInstance.close();
}

let treeClimateChildren1 = []
let treeCarbonDataChildren1 = []
// 树状结构(待选指标)
export let indicatorTreeLists = [
    {
        name: 'carbonDataList',
        arr: [
            {
                id: 2,
                type: 'carbon',
                label: '',
                children: treeCarbonDataChildren1,
            },
        ],
    },
    {
        name: 'treeClimateRiskList',
        arr: [
            {
                id: 1,
                type: 'climate',
                label: '',
                children: treeClimateChildren1
            },
        ],
    }
]

// if (getToken()) {
// 指标--气候相关风险
export function getDictsForTree(key) {
    // treeLoadingInstance = Loading.service({
    //     lock: true,
    //     text: '',
    //     spinner: "el-icon-loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    // })
    let list = []
    getDicts(key).then(({ data }) => {
        data.map((item) => {
            list.push({
                id: item.dictValue,
                label: item.dictLabel
            })
        })
    }).catch((err) => {
        console.log(err)
    });
    // treeLoadingInstance.close();
    return list;
}

// 操作符
export const operatorList = ['=', '!=', '<', '<=', '>', '>=', 'in', 'not in', 'like', 'not like', 'is null', 'is not null', 'between'];

// 参数子类型
export const paraSubTypeList = [
  {
    label: 'Json',
    value: 0
  },
  {
    label: 'Form-Data',
    value: 1
  },
  {
    label: 'X-www-form-urlencoded',
    value: 2
  },
  {
    label: 'Raw',
    value: 3
  },
  {
    label: 'Binary',
    value: 4
  },
  {
    label: 'None',
    value: 5
  },
];

// 请求参数数据类型
export const dataTypeList = [
  {
    label: 'String',
    value: 0
  },
  {
    label: 'Date',
    value: 1
  },
  {
    label: 'Datetime',
    value: 2
  },
  {
    label: 'Integer',
    value: 3
  }
]

export const yearReportPeriodList = [
  {
    label: '今年一季报',
    value: '@this_Q1@'
  },
  {
    label: '今年中报',
    value: '@this_Q2@'
  },
  {
    label: '今年三季报',
    value: '@this_Q3@'
  },
  {
    label: '去年一季报',
    value: '@last_Q1@'
  },
  {
    label: '去年中报',
    value: '@last_Q2@'
  },
  {
    label: '去年三季报',
    value: '@last_Q3@'
  },
  {
    label: '去年年报',
    value: '@last_Q4@'
  },
  {
    label: '自选报告期',
    value: 'custom'
  },
]

export const reportPeriodList = [
  {
    label: '一季报',
    value: 'Q1'
  },
  {
    label: '中报',
    value: 'Q2'
  },
  {
    label: '三季报',
    value: 'Q3'
  },
  {
    label: '年报',
    value: 'Q4'
  }
]

export const dateFilterTypeList = [
  // {
  //   label: '使用报告日期',
  //   value: 1
  // },
  {
    label: '使用日期范围',
    value: 2
  },
  // {
  //   label: '使用年/月份范围',
  //   value: 3
  // },
  {
    label: '使用年份选择',
    value: 4
  },
  {
    label: '使用季度选择',
    value: 5
  },
  {
    label: '使用月份选择',
    value: 6
  },
]
