var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog_DB",
      attrs: {
        title: _vm.titleSelfSelect,
        visible: _vm.visible,
        "before-close": _vm.handleClose,
        width: "460px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "dialog_DB_form",
          attrs: {
            model: _vm.form,
            rules: _vm.selfRules,
            "label-width": "170px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("selfSelect.formLabel"), prop: "name" } },
            [
              _c("el-input", {
                on: {
                  blur: function ($event) {
                    _vm.form.name = $event.target.value.trim()
                  },
                },
                model: {
                  value: _vm.sName,
                  callback: function ($$v) {
                    _vm.sName = $$v
                  },
                  expression: "sName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v(_vm._s(_vm.$t("elbtn.cancel"))),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.$t("elbtn.apply")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }