<template>
  <div class="navigation">
    <div ref="navigation" style="display: inline-block; width: 100%"></div>
    <div
      class="navigation-heading"
      :style="{ width: navigationWidth }"
      v-loading="loading"
    >
      <div
        v-for="(item, index) in heading"
        :key="index"
        :class="'heading-item heading-' + item.level"
        @click="headingItemClick(item)"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      loading: true,
      navigationWidth: "100px",
    };
  },
  mounted() {
    // 计算宽度
    window.onresize = () => {
      this.computeNavigationWidth();
    };
    setTimeout(() => this.computeNavigationWidth(), 100);
  },
  methods: {
    computeNavigationWidth() {
      this.navigationWidth = window.getComputedStyle(
        this.$refs.navigation,
        null
      ).width;
    },

    headingItemClick(item) {
      // 滚动到指定节点(过渡效果，垂直方向对齐，水平方向对齐)
      //   item.node.scrollIntoView({
      //     behavior: "smooth",
      //     block: "start",
      //     inline: "nearest",
      //   });
      item.node.parentNode.scrollTop = item.node.offsetTop;
    },
  },
};
</script>
<style>
.navigation {
  width: 100%;
}

.navigation-heading {
  /* position: fixed;
  z-index: 4;
  top: 250px; */
  max-height: calc(100vh - 290px);
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.navigation-heading .heading-item {
  padding: 5px 0;
  cursor: pointer;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navigation-heading .heading-item:hover {
  color: #3370ff;
}

.navigation-heading .heading-1 {
  padding-left: 0;
}

.navigation-heading .heading-2 {
  padding-left: 16px;
}

.navigation-heading .heading-3 {
  padding-left: 32px;
}

.navigation-heading .heading-4 {
  padding-left: 48px;
}

.navigation-heading .heading-5 {
  padding-left: 64px;
}

.navigation-heading .heading-6 {
  padding-left: 80px;
}

@media screen and (min-width: 1770px) {
  .navigation-heading {
    max-height: calc(100vh - 430px);
  }
}
</style>

