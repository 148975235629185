<template>
    <div class="review-page" v-loading="loading">
        <div class="review-main">
            <div class="review-left">
                <Menu @showView="handleViewChange"></Menu>
            </div>

            <div
                class="review-right"
                :class="viewId !== 'summary' ? 'review-right-fixed' : ''"
            >
                <transition name="fade">
                    <div v-if="viewId === 'summary'">
                        <summary-table
                            :totalColumn="totalColumn"
                            :data="mandatoryData"
                        ></summary-table>
                        <summary-table
                            :totalColumn="totalColumn"
                            :data="
                                additionalData.filter(
                                    (item) =>
                                        item.categoryId === 1 || item.isClimate
                                )
                            "
                        ></summary-table>
                        <summary-table
                            :totalColumn="totalColumn"
                            :data="
                                additionalData.filter(
                                    (item) =>
                                        item.categoryId === 2 || item.isSocial
                                )
                            "
                        ></summary-table>
                    </div>
                </transition>
                <transition name="fade">
                    <div
                        v-show="viewId === 'main'"
                        class="fixed-table-container"
                    >
                        <summary-table
                            :totalColumn="totalColumn"
                            :data="mandatoryData"
                        ></summary-table>
                    </div>
                </transition>
                <transition name="fade">
                    <div
                        v-if="viewId === 'option'"
                        class="fixed-table-container"
                    >
                        <summary-table
                            :totalColumn="totalColumn"
                            :data="additionalData"
                        ></summary-table>
                    </div>
                </transition>
            </div>
        </div>
        <div class="footer">
            <div class="btn-container">
                <el-button class="primary" @click="dialogVisible = true"
                    >Reset</el-button
                >
                <el-button
                    class="primary"
                    type="primary"
                    @click="handleDownloadReport"
                    >Download Report as Excel</el-button
                >
                <el-button
                    class="primary"
                    type="primary"
                    @click="showDownloadReminder = true"
                    >Download Underlying Data</el-button
                >
            </div>
        </div>
        <el-dialog
            title="Reminder"
            :visible.sync="dialogVisible"
            :show-close="false"
        >
            <span
                >Please ensure that your data has been downloaded before
                resetting. Do you really want to reset and return to the "Upload
                Portfolio" page?</span
            >
            <span slot="footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="reset">Confirm</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :visible.sync="showDownloadReminder"
            :show-close="false"
            :close-on-click-modal="false"
        >
            <span
                >If you need underlying data of the uploaded portfolio, please
                contact us via
                <strong>clientsupport@governance-solutions.com</strong></span
            >
            <span slot="footer">
                <el-button type="primary" @click="showDownloadReminder = false"
                    >Close</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Menu from '../components/review/Menu.vue'
import MandatoryTable from '../components/mandatory-pai/MandatoryTable.vue'
import SummaryTable from '../components/review/SummaryTable.vue'
import downloadMixin from '../mixin/downloadMixin'
import messageMixin from '../mixin/messageMixin'
import { calculatePAIData } from '@/api/data-platform/SFDR.js'

export default {
  components: {
    Menu,
    SummaryTable,
    MandatoryTable
  },
  mixins: [downloadMixin, messageMixin],
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    operationId: {
      type: String,
      default: null
    },
    mandatoryData: {
      type: Array,
      default: null
    },
    additionalData: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      // summary, main, option
      viewId: 'summary',
      loading: false,
      dialogVisible: false,
      showDownloadReminder: false
    }
  },
  computed: {
    totalColumn() {
      return this.columns.find((col) => col.prop === 'yearlyValue')
    }
  },
  async created() {
    console.log('mandatoryData:', this.mandatoryData)
    if (!this.operationId) {
      return
    }

    const additionalPaiList =
      this.additionalData
        ?.filter((item) => !!item.paiOrder)
        .map((item) => item.paiOrder) || []
    console.log('opptionlsit', additionalPaiList)
    this.paiIDList = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17
    ].concat(additionalPaiList)
    console.log('paiIDList', this.paiIDList)
  },

  methods: {
    handleViewChange(viewId) {
      this.viewId = viewId
    },
    async handleDownloadReport() {
      this.loading = true
      try {
        await this.downloadExcelReportData()
      } catch (error) {
        this.popError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/footer.scss";

.footer {
    padding: 16px 0;
}

.review-page {
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);

    .review-main {
        display: flex;
        flex: 1;
        height: calc(100%);

        .review-left {
            width: 170px;
            padding: 16px 16px;
            background: #ffffff;
            margin-right: 16px;
        }

        .review-right {
            flex: 1;
            overflow: auto;

            ::v-deep .summary-data-wrapper {
                margin-bottom: 22px;
            }
        }

        .review-right-fixed {
            height: 100%;
        }

        .fixed-table-container {
            height: 100%;

            ::v-deep .summary-data-wrapper {
                height: calc(100%);
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
}
</style>
