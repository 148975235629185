var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help-box" },
    [
      _c(
        "el-button",
        {
          ref: "btn1",
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              _vm.open = true
            },
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$store.getters.language === "zh" ? "帮助支持" : "Help"
              ) +
              " "
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              this.language == "zh"
                ? "帮助支持文档"
                : "Help Support Documentation",
            visible: _vm.open,
            width: "70%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c("div", { staticClass: "article" }, [
            _c("ul", [
              _c("li", { staticClass: "quick-start" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("help.h2Title1"))),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("help.articleText1")),
                    },
                  }),
                ]),
              ]),
              _c("li", { staticClass: "data-brower" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("help.h1Title1"))),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("help.articleText2")),
                    },
                  }),
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    this.language == "zh"
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/help-down.png"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/images/help-down-en.png"),
                            alt: "",
                          },
                        }),
                  ]),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("help.articleText6")),
                    },
                  }),
                ]),
              ]),
              _c("li", { staticClass: "fmcc" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("help.h1Title2"))),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("help.articleText3")),
                    },
                  }),
                ]),
              ]),
              _c("li", { staticClass: "connect" }, [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("help.h1Title3"))),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("help.articleText4")),
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t("help.articleText5")) },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }