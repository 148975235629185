<template>
    <div class="instructions">
        <div class="header">
            <span class="header-title">Instructions</span>
            <i
                class="el-icon-close flex-center"
                @click="$emit('showHelp', false)"
            ></i>
        </div>
        <div class="body">
            <div class="row flex-start">
                <div class="left flex-center border">
                    <span>Steps</span>
                </div>
                <div class="right">
                    <div class="info-row">
                        <div class="number flex-center border">1</div>
                        <div class="text flex-start border">
                            Upload Portfolio first
                        </div>
                    </div>
                    <div class="info-row grey">
                        <div class="number flex-center border">2</div>
                        <div class="text flex-start border">
                            Preview "Mandatory PAI"
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="number flex-center border">3</div>
                        <div class="text flex-start border">
                            Then you may go to the “Additional PAI” section for
                            choosing additional indicators
                        </div>
                    </div>
                    <div class="info-row grey">
                        <div class="number flex-center border">4</div>
                        <div class="text flex-start border">
                            Click the calculated value, you may review the
                            underlying data for both “Mandatory PAI" and
                            "Additional PAI"
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="number flex-center border">5</div>
                        <div class="text flex-start border">
                            Generate and download the PAI data
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.instructions {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 53px;
    left: 16px;
    right: 16px;
    bottom: 15px;
    z-index: 100;
    .header {
        background: #e1ebf8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        padding: 0 10px 0 18px;
        .header-title {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #215796;
            line-height: 20px;
        }

        i {
            cursor: pointer;
            color: #5998f0;
            font-size: 18px;
            width: 26px;
            height: 22px;
            text-align: center;
        }
    }
    .body {
        background: #ffffff;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        flex: 1;
        .row {
            height: 210px;
        }
        .left {
            width: 318px;
            height: 100%;
        }
        .right {
            flex: 1;
            .info-row {
                height: 42px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                div {
                    height: 100%;
                }
            }
            .number {
                width: 100px;
            }
            .text {
                width: calc(100% - 100px);
                padding-left: 20px;
                border-right: none;
            }
        }
    }
}
.grey {
    background: #fafafa;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-auto {
    flex: 1;
}
.border {
    border-right: 1px dashed #eee;
    border-bottom: 1px dashed #eee;
}
</style>