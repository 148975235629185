<template>
  <div>
    <!-- 顶部菜单 -->
    <navbar />
    <div
      :class="classObj"
      class="app-wrapper"
      :style="{ '--current-color': theme }"
    >
      <!-- 中间内容部分 -->
      <app-main />
    </div>

    <!-- 底部文字 -->
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import RightPanel from "@/components/RightPanel";
import {
  AppMain,
  Navbar,
  Settings,
  Sidebar,
  TagsView,
  SubNav,
  AppFooter,
} from "./components";

import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import variables from "@/assets/styles/variables.scss";

export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView,
    SubNav,
    AppFooter,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      theme: (state) => state.settings.theme,
      sideTheme: (state) => state.settings.sideTheme,
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
    variables() {
      return variables;
    },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";
@import "~@/assets/styles/variables.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: calc(100vh - 120px);
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.app-main {
  // margin-top: 92px;
  margin-top: 60px;
  background-color: #f5f6f8;
}
</style>
