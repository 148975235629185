import {
  requestFileTemplate
} from '@/api/data-platform/FMCC.js'

const mixin = {
  methods: {
    async downloadTemplate() {
      if (this.hasDownloadTemplate) {
        this.$notify({
          title: this.lang === 'en' ? 'Note' : '注意',
          type: 'info',
          duration: 3000,
          dangerouslyUseHTMLString: true,
          customClass: 'sfdr-notification-download',
          message: this.$t("downloadTemplate.tips"),
        })
        return
      }

      const fileStream = await requestFileTemplate({
        templateType: this.templateType,
        lang: this.lang
      })
      const blob = new Blob([fileStream], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      const a = document.createElement('a')
      const href = window.URL.createObjectURL(blob) // 创建下载连接
      a.href = href
      a.download = this.templateTitle;//模板题目
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a) // 下载完移除元素
      window.URL.revokeObjectURL(href) // 释放掉blob对象
      this.hasDownloadTemplate = true
    },
  }
};
export default mixin;
