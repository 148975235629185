<template>
  <div class="company-detail">
    <!-- 第一部分 -->
    <h2 class="font-32">{{ part1Data.name }}</h2>
    <h2 class="font-26">{{ part1Data.id }}</h2>
    <el-divider></el-divider>
    <div><span class="font-24" v-html="'行业名称(申万一级2014)&nbsp;&nbsp;&nbsp;&nbsp;'"></span>{{ part1Data.swName}}</div>
    <div><span class="font-24" v-html="'经营范围&nbsp;&nbsp;&nbsp;&nbsp;'"></span> {{ part1Data.businessScope }} </div>
    <el-divider></el-divider>
    <!-- 第二部分  flop异常指数图 -->
    <h1 class="font-24">FLOP财报异常指数 <!--<i class="el-icon-question"></i>--></h1>
    <FlopAnomalyIndex :flopAnomalyEchartsData="renderData.part2" :echartsTitle="part1Data.name" />
    <div v-if="companyAbnormal">
      <!-- 第三部分  公司情况汇总 //只有异常公司展示 -->
      <h3 class="font-24">公司情况汇总</h3>
      <el-table
        :data="renderData.part3"
        style="width: 100%;">
        <el-table-column prop="id" label="ID" align="center" />
        <el-table-column prop="date" label="Date" align="center" >
          <template slot-scope="scope">
            <span>{{ formatDate(scope.row.date) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="frdProbPmm" label="异常指数" align="center" />
        <el-table-column prop="isFrdPrePmm" label="模型认定的违规" align="center" >
          <template slot-scope="scope">
            <span :style="scope.row.isFrdPrePmm === 1?'color: red':''">{{ scope.row.isFrdPrePmm }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isFrd127" label="监管认定违规" align="center" >
          <template slot-scope="scope">
            <span :style="scope.row.isFrd127 === 1?'color: red':''">{{ scope.row.isFrd127 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="违规类型" align="center">
          <template slot-scope="scope" v-if="scope.row.violationType">
            <!-- <el-popover
              placement="top-start"
              width="600"
              trigger="hover">
              <p v-html="scope.row.activity"></p> -->
              <el-button slot="reference" @click="openActicityDialog(scope.row.activity)">{{ handleViolationType(scope.row.violationType) }}</el-button>
            <!-- </el-popover> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 第四部分  FLOP异常指标 -->
      <h3 class="font-24">FLOP异常指标</h3>
      <FlopAbnormalTarget :abnormalTargetData="renderData.part4" :unitId="part1Data.id"/>
    </div>
    <!-- 违规内容具体内容 弹框显示 -->
    <el-dialog
      title="违规内容"
      :visible.sync="open"
      class="dialog-box"
      width="45%">
      <p class="dialog-text">{{ violationActivity }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="open = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FlopAnomalyIndex from './flopAnomalyIndex.vue'
import FlopAbnormalTarget from './flopAbnormalTarget.vue'
export default {
  components: {
    FlopAnomalyIndex,
    FlopAbnormalTarget,
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    companyAbnormal: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      componySituatinList: [{
        anomalyType: '重大遗漏'
      }],
      part1Data: {
        name: '',
        id: '',
        swName: '',
      },
      open: false,
      violationActivity: '',
    }
  },
  computed: {
    // 数据处理
    renderData(num) {
      let res = {};
      this.part1Data = this.form.part1
      res.part2 = this.form.part2
      res.part3 = this.form.part3
      res.part4 = this.form.part4
      return res
    }
  },
  methods: {
    // 时间处理 只显示年-月-日
    formatDate(cellValue){
      var date = new Date(cellValue) 
      var year = date.getFullYear()
      var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() 
      return year + '-' + month + '-' + day
    },
    // 违规类型format处理
    handleViolationType(type) {
      if(type){
        let violationTypeButton = []
        type.split(',').map(item => {
          let typeText = item === 'P2501'? '虚构利润' : item === 'P2502' ? '虚列资产' : '欺诈上市'
          violationTypeButton.push(typeText)
        })
        return violationTypeButton.join(',')
      }else{
        return ''
      }
    },
    // 点击打开弹框
    openActicityDialog(text) {
      this.open = true
      this.violationActivity = text?text:'暂无内容'
    }
  }
}
</script>

<style lang="scss" scoped>
.company-detail{
  width: 100%;
}
.font-32{
  font-size: 32px;
  font-weight: bolder;
}
.font-26{
  font-size: 26px;
  font-weight: 700;
}
.font-24{
  font-size: 22px;
  font-weight: 600;
  white-space: pre;
}
.dialog-box{
  ::v-deep .el-dialog{
    margin-top: 90px !important;
  }
  .dialog-text{
    text-indent: 2em;
    max-height: calc(100vh - 350px);
    overflow: auto;
  }
}
</style>