<template>
  <!-- 顶部功能区 -->
  <div class="navbar-sub">
    <!-- 左侧button -->
    <div class="top-navbar-btns">
      <!-- 导入 -->
      <el-button plain @click="handleImport"
        >{{ $t("elbtn.import") }}
      </el-button>
      <!-- 保存为自选清单 -->
      <el-button plain @click="addSelfSelect">{{
        $t("elbtn.saveSelf")
      }}</el-button>
      <!-- 提取-->
      <el-button plain @click="extract">{{ $t("elbtn.extract") }}</el-button>
      <!-- 导出 -->
      <el-button plain @click="handleExport">{{
        $t("elbtn.export")
      }}</el-button>
    </div>

    <!-- 右侧账户信息 -->
    <div class="right-menu top-navbar-btns">
      <!-- 指示说明 -->
      <el-button plain @click="instructions">{{
        $t("elbtn.instructions")
      }}</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 控制按钮的显隐
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    // 获取当前语言
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    //1.导入
    handleImport() {
      this.$emit("handleImport");
    },
    //2.导出按钮操作
    handleExport() {
      this.$emit("handleExport");
    },
    //3.提取
    extract() {
      this.$emit("handleExtract");
    },
    // 4.保存为自选清单
    addSelfSelect() {
      this.$emit("addSelfSelect");
    },
    // 5.指示说明
    instructions() {
      this.$emit("handleInstructions");
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-sub {
  // height: 60px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  .top-navbar-btns {
    // .el-button--plain {
    //   background-color: transparent;
    //   border: 1px solid transparent;
    // }
    .el-button {
      color: #205080;
      border: 1px solid #D9D9D9;
    }
    .el-button.el-button:hover,
    .el-button.el-button:focus,
    .el-button.el-button:active {
      background-color: #4598f7;
      border-color: #4598f7;
      color: #fff;
    }
    .el-button--medium {
      padding: 7px 20px;
      border-radius: 6px;
    }
  }
}
</style>