var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "additional-page" },
    [
      !_vm.showDrillDownTable
        ? _c("div", { staticClass: "additional-header" }, [
            _c(
              "div",
              { staticClass: "additional-pai-select-wrapper" },
              [
                _c(
                  "span",
                  {
                    staticClass: "additional-pai-select",
                    on: {
                      click: function ($event) {
                        _vm.showPaiSelect = true
                      },
                    },
                  },
                  [_vm._v("Select additional indicators")]
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "right" },
                  },
                  [
                    _c("i", { staticClass: "el-icon-question" }),
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("p", [_vm._v("Notes:")]),
                      _c("p", [
                        _vm._v(
                          " (a) At least one additional indicator related to principal adverse impacts on a climate or other "
                        ),
                      ]),
                      _c("p", [
                        _vm._v("environment related sustainability factor;"),
                      ]),
                      _c("p", [
                        _vm._v(
                          " (b) At least one additional indicator related to principal adverse impacts on a social, employee, "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " human rights, anti-corruption or anti-bribery sustainability factor. "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showPaiSelect,
                    expression: "!showPaiSelect",
                  },
                ],
                staticClass: "display-select-wrapper",
              },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "full" },
                    model: {
                      value: _vm.currentDisplayType,
                      callback: function ($$v) {
                        _vm.currentDisplayType = $$v
                      },
                      expression: "currentDisplayType",
                    },
                  },
                  [_vm._v("Full Display")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "simple" },
                    model: {
                      value: _vm.currentDisplayType,
                      callback: function ($$v) {
                        _vm.currentDisplayType = $$v
                      },
                      expression: "currentDisplayType",
                    },
                  },
                  [_vm._v("Summary Display")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.showDrillDownTable
        ? _c("mandatory-table", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showPaiSelect,
                expression: "!showPaiSelect",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              data: _vm.tableData,
              columns: _vm.tableColumn,
              displayType: _vm.currentDisplayType,
              hasDrillDownPermission: _vm.hasDrillDownPermission,
            },
            on: { cellClick: _vm.cellClickHandler },
          })
        : _vm._e(),
      _vm.showDrillDownTable
        ? _c("DrillDownTable", {
            attrs: { data: _vm.drillDownData, columns: _vm.drillDownColumns },
            on: { cellClick: _vm.cellClickHandler },
          })
        : _vm._e(),
      _c("AdditionalPaiSelect", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPaiSelect,
            expression: "showPaiSelect",
          },
        ],
        attrs: { paiGroup: _vm.paiGroup },
        on: { updatePaiIdList: _vm.paiIdListHandler },
      }),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showPaiSelect,
                expression: "!showPaiSelect",
              },
            ],
            staticClass: "btn-container",
          },
          [
            !_vm.showDrillDownTable
              ? _c("el-button", { on: { click: _vm.backStep } }, [
                  _vm._v("Back"),
                ])
              : _vm._e(),
            !_vm.showDrillDownTable
              ? _c(
                  "el-button",
                  {
                    staticClass: "primary",
                    attrs: { type: "primary" },
                    on: { click: _vm.nextStep },
                  },
                  [_vm._v("Review full report")]
                )
              : _vm._e(),
            _vm.showDrillDownTable
              ? _c(
                  "el-button",
                  {
                    staticClass: "primary",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.showDrillDownTable = false
                      },
                    },
                  },
                  [_vm._v("Close")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPaiSelect,
                expression: "showPaiSelect",
              },
            ],
            staticClass: "btn-container",
          },
          [
            _c("el-button", { on: { click: _vm.cancelHandler } }, [
              _vm._v("Cancel"),
            ]),
            _c(
              "el-button",
              {
                staticClass: "primary",
                attrs: { type: "primary" },
                on: { click: _vm.calculationHandler },
              },
              [_vm._v("Calculation")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }