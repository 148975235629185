<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    :show-header="false"
    ref="table"
    class="right-table"
    :cell-style="{ padding: '4px 0px' }"
    :row-key="getRowKeys"
    :expand-row-keys="expands"
  >
    <el-table-column prop="title" width="100%">
      <template slot-scope="scope">
        <div @click="toogleExpand(scope.row)">
          <svg
            class="expond-svg"
            width="5"
            height="9"
            viewBox="0 0 5 9"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.770752 0.753032C0.770752 0.556335 0.801392 0.396944 0.862672 0.274858C0.923951 0.15277 1.00473 0.0713787 1.10501 0.0306825C1.20528 -0.0100126 1.31809 -0.0100127 1.44344 0.0306825C1.56878 0.0713787 1.69274 0.159553 1.8153 0.295205C2.31668 0.844597 2.80692 1.37364 3.28601 1.88234C3.76511 2.39103 4.21357 2.88277 4.63139 3.35755C4.7428 3.48642 4.82915 3.63734 4.89043 3.81029C4.95171 3.98325 4.98375 4.16129 4.98653 4.34442C4.98932 4.52755 4.96564 4.7039 4.9155 4.87347C4.86536 5.04303 4.78737 5.18547 4.68152 5.30077C4.44198 5.56529 4.19686 5.83999 3.94617 6.12486L3.19409 6.99982C2.9434 7.28469 2.69689 7.56786 2.45456 7.84934C2.21222 8.13082 1.97685 8.39364 1.74845 8.63782C1.6426 8.75312 1.53257 8.82773 1.41837 8.86164C1.30417 8.89556 1.19971 8.89216 1.10501 8.85147C1.0103 8.81077 0.932308 8.73277 0.871028 8.61747C0.809748 8.50216 0.779108 8.35295 0.779108 8.16982C0.779108 7.93243 0.777715 7.60516 0.77493 7.18803C0.772145 6.7709 0.770752 6.31477 0.770752 5.81964L0.770752 4.29355L0.770752 2.79799L0.770752 1.5466L0.770752 0.753032Z"
              fill="#AEAEB2"
            />
          </svg>
          <p class="right-table-title">{{ scope.row.title }}</p>
          <!-- <el-button type="text" class="right-table-title">{{
            scope.row.title
          }}</el-button> -->
        </div>
      </template>
    </el-table-column>

    <el-table-column type="expand">
      <template slot-scope="props">
        <el-form label-position="left" inline class="right-table-expand">
          <el-form-item
            v-for="item in props.row.list"
            :key="item.label"
            class="right-table-con"
          >
            <div>
              <router-link
                class="link-type"
                :to="item.path"
                @click.native="clickItem(item)"
                :class="item.isClose ? 'close-item' : ''"
              >
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8381 14.3184H2.82783C1.34717 14.3184 0.141113 13.1123 0.141113 11.6316V5.8166C0.141113 4.33594 1.34531 3.12988 2.82783 3.12988H10.5336C10.7915 3.12988 10.9993 3.33955 10.9993 3.59561C10.9993 3.85166 10.7915 4.06133 10.5336 4.06133H2.82783C1.86113 4.06133 1.07441 4.84805 1.07441 5.81475V11.6298C1.07441 12.5965 1.86113 13.3832 2.82783 13.3832H12.8381C13.8048 13.3832 14.5915 12.5965 14.5915 11.6298V5.8166C14.5915 5.55869 14.7993 5.35088 15.0572 5.35088C15.3151 5.35088 15.5229 5.56055 15.5229 5.8166V11.6316C15.5248 13.1142 14.3187 14.3184 12.8381 14.3184Z"
                    fill="#1F5080"
                  />
                  <path
                    d="M7.38115 10.8716C7.25127 10.8716 7.12324 10.8178 7.03047 10.7139C6.85977 10.5209 6.87832 10.2259 7.07129 10.0552C7.1251 10.0069 12.4855 5.23838 13.0941 1.73526C13.1684 1.31036 13.4745 0.974515 13.8938 0.861332C14.3206 0.744437 14.7641 0.881742 15.0498 1.21758L16.6177 3.06377C16.7847 3.26045 16.7605 3.55362 16.5639 3.72061C16.3672 3.8876 16.074 3.86348 15.907 3.6668L14.3392 1.82061C14.2687 1.73711 14.1759 1.7501 14.1388 1.75938C14.0905 1.77237 14.0293 1.80762 14.0145 1.89297C13.3502 5.71709 7.92109 10.5487 7.68916 10.7528C7.6001 10.8345 7.49062 10.8716 7.38115 10.8716Z"
                    fill="#1F5080"
                  />
                  <path
                    d="M2.7833 11.5816C2.65342 11.5816 2.52539 11.5278 2.43262 11.4238C2.26191 11.2309 2.28047 10.9359 2.47344 10.7652C3.24531 10.0823 5.30674 7.99123 5.56094 6.53098C5.61475 6.22297 5.8374 5.9799 6.13984 5.89641C6.44785 5.81291 6.76699 5.91125 6.97295 6.15432L7.79492 7.12102C7.96191 7.3177 7.93779 7.61086 7.74111 7.77785C7.54443 7.94485 7.25127 7.92072 7.08428 7.72404L6.42188 6.94475C5.88564 8.97649 3.2082 11.3589 3.08945 11.4628C3.00225 11.5426 2.89277 11.5816 2.7833 11.5816Z"
                    fill="#1F5080"
                  />
                </svg>
                <span>{{ item.label }}</span>
              </router-link>
            </div>
          </el-form-item>
        </el-form>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: "ExpandTable",
  data() {
    return {
      tableData: [
        {
          id: "1",
          title: "carbon data",
          list: [
            {
              label: "data browser",
              path: "/data-platform/carbon/dataBrowser",
              isClose: false,
            },
            {
              label: "FMCC climate risk analyze",
              path: "/data-platform/Carbon/FMCCClimateRisk",
              isClose: false,
            },
            {
              label: "portfolio carbon footprint",
              path: "",
              isClose: true,
            },
            {
              label: "engagement letter",
              path: "",
              isClose: true,
            },
          ],
        },
        {
          id: "2",
          title: "SFDR",
          list: [
            // {
            //   label: "SFDR",
            //   path: "",
            //   isClose: true,
            // },
          ],
        },
        {
          id: "3",
          title: "GSG IQ",
          list: [
            // {
            //   label: "GSG IQ",
            //   path: "",
            //   isClose: true,
            // },
          ],
        },
        {
          id: "4",
          title: "portfolio analyze",
          list: [
            // {
            //   label: "portfolio analyze",
            //   path: "",
            //   isClose: true,
            // },
          ],
        },
        {
          id: "5",
          title: "ESG data",
          list: [
            // {
            //   label: "ESG data",
            //   path: "",
            //   isClose: true,
            // },
          ],
        },
        {
          id: "6",
          title: "Governance Thematic Data",
          list: [
            // {
            //   label: "Governance Thematic Data",
            //   path: "",
            //   isClose: true,
            // },
          ],
        },
        {
          id: "7",
          title: "Finanial Fraud Analysis",
          list: [
            // {
            //   label: "Finanial Fraud Analysis",
            //   path: "",
            //   isClose: true,
            // },
          ],
        },
      ],
      expands: [], //展开行
      getRowKeys(row) {
        return row.id;
      },
    };
  },
  mounted() {
    //默认展开行
    this.expands.push(this.tableData[0].id);
  },
  methods: {
    // 点击表头,展开list
    toogleExpand(row) {
      // 如果没有list,就提示没有权限
      if (!row.list || row.list.length < 1) {
        this.$notify({
          title: this.$t("tips.warning"),
          message: this.$t("tips.msg9"),
          type: "warning",
          offset: 100,
        });
      } else {
        let $table = this.$refs.table;
        this.tableData.map((item) => {
          if (row.id != item.id) {
            $table.toggleRowExpansion(item, false);
          }
        });
        $table.toggleRowExpansion(row);
      }
    },

    // 点击展开项中的某一项
    clickItem(item) {
      // 如果没有路径，提示没有权限
      if (item.path == "" || !item.isClose) {
        this.$notify({
          title: this.$t("tips.warning"),
          message: this.$t("tips.msg9"),
          type: "warning",
          offset: 100,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.right-table {
  .right-table-expand {
    font-size: 0;
    margin-left: 25px;
  }
  .right-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .right-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .link-type {
    padding: 10px;
  }
  .link-type:focus,
  .link-type:hover {
    color: #337ab7;
    cursor: pointer;
    background-color: #dfebf9;
    border-radius: 3px;
    padding: 10px;
  }
  .link-type.close-item {
    opacity: 0.5;
  }
  ::v-deep .el-table__row {
    cursor: pointer;
    height: 35px;
  }
  ::v-deep .el-table__body {
    width: 100% !important;
  }
  // 二级标题
  .right-table-title {
    color: #aeaeb2;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }

  // .right-table-title:hover,
  .right-table-title:focus {
    color: #01579b;
  }
  // 图标样式
  .expond-svg {
    cursor: pointer;
  }
  .expanded {
    .expond-svg {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      *,
      *:before,
      *:after {
        fill: #01579b;
      }
    }
    .right-table-title {
      color: #01579b;
    }
    // 下划线
    .right-table-title::after {
      content: "";
      width: 130px;
      height: 10px;
      border-bottom: 4px solid #01579b;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 0;
      top: 27px;
      /* 自动内减 */
      box-sizing: border-box;
    }
  }

  // 隐藏图标
  ::v-deep .el-table__expand-icon {
    display: none;
  }
  .row-style {
    height: 35px;
  }
}
// 只用于大屏幕
@media screen and (min-width: 1770px) {
  .right-table {
    // 行高
    ::v-deep .el-table__row {
      height: 55px;
    }
    // 下划线
    .expanded .right-table-title::after {
      content: "";
      top: 45px;
    }
  }
}
</style>