import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import ForRouter from "@/layout/components/ForRouter.vue"


/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect')
  }]
},
{
  path: '/login',
  component: () => import('@/views/login'),
  hidden: true
},
{
  path: '/register',
  component: () => import('@/views/register'),
  hidden: true
},
{
  path: '/404',
  component: () => import('@/views/error/404'),
  hidden: true
},
{
  path: '/401',
  component: () => import('@/views/error/401'),
  hidden: true
},
{
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'profile/:lang(en)',
    component: () => import('@/views/system/user/profile/indexEnDL.vue'),
    name: 'enProfile',
    meta: {
      title: 'My Profile'
    },
    query: {
      lang: 'en'
    }
  }, {
    path: 'profile/:lang(zh)',
    component: () => import('@/views/system/user/profile/indexZhDL.vue'),
    name: 'zhProfile',
    meta: {
      title: '个人中心'
    },
    query: {
      lang: 'zh'
    }
  }, {
    path: 'profile/api',
    component: () => import('@/views/system/user/api.vue'),
    name: 'apiRepository',
    meta: {
      title: 'API库'
    },
  }]
},
{
  path: '',//首次进入
  component: Layout,
  redirect: '/index',
  children: [{
    path: '/index',
    component: () => import('@/views/home'),
    meta: {
      affix: true,
    }
  }]
},
{
  path: '/data-platform', //数据平台
  component: Layout,
  children: [{
    path: 'carbon', //carbon data
    redirect: 'carbon/dataBrowser',
    name: 'carbon',
    component: ForRouter,
    meta: {
      title: 'Carbon Data'
    },
    children: [{
      path: 'dataBrowser',
      component: () => import('@/views/data-platform/carbon/dataBrowser.vue'),
      name: 'dataBrowser',
      meta: {
        title: 'dataBrowser',
        activeMenu: '/data-platform/carbon'
      }
    },
    {
      path: 'portfolioCarbonFootprint',
      component: () => import('@/views/data-platform/carbon/portfolioCarbonFootprint.vue'),
      name: 'portfolioCarbonF',
      meta: {
        title: 'portfolioCarbon',
        activeMenu: '/data-platform/carbon'
      }
    },
    {
      path: 'FMCCClimateRisk', //FMCCRisk
      component: () => import('@/views/data-platform/carbon/FMCCClimateRisk.vue'),
      name: 'FMCCClimateRisk',
      meta: {
        title: 'FMCCClimateRisk',
        activeMenu: '/data-platform/carbon'
      }
    },
      // {
      //   path: 'engagementLetter',//Letter
      //   component: () => import('@/views/data-platform/friendly-reminder/index.vue'),
      //   name: 'engagementLetter',
      //   meta: { title: 'engagementLetter', activeMenu: '/data-platform/carbon' }
      // },
    ]
  },
  {
    path: 'sfdr',
    component: () => import('@/views/sfdr/SFDR.vue'),
    name: 'SFDR',
    meta: {
      title: 'SFDR',
      affix: true,
    },
  }, {
    path: 'gsgiq',
    component: () => import('@/views/data-platform/friendly-reminder/index.vue'),
    name: 'GSGIQ',
    meta: {
      title: 'GSGIQ'
    }
  },
  {
    path: 'portfolio',
    component: () => import('@/views/data-platform/friendly-reminder/index.vue'),
    name: 'portfolio',
    meta: {
      title: 'portfolio'
    }
  }, {
    path: 'esg',
    component: () => import('@/views/data-platform/friendly-reminder/index.vue'),
    name: 'ESG',
    meta: {
      title: 'ESG'
    }
  }, {
    path: 'govermance',
    component: () => import('@/views/data-platform/friendly-reminder/index.vue'),
    name: 'govermance',
    meta: {
      title: 'govermance'
    }
  }, {
    path: 'financial',
    component: () => import('@/views/flop/FLOP.vue'),
    name: 'financial',
    meta: {
      title: 'financial'
    }
  },
  {
    path: 'admin-center',
    component: () => import('@/views/data-platform/admin-center/index.vue'),
    name: 'Admin',
    meta: {
      title: 'Admin'
    }
  },
    {
      path: 'production',
      component: () => import('@/views/data-platform/production/index.vue'),
      name: 'production',
      meta: {
        title: 'production'
      }
    },
  {
    path: 'dl-databrowser',
    component: () => import('@/views/data-platform/dl-databrowser/index.vue'),
    name: 'DLDatabrowser',
    meta: {
      title: 'DLDatabrowser'
    }
  },
  {
    path: 'template-category',
    component: () => import('@/views/data-platform/template-category/index.vue'),
    name: 'TemplateCategory',
    meta: {
      title: 'TemplateCategory'
    }
  },
  ]
},
{
  path: '/worldmap-login',
  component: () => import('@/views/worldMapLogin.vue'),
  hidden: true
},
{
  path: '/worldmap-board',
  component: () => import('@/views/worldMapBoard/index.vue'),
  hidden: true
},
{
  path: '/about-data',
  component: () => import('@/views/worldMapBoard/aboutData.vue'),
  hidden: true
},
{
  path: '/about-project',
  component: () => import('@/views/worldMapBoard/aboutProject.vue'),
  hidden: true
}
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [{
  path: '/system/user-auth',
  component: Layout,
  hidden: true,
  permissions: ['system:user:edit'],
  children: [{
    path: 'role/:userId(\\d+)',
    component: () => import('@/views/system/user/authRole'),
    name: 'AuthRole',
    meta: {
      title: '分配角色',
      activeMenu: '/system/user'
    }
  }]
},
{
  path: '/system/role-auth',
  component: Layout,
  hidden: true,
  permissions: ['system:role:edit'],
  children: [{
    path: 'user/:roleId(\\d+)',
    component: () => import('@/views/system/role/authUser'),
    name: 'AuthUser',
    meta: {
      title: '分配用户',
      activeMenu: '/system/role'
    }
  }]
},
{
  path: '/system/dict-data',
  component: Layout,
  hidden: true,
  permissions: ['system:dict:list'],
  children: [{
    path: 'index/:dictId(\\d+)',
    component: () => import('@/views/system/dict/data'),
    name: 'Data',
    meta: {
      title: '字典数据',
      activeMenu: '/system/dict'
    }
  }]
},
{
  path: '/monitor/job-log',
  component: Layout,
  hidden: true,
  permissions: ['monitor:job:list'],
  children: [{
    path: 'index',
    component: () => import('@/views/monitor/job/log'),
    name: 'JobLog',
    meta: {
      title: '调度日志',
      activeMenu: '/monitor/job'
    }
  }]
},
{
  path: '/tool/gen-edit',
  component: Layout,
  hidden: true,
  permissions: ['tool:gen:edit'],
  children: [{
    path: 'index/:tableId(\\d+)',
    component: () => import('@/views/tool/gen/editTable'),
    name: 'GenEdit',
    meta: {
      title: '修改生成配置',
      activeMenu: '/tool/gen'
    }
  }]
}
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})
