export default {
  FMCCTop: {
    upload: '上传投资组合',
    date: '时间',
    unit: 'TNA货币单位',
    name: '组合名称',
    calculate: '计 算',
    down: '下载报告',
    more: '高级选项',
    title1: '气候风险评估-相关性',
    content1: '风险暴露的相关性阈值',
    title2: '气候风险评估-重要性(方案1)',
    content2: '风险暴露的重要性阈值',
    title3: '气候风险评估-重要性(方案2)',
    table1: '投资组合面临的气候风险(%)',
    table2: '影子碳成本/税息折旧及摊销前利润 超过',
    table3: '物理风险阈值',
    table4: '过渡风险阈值',
    useBtn: '应 用',
    confirmBtn: '确 认',
    cancelBtn: '取 消',
    contactBtn:'联系我们',
    uploadRes: '上传结果',
    CalculateRes: '计算结果'
  },
  baseTip: {
    title: '投资组合的碳足迹评估',
    tableTh1: '*指标',
    tableTh2: '单位',
    tableTh3: '数值',
    indicator1: '投资组合碳排放',
    indicator2: '**投资组合碳足迹',
    indicator3: '碳强度',
    indicator4: '加权平均碳排放强度(WACI)',
    indicator5: '碳排放数据来源于报告采集的投资标的占比',
    indicator6: '碳排放数据源于来模型估算的投资标的占比',
    unit1: '吨二氧化碳当量',
    unit2: '吨二氧化碳当量/百万营收 CNY',
    text1: '*PFAF和TCFD是全球计算投资组合碳足迹最为广泛接受的主流算法。在这两种算法当中，指标代表了相同的含义，但是在详细的计算过程中仍然存在差异。一个关键的差异是在计算分布因子上，该分配因子会决定整体投资组合中如何分配投资标的本身的被纳入总排放的程度。更多的细节如下：总体来说，EVIC是大于市值的，这两个值都是分布因子的分母。因此，根据TCFD的定义，分配因子的分母用的是市值，这会得到较高的分配因子值，并且会得到较高的投资组合层面的排放值，PCAF采用EVIC做分母，得到的排放值会相对较低。',
    text2: '***在计算和评估投资组合的碳足迹情况过程中，GSG选用FMCC建议的PCAF分配因子算法。',
    table1: 'FMCC 定义',
    table2: 'TCFD 定义',
    table3: 'PCAF 分配因数',
    table4: '碳排放总量',
    table5: '碳足迹',
    table6: '碳强度',
    table7: 'WACI'
  },
  fileTable: {
    fundId: '投资组合代码',
    fundName: '投资组合名称',
    portfolioDate: '投资组合日期',
    isin: 'ISIN',
    ticker: 'Ticker',
    securityName: '证券名称',
    totalNetAsset: '总净资产',
    assetType: '资产类型',
    currency: '货币',
    shares: '数量(张或股)',
    marketValue: '公允价值',
    nodataText: '您的投资清单目前为空，请先上传您的文件。'
  },
  tabs: {
    tab1: '基础数据',
    tab2: '气候相关风险评估',
    tab3: '投资组合碳足迹',
    tab4: '观察名单'
  },
  processed: {
    fundId: '投资组合代码',
    fundName: '投资组合名称',
    portfolioDate: '投资组合日期',
    isin: 'ISIN',
    ticker: 'Ticker',
    securityName: '证券名称',
    totalNetAsset: '总净资产',
    assetType: '资产类型',
    currency: '货币',
    shares: '数量(张或股)',
    marketValue: '公允价值({0})',
    scope12Emissions: '范围1+2碳排放量 (吨二氧化碳当量）',
    revenue: '营业总收入 (百万{0})',
    intensity: '碳排放强度 (吨二氧化碳当量/百万{0})',
    weighting: '占基金资产净值比例 (％)',
    shadowCost: '影子碳成本（{0}）',
    ebitda: '税息折旧及摊销前利润（{0}）',
    evic: '企业价值含现金（{0}）',
    allocationFactor: '分配系数',
    allocatedAbs: '碳排放绝对量分配 (吨二氧化碳当量)',
    emissionDataType: '数据来源类型',
    sasbIndustry: '基于SASB分类行业',
    physicalRiskScore: '物理风险分数',
    transitionRiskScoreA: '转型风险分数A',
    transitionRiskScoreB: '转型风险分数B（影子碳成本/EBITDA）',
  },
  watchList: {
    fundId: '投资组合代码',
    fundName: '投资组合名称',
    portfolioDate: '投资组合日期',
    isin: 'ISIN',
    ticker: 'Ticker',
    securityName: '证券名称',
    sasbIndustry: '基于SASB分类行业',
    text1: `基于碳排放与影子碳成本，进一步分析各标的其气候相关风险重大性。通过计算影子碳成本占EBITDA的比例，评估未来由于气候变化引发的碳排放成本变化对所投资标的价值可能造成的影响，基于本年度开展气候相关风险测算的结果，选择影子碳成本对企业EBITDA影响超过设定阈值的标的，作为组成可能面临较高气候相关风险的watchlist，辅助事前预警和风险管理的一个作用。`
  },
  footprint: {
    title1: '投资组合的碳足迹评估',
    table1Th1: '*指标',
    table1Th2: '单位',
    table1Th3: '数值',
    text1: `*PFAF和TCFD是全球计算投资组合碳足迹最为广泛接受的主流算法。在这两种算法当中，指标代表了相同的含义，但是在详细的计算过程中仍然存在差异。一个关键的差异是在计算分布因子上，该分配因子会决定整体投资组合中如何分配投资标的本身的被纳入总排放的程度。更多的细节如下：总体来说，EVIC是大于市值的，这两个值都是分布因子的分母。因此，根据TCFD的定义，分配因子的分母用的是市值，这会得到较高的分配因子值，并且会得到较高的投资组合层面的排放值，PCAF采用EVIC做分母，得到的排放值会相对较低。在计算和评估投资组合的碳足迹情况过程中，GSG选用FMCC建议的PCAF分配因子算法。`,
    text2: '** 投资组合碳足迹指标是FMCC要求大型基金经理进行披露的。',
    title2: '基于SASB行业分类的数据统计',
    table2Th1: '基于SASB方法的行业分类',
    table2Th2: '标的数目',
    table2Th3: '公允价值',
    table2Th4: '占基金资产净值比例',
    table2Th5: '碳排放绝对量分配 (吨二氧化碳当量)',
    table2Th6: '排放量分配占比',
    title3: '基于SASB行业分类统计的净值比例及排放量分配比例对比'
  },
  risk: {
    title1: '气候相关风险关联性分析',
    title2: '风险暴露的相关性阈值',
    title3: '基于SASB行业分类的数据统计',
    title4: '基于SASB行业分类统计的净值比例及排放量分配比例对比',
    title5: '气候相关风险重大性分析-选项 1',
    title6: '气候相关风险重大性分析-选项 2',
    text1_1: `参照FMCC所推荐的SASB方法学，对气候相关风险的关联性进行分析。统计数据结果如下。`,
    text1_2: `物理风险和转型风险的评估基于FMCC推荐的SASB方法学，其中，SASB的气候风险地图将不同行业可能面临的气候相关风险（物理风险和转型风险）进行识别。参照SASB的行业分类方法，结合持有标的的分行业净值，得到投资组合层面的物理风险和转型风险的暴露程度。
基金经理可在投资组合层面，根据风险偏好水平设置临界值以此判断物理风险和转型风险的关联性。测算结果超过阈值则判定为具有关联性，低于阈值则判定为不具备关联性。
`,
    table1_1: '气候相关风险暴露比例（净值比例）',
    table1_2: '临界值',
    table1_3: `气候相关风险暴露比例（净值比例）`,
    table1_4: '关联性判断',
    table1_5: '注释',
    table1_6: '物理风险',
    table1_7: `组合持仓层面”基础数据“页的”物理风险分数“列为0意味着SASB方法学评估判定该标的物理风险为非重大，该列分数为1意味着重大。此处对“重大“的净值比例求和，得到组合层面物理风险暴露的净值比例即左侧单元格数值，并进入临界值（e.g. 5%）判断。`,
    table1_8: '转型风险',
    table1_9: `组合持仓层面”基础数据“页的”转型风险分数“列为0意味着SASB方法学评估判定该标的转型风险为非重大，该列分数为1意味着重大。此处对“重大“的净值比例求和，得到组合层面转型风险暴露的净值比例即左侧单元格数值，并进入临界值（e.g. 5%）判断。`,
    table1_10: `* 投资组合面临的气候风险(%) 该点纳入计算的标的净值总和低于阈值，重大性（或者是相关性）判断或有偏差。`,
    table1_11: `纳入评估的标的所占资产净值比例`,
    table2_1: `基于SASB方法的行业分类`,
    table2_2: `标的数目`,
    table2_3: `公允价值`,
    table2_4: `占基金资产净值比例`,
    table2_5: `碳排放绝对量分配 (吨二氧化碳当量)`,
    table2_6: `排放量分配占比`,
    text2: `物理风险和转型风险的评估基于FMCC推荐的SASB方法学，其中，SASB的气候风险地图将不同行业可能面临的气候相关风险（物理风险和转型风险）进行识别。参照SASB的行业分类方法，结合持有标的的分行业净值，得到投资组合层面的物理风险和转型风险的暴露程度。
基金经理可在投资组合层面，根据风险偏好水平设置临界值以此判断物理风险和转型风险的关联性。测算结果超过阈值则判定为具有关联性，低于阈值则判定为不具备关联性。
`,
    table3: '风险暴露的重要性阈值',
    Materiality: '重大性判断',
    text3_1: `物理风险和转型风险的评估基于FMCC推荐的SASB方法学，其中，SASB的气候风险地图将不同行业可能面临的气候相关风险（物理风险和转型风险）进行识别。参照SASB的行业分类方法，结合持有标的的分行业净值，得到投资组合层面的物理风险和转型风险的暴露程度。`,
    text3_2: `在此基础上，物理风险设定一临界值，作为重大性的判断标准，低于临界值为不重大，高于临界值为重大。`,
    text3_3: `SASB方法学对绝大多数行业的转型风险判断为重大性，从而使得投资组合层面的转型风险暴露比例高，很大可能超过重大性临界值；选项2在选项1基础上，对转型风险进一步分析，基于碳排放与影子碳成本，进一步分析各标的其重大性。计算影子碳成本占EBITDA的比例，设定比例临界值，判断重大性。(亦可在此基础上构建估值调整模型） `,
    table3_1: `风险类型`,
    table3_2: `气候相关风险暴露比例（净值比例）`,
    table3_3: `影子成本占EBITDA占比超过{0}%的比例（净值比例）`,
    table3_4: `物理风险重大性判断临界值`,
    table3_5: `转型风险重大性判断临界值`,
    table3_node1: `组合持仓层面”基础数据“页的”物理风险分数“列为0意味着SASB方法学评估判定该标的物理风险为非重大，该列分数为1意味着重大。此处对“重大“的净值比例求和，得到组合层面物理风险暴露的净值比例即左侧单元格数值，并进入临界值（e.g. 50%）判断。`,
    table3_node2: `注释：1.组合持仓层面“基础数据”页为0意味着SASB方法学认定该标的转型风险非重大，为1意味着重大。此处对“重大“的净值比例求和，得到组合层面气候相关风险暴露的净值比例，并进入临界值（e.g. 50%）判断；2. 在第一步超过临界值（e.g. 50%）的基础上，进一步基于影子碳成本对标的EBITDA影响程度进行判断，对影子碳成本对EBITDA影响比例超过5%的标的净值进行求和得到左侧单元格数值，进入临界值（e.g. 10%)判断。`,
    text4: `重大性Option1&2测算结果当前同时呈现，以供您参考。如您有内部记录备存需求，建议您下载后仅保留您最终选择的重大性评估测算结果和相应结论即可。 `
  }
}
