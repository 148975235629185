import request from '@/utils/request'

/**
* 1.关键词查询
* @param seccode 股票代码
* @param secname 股票名称
* @param ISIN 
* @return Promise
* */
export function getAllStocks() {
  return request({
    url: '/carbon-data/stock/all',
    method: 'get'
  })
}

/**
* 2.提取
* @param indicatorList:[whichYear,indicator] 指标列表
* @param index 指数类型
* @param seccodeList:[] 股票代码列表
* @param pageNum 
* @param pageSize 
* @return Promise
* */
export function getStockCarbonIndicator(data) {
  return request({
    url: '/carbon-data/stockCarbonIndicator/list',
    method: 'post',
    data
  })
}
/**
* 3.根据指数查询股票
* @param index 指数id
* @param pageNum 
* @param pageSize 
* @return Promise
* */
export function getStocksByIndex(query) {
  return request({
    url: '/carbon-data/stockIndex/list',
    method: 'get',
    params: query
  })
}

/**
* 4.删除自选清单-股票
* @param id 自选清单股票id
* @return Promise
* */
export function delSelfSelectionDetail(data) {
  return request({
    url: '/carbon-data/selfSelectionDetail/delete',
    method: 'post',
    data
  })
}

/**
* 11.自选清单-追加股票
* @param selfSelectionId 自选清单id
* @param stockList [seccode,secname]
* @return Promise
* */
export function addStockToSelfSelection(data) {
  return request({
    url: '/carbon-data/selfSelectionDetail/add',
    method: 'post',
    data
  })
}

/**
* 5.删除自选清单
* @param id 自选清单id
* @return Promise
* */
export function delSelfSelection(data) {
  return request({
    url: '/carbon-data/selfSelection/delete',
    method: 'post',
    data
  })
}
/**
* 6.获取所有自选清单
* */
export function getAllSelfSelection(query) {
  return request({
    url: '/carbon-data/selfSelection/all',
    method: 'get',
    params: query
  })
}
/**
* 7.保存为/新增自选清单
* @param selfSelectionId 自选清单id
* @param stockList [seccode, secname]
* @return Promise
* */
export function addSelfSelection(data) {
  return request({
    url: '/carbon-data/selfSelection/add',
    method: 'post',
    data
  })
}

/**
* 8.根据自选清单查询股票
* @param selfSelectionId 指数id
* @param pageNum 
* @param pageSize 
* @return Promise
* */
export function getStocksBySelfSelec(query) {
  return request({
    url: '/carbon-data/selfSelectionDetail/list',
    method: 'get',
    params: query
  })
}
/**
* 9.重命名自选清单
* @param id 自选清单id
* @param name 自选清单新名字
* @return Promise
* */
export function updateSelfSelection(data) {
  return request({
    url: '/carbon-data/selfSelection/modify',
    method: 'post',
    data
  })
}

/**
* 10.导入后--通过id获取股票信息
* @param id 导入后返回的id
* @param pageNum 
* @param pageSize 
* @return Promise
* */
export function getStocksByExport(query) {
  return request({
    url: '/carbon-data/dataBrowserImportDetail/list',
    method: 'get',
    params: query
  })
}
/**
* 11.导出
* @param indicatorList:[whichYear,indicator] 指标列表
* @param index 指数类型
* @param seccodeList:[] 股票代码列表
* @param pageNum 
* @param pageSize 
* @return Promise
* */
export function importData(data) {
  return request({
    url: '/carbon-data/stockCarbonIndicator/export',
    method: 'post',
    data
  })
}

/**
* 12.数据上传检查
* @param importId 
* @return Promise
* */
export function importCheck(data) {
  return request({
    url: '/carbon-data/stockCarbonIndicator/importCheck',
    method: 'post',
    data
  })
}
/**
* 13.导入-追加股票
* @param importId id
* @param stockList [seccode,secname,secnameEn,secnameEnFull]
* @return Promise
* */
export function addStockToImport(data) {
  return request({
    url: '/carbon-data/dataBrowserImportDetail/add',
    method: 'post',
    data
  })
}
