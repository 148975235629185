var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about-data" }, [
      _c("div", { staticClass: "clearfix" }, [
        _c(
          "div",
          { staticClass: "section section_title", attrs: { id: "comp-title" } },
          [
            _c(
              "div",
              {
                staticClass: "container",
                staticStyle: { "text-align": "center" },
              },
              [
                _c("span", { staticStyle: { color: "rgb(34, 34, 38)" } }, [
                  _vm._v("鼎力物理风险交互式地图集数据说明"),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "section section_text_editor",
          staticStyle: { "background-color": "rgb(255, 255, 255)" },
          attrs: { id: "textEditor" },
        },
        [
          _c(
            "div",
            {
              staticClass: "container",
              staticStyle: { "padding-left": "0px", "padding-right": "0px" },
            },
            [
              _c("div", [
                _c("div", { staticStyle: { "text-align": "right" } }, [
                  _c("h4", [_vm._v("更新日期：2023年6月30日")]),
                ]),
              ]),
              _c(
                "pre",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-wrap": "break-word",
                    "margin-top": "20px",
                  },
                },
                [
                  _vm._v(
                    "              鼎力物理风险交互式地图集所用数据来自于对公开数据的处理，数据源来自IPCC, WRI以及Climate Analytics。IPCC WGI Interactive Atlas和WRI Aqueduct的数据均在Creative Commons Attribution 4.0 International License下，Climate Analytics同样支持数据再使用。鼎力物理风险交互式地图集于此表示对这些公开数据的致谢，并遵循各数据源预期被引用的方式，将对应的链接列示如下。\n\n              "
                  ),
                  _c("p", [_c("strong", [_vm._v("数据来源Data Source:")])]),
                  _vm._v(
                    "\n              (1) Climate Analytics, 2022. Climate Impact Explorer. Available at: https://climate-impact-explorer.climateanalytics.org/\n              (2) WRI Aqueduct, accessed on [2023/06/01]https://www.wri.org/aqueduct\n              (3) IPCC WGI Interactive Atlas, available at: https://interactive-atlas.ipcc.ch/regional-information#eyJ0eXBlIjoiQVRMQVMiLCJjb21tb25zIjp7ImxhdCI6Mjg4MzM1NiwibG5nIjoyOTgwODQsInpvb20iOjMsInByb2oiOiJFUFNHOjU0MDMwIiwibW9kZSI6ImNvbXBsZXRlX2F0bGFzIn0sInByaW1hcnkiOnsic2NlbmFyaW8iOiJzc3AxMjYiLCJwZXJpb2QiOiJtZWRpdW0iLCJzZWFzb24iOiJ5ZWFyIiwiZGF0YXNldCI6IkNNSVA2IiwidmFyaWFibGUiOiJzbHIiLCJ2YWx1ZVR5cGUiOiJBTk9NQUxZIiwiaGF0Y2hpbmciOiJTSU1QTEUiLCJyZWdpb25TZXQiOiJhcjYiLCJiYXNlbGluZSI6IkFSNiIsInJlZ2lvbnNTZWxlY3RlZCI6W119LCJwbG90Ijp7ImFjdGl2ZVRhYiI6InBsdW1lIiwibWFzayI6Im5vbmUiLCJzY2F0dGVyWU1hZyI6bnVsbCwic2NhdHRlcllWYXIiOm51bGwsInNob3dpbmciOmZhbHNlfX0=\n            \n              "
                  ),
                  _c("p", [
                    _c("strong", [_vm._v("技术支持Technical Support:")]),
                  ]),
                  _vm._v(
                    "\n              (1)蚂蚁金服 AntV L7,available at: https://l7.antv.antgroup.com/tutorial/l7\n              (2)高德开放平台Amap API, available at: https://lbs.amap.com/\n\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }