<template>
  <el-form ref="form" :model="user" label-width="90px" label-position="left">
    <el-row>
      <el-col :xl="12">
        <!-- 用户昵称 -->
        <el-form-item :label="vm.$t('profile.nickName')">
          <p>{{ user.nickName }}</p>
        </el-form-item>
      </el-col>
      <el-col :lg="12">
        <!-- 用户邮箱 -->
        <el-form-item :label="$t('profile.email')">
          <p>{{ user.email }}</p>
          <!-- <el-input v-model="user.email" maxlength="50" /> -->
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xl="12">
        <!-- 公司名称 -->
        <el-form-item :label="$t('profile.deptName')">
          <p v-if="user.dept">{{ user.dept.deptName }}</p>
          <!-- <el-input v-if="user.dept" v-model="user.dept.deptName" maxlength="50" /> -->
        </el-form-item>
      </el-col>
      <el-col :xl="12">
        <!-- 公司职务 -->
        <el-form-item :label="vm.$t('profile.postGroup')">
          <p>{{ postGroup }}</p>
          <!-- <el-input v-model="postGroup" maxlength="50" /> -->
        </el-form-item>
      </el-col>
    </el-row>

    <!-- <el-form-item align='right'>
      <el-button type="primary" size="mini" @click="submit">保存</el-button>
      <el-button type="danger" size="mini" @click="close">关闭</el-button>
    </el-form-item> -->
  </el-form>
</template>

<script>
import { updateUserProfile } from "@/api/system/user";
export default {
  props: {
    user: {
      type: Object,
    },
    roleGroup: {
      type: [String, Object],
    },
    postGroup: {
      type: String,
    },
  },
  created() {
  },
  data() {
    const vm = window.vm; //多语言
    return {
      vm: vm, //多语言
      // 表单校验
      // rules: {
      //   email: [
      //     // "邮箱地址不能为空"
      //     { required: true, message: $t('rules.msg1'), trigger: "blur" },
      //     // 请输入正确的邮箱地址"
      //     {
      //       type: "email",
      //       message: $t('rules.msg2'),
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      // },
    };
  },
  methods: {
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let obj = {
            ...this.user,
            ...this.roleGroup,
          };
          updateUserProfile(obj).then((response) => {
            this.$modal.msgSuccess($t("tips.selfTip2"));
          });
        }
      });
    },
    close() {
      this.$tab.closePage();
    },
  },
};
</script>
