<template>
  <div>
    <template v-for="(item, index) in optionsArr">
      <template v-if="values.includes(item.id)">
        <span :key="item.label" :index="index">{{ item.label }}</span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "DictTagDL",
  props: {
    options1: {
      type: Array,
      default: () => {
        return {};
      },
    },
    options2: {
      type: Array,
      default: () => {
        return {};
      },
    },

    value: [Number, String, Array],
  },
  data() {
    return {
      optionsArr: [],
    };
  },
  watch: {},
  created() {
    this.getD();
  },
  computed: {
    values() {
      if (this.value !== null && typeof this.value !== "undefined") {
        return Array.isArray(this.value) ? this.value : [String(this.value)];
      } else {
        return [];
      }
    },
  },

  methods: {
    getD() {
      // let a = JSON.parse(JSON.stringify(this.options));
      this.optionsArr = [...this.options1, ...this.options2];
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>