<template>
  <div class="admin-center-page">
    <div class="page-title">
      <span style="font-size: 28px;">API清单</span>
      <div>
        <el-button type="primary" size="small" @click="handleAdd">新增</el-button>
        <el-button size="small" @click="handleBind">绑定用户</el-button>
      </div>
    </div>
    <div>
      <el-form :model="queryParams" inline label-width="100px">
        <el-form-item label="API名称">
          <el-input v-model="queryParams.apiName" placeholder="请输入"></el-input>
        </el-form-item>
        <!-- <el-form-item label="模板名称">
          <el-input v-model="queryParams.apiTemplateName" placeholder="请输入"></el-input>
        </el-form-item> -->
        <el-form-item label="状态">
          <el-select v-model="queryParams.apiStatus" clearable>
            <el-option label="开发中" :value="1"></el-option>
            <el-option label="已发布" :value="2"></el-option>
            <el-option label="已停用" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-area common-table-style">
      <el-table :data="tableData" height="100%" stripe>
        <el-table-column label="API名称" prop="apiName" show-overflow-tooltip></el-table-column>
        <el-table-column label="版本" prop="apiVersion" show-overflow-tooltip width="80"></el-table-column>
        <!-- <el-table-column label="模板名称" prop="apiTemplateName" show-overflow-tooltip></el-table-column> -->
        <el-table-column label="数据库" prop="apiCategoryGroup" show-overflow-tooltip width="160"></el-table-column>
        <el-table-column label="请求方法" prop="apiRequestMethod" show-overflow-tooltip width="100"></el-table-column>
        <el-table-column label="状态" width="120">
          <template #default="{ row }">
            <span>
              <el-tag v-if="row.apiStatus === 1">开发中</el-tag>
              <el-tag v-else-if="row.apiStatus === 2" type="success">已发布</el-tag>
              <el-tag v-else-if="row.apiStatus === 3" type="danger">已停用</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="描述" prop="apiDescription" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="180">
          <template #default="{ row }">
            <el-button v-if="row.apiStatus !== 3" type="text" size="small" @click="handleEdit(row)">编辑</el-button>
            <el-button v-if="row.apiStatus === 1" type="text" size="small" @click="handleRelease(row)">发布</el-button>
            <el-button v-if="row.apiStatus === 2" type="text" size="small" @click="handleStop(row)">停用</el-button>
            <el-button type="text" size="small" @click="handleDelete(row)">删除</el-button>
            <el-button type="text" size="small" @click="handleCopyCurl(row)">复制curl</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageIndex"
      :limit.sync="queryParams.pageSize"
      @pagination="getTableData"
    />
    <apiEditDrawer
      :visible="apiEditDrawerVisible"
      :apiId="currentApiId"
      @saveSuccess="getTableData"
      @close="apiEditDrawerVisible = false"
    />
    <apiCheckDialog
      v-if="apiCheckDialogVisible"
      :visible="apiCheckDialogVisible"
      :apiId="currentApiId"
      @confirmRelease="confirmRelease"
      @dialogClose="apiCheckDialogVisible = false"
    />
    <bindingUserTreeDialog
      v-if="bindingUserTreeDialogVisible"
      :visible="bindingUserTreeDialogVisible"
      @dialogClose="bindingUserTreeDialogVisible = false"
    />
  </div>
</template>
<script>
import { getApiMetaListWithPaging, onlineApi, offlineApi, deleteConfigApiByApiId, exportCurlCommand } from "@/api/data-platform/admin-center.js";
import apiEditDrawer from "./child/apiEditDrawer.vue";
import apiCheckDialog from './child/apiCheckDialog.vue';
import bindingUserTreeDialog from './child/bindingUserTreeDialog.vue';
// 分页组件
import pagination from "@/components/Pagination";
export default {
  name: 'adminCenter',
  components: {
    apiEditDrawer,
    apiCheckDialog,
    pagination,
    bindingUserTreeDialog
  },
  data () {
    return {
      tableData: [],
      apiEditDrawerVisible: false,
      currentApiId: '',
      apiCheckDialogVisible: false,
      total: 0,
      queryParams: {
        pageIndex: 1,
        pageSize: 20,
        apiName: '',
        apiTemplateName: '',
        apiStatus: ''
      },
      bindingUserTreeDialogVisible: false
    }
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 查询
    handleSearch () {
      this.queryParams.pageIndex = 1;
      this.getTableData();
    },
    // 查询表格数据
    getTableData () {
      const params = {
        ...this.queryParams
      }
      getApiMetaListWithPaging(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records || [];
          this.total = res.data.total;
        }
      })
    },
    handleBind () {
      this.bindingUserTreeDialogVisible = true;
    },
    handleAdd () {
      this.currentApiId = '';
      this.apiEditDrawerVisible = true;
    },
    handleEdit (row) {
      this.currentApiId = row.apiId;
      this.apiEditDrawerVisible = true;
    },
    confirmRelease () {
      onlineApi({apiId: this.currentApiId}).then((res) => {
        if (res.code === 200) {
          this.$modal.msgSuccess('发布成功');
          this.getTableData();
        }
      })
    },
    // 发布
    handleRelease (row) {
      this.$modal.confirm('请确认是否发布此API？', '确定').then(() => {
        this.currentApiId = row.apiId;
        this.apiCheckDialogVisible = true;
      }).catch(function() {
      });
    },
    // 停用
    handleStop (row) {
      this.$modal.confirm('请确认是否停用此API？', '确定').then(() => {
        offlineApi({apiId: row.apiId}).then((res) => {
          if (res.code === 200) {
            this.$modal.msgSuccess('发布成功');
            this.getTableData();
          }
        })
      }).catch(function() {
      });
    },
    // 删除
    handleDelete (row) {
      this.$modal.confirm('请确认是否删除此API？', '确定').then(() => {
        deleteConfigApiByApiId({apiId: row.apiId}).then((res) => {
          if (res.code === 200) {
            this.$modal.msgSuccess('删除成功');
            this.getTableData();
          }
        })
      }).catch(function() {
      });
    },
    // 复制curl
    handleCopyCurl (row) {
      exportCurlCommand(row.apiId).then((res) => {
        if (res.code === 200) {
          navigator.clipboard.writeText(res.data).then(() => {
            this.$message.success('复制成功')
          })
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.admin-center-page {
  padding: 20px;
  background-color: #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  .page-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e7ed;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .table-area {
    flex: 1;
    height: 0;
  }
}
</style>
