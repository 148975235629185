var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about-data" }, [
      _c("div", { staticClass: "clearfix" }, [
        _c(
          "div",
          { staticClass: "section section_title", attrs: { id: "comp-title" } },
          [
            _c(
              "div",
              {
                staticClass: "container",
                staticStyle: { "text-align": "center" },
              },
              [
                _c("span", { staticStyle: { color: "rgb(34, 34, 38)" } }, [
                  _vm._v(
                    "汇丰中国 双碳目标下中国高排放行业低碳转型支持公益项目说明"
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "section section_text_editor",
          staticStyle: { "background-color": "rgb(255, 255, 255)" },
          attrs: { id: "textEditor" },
        },
        [
          _c(
            "div",
            {
              staticClass: "container",
              staticStyle: { "padding-left": "0px", "padding-right": "0px" },
            },
            [
              _c(
                "pre",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-wrap": "break-word",
                    "margin-top": "20px",
                  },
                },
                [
                  _vm._v(" \n              "),
                  _c("p", [_c("strong", [_vm._v("地图集工具说明")])]),
                  _vm._v(
                    "\n              本页面所提供物理风险交互式地图集在汇丰中国双碳目标下中国高排放行业低碳转型支持公益项目的支持下完成开发，免费服务高排放行业企业，帮助企业开展因气温升高、海平面上升等多重物理因素驱动导致的气候相关物理风险的识别与评估，为企业选定最大相关性的气候物理风险评估参数提供方向性指引。\n              "
                  ),
                  _c("p", [_c("strong", [_vm._v("项目说明")])]),
                  _vm._v(
                    "\n              本公益项目基于石化、电力、化工、钢铁、建材等高排放重点行业的净零转型路线图，聚焦于高排放行业低碳转型中面临的资金支持难获取、技术路线不明确、低碳资源未对接等挑战，通过开发一系列实用的指导工具和试点项目，从前端披露、过程管理和产业链传导三个方面，推动中国高排放行业的低碳转型。\n              汇丰中国资助本项目研究，旨在为高排放行业提供低碳转型三大问题的解决方案：一是提升高排放行业气候相关信息披露工作，建立实用可操作的指标、方法和工具，推进重点企业开展气候风险识别、量化、监控和报告，助力转型金融资金流入高排放行业企业低碳转型领域；二是探索通过提升需求侧采购要求推动上游高排放行业低碳转型的路径，聚焦特殊高排放行业绿色转型的推荐技术，支持突破性低碳技术的发展与应用；三是加强利益相关方和产业链融合发展，探索跨行业资源循环利用的潜力，准确对接低碳资源和企业需求。\n              该公益项目成果直接服务于高排放行业企业，并使全国不同年龄段各地区人口作为工业产品的消费者和中国产业低碳转型的受益者间接受益，也将在地区探索产业低碳融合发展模式、行业制定低碳转型政策、金融监管机构支持重点行业减排研究、气候及绿色投融资研究等多领域持续发挥作用。\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }