<template>
  <div>
    <!-- 当路由是碳足迹时，显示该table -->
    <el-table
      v-if="flag === 'FootPrint'"
      class="fileTable"
      :data="rows"
      style="width: 100%"
      stripe
      fit
      :header-row-class-name="tableHead"
      v-loading="loading"
    >
      <template slot="empty">
        <span
          >Your investment list is currently empty, please upload your file
          first.</span
        >
      </template>
      <el-table-column
        align="center"
        min-width="120px"
        prop="portfolioDate"
        :label="$t('processed.portfolioDate')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="100px"
        prop="fundId"
        :label="$t('processed.fundId')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="200px"
        prop="fundName"
        :label="$t('processed.fundName')"
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="isin"
        min-width="130px"
        :label="$t('processed.isin')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="130px"
        prop="ticker"
        :label="$t('processed.ticker')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="200px"
        prop="securityName"
        :label="$t('processed.securityName')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="140px"
        prop="totalNetAsset"
        :label="$t('processed.totalNetAsset')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="120px"
        prop="assetType"
        :label="$t('processed.assetType')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="currency"
        min-width="120px"
        :label="$t('processed.currency')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="shares"
        :label="$t('processed.shares')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="160px"
        prop="marketValue"
        :label="$t('processed.marketValue', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="240px"
        prop="scope12Emissions"
        :label="$t('processed.scope12Emissions')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="revenue"
        min-width="180px"
        :label="$t('processed.revenue', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="intensity"
        min-width="195px"
        :label="$t('processed.intensity', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="150px"
        prop="weighting"
        :label="$t('processed.weighting')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="160px"
        prop="shadowCost"
        :label="$t('processed.shadowCost', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="160px"
        prop="ebitda"
        :label="$t('processed.ebitda', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="150px"
        prop="evic"
        :label="$t('processed.evic', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="140px"
        prop="allocationFactor"
        :label="$t('processed.allocationFactor')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="190px"
        prop="allocatedAbs"
        :label="$t('processed.allocatedAbs')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="160px"
        prop="emissionDataType"
        :label="$t('processed.emissionDataType')"
      >
      </el-table-column>
    </el-table>

    <el-table
      v-else
      class="fileTable"
      :data="rows"
      style="width: 100%"
      stripe
      fit
      :header-row-class-name="tableHead"
      v-loading="loading"
    >
      <template slot="empty">
        <span
          >Your investment list is currently empty, please upload your file
          first.</span
        >
      </template>
      <el-table-column
        align="center"
        min-width="120px"
        prop="portfolioDate"
        :label="$t('processed.portfolioDate')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="100px"
        prop="fundId"
        :label="$t('processed.fundId')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="200px"
        prop="fundName"
        :label="$t('processed.fundName')"
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="isin"
        min-width="130px"
        :label="$t('processed.isin')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="130px"
        prop="ticker"
        :label="$t('processed.ticker')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="200px"
        prop="securityName"
        :label="$t('processed.securityName')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="140px"
        prop="totalNetAsset"
        :label="$t('processed.totalNetAsset')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="120px"
        prop="assetType"
        :label="$t('processed.assetType')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="currency"
        min-width="120px"
        :label="$t('processed.currency')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="shares"
        :label="$t('processed.shares')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="160px"
        prop="marketValue"
        :label="$t('processed.marketValue', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="240px"
        prop="scope12Emissions"
        :label="$t('processed.scope12Emissions')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="revenue"
        min-width="180px"
        :label="$t('processed.revenue', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="intensity"
        min-width="195px"
        :label="$t('processed.intensity', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="150px"
        prop="weighting"
        :label="$t('processed.weighting')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="160px"
        prop="shadowCost"
        :label="$t('processed.shadowCost', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="160px"
        prop="ebitda"
        :label="$t('processed.ebitda', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="150px"
        prop="evic"
        :label="$t('processed.evic', [unit])"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="140px"
        prop="allocationFactor"
        :label="$t('processed.allocationFactor')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="190px"
        prop="allocatedAbs"
        :label="$t('processed.allocatedAbs')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="160px"
        prop="emissionDataType"
        :label="$t('processed.emissionDataType')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="180px"
        prop="sasbIndustry"
        :label="$t('processed.sasbIndustry')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="120px"
        prop="physicalRiskScore"
        :label="$t('processed.physicalRiskScore')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="120px"
        prop="transitionRiskScoreA"
        :label="$t('processed.transitionRiskScoreA')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="220px"
        prop="transitionRiskScoreB"
        :label="$t('processed.transitionRiskScoreB')"
      >
      </el-table-column>
    </el-table>

    <div class="text">
      {{ renderNote }}
    </div>
    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :total="renderData.total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getCalculateResultList } from "@/api/data-platform/FMCC";

export default {
  props: ["baseFormData", "id", "note", "flag"],
  data() {
    let vm = window.vm;
    return {
      vm: vm,
      fileData: null,
      val: 1,
      loading: false,
    };
  },
  computed: {
    // 数据处理
    renderData() {
      let res = {};
      if (this.fileData && this.fileData.rows) {
        // console.log(this.fileData)
        res = {
          ...this.fileData,
        };
        res.rows = this.fileData.rows.map((item) => {
          return {
            ...item,
            weighting: Number(item.weighting * 100).toFixed(2) + "%",
            allocationFactor: item.allocationFactor
              ? Number(item.allocationFactor * 100).toFixed(5) + "%"
              : null,
            transitionRiskScoreB: item.transitionRiskScoreB
              ? Number(item.transitionRiskScoreB * 100).toFixed(3) + "%"
              : null,
          };
        });
      }
      // console.log(res)
      return res;
    },
    lang() {
      return this.$store.getters.language;
    },
    // 单位拼接
    unit() {
      if (this.fileData && this.fileData.rows) {
        return this.fileData.rows[0].currency;
      } else {
        return null;
      }
    },
    // 提取渲染数据
    rows() {
      let res = [];
      if (this.renderData && this.renderData.rows) {
        res = this.renderData.rows;
      }
      return res;
    },
    // note格式化
    renderNote() {
      if (this.note) {
        if (this.note.indexOf("Warning") > -1) {
        // if (this.lang === "en") {
          return this.note
            .replace(/<\/?.+?\/?>/g, "")
            .replace("Warning", "Note")
            .split("Do")[0]
            .replace(",", "; ");
        } else if (this.note.indexOf("警告") > -1) {
          return this.note
            .replace(/<\/?.+?\/?>/g, "")
            .replace("警告", "注意")
            .split("请问")[0]
            .replace(",", "; ");
        }
      } else {
        return null;
      }
    },
  },
  watch: {
    lang(newVal, old) {
      if (this.id && this.baseFormData) {
        this.getCalculate();
      }
    },
    
  },
  // mounted() {
  //   this.getCalculate()
  // },
  methods: {
    // 样式
    tableHead() {
      return "tableHead";
    },
    // 分页切换
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.val = val;
      this.getCalculate();
    },
    // 获取数据
    getCalculate(data) {
      this.loading = true;
      // console.log(data);
      // console.log(this.baseFormData);
      let paramsData = data ? data : this.baseFormData;
      getCalculateResultList({
        id: this.id,
        pageNum: this.val,
        ...paramsData,
        lang: this.lang,
      }).then((res) => {
        this.fileData = res;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@mixin fontStyle {
  font-family: "PingFang SC";
  font-weight: 900;
}

.fileTable {
  ::v-deep .tableHead th {
    @include fontStyle;
    height: 70px;
    font-size: 14px;
    color: #1f5080;
    background: #dfebf9;
  }

  ::v-deep .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 40px;
    line-height: 20px;
    font-family: "PingFang SC";
    font-size: 14px;
    color: #1f5080;
  }
  ::v-deep th .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
  }
}

.pagination {
  margin: 10px 10px 30px 0;
  text-align: right;
}
::v-deep .el-pager li {
  font-weight: 400;
}
.text {
  padding: 16px 30px 0px 30px;
  font-family: "PingFang SC";
  font-size: 14px;
  line-height: 20px;
  color: #1f5080;
}
</style>
