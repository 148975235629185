import { render, staticRenderFns } from "./tabsForFootprint.vue?vue&type=template&id=3629fa9e&scoped=true"
import script from "./tabsForFootprint.vue?vue&type=script&lang=js"
export * from "./tabsForFootprint.vue?vue&type=script&lang=js"
import style0 from "./tabsForFootprint.vue?vue&type=style&index=0&id=3629fa9e&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3629fa9e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/data-platform-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3629fa9e')) {
      api.createRecord('3629fa9e', component.options)
    } else {
      api.reload('3629fa9e', component.options)
    }
    module.hot.accept("./tabsForFootprint.vue?vue&type=template&id=3629fa9e&scoped=true", function () {
      api.rerender('3629fa9e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/data-platform/carbon/components/tabsForFootprint.vue"
export default component.exports