// 回显用户名
export function selectUserName(datas, value) {
    if (value === undefined) {
      return "";
    }
    var actions = [];
    Object.keys(datas).some((key) => {
      if (datas[key].userId==value) {
        actions.push(datas[key].nickName);
        return true;
      }
    })
    if (actions.length === 0) {
      actions.push(value);
    }
    return actions.join('');
  }

// 回显数据点名
export function selectDataPointName(datas, value) {
    if (value === undefined) {
      return "";
    }
    var actions = [];
    Object.keys(datas).some((key) => {
      if (datas[key].dataPointId==value) {
        actions.push(datas[key].dataPointName);
        return true;
      }
    })
    if (actions.length === 0) {
      actions.push(value);
    }
    return actions.join('');
  }

// 获取一个数据点的所有信息
export function selectDataPointObj(datas, value) {
  if (value === undefined) {
    return "";
  }

  for(let item of datas)
  {
    if(item.dataPointId==value)
    {
      return item;
    }
  }
  return "";
}
//通过list通过value找到label
export function listValue2label(datas, value) {
  if (value === undefined) {
    return "";
  }
  for(let item of datas)
  {
    if(item.value==value)
    {
      return item.label;
    }
  }
  return value;
}
// 获取一个任务数据点的Note
export function selectTaskDataPointNoteObj(datas, value) {
  if (value === undefined) {
    return "";
  }

  for(let item of datas)
  {
    if(item.dataPointId==value)
    {
      return item;
    }
  }
  return "";
}

// 获取一个任务数据点的Attribute
export function selectTaskDataPointAttributeObj(datas, value) {
  if (value === undefined) {
    return "";
  }

  for(let item of datas)
  {
    if(item.attributeId==value)
    {
      return item;
    }
  }
  return "";
}

