var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tree-container" }, [
    _c("p", { staticClass: "tree-top-title" }, [_vm._v(_vm._s(_vm.treeTitle))]),
    _c(
      "div",
      { staticClass: "tree-content" },
      _vm._l(_vm.treeList, function (item, index) {
        return _c("div", { key: index }, [
          _c(
            "div",
            { staticClass: "head-container tree-box" },
            [
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isLazy ? _vm.loading : "",
                    expression: "isLazy ? loading : ''",
                  },
                ],
                ref: "tree",
                refInFor: true,
                staticClass: "tree-d",
                attrs: {
                  data: item.arr,
                  "node-key": "id",
                  props: _vm.defaultProps,
                  "expand-on-click-node": true,
                  "filter-node-method": _vm.filterNode,
                  "default-expand-all": "",
                  accordio: "",
                  "highlight-current": "",
                },
                on: {
                  "node-click": _vm.handleNodeClick,
                  "node-contextmenu": _vm.rightClick,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c("span", { staticClass: "span__" }, [
                          _c("div", { staticClass: "children-list" }, [
                            !data.children
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { title: node.label },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "el-icon-second" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            slot: "prefix",
                                            "icon-class": "stock-market",
                                          },
                                          slot: "prefix",
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("span", [_vm._v(_vm._s(node.label))]),
                                  ]
                                )
                              : data.flag === "selfSelect"
                              ? _c("span", { staticClass: "tree-node-span" }, [
                                  _c(
                                    "div",
                                    { staticClass: "el-icon-indicator" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class": "subtract",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("selfSelect.label"))),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "el-icon-arrow-blue" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class": "arrow",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _c("span", { staticClass: "tree-node-span" }, [
                                  data.type === "carbon"
                                    ? _c(
                                        "div",
                                        { staticClass: "el-icon-indicator" },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              slot: "prefix",
                                              "icon-class": "carbon",
                                            },
                                            slot: "prefix",
                                          }),
                                        ],
                                        1
                                      )
                                    : data.type === "climate"
                                    ? _c(
                                        "div",
                                        { staticClass: "el-icon-indicator" },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              slot: "prefix",
                                              "icon-class": "climate",
                                            },
                                            slot: "prefix",
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(_vm._s(node.label) + " "),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "el-icon-arrow-blue" },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class": "arrow",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }