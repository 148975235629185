var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "sub-el-menu",
      attrs: {
        "default-active": _vm.activeSubMenu,
        mode: "horizontal",
        "active-text-color": "#ffd04b",
      },
      on: { select: _vm.handleSubSelect },
    },
    [
      _vm._l(_vm.topSubMenus, function (item, index) {
        return [
          _c("el-menu-item", { key: index, attrs: { index: item.path } }, [
            _vm._v(" " + _vm._s(item.meta.title) + " "),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }