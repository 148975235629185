<template>
  <div class="table_box" v-loading="loading">
    <el-table
      class="fileTable"
      :data="renderData.rows"
      style="width: 100%"
      stripe
      :header-row-class-name="tableHead"
    >
      <template slot="empty">
        <span>{{$t('fileTable.nodataText')}}</span>
      </template>
      <el-table-column
        align="center"
        min-width="120px"
        prop="portfolioDate"
        :label="$t('fileTable.portfolioDate')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="100px"
        prop="fundId"
        :label="$t('fileTable.fundId')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="140px"
        prop="fundName"
        :label="$t('fileTable.fundName')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="140px"
        prop="totalNetAsset"
        :label="$t('fileTable.totalNetAsset')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="130px"
        prop="isin"
        :label="$t('fileTable.isin')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="130px"
        prop="ticker"
        :label="$t('fileTable.ticker')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="200px"
        prop="securityName"
        :label="$t('fileTable.securityName')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="shares"
        :label="$t('fileTable.shares')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="160px"
        prop="marketValue"
        :label="$t('fileTable.marketValue')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="120px"
        prop="navCurrency"
        :label="$t('fileTable.currency')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        min-width="120px"
        prop="assetType"
        :label="$t('fileTable.assetType')"
      >
      </el-table-column>

    </el-table>
    <el-pagination
      v-if="renderData.total"
      class="pagination"
      background
      layout="prev, pager, next"
      :total="renderData.total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getList } from '@/api/data-platform/FMCC'

export default {
  name: 'fileTable',
  props: ['data', 'id'],
  data() {
    return {
      fileData: null,
      val: 1,
      loading: false
    }
  },
  computed: {
    // 数据处理
    renderData() {
      return this.fileData ? this.fileData : this.data
    },
    lang() {
      return this.$store.getters.language
    }
  },
  watch: {
    // 语言切换重新请求
    lang(newVal, old) {
      if(this.id) {
        this.getList()
      }
    }
  },
  methods: {
    // 样式
    tableHead() {
      return 'tableHead'
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.val = val
      this.getList()
    },
    getList() {
      this.loading = true
      getList({
        id: this.id,
        pageNum: this.val,
        lang: this.lang
      }).then(res => {
        this.fileData = res
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
@mixin fontStyle {
  font-family: 'PingFang SC';
  font-weight: 900;
}

::v-deep .el-table::before {
  height: 0;
}

.fileTable {
  border-radius: 6px;

  ::v-deep .tableHead th {
    @include fontStyle;
    height: 40px;
    font-size: 14px;
    color: #01579B;
    background: #DFEBF9;
  }

  //无数据文案
  ::v-deep .el-table__empty-text {
    display: inline-block;
    height: 278px;
    line-height: 278px;
    width: 100%;
    font-size: 24px;
    color: #01579B;
    background-color: #f5f6f8;
    @include fontStyle;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #4598F7;
  }
}

.pagination {
  margin: 10px 10px 40px 0;
  text-align: right;
}
::v-deep .el-pager li {
  font-weight: 400;
}
</style>
