var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "top-menus",
      attrs: {
        "default-active": _vm.activeMenu,
        mode: "horizontal",
        "active-text-color": "#fff",
      },
    },
    [
      _vm._l(_vm.topMenus, function (item, index) {
        return [
          !item.children
            ? _c(
                "el-menu-item",
                {
                  key: index,
                  staticClass: "menu-no-child",
                  attrs: { index: item.path },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleSelect(item)
                    },
                  },
                },
                [
                  _vm.$store.getters.language === "en"
                    ? _c("span", [_vm._v(" " + _vm._s(item.nameEn))])
                    : _c("span", [_vm._v(_vm._s(item.meta.title))]),
                ]
              )
            : _c(
                "el-submenu",
                {
                  key: index,
                  staticClass: "dropdown-container",
                  class: _vm.activeMenu === item.path ? "is-active" : "",
                  attrs: { index: item.path },
                },
                [
                  _c("template", { slot: "title" }, [
                    _vm.$store.getters.language === "en"
                      ? _c("span", [_vm._v(" " + _vm._s(item.nameEn))])
                      : _c("span", [_vm._v(_vm._s(item.meta.title))]),
                    _c(
                      "div",
                      { staticClass: "el-arrow-white" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            slot: "prefix",
                            "icon-class": "arrow-white",
                          },
                          slot: "prefix",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._l(item.children, function (dropdownItem) {
                    return _c(
                      "el-menu-item",
                      {
                        key: dropdownItem.path,
                        staticClass: "dropdown-li",
                        class: _vm.currentRouteSplice.includes(
                          dropdownItem.path
                        )
                          ? "is-active"
                          : "",
                        attrs: { index: dropdownItem.path },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleSelect(dropdownItem)
                          },
                        },
                      },
                      [
                        _vm.$store.getters.language === "en"
                          ? _c("span", [
                              _vm._v(" " + _vm._s(dropdownItem.nameEn)),
                            ])
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(dropdownItem.meta.title) + " "
                              ),
                            ]),
                      ]
                    )
                  }),
                ],
                2
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }