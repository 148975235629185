<template>
  <div id="bar_box3" ref="imageDom"></div>
</template>

<script>
import {
  downloadReport,
  downloadReportFootPrint,
  portfolioCarbonFootprint,
} from "@/api/data-platform/FMCC";
import * as echarts from "echarts";
import html2canvas from "html2canvas";

export default {
  props: ["baseFormData", "id", "fatherFlag"],
  name: "echarts",
  data() {
    return {
      portfolioCarbonFootprint: null,
    };
  },
  computed: {
    weightings() {
      let res = [];
      if (
        this.portfolioCarbonFootprint &&
        this.portfolioCarbonFootprint.sasbClassification
      ) {
        this.portfolioCarbonFootprint.sasbClassification.forEach((item) => {
          res.push(item.weightings);
        });
      }
      return res;
    },
    emissionShares() {
      let res = [];
      if (
        this.portfolioCarbonFootprint &&
        this.portfolioCarbonFootprint.sasbClassification
      ) {
        this.portfolioCarbonFootprint.sasbClassification.forEach((item) => {
          res.push(item.emissionShares);
        });
      }
      return res;
    },
    sasb() {
      let res = [];
      if (
        this.portfolioCarbonFootprint &&
        this.portfolioCarbonFootprint.sasbClassification
      ) {
        this.portfolioCarbonFootprint.sasbClassification.forEach((item) => {
          res.push(item.sasb);
        });
      }
      return res;
    },
    lang() {
      return this.$store.getters.language;
    },
  },
  watch: {
    lang(newVal, old) {
      this.getFootprint();
    },
  },
  mounted() {
    console.log(1);
    this.getFootprint().then(() => {
      this.$nextTick(() => {
        this.init();
      });
    });
  },
  methods: {
    // 生成图片
    clickGeneratePicture() {
      //生成图片
      return new Promise((resolve, reject) => {
        html2canvas(this.$refs.imageDom).then((canvas) => {
          // 转成图片，生成图片地址
          let imgUrl = canvas.toDataURL("image/png"); //可将 canvas 转为 base64 格式
          resolve(imgUrl);
        });
      });
    },
    // 下载FMCC报告
    downloadFMCC() {
      this.clickGeneratePicture().then((res) => {
        console.log(res);
        downloadReport({
          id: this.id,
          ...this.baseFormData,
          lang: this.lang,
          eCharts: res,
        }).then((res) => {
          this.createExcel(res, this.lang == "zh" ? "fmcc报告" : "fmcc-report");
        });
      });
    },
    // 下载碳足迹报告
    downloadFootPrint() {
      this.clickGeneratePicture().then((res) => {
        console.log(res);
        downloadReportFootPrint({
          id: this.id,
          ...this.baseFormData,
          lang: this.lang,
          eCharts: res,
        }).then((res) => {
          this.createExcel(
            res,
            this.lang == "zh" ? "碳足迹测算报告" : "Carbon Footprint Report"
          );
        });
      });
    },
    createExcel(res, name) {
      let blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let fileName = name + ".xlsx";
      // 允许用户在客户端上保存文件
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        //释放内存
        window.URL.revokeObjectURL(link.href);
        this.$emit("downloaded");
      }
    },
    getFootprint() {
      return new Promise((resolve) => {
        portfolioCarbonFootprint({
          id: this.id,
          ...this.baseFormData,
          lang: this.lang,
        }).then((res) => {
          this.portfolioCarbonFootprint = res.data;
          resolve();
        });
      });
    },
    // 一打开就触发
    init() {
      console.log(this.fatherFlag, "oooo");
      let chartDom = document.getElementById("bar_box3");
      let myChart = echarts.init(chartDom);
      console.log(myChart);
      myChart.resize({ height: this.sasb.length * 14 + 120 });
      let option;

      option = {
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'shadow'
        //   }
        // },
        animation: false,
        legend: {
          bottom: 20,
        },
        grid: {
          top: 40,
          left: "8%",
          right: "4%",
          bottom: "50",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisLabel: {
            formatter: function (value) {
              return Number(value * 100).toFixed(2) + "%";
            },
          },
        },
        yAxis: {
          type: "category",
          data: this.sasb,
          axisLabel: {
            interval: 0,
          },
        },
        series: [
          {
            name: this.lang === "en" ? "Weightings" : "占基金资产净值比例",
            type: "bar",
            data: this.weightings,
          },
          {
            name: this.lang === "en" ? "Emission shares" : "排放量分配占比",
            type: "bar",
            data: this.emissionShares,
          },
        ],
      };
      option && myChart.setOption(option, true);
      myChart.finished =
        this.fatherFlag && this.fatherFlag === "footprint"
          ? this.downloadFootPrint()
          : this.downloadFMCC();
    },
  },
};
</script>

<style scoped>
#bar_box3 {
  width: 100%;
  min-height: 120px;
}
</style>
