<template>
  <!-- 顶部一级菜单 -->
  <el-menu
    :default-active="activeMenu"
    mode="horizontal"
    active-text-color="#fff"
    class="top-menus"
  >
    <template v-for="(item, index) in topMenus">
      <!-- 无子菜单 -->
      <el-menu-item
        v-if="!item.children"
        :index="item.path"
        :key="index"
        @click.native="handleSelect(item)"
        class="menu-no-child"
      >
        <span v-if="$store.getters.language === 'en'"> {{ item.nameEn }}</span>
        <span v-else>{{ item.meta.title }}</span>
      </el-menu-item>

      <!-- 下拉菜单 -->
      <el-submenu
        v-else
        :index="item.path"
        :key="index"
        class="dropdown-container"
        :class="activeMenu === item.path ? 'is-active' : ''"
      >
        <!-- 一级菜单 -->
        <template slot="title">
          <span v-if="$store.getters.language === 'en'">
            {{ item.nameEn }}</span
          >
          <span v-else>{{ item.meta.title }}</span>
          <!-- 小图标 -->
          <div class="el-arrow-white">
            <svg-icon slot="prefix" icon-class="arrow-white" />
          </div>
        </template>

        <!-- 二级菜单 -->
        <el-menu-item
          class="dropdown-li"
          v-for="dropdownItem in item.children"
          :index="dropdownItem.path"
          :key="dropdownItem.path"
          :class="
            currentRouteSplice.includes(dropdownItem.path) ? 'is-active' : ''
          "
          @click.native.prevent="handleSelect(dropdownItem)"
        >
          <span v-if="$store.getters.language === 'en'">
            {{ dropdownItem.nameEn }}</span
          >
          <span v-else> {{ dropdownItem.meta.title }} </span>
        </el-menu-item>
      </el-submenu>
    </template>
  </el-menu>
</template>

<script>
import { constantRoutes } from "@/router";

// 隐藏侧边栏路由
const hideList = ["/index", "/user/profile"];
export default {
  data() {
    return {
      // 顶部栏初始数
      visibleNumber: 7,
      // 当前激活菜单的 index
      currentIndex: undefined,
      parentPathsArr: [],
      // defaultOpen: [], // 默认展开菜单项
      currentRouteSplice: "",
    };
  },
  computed: {
    userName () {
      return this.$store.state.user.name;
    },
    theme() {
      return this.$store.state.settings.theme;
    },
    // 所有的路由信息
    routers() {
      return this.$store.state.permission.topbarRouters;
    },
    // 数据平台路由
    dataPlatformRouters() {
      return this.$store.state.permission.dataPlatformRoutes;
    },

    // 顶部显示菜单
    topMenus() {
      let topMenus = [];
      let subActiveMenu = "";
      const route = this.$route;
      const { meta } = route;
      if (meta.activeMenu) {
        subActiveMenu = meta.activeMenu;
      }
      this.routers.map((menu) => {
        if (menu.hidden !== true) {
          if (menu.name === "Data-platform") {
            menu.children.forEach((item) => {
              //一级路由
              if (
                item.children &&
                item.children.length > 0 &&
                item.name === "Carbon"
              ) {
                //二级路由
                item.path = subActiveMenu || "/data-platform/carbon";
              }
              topMenus.push(item);
            });
          }
        }
      });
      this.parentPathsArr = topMenus;
      if (this.userName === 'DataViewer' || this.userName === 'DataUser') {
        return []
      }
      return topMenus;
    },

    // 设置子路由
    childrenMenus() {
      var childrenMenus = [];
      this.routers.map((router) => {
        for (var item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === "/") {
              router.children[item].path = "/" + router.children[item].path;
            } else {
              if (!this.ishttp(router.children[item].path)) {
                router.children[item].path =
                  router.path + "/" + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    },
    // 默认激活的菜单
    activeMenu() {
      const path = this.$route.path;
      this.currentRouteSplice = path.split("/");
      const route = this.$route;
      const { meta } = route;
      let subActiveMenu = "";
      if (meta.activeMenu) {
        subActiveMenu = meta.activeMenu;
        this.$store.commit("SET_CURRENT_ROUTER", subActiveMenu);
        this.activeRoutes(subActiveMenu); //激活高亮菜单
        return meta.activeMenu;
      }

      let parent = path.substring(0, path.lastIndexOf("/"));
      let activePath = path;

      if (
        path !== undefined &&
        path.lastIndexOf("/") > 0 &&
        hideList.indexOf(path) === -1
      ) {
        const tmpPath = path.substring(1, path.length);

        //所有platform
        if (tmpPath.substring(0, tmpPath.indexOf("/")) == "data-platform") {
          activePath = "/" + tmpPath.substring(0);
        } else {
          activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
        }
      } else if (!this.$route.children) {
        activePath = path;
      }

      this.activeRoutes(activePath);
      this.$store.commit("SET_CURRENT_ROUTER", activePath);
      console.log("activePath:", activePath);
      return activePath;
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.setVisibleNumber);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setVisibleNumber);
  },
  mounted() {
    this.setVisibleNumber();
  },
  methods: {
    // 根据宽度计算设置显示栏数
    setVisibleNumber() {
      const width = document.body.getBoundingClientRect().width / 3;
      this.visibleNumber = parseInt(width / 85);
    },

    // 一级菜单选择事件
    handleSelect(obj) {
      let key = obj.name;
      const route = this.dataPlatformRouters.find((item) => {
        if (item.name === key) {
          return item;
        } else if (item.children && item.children.length > 0) {
          return item.children.find((c) => {
            return c.name === key;
          });
        }
      });

      if (this.ishttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (!route || !route.children) {
        // 没有子路由路径内部打开
        this.$router.push({ path: "/data-platform/" + key.toLowerCase() });
        this.currentIndex = "/data-platform/" + key.toLowerCase();
      } else {
        this.$router.push({ path: "/data-platform/" + route.name + "/" + key });
        this.currentIndex = "/data-platform/" + route.name + "/" + key;
        // 显示左侧联动菜单
        this.activeRoutes(key);
      }
      this.$store.commit("SET_CURRENT_ROUTER", this.currentIndex);
    },

    // 当前激活的路由
    activeRoutes(key) {
      var routes = [];
      if (this.childrenMenus && this.childrenMenus.length > 0) {
        this.childrenMenus.map((item) => {
          if (
            key == item.parentPath ||
            (key == "index" && "" == item.path) ||
            item.parentPath == "/data-platform"
          ) {
            routes.push(item);
          }
        });
      }
      if (routes.length > 0) {
        this.$store.commit("SET_SIDEBAR_ROUTERS", routes);
      }
    },

    ishttp(url) {
      return url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1;
    },
  },
};
</script>
<style lang="scss" scoped>
// 图标
::v-deep .el-icon-arrow-down {
  display: none;
}
::v-deep .el-icon-arrow-down:before {
  content: "";
}
</style>
<style lang="scss">
// 顶部一级菜单容器
.el-menu {
  background-color: #01579b;
}
.topmenu-container {
  display: flex;
  align-items: center;
  // 更换的小图标
  .el-arrow-white {
    display: inline-block;
    margin-left: 4px;
    .svg-icon {
      width: 0.7em;
    }
  }
  // ::v-deep .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  // ::v-deep .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  //   background-color: unset !important;
  // }
}
// 一级菜单项
.el-menu--horizontal > .el-menu-item {
  height: 100%;
  line-height: 30px !important;
  color: #fff !important;
  border-bottom: unset;
  margin-left: 5px;
}
.menu-no-child {
  padding: 0 10px;
}
// 顶部被激活的菜单项样式
.topmenu-container.el-menu--horizontal > .el-menu-item.is-active,
.topmenu-container.el-menu--horizontal
  > .el-submenu.is-active
  .el-submenu__title {
  background-color: #2e74b9 !important;
  border-bottom: unset;
  border-radius: 4px;
  padding: 0 10px;
}

/* submenu item */
.topmenu-container.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #fff !important;
}

// 鼠标滑过顶部菜单项
.topmenu-container .el-menu-item {
  &:hover {
    background-color: #2e74b9 !important;
    border-radius: 4px;
  }
}

// 去掉白色背景
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  background-color: unset;
}
.el-menu.el-menu--horizontal {
  border: unset;
}

// 带下拉的一级菜单的样式
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #2e74b9 !important;
  border-radius: 4px;
}

// 下拉图标旋转
.el-submenu.is-opened > .el-submenu__title .el-arrow-white {
  transition: all 0.3s ease;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.el-menu--horizontal {
  z-index: 20001 !important;
}
// 下拉菜单样式
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #191919;
  line-height: 40px;
  height: 40px;
}
.el-menu--horizontal .el-menu .el-menu-item.dropdown-li.is-active,
.el-menu--horizontal .el-menu .el-menu-item.dropdown-li:hover,
.el-menu--horizontal .el-menu-item.dropdown-li:focus {
  background-color: #DFEBF9;
  color: #14487b !important;
}

.el-menu-item.dropdown-li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.el-menu-item.dropdown-li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.el-menu--popup {
  padding: unset;
  border-radius: 8px;
}
.el-menu--horizontal > .dropdown-container.el-submenu .el-submenu__title {
  line-height: 30px !important;
  height: unset;
}
.dropdown-container.el-submenu [class^="el-icon-"] {
  margin-right: unset !important;
  width: unset !important;
}
</style>
