var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-container" }, [
    _c("p", { staticClass: "company-top-title" }, [
      _vm._v(_vm._s(_vm.companyTitle)),
    ]),
    _c(
      "div",
      { staticClass: "company-table" },
      [
        _c(
          "el-collapse",
          {
            staticClass: "company-collapse",
            on: { change: _vm.handleChange },
            model: {
              value: _vm.activeNames,
              callback: function ($$v) {
                _vm.activeNames = $$v
              },
              expression: "activeNames",
            },
          },
          [
            _c(
              "el-collapse-item",
              { attrs: { name: "1" } },
              [
                _c("template", { slot: "title" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "text-align": "center",
                        "background-color": "rgba(249, 194, 194, 1)",
                      },
                    },
                    [_vm._v("监管认定异常公司")]
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { padding: "0 10px" } },
                  [
                    _c("el-autocomplete", {
                      staticStyle: { margin: "10px 0" },
                      attrs: {
                        "fetch-suggestions": _vm.querySearch,
                        placeholder: "请输入搜索条件",
                        "trigger-on-focus": false,
                      },
                      on: { select: _vm.handleSelect },
                      model: {
                        value: _vm.searchStr1,
                        callback: function ($$v) {
                          _vm.searchStr1 = $$v
                        },
                        expression: "searchStr1",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "400px",
                          "overflow-y": "auto",
                          "margin-bottom": "10px",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            attrs: {
                              height: "400",
                              data: _vm.abnormalUtilList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "id",
                                label: "ID",
                                align: "center",
                                width: "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "cursor-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCellClick(
                                                scope.row.id,
                                                10
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.id))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "Name",
                                align: "center",
                                width: "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "cursor-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCellClick(
                                                scope.row.id,
                                                10
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.name))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "el-collapse-item",
              { attrs: { name: "2" } },
              [
                _c("template", { slot: "title" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "text-align": "center",
                        "background-color": "rgba(184, 240, 217, 1)",
                      },
                    },
                    [_vm._v("其他公司")]
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { padding: "0 10px" } },
                  [
                    _c("el-autocomplete", {
                      staticStyle: { margin: "10px 0" },
                      attrs: {
                        "fetch-suggestions": _vm.querySearch2,
                        placeholder: "请输入搜索条件",
                        "trigger-on-focus": false,
                      },
                      on: { select: _vm.handleSelect2 },
                      model: {
                        value: _vm.searchStr2,
                        callback: function ($$v) {
                          _vm.searchStr2 = $$v
                        },
                        expression: "searchStr2",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "400px",
                          "overflow-y": "auto",
                          "margin-bottom": "10px",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            attrs: {
                              height: "400",
                              data: _vm.noneAbnormalList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "id",
                                label: "ID",
                                align: "center",
                                width: "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "cursor-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCellClick(
                                                scope.row.id,
                                                0
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.id))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "Name",
                                align: "center",
                                width: "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "cursor-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCellClick(
                                                scope.row.id,
                                                0
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.name))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }