export default {
    //个人中心
    profile: {
        nickName: 'Nick Name',
        email: 'Email',
        deptName: 'Company',
        postGroup: 'Post',
        avatorTitle1: 'Click to upload avatar',
        avatorTitle2: 'Update avatar',
        selec: 'Select',
        submit: 'Submit',
    },
    // api 库
    apiRepository: {
        copyBtn: 'Copy',
        document: 'API Document',
        key: 'API Key',
        copySuccess:'Copy succeeded',
        copyFail:'Copy failed'
    }
}