var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-center" },
    [
      _c(
        "el-col",
        { attrs: { span: 4, xs: 24 } },
        [
          _c("el-card", { staticClass: "box-card" }, [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("userAvatar", {
                  staticClass: "left-avatar",
                  attrs: { user: _vm.user },
                }),
                _c("p", { staticClass: "nick-name" }, [
                  _vm._v(_vm._s(_vm.user.nickName)),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 20, xs: 24 } },
        [
          _c(
            "el-card",
            { staticClass: "api-repository" },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: {
                          label: _vm.$t("apiRepository.document"),
                          name: "101",
                        },
                      }),
                      _c("el-tab-pane", {
                        attrs: {
                          label: _vm.$t("apiRepository.key"),
                          name: "102",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(_vm.currenComponent, { tag: "component" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }