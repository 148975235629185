var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tree-component" },
    [
      _vm.showFilterInput
        ? _c("el-input", {
            staticStyle: { "margin-bottom": "12px" },
            attrs: {
              placeholder: "请输入",
              clearable: "",
              "prefix-icon": "el-icon-search",
            },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          })
        : _vm._e(),
      _c("el-tree", {
        ref: "tree",
        staticClass: "tree",
        style: { height: _vm.showFilterInput ? "calc(100% - 47px)" : "100%" },
        attrs: {
          data: _vm.treeData,
          "node-key": _vm.nodeKey,
          "default-expand-all": _vm.defaultExpandAll,
          "highlight-current": "",
          props: _vm.treeProps,
          "expand-on-click-node": _vm.expandOnClickNode,
          "default-expanded-keys": _vm.defaultExpandedKeys,
          "filter-node-method": _vm.filterNode,
        },
        on: { "node-click": _vm.nodeClick },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var node = ref.node
                var data = ref.data
                return _c(
                  "span",
                  { staticClass: "custom-tree-node" },
                  [
                    _vm.$scopedSlots.default
                      ? _vm._t("default", null, { data: data })
                      : _c(
                          "div",
                          {
                            staticClass: "node-label",
                            attrs: { title: node.label },
                          },
                          [_vm._v(_vm._s(node.label))]
                        ),
                  ],
                  2
                )
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }