<template>
  <div class="account-center">
    <!-- 个人中心 -->
    <el-row>
      <el-col :span="4" :xs="24">
        <el-card class="box-card">
          <div class="text-center">
            <userAvatar :user="user" class="left-avatar" />
            <p class="nick-name">{{ user.nickName }}</p>
          </div>
        </el-card>
      </el-col>

      <!-- 中间部分 -->
      <el-col :span="8" :xs="24" class="el-col-middle">
        <div class="user-info">
          <el-card class="user-info-top">
            <div slot="header" class="clearfix">
              <span class="head">用户信息</span>
            </div>
            <ul class="list-group list-group-striped">
              <userInfo
                :user="user"
                :roleGroup="roleGroup"
                :postGroup="postGroup"
              />
            </ul>
          </el-card>

          <el-card class="user-info-middle">
            <div slot="header" class="clearfix">
              <span class="head">客户经理</span>
            </div>
            <el-form
              ref="form"
              :model="user"
              label-width="90px"
              label-position="left"
            >
              <el-form-item label="客户经理">
                <p>{{ user.managerName }}</p>
              </el-form-item>
              <el-form-item label="手机号码">
                <p>{{ user.managerPhone }}</p>
              </el-form-item>
              <el-form-item label="经理邮箱">
                <p>{{ user.managerEmail }}</p>
              </el-form-item>
            </el-form>
          </el-card>

           <el-card class="user-info-bottom">
            <div slot="header" class="clearfix">
              <span class="head">开发者工具</span>
            </div>
            <span class="links">
              <router-link :to="{path:'/user/profile/api',query:{activeName: '101',label:'document'}}">API 文档</router-link></span
            >
            <span class="links">
              <router-link :to="{path:'/user/profile/api',query:{activeName: '102',label:'keyForm'}}">API 密钥</router-link></span
            >
          </el-card>
        </div>
      </el-col>

      <el-col :span="12" :xs="24">
        <el-card class="carbon-data">
          <div slot="header" class="clearfix">
            <span class="head">数据功能</span>
          </div>

          <ExpandTable />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userAvatar from "./userAvatar";
import userInfo from "./userInfoDL.vue";
import resetPwd from "./resetPwd";
import { getUserProfile } from "@/api/system/user";
import ExpandTable from "./expandTable";
export default {
  name: "ProfileZh",
  components: { userAvatar, userInfo, resetPwd, ExpandTable },
  data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: undefined,
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      getUserProfile()
        .then((response) => {
          this.user = response.data;
          this.roleGroup = response.roleGroup;
          this.postGroup = response.postGroup;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // legendClick(tab, event) {
    //   let self = this;
    //   var tab_content = document.querySelectorAll(
    //     ".com-resource-legend .el-tabs__content"
    //   );
    //   if (self.currentLab.index == tab.index) {
    //     tab_content[0].style.display == "none" || ""
    //       ? (tab_content[0].style.display = "block")
    //       : (tab_content[0].style.display = "none");
    //   }
    //   if (self.currentLab.index != tab.index) {
    //     self.currentLab.index = tab.index;
    //     tab_content[0].style.display = "block";
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.account-center {
  height: calc(100vh - 120px);
  // overflow: auto;
  padding: 20px;
  div,
  .head,
  ::v-deep .el-form-item__label {
    color: #01579b;
    font-weight: 500;
  }
  .head {
    font-weight: 700;
  }

  .nick-name {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
  }
  .box-card,
  .user-info,
  .carbon-data {
    height: calc(100vh - 170px);
  }
  ::v-deep .el-card__body {
    padding: 8px;
  }
  .user-info {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    ::v-deep .el-form-item {
      margin-bottom: unset;
    }
    .user-info-top,
    .user-info-middle {
      margin-bottom: 10px;
    }
    .user-info-top,
    .user-info-bottom,
    .user-info-middle {
      ::v-deep .el-form-item {
        .el-form-item__label,
        .el-form-item__content {
          line-height: 25px;
        }
      }
      .links {
        font-size: 14px;
        margin-right: 20px;
      }
    }
    .user-info-top,
    // .user-info-bottom,
    .user-info-middle {
      flex: 1;
    }
    .list-group {
      margin: 0;
    }
    .list-group-item {
      margin: unset;
      border-bottom: unset;
      border-top: unset;
    }
  }
  // 左侧头像部分
  .box-card {
    border: unset;
    background-color: #01579b;
    // 背景图
    background-image: url("~@/assets/images/avat-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .left-avatar {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .account-center {
    .user-info {
      margin: unset;
    }
    .el-col-middle {
      margin: 10px 0;
    }
  }
}
// 只用于大屏幕
@media screen and (min-width: 1770px) {
  .account-center {
    padding: 80px 20px;
    height: calc(100vh - 300px);
    .user-info {
      .user-info-top,
      .user-info-bottom,
      .user-info-middle {
        .el-form-item {
          ::v-deep .el-form-item__label,
          ::v-deep .el-form-item__content {
            line-height: 60px;
          }
        }
      }
    }

    .box-card,
    .user-info,
    .carbon-data {
      height: calc(100vh - 300px);
    }
  }
}
</style>