<template>
    <div class="additional-pai-select-wrapper" v-if="refresh">
        <div class="category-section">
            <div class="category-header">
                <p class="category-title">
                    <img
                        class="point-icon"
                        src="~@/assets/sfdr/images/A.png"
                        alt=""
                    />
                    Climate and other environment-related indicators (Select at
                    least one ) <span>*</span>
                </p>
                <el-checkbox class="select-all" v-model="part1SelectAll"
                    >Select All</el-checkbox
                >
            </div>
            <div class="impacts-section">
                <p class="impacts-title">Emissions</p>
                <div>
                    <SelectItem
                        v-for="factor in impactFactorsMapping['Emissions']"
                        :selected="!!selectItemObj[factor]"
                        :key="factor"
                        :id="factor"
                        :name="factor"
                        impact="Emissions"
                        :categoryId="1"
                        @paiSelected="handlePaiItemSelect"
                        @paiUnSelected="handlePaiItemUnSelect"
                    >
                    </SelectItem>
                </div>
            </div>
            <div class="impacts-section">
                <p class="impacts-title">Energy performance</p>
                <div>
                    <SelectItem
                        v-for="factor in impactFactorsMapping[
                            'Energy performance'
                        ]"
                        :selected="!!selectItemObj[factor]"
                        :key="factor"
                        :id="factor"
                        :name="factor"
                        impact="Energy performance"
                        :categoryId="1"
                        @paiSelected="handlePaiItemSelect"
                        @paiUnSelected="handlePaiItemUnSelect"
                    >
                    </SelectItem>
                </div>
            </div>
            <div class="impacts-section">
                <p class="impacts-title">Water, waste and material emissions</p>
                <div>
                    <SelectItem
                        v-for="factor in impactFactorsMapping[
                            'Water, waste and material emissions'
                        ]"
                        :selected="!!selectItemObj[factor]"
                        :key="factor"
                        :id="factor"
                        :name="factor"
                        impact="Water, waste and material emissions"
                        :categoryId="1"
                        @paiSelected="handlePaiItemSelect"
                        @paiUnSelected="handlePaiItemUnSelect"
                    >
                    </SelectItem>
                </div>
            </div>
        </div>
        <div class="border-split"></div>
        <div class="category-section">
            <div class="category-header">
                <p class="category-title">
                    <img
                        class="point-icon"
                        src="~@/assets/sfdr/images/B.png"
                        alt=""
                    />
                    Social and employee, respect for human rights,
                    anti-corruption and anti-bribery matters (Select at least
                    one)
                    <span>*</span>
                </p>
                <el-checkbox class="select-all" v-model="part2SelectAll"
                    >Select All</el-checkbox
                >
            </div>
            <div class="impacts-section">
                <p class="impacts-title">Social and employee matters</p>
                <div>
                    <SelectItem
                        v-for="factor in impactFactorsMapping[
                            'Social and employee matters'
                        ]"
                        :selected="!!selectItemObj[factor]"
                        :key="factor"
                        :id="factor"
                        :name="factor"
                        impact="Social and employee matters"
                        :categoryId="2"
                        @paiSelected="handlePaiItemSelect"
                        @paiUnSelected="handlePaiItemUnSelect"
                    >
                    </SelectItem>
                </div>
            </div>
            <div class="impacts-section">
                <p class="impacts-title">Human Rights</p>
                <div>
                    <SelectItem
                        v-for="factor in impactFactorsMapping['Human Rights']"
                        :selected="!!selectItemObj[factor]"
                        :key="factor"
                        :id="factor"
                        :name="factor"
                        impact="Human Rights"
                        :categoryId="2"
                        @paiSelected="handlePaiItemSelect"
                        @paiUnSelected="handlePaiItemUnSelect"
                    >
                    </SelectItem>
                </div>
            </div>
            <div class="impacts-section">
                <p class="impacts-title">Anti-corruption and anti-bribery</p>
                <div>
                    <SelectItem
                        v-for="factor in impactFactorsMapping[
                            'Anti-corruption and anti-bribery'
                        ]"
                        :selected="!!selectItemObj[factor]"
                        :key="factor"
                        :id="factor"
                        :name="factor"
                        impact="Anti-corruption and anti-bribery"
                        :categoryId="2"
                        @paiSelected="handlePaiItemSelect"
                        @paiUnSelected="handlePaiItemUnSelect"
                    >
                    </SelectItem>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectItem from './SelectItem.vue'
export default {
  components: { SelectItem },
  props: {
    paiGroup: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      categoryIdGroup: {
        1: [],
        2: []
      },
      part1SelectAll: false,
      part2SelectAll: false,
      selectItemObj: {},
      refresh: true
    }
  },
  watch: {
    part1SelectAll(val) {
      this.handlePaiSelectAll(1, val)
    },
    part2SelectAll(val) {
      this.handlePaiSelectAll(2, val)
    }
  },
  created() {
    this.initPaiList()
  },
  computed: {
    impactFactorsMapping() {
      // {impact} => {Factors} => [PAI]
      const groupSet = {}
      Object.keys(this.paiGroup).forEach((impact) => {
        const factorArray = Object.keys(
          this.groupBy(
            this.paiGroup[impact],
            (item) => item.adverseImpactOnSustainabilityFactors
          )
        )
        groupSet[impact] = factorArray
      })
      return groupSet
    }
  },
  methods: {
    initPaiList() {
      let list = []
      Object.keys(this.paiGroup).forEach((impact) => {
        list = list.concat(this.paiGroup[impact])
      })
      this.paiList = list
    },
    handlePaiItemSelect(factor, impact, categoryId) {
      this.paiGroup[impact]
        .filter((o) => o.adverseImpactOnSustainabilityFactors === factor)
        .forEach((o) => {
          this.categoryIdGroup[categoryId].push(o.paiOrder)
        })

      this.$emit('updatePaiIdList', this.categoryIdGroup)
    },
    handlePaiItemUnSelect(factor, impact, categoryId) {
      const delPaiIds = this.paiGroup[impact]
        .filter((o) => o.adverseImpactOnSustainabilityFactors === factor)
        .map((o) => o.paiOrder)

      const categoryPaiIdList = this.categoryIdGroup[categoryId]

      this.categoryIdGroup[categoryId] = categoryPaiIdList.filter(
        (id) => delPaiIds.indexOf(id) < 0
      )

      this.$emit('updatePaiIdList', this.categoryIdGroup)
    },
    handlePaiSelectAll(_categoryId = 1, val) {
      if (val) {
        this.paiList.forEach((o) => {
          if (o.categoryId === _categoryId) {
            this.categoryIdGroup[_categoryId].push(o.paiOrder)
            this.selectItemObj[o.adverseImpactOnSustainabilityFactors] = true
          }
        })
      } else {
        const delPaiIds = this.paiList
          .filter((o) => o.categoryId === _categoryId)
          .map((o) => {
            delete this.selectItemObj[o.adverseImpactOnSustainabilityFactors]
            return o.paiOrder
          })

        const categoryPaiIdList = this.categoryIdGroup[_categoryId]

        this.categoryIdGroup[_categoryId] = categoryPaiIdList.filter(
          (id) => delPaiIds.indexOf(id) < 0
        )
      }
      this.$emit('updatePaiIdList', this.categoryIdGroup)

      this.refresh = false
      this.$nextTick(() => {
        this.refresh = true
      })
      // const categoryImpacts = {
      //   1: [
      //     'Emissions',
      //     'Energy performance',
      //     'Water, waste and material emissions'
      //   ],
      //   2: [
      //     'Social and employee matters',
      //     'Human Rights',
      //     'Anti-corruption and anti-bribery'
      //   ]
      // }
      // const impacts = categoryImpacts[_categoryId]
      // this.impactFactorsMapping()
    },
    groupBy(list, fn) {
      const groups = {}
      list.forEach(function(o) {
        const group = fn?.(o)
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return groups
    }
  }
}
</script>

<style lang="scss" scoped>
.additional-pai-select-wrapper {
    font-family: PingFang SC-Regular;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: scroll;
    border-bottom: 1px solid #e3e3f0;
    // padding-bottom: 24px;

    // box-shadow: 2px 5px 4px rgb(0 0 0 / 30%);

    .category-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 12px 20px 0;

        .category-title {
            .point-icon {
                width: 17px;
                height: 17px;
                position: relative;
                top: 3px;
                margin-right: 5px;
            }
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #5998f0;
            line-height: 20px;
            span {
                color: red;
                font-weight: 600;
            }
        }
        .select-all {
            margin-left: 10px;
            padding: 2px 8px 2px 6px;
            background: rgba(#ebedf0, 0.6);
            border-radius: 2px 2px 2px 2px;
        }
    }

    .impacts-title {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        // color: #606266;
        color: #000000;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .category-section {
        margin-bottom: 20px;
        width: 46%;
    }

    .border-split {
        width: 3px;
        background: #ebedf0;
        height: 100%;
        position: relative;
        right: 6px;
        margin: 0 3%;
    }

    .impacts-section {
        padding: 0 0 18px;
        > div {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: space-between;
        }
    }
}
</style>