var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-breadcrumb",
        { staticClass: "menu", attrs: { separator: "/" } },
        [
          _c(
            "el-breadcrumb-item",
            { attrs: { to: { path: "/data-platform/carbon/dataBrowser" } } },
            [_vm._v("Home")]
          ),
          _c("el-breadcrumb-item", [_vm._v("Flop")]),
        ],
        1
      ),
      _c("div", { staticClass: "outer-box" }, [
        _c(
          "div",
          { staticClass: "left-box" },
          [
            _c("CompanyList", {
              attrs: { companyTitle: _vm.companyTitle },
              on: { handleCellClick: _vm.handleCellClick },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content-right" },
          [
            _vm.form && _vm.form.part1 && _vm.form.part1.id
              ? _c("CompanyDetailInfo", {
                  attrs: {
                    form: _vm.form,
                    companyAbnormal: _vm.companyAbnormal,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }