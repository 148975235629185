var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.user,
        "label-width": "90px",
        "label-position": "left",
      },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.vm.$t("profile.nickName") } },
                [_c("p", [_vm._v(_vm._s(_vm.user.nickName))])]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("profile.email") } },
                [_c("p", [_vm._v(_vm._s(_vm.user.email))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("profile.deptName") } },
                [
                  _vm.user.dept
                    ? _c("p", [_vm._v(_vm._s(_vm.user.dept.deptName))])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.vm.$t("profile.postGroup") } },
                [_c("p", [_vm._v(_vm._s(_vm.postGroup))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }