<template>
  <div class="navbar-sub">
    <!-- 左侧button -->
    <div class="btns">
      <el-button type="danger" size="mini">提取</el-button>
      <el-button type="warning" size="mini">导出</el-button>
      <el-button  plain size="mini">保存为自选清单</el-button>
    </div>
    <!-- <div class="container"> -->
      <!-- 顶部二级菜单 -->
      <!-- <topSubNav></topSubNav>
    </div> -->

    <!-- 右侧账户信息 -->
    <div class="right-menu">
      <el-button size="mini" type="info">指示说明</el-button>
      <!-- <el-button size="mini" type="info">帮助支持</el-button> -->
    </div>
  </div>
</template>
<script>
import topSubNav from "@/components/topSubNav";
export default {
  components: {
    topSubNav,
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 900px;
  margin: 0 auto;
  position: relative;
}
.navbar-sub {
  padding: 0 20px;
  height: 55px;
  line-height: 50px;
  width: 100%;
  position: fixed;
  top: 65px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  background-color: #fff;
  z-index: 9999;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  overflow: hidden;
}
</style>