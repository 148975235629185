var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "el-zoom-in-top" },
      on: {
        "after-leave": function ($event) {
          return _vm.$emit("dodestroy")
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "el-time-panel el-popper",
          class: _vm.popperClass,
        },
        [
          _c(
            "div",
            {
              staticClass: "el-time-panel__content",
              class: { "has-seconds": _vm.showSeconds },
            },
            [
              _c("time-spinner", {
                ref: "spinner",
                attrs: {
                  "arrow-control": _vm.useArrow,
                  "show-seconds": _vm.showSeconds,
                  "am-pm-mode": _vm.amPmMode,
                  date: _vm.date,
                },
                on: {
                  change: _vm.handleChange,
                  "select-range": _vm.setSelectionRange,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "el-time-panel__footer" }, [
            _c(
              "button",
              {
                staticClass: "el-time-panel__btn cancel",
                attrs: { type: "button" },
                on: { click: _vm.handleCancel },
              },
              [_vm._v(_vm._s(_vm.t("el.datepicker.cancel")))]
            ),
            _c(
              "button",
              {
                staticClass: "el-time-panel__btn",
                class: { confirm: !_vm.disabled },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.handleConfirm()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.t("el.datepicker.confirm")))]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }