var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "review-page",
    },
    [
      _c("div", { staticClass: "review-main" }, [
        _c(
          "div",
          { staticClass: "review-left" },
          [_c("Menu", { on: { showView: _vm.handleViewChange } })],
          1
        ),
        _c(
          "div",
          {
            staticClass: "review-right",
            class: _vm.viewId !== "summary" ? "review-right-fixed" : "",
          },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.viewId === "summary"
                ? _c(
                    "div",
                    [
                      _c("summary-table", {
                        attrs: {
                          totalColumn: _vm.totalColumn,
                          data: _vm.mandatoryData,
                        },
                      }),
                      _c("summary-table", {
                        attrs: {
                          totalColumn: _vm.totalColumn,
                          data: _vm.additionalData.filter(function (item) {
                            return item.categoryId === 1 || item.isClimate
                          }),
                        },
                      }),
                      _c("summary-table", {
                        attrs: {
                          totalColumn: _vm.totalColumn,
                          data: _vm.additionalData.filter(function (item) {
                            return item.categoryId === 2 || item.isSocial
                          }),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("transition", { attrs: { name: "fade" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.viewId === "main",
                      expression: "viewId === 'main'",
                    },
                  ],
                  staticClass: "fixed-table-container",
                },
                [
                  _c("summary-table", {
                    attrs: {
                      totalColumn: _vm.totalColumn,
                      data: _vm.mandatoryData,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.viewId === "option"
                ? _c(
                    "div",
                    { staticClass: "fixed-table-container" },
                    [
                      _c("summary-table", {
                        attrs: {
                          totalColumn: _vm.totalColumn,
                          data: _vm.additionalData,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "el-button",
              {
                staticClass: "primary",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = true
                  },
                },
              },
              [_vm._v("Reset")]
            ),
            _c(
              "el-button",
              {
                staticClass: "primary",
                attrs: { type: "primary" },
                on: { click: _vm.handleDownloadReport },
              },
              [_vm._v("Download Report as Excel")]
            ),
            _c(
              "el-button",
              {
                staticClass: "primary",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.showDownloadReminder = true
                  },
                },
              },
              [_vm._v("Download Underlying Data")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Reminder",
            visible: _vm.dialogVisible,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              'Please ensure that your data has been downloaded before resetting. Do you really want to reset and return to the "Upload Portfolio" page?'
            ),
          ]),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.reset } },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showDownloadReminder,
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDownloadReminder = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "If you need underlying data of the uploaded portfolio, please contact us via "
            ),
            _c("strong", [_vm._v("clientsupport@governance-solutions.com")]),
          ]),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showDownloadReminder = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }