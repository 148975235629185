<template>
  <div class="account-center">
    <!-- API 库 -->
    <!-- 头像 -->
    <el-col :span="4" :xs="24">
      <el-card class="box-card">
        <div class="text-center">
          <userAvatar :user="user" class="left-avatar" />
          <p class="nick-name">{{ user.nickName }}</p>
        </div>
      </el-card>
    </el-col>

    <el-col :span="20" :xs="24">
      <el-card class="api-repository">
        <div slot="header" class="clearfix">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- API 文档 -->
            <el-tab-pane :label="$t('apiRepository.document')" name="101">
            </el-tab-pane>

            <!-- API 密钥 -->
            <el-tab-pane :label="$t('apiRepository.key')" name="102">
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 根据tab动态加载组件 -->
        <component :is="currenComponent" />
      </el-card>
    </el-col>
  </div>
</template>
<script>
import userAvatar from "./profile/userAvatar.vue";
import { getUserProfile } from "@/api/system/user";
import KeyForm from "./apiRepository/KeyDL.vue";
import Document from "./apiRepository/DocumentDL_V2.vue";
export default {
  name: "ApiRepository",
  components: { userAvatar, KeyForm, Document },
  data() {
    return {
      user: {},
      activeName: "101",
      currenComponent: "document", //动态组件
    };
  },
  created() {
    this.getUser();
    this.showTabs();
  },
  methods: {
    getUser() {
      getUserProfile()
        .then((response) => {
          this.user = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //根据url显示不同的tab
    showTabs() {
      if (this.$route.query.activeName != null) {
        this.activeName = this.$route.query.activeName;
        this.currenComponent = this.$route.query.label;
      }
    },

    //点击tab
    handleClick(tab, event) {
      if (tab.name === "101") {
        this.currenComponent = "document";
        this.$router.push({
          path: "/user/profile/api",
          query: { activeName: "101", label: "document" },
        });
      } else if (tab.name === "102") {
        this.currenComponent = "keyForm";
        this.$router.push({
          path: "/user/profile/api",
          query: { activeName: "102", label: "keyForm" },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.account-center {
  height: calc(100vh - 120px);
  // overflow: auto;
  padding: 20px;
  div,
  .head,
  ::v-deep .el-form-item__label {
    color: #01579b;
    font-weight: 500;
  }
  .head {
    font-weight: 700;
  }

  .nick-name {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
  }
  .box-card,
  .api-repository {
    height: calc(100vh - 170px);
    .el-tabs__item {
      padding: 0 50px;
    }
  }
  ::v-deep .el-card__body {
    padding: 15px;
    padding-top: 0;
    height: 100%;
  }

  // 左侧头像部分
  .box-card {
    margin-right: 20px;
    border: unset;
    background-color: #01579b;
    // 背景图
    background-image: url("~@/assets/images/avat-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .left-avatar {
    margin-top: 80px;
  }
  //右侧部分
  .api-repository {
    ::v-deep .el-card__header {
      border-bottom: unset;
    }
    // .el-tabs__active-bar{
    //   width: 80px;
    // }
    // ::v-deep .el-tabs__active-bar {
    //   display: none;
    // }
    // ::v-deep .el-tabs__item.is-active {
    //   border-bottom: 2px solid #1890ff;
    // }
  }
}

@media (max-width: 768px) {
  .account-center {
    .el-col-middle {
      margin: 10px 0;
    }
  }
}
// 只用于大屏幕
@media screen and (min-width: 1770px) {
  .account-center {
    padding: 80px 20px;
    height: calc(100vh - 200px);

    .box-card,
    .api-repository {
      height: calc(100vh - 300px);
    }
  }
}
</style>
