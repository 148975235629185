<template>
  <div class="echarts-flop-anomaly">
    <div id="flopAnomaly" style="height: 400px;width: 100%;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    flopAnomalyEchartsData: {
      type: Array,
      default: () => {
        return []
      }
    },
    echartsTitle: {
      type: String,
      default: '',
    }
  },
  data() {
    return {}
  },
  created() {
    // 监听echarts图例随屏幕变化放大缩小
    window.addEventListener("resize", ()=>{
      echarts.init(document.getElementById('flopAnomaly')).resize();
    })
  },
  mounted() {
    this.anomalyEcharts()
  },
  watch: {
    flopAnomalyEchartsData() {
      this.anomalyEcharts()
    }
  },
  methods: {
    // 生成echarts图表
    anomalyEcharts() {
      var myChart = echarts.init(document.getElementById('flopAnomaly'));
      var option;
      // const legendData = [this.echartsTitle, '造假预警阈值', '官方认定造假', '模型认定造假']
      // 横纵坐标数据处理
      const xArisData = this.flopAnomalyEchartsData.map( item => String(item.year))
      const frdProbPmmData = this.flopAnomalyEchartsData.map( item => item.frdProbPmm)  // 带数据标记的折线图  财报异常指数
      const ind1MedianData = this.flopAnomalyEchartsData.map( item => item.ind1Median)  // 带数据标记的折线图  行业内异常公司指数中位数
      const ind0MedianData = this.flopAnomalyEchartsData.map( item => item.ind0Median)  // 带数据标记的折线图  行业内无异常公司指数中位数
      const ind25Data = this.flopAnomalyEchartsData.map( item => item.ind25)  // 堆积面积图  行业25%分位
      const ind50Data = this.flopAnomalyEchartsData.map( item => item.ind50)  // 堆积面积图  行业50%分位
      const ind75Data = this.flopAnomalyEchartsData.map( item => item.ind75)  // 堆积面积图  行业75%分位
      const triangleData = []
      const circleData = []
      // 生成point-Data
      this.flopAnomalyEchartsData.filter((item) => {
        if(item.isFrd127 === 1){
          triangleData.push({
            coord: [String(item.year),String(item.frdProbPmm)]
          })
        }
        if(item.isFrdPrePmm === 1){
          circleData.push({
            symbol: 'diamond',
            symbolSize: 13,
            itemStyle: {
              color: 'rgb(179,118,77)',
            },
            coord: [String(item.year),item.frdProbPmm]
          })
        }
      })
      var markPoint = triangleData.concat(circleData)
      option = {
        title: [
          {
            left: 'center',
            text: this.echartsTitle,
            textStyle: {
              fontSize: 20,
            }
          }
        ],
        xAxis: {
          name: '年份',
          nameLocation: 'middle',
          nameGap: 35,
          nameTextStyle: {
            fontSize: 16,
          },
          type: 'category',
          data: xArisData
        },
        yAxis: {
          name: '财报异常指数',
          type: 'value',
          splitLine: {
            show: false
          },
          nameTextStyle: {
            fontSize: 16,
          },
        },
        legend: {
          orient: 'vertical',
          top: 0,
          right: 10,
          y: 'center',
          textStyle: {
            padding: [8, 0, 0, 0],
          },
          data: [
            {name: '行业75%分位', icon: 'path://M0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z'},
            {name: '行业50%分位', icon: 'path://M0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z'},
            {name: '行业25%分位', icon: 'path://M0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z'},
            {name: this.echartsTitle + '财报异常指数'},
            {name: '行业内异常公司指数中位数'},
            {name: '行业内无异常公司指数中位数'},
            {name: '财报异常信号阈值',icon: 'path://M112 476h160v72H112z m320 0h160v72H432z m320 0h160v72H752z'},
            {name: '监管认定违规',icon: 'triangle'},
            {name: '模型提示违规信号',icon: 'diamond'},
          ]
        },
        series: [
          {
            name: this.echartsTitle + '财报异常指数',
            data: frdProbPmmData,
            type: 'line',
            itemStyle: {
              color: 'red',
            },
            lineStyle: {
              color: 'red',
              width: 2,
              type: 'solid'
            },
            // markPoint:{
            //   symbol: 'triangle',
            //   symbolSize: 20,
            //   itemStyle: {
            //     color: 'rgb(94,148,162)'
            //   },
            //   data: markPoint,
            // },
          },
          {
            name: '行业内异常公司指数中位数',
            data: ind1MedianData,
            type: 'line',
            itemStyle: {
              color: '#4B6182',
            },
            lineStyle: {
              color: '#4B6182',
              width: 2,
              type: 'solid'
            },
          },
          {
            name: '行业内无异常公司指数中位数',
            data: ind0MedianData,
            type: 'line',
            itemStyle: {
              color: 'green',
            },
            lineStyle: {
              color: 'green',
              width: 2,
              type: 'solid'
            },
          },
          {
            name: '行业75%分位',
            data: ind75Data,
            type: 'line',
            itemStyle: {
              color: '#FDF0BD',
            },
            stack: 'Total',
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            sampling: 'average',
            areaStyle: {
              color: '#FDF0BD',
            },
            emphasis: {
              focus: 'series'
            },
          },
          {
            name: '行业50%分位',
            data: ind50Data,
            type: 'line',
            itemStyle: {
              color: '#F9D23A'
            },
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            sampling: 'average',
            areaStyle: {
              color: '#F9D23A',
            },
          },
          {
            name: '行业25%分位',
            data: ind25Data,
            type: 'line',
            itemStyle: {
              color: '#D6AC08',
            },
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            sampling: 'average',
            areaStyle: {
              color: '#D6AC08',
            },
          },
          {
            name: '财报异常信号阈值',
            type: 'line',
            data: [0.019108],
            symbolSize: 0,
            lineStyle: {
              type: 'dashed',
            },
            itemStyle: {
              color: 'black',
            },
            // 标识线
            markLine: {
              symbol: ['none', 'none'], //去掉箭头
              lineStyle: {
                normal: {
                  type: 'dashed',
                  color: 'black', // 这儿设置安全基线颜色
                },
              },
              data: [
                {
                  yAxis: 0.019108.toFixed(6),
                },
              ],
              label: {
                show: false,
              },
            }
          },
          {
            name: '监管认定违规',
            type: 'line',
            // name: this.echartsTitle + '财报异常指数',
            data: frdProbPmmData,
            itemStyle: {
              color: 'rgb(94,148,162)',
            },
            lineStyle: {
              color: 'red',
              width: 2,
              type: 'solid'
            },
            markPoint:{
              symbol: 'triangle',
              symbolSize: 20,
              itemStyle: {
                color: 'rgb(94,148,162)'
              },
              data: triangleData,
            },
          },
          {
            name: '模型提示违规信号',
            type: 'line',
            // name: this.echartsTitle + '财报异常指数',
            data: frdProbPmmData,
            itemStyle: {
              color: 'rgb(179,118,77)',
            },
            lineStyle: {
              color: 'red',
              width: 2,
              type: 'solid'
            },
            markPoint:{
              data: circleData,
            },
          }
        ],
      };
      // 配置point图例点击切换显示/隐藏
      if (option && typeof option === "object") {
        myChart.on('legendselectchanged', (params) => {
          var selected = params.selected
          var name = params.name; // current clicked one
          if(name == this.echartsTitle + '财报异常指数'){
            myChart.dispatchAction({
              type: selected[name]?'legendSelect':'legendUnSelect',
              name: '监管认定违规'
            })
            myChart.dispatchAction({
              type: selected[name]?'legendSelect':'legendUnSelect',
              name: '模型提示违规信号'
            })
          }
          if(name == '监管认定违规' || name == '模型提示违规信号'){
            myChart.dispatchAction({
              type: selected[name]?'legendSelect':'',
              name: this.echartsTitle + '财报异常指数'
            })
          }
        })
        option && myChart.setOption(option);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.echarts-flop-anomaly{
  width: 100%;
}
</style>