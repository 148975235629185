var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.fieldInfo.columnMetaId ? "编辑输出字段" : "编辑输出字段",
        width: "600px",
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.formRules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "输出字段", prop: "columnAlias" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入输出字段" },
                model: {
                  value: _vm.formData.columnAlias,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "columnAlias", $$v)
                  },
                  expression: "formData.columnAlias",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "输出名称", prop: "columnOutputTitle" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入输出名称" },
                model: {
                  value: _vm.formData.columnOutputTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "columnOutputTitle", $$v)
                  },
                  expression: "formData.columnOutputTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "输出顺序", prop: "finalOrder" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "100%" },
                attrs: { precision: 0, size: "small", controls: false },
                model: {
                  value: _vm.formData.finalOrder,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "finalOrder", $$v)
                  },
                  expression: "formData.finalOrder",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "运算语句", prop: "expressionContent" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: "5",
                  placeholder: "请输入字段定义表达式",
                },
                model: {
                  value: _vm.formData.expressionContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "expressionContent", $$v)
                  },
                  expression: "formData.expressionContent",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }