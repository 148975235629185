<template>
  <div class="risk_container" v-loading="loading">
    <div class="footprint_title title_bg">
      {{ $t('risk.title1') }}
    </div>
    <div class="text">
      <p v-html="$t('risk.text1_1')">
        <!-- {{ $t('risk.text1_1') }} -->
      </p>
      <p>
        {{ $t('risk.text1_2') }}
      </p>
    </div>
    <div class="footprint_title title_mid">
      {{ $t('risk.title2') }}
    </div>
    <table class="table" border="0" cellspacing="0" v-if="renderData.climateRisk">
      <tr>
        <td>{{ $t('risk.table1_1') }}</td>
        <td colspan="4">{{ Number(renderData.climateRisk.threshold * 100).toFixed(2) + '%' }}</td>
      </tr>
      <tr class="bg">
        <td>{{ $t('risk.table1_2') }}</td>
        <td>
          {{ $t('risk.table1_3') }}
        </td>
        <td>{{ $t('risk.table1_4') }}</td>
        <td>{{ $t('risk.table1_11') }}</td>
        <td>{{ $t('risk.table1_5') }}</td>
      </tr>
      <tr>
        <td>{{ $t('risk.table1_6') }}</td>
        <td>
          <div :class="isTable1Line1tag ? 'red' : ''">
            {{ isTable1Line1tag ? '*' : '' }}{{ Number(renderData.climateRisk.physicalRisk * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          <div :class="isTable1Line1tag ? 'red' : ''">
            {{ isTable1Line1tag ? '*' : '' }}{{ renderData.climateRisk.physicalRiskRelevance }}
          </div>
        </td>
        <td>
          <div :class="isTable1Line1tag ? 'red' : ''">
            {{
              isTable1Line1tag ? '*' : ''
            }}{{ Number(renderData.climateRisk.physicalRiskWeighting * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          {{ $t('risk.table1_7') }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('risk.table1_8') }}</td>
        <td>
          <div :class="isTable1Line2tag ? 'red' : ''">
            {{ isTable1Line2tag ? '*' : '' }}{{ Number(renderData.climateRisk.transitionRisk * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          <div :class="isTable1Line2tag ? 'red' : ''">
            {{ isTable1Line2tag ? '*' : '' }}{{ renderData.climateRisk.transitionRiskRelevance }}
          </div>
        </td>
        <td>
          <div :class="isTable1Line2tag ? 'red' : ''">
            {{isTable1Line2tag ? '*' : '' }}{{ Number(renderData.climateRisk.transitionRiskWeighting * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          {{ $t('risk.table1_9') }}
        </td>
      </tr>
      <tr
        v-if="isTable1Note"
      >
        <td colspan="5">
          {{ $t('risk.table1_10') }}
        </td>
      </tr>
    </table>
    <div class="footprint_title title_bg">
      {{ $t('risk.title3') }}
    </div>
    <el-table
      :data="renderData.statsOfSASB"
      stripe
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="sasb"
        :label="$t('risk.table2_1')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="amount"
        :label="$t('risk.table2_2')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="value"
        :label="$t('risk.table2_3')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="weightings"
        :label="$t('risk.table2_4')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="scope12Emission"
        :label="$t('risk.table2_5')"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="emissionShares"
        :label="$t('risk.table2_6')"
      >
      </el-table-column>
    </el-table>
    <div class="footprint_title title_bg">
      {{ $t('risk.title4') }}
    </div>
    <div id="bar_box1"></div>
    <div class="footprint_title title_bg">
      {{ $t('risk.title5') }}
    </div>
    <div class="text text_mid">
      <p v-html="$t('risk.text2')"></p>
      <!-- {{ $t('risk.text2') }} -->
    </div>
    <div class="footprint_title title_mid">
      {{ $t('risk.table3') }}
    </div>

    <table class="table" border="0" cellspacing="0" v-if="renderData.climateRiskMateriality1">
      <tr>
        <td>{{ $t('risk.table1_1') }}</td>
        <td colspan="4">{{ Number(renderData.climateRiskMateriality1.threshold * 100).toFixed(2) + '%' }}</td>
      </tr>
      <tr class="bg">
        <td>{{ $t('risk.table1_2') }}</td>
        <td>
          {{ $t('risk.table1_3') }}
        </td>
        <td>{{ $t('risk.Materiality') }}</td>
        <td>{{ $t('risk.table1_11') }}</td>
        <td>{{ $t('risk.table1_5') }}</td>
      </tr>
      <tr>
        <td>{{ $t('risk.table1_6') }}</td>
        <td>
          <div :class="isTable2Line1tag? 'red' : ''">
            {{
              isTable2Line1tag ? '*' : ''
            }}{{ Number(renderData.climateRiskMateriality1.physicalRisk * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          <div :class="isTable2Line1tag ? 'red' : ''">
            {{ isTable2Line1tag ? '*' : '' }}{{ renderData.climateRiskMateriality1.physicalRiskMaterial }}
          </div>
        </td>
        <td>
          <div :class="isTable2Line1tag ? 'red' : ''">
            {{
              isTable2Line1tag ? '*' : ''
            }}{{ Number(renderData.climateRiskMateriality1.physicalRiskWeighting * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          {{ $t('risk.table1_7') }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('risk.table1_8') }}</td>
        <td>
          <div :class="isTable2Line2tag ? 'red' : ''">
            {{
              isTable2Line2tag ? '*' : ''
            }}{{ Number(renderData.climateRiskMateriality1.transitionRisk * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          <div :class="isTable2Line2tag ? 'red' : ''">
            {{ isTable2Line2tag ? '*' : '' }}{{ renderData.climateRiskMateriality1.transitionRiskMaterial }}
          </div>
        </td>
        <td>
          <div :class="isTable2Line2tag ? 'red' : ''">
            {{
              isTable2Line2tag ? '*' : ''
            }}{{ Number(renderData.climateRiskMateriality1.transitionRiskWeighting * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          {{ $t('risk.table1_9') }}
        </td>
      </tr>
      <tr
        v-if="isTable2Note"
      >
        <td colspan="5">
          {{ $t('risk.table1_10') }}
        </td>
      </tr>
    </table>
    <div class="footprint_title title_bg">
      {{ $t('risk.title6') }}
    </div>
    <div class="text text_mid">
      <!-- <p>
        {{ $t('risk.text3_1') }}
      </p>
      <p>
        {{ $t('risk.text3_2') }}
      </p> -->
      <p>
        {{ $t('risk.text3_3') }}
      </p>
    </div>

    <table class="table" border="0" cellspacing="0" v-if="baseFormData && renderData.climateRiskMateriality2">
      <tr class="bg">
        <td>{{ $t('risk.table3_1') }}</td>
        <td>
          {{ $t('risk.table3_2') }}
        </td>
        <td>
          {{ $t('risk.table3_3', [baseFormData.shadowDevideEbitdaOption2]) }}
        </td>
        <td colspan="3"></td>
      </tr>
      <tr>
        <td>{{ $t('risk.table3_4') }}</td>
        <td>
          {{ Number(renderData.climateRiskMateriality2.thresholdOfPhysical * 100).toFixed(2) + '%' }}
        </td>
        <td>/</td>
        <td colspan="3"></td>
      </tr>
      <tr>
        <td>{{ $t('risk.table3_5') }}</td>
        <td>/</td>
        <td>
          {{ Number(renderData.climateRiskMateriality2.materialityThresholdOfShadow * 100).toFixed(2) + '%' }}
        </td>
        <td colspan="3">
        </td>
      </tr>
      <tr class="bg">
        <td>{{ $t('risk.table1_2') }}</td>
        <td>{{ $t('risk.table3_2') }}</td>
        <td>{{ $t('risk.table3_3', [baseFormData.shadowDevideEbitdaOption2]) }}</td>
        <td>{{ $t('risk.Materiality') }}</td>
        <td>{{ $t('risk.table1_11') }}</td>
        <td>{{ $t('risk.table1_5') }}</td>
      </tr>
      <tr>
        <td>{{ $t('risk.table1_6') }}</td>
        
        <td>
          <div :class="isTable3Line1tag ? 'red' : ''">
            {{
              isTable3Line1tag ? '*' : ''
            }}{{ Number(renderData.climateRiskMateriality2.physicalRisk * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>/</td>
        <td>
          <div :class="isTable3Line1tag ? 'red' : ''">           
            {{ isTable3Line1tag ? '*' : '' }}{{ renderData.climateRiskMateriality2.physicalRiskMaterial }}
          </div>
        </td>
        <td>
          <div :class="isTable3Line1tag ? 'red' : ''">
            {{
              isTable3Line1tag ? '*' : ''
            }}{{ Number(renderData.climateRiskMateriality2.physicalRiskWeighting * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          {{ $t('risk.table3_node1') }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('risk.table1_8') }}</td>
        <td>/</td>
        <!-- <td>
          <div :class="isTable3Line2tag ? 'red' : ''">
            {{
              isTable3Line2tag ? '*' : ''
            }}{{ Number(renderData.climateRiskMateriality2.transitionRisk * 100).toFixed(2) + '%' }}
          </div>
        </td> -->
        <td>
          <div :class="isTable3Line2tag ? 'red' : ''">
            {{
              isTable3Line2tag ? '*' : ''
            }}{{ Number(renderData.climateRiskMateriality2.transitionRiskOfShadow * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          <div :class="isTable3Line2tag ? 'red' : ''">
            {{ isTable3Line2tag ? '*' : '' }}{{ renderData.climateRiskMateriality2.transitionRiskMaterial }}
          </div>
        </td>
        <td>
          <div :class="isTable3Line2tag ? 'red' : ''">
            {{
              isTable3Line2tag ? '*' : ''
            }}{{ Number(renderData.climateRiskMateriality2.transitionRiskWeighting * 100).toFixed(2) + '%' }}
          </div>
        </td>
        <td>
          {{ $t('risk.table3_node2') }}
        </td>
      </tr>
      <tr
        v-if="isTable3Note"
      >
        <td colspan="6">
          {{ $t('risk.table1_10') }}
        </td>
      </tr>
    </table>
    <div class="text text_bottom">
      {{ $t('risk.text4') }}
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { calculate } from '@/api/data-platform/FMCC'

export default {
  name: 'riskAssessment',
  props: ['baseFormData', 'id'],
  data() {
    return {
      calculate: null,
      loading: false
    }
  },
  computed: {
    // 获取的数据处理
    renderData() {
      let res = {}
      if (this.calculate && this.calculate.statsOfSASB) {
        res = { ...this.calculate }
        res.statsOfSASB = this.calculate.statsOfSASB.map(item => {
          return {
            ...item,
            weightings: Number(item.weightings * 100).toFixed(2) + '%',
            emissionShares: Number(item.emissionShares * 100).toFixed(2) + '%'
          }
        })
      }
      this.$nextTick(() => {
        this.init()
      })
      return res
    },
    // echarts数据处理
    weightings() {
      let res = []
      if (this.calculate && this.calculate.statsOfSASB) {
        this.calculate.statsOfSASB.forEach(item => {
          res.push(item.weightings)
        })
      }
      return res
    },
    // echarts数据处理
    emissionShares() {
      let res = []
      if (this.calculate && this.calculate.statsOfSASB) {
        this.calculate.statsOfSASB.forEach(item => {
          res.push(item.emissionShares)
        })
      }
      return res
    },
    // echarts数据处理
    sasb() {
      let res = []
      if (this.calculate && this.calculate.statsOfSASB) {
        this.calculate.statsOfSASB.forEach(item => {
          res.push(item.sasb)
        })
      }
      return res
    },
    lang() {
      return this.$store.getters.language
    },
    // 标签，提示显示判断
    isTable1Line1tag() {
      if ((this.calculate.climateRisk.physicalRisk < this.calculate.climateRisk.threshold) && (this.calculate.climateRisk.physicalRiskWeighting < this.calculate.climateRisk.threshold)) {
        return true
      } else {
        return false
      }
    },
    isTable1Line2tag() {
      if ((this.calculate.climateRisk.transitionRisk < this.calculate.climateRisk.threshold) && (this.calculate.climateRisk.transitionRiskWeighting < this.calculate.climateRisk.threshold)) {
        return true
      } else {
        return false
      }
    },
    isTable1Note() {
      return this.isTable1Line1tag || this.isTable1Line2tag
    },
    isTable2Line1tag() {
      if ((this.calculate.climateRiskMateriality1.physicalRisk < this.calculate.climateRiskMateriality1.threshold) && (this.calculate.climateRiskMateriality1.physicalRiskWeighting < this.calculate.climateRiskMateriality1.threshold)) {
        return true
      } else {
        return false
      }
    },
    isTable2Line2tag() {
      if ((this.calculate.climateRiskMateriality1.transitionRisk < this.calculate.climateRiskMateriality1.threshold) && (this.calculate.climateRiskMateriality1.transitionRiskWeighting < this.calculate.climateRiskMateriality1.threshold)) {
        return true
      } else {
        return false
      }
    },
    isTable2Note() {
      return this.isTable2Line1tag || this.isTable2Line2tag
    },
    isTable3Line1tag() {
      if ((this.calculate.climateRiskMateriality2.physicalRisk < this.calculate.climateRiskMateriality2.thresholdOfPhysical) && (this.calculate.climateRiskMateriality2.physicalRiskWeighting < this.calculate.climateRiskMateriality2.thresholdOfPhysical)) {
        return true
      } else {
        return false
      }
    },
    isTable3Line2tag() {
      if ((this.calculate.climateRiskMateriality2.transitionRisk < this.calculate.climateRiskMateriality2.materialityThresholdOfShadow) && (this.calculate.climateRiskMateriality2.transitionRiskWeighting < this.calculate.climateRiskMateriality2.materialityThresholdOfShadow)) {
        return true
      } else {
        return false
      }
    },
    isTable3Note() {
      return this.isTable3Line1tag || this.isTable3Line2tag
    }
  },
  watch: {
    // 切换语言重新请求数据
    lang(newVal, old) {
      if (this.id && this.baseFormData) {
        this.getRisk()
      }
    }
  },
  // mounted() {
  //   this.getRisk()
  // },
  methods: {
    // 请求本页数据
    getRisk() { 
      // if(this.baseFormData !==null && this.id !==""){
        this.loading = true
        calculate({
          id: this.id,
          ...this.baseFormData,
          lang: this.lang
        }).then(res => {
          console.log(res)
          this.calculate = res.data
          this.loading = false
        })
      // }
    
    },
    // 生成echarts图
    init() {
      let chartDom = document.getElementById('bar_box1')
      let myChart = echarts.init(chartDom)
      myChart.resize({ height: this.sasb.length * 14 + 120 })
      let option

      option = {
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'shadow'
        //   },
        // },
        legend: {
          bottom: 20
        },
        grid: {
          top: '40',
          left: '8%',
          right: '4%',
          bottom: '50',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisLabel: {
            formatter: function(value) {
              return Number(value * 100).toFixed(2) + '%'
            }
          }
        },
        yAxis: {
          type: 'category',
          data: this.sasb,
          axisLabel: {
            interval: 0
          }
        },
        series: [
          {
            name: this.lang === 'en' ? 'Weightings' : '占基金资产净值比例',
            type: 'bar',
            data: this.weightings
          },
          {
            name: this.lang === 'en' ? 'Emission shares' : '排放量分配占比',
            type: 'bar',
            data: this.emissionShares
          }
        ]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped lang="scss">
$text_color: #1F5080;
@mixin fontStyle {
  font-family: 'PingFang SC';
  font-weight: 900;
}

#bar_box1 {
  width: 100%;
  min-height: 120px;
}

.risk_container {

  .footprint_title {
    height: 40px;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: $text_color;
    @include fontStyle
  }

  .title_bg {
    height: 40px;
    background-color: #DFEBF9;
    line-height: 40px;
  }

  .text {
    padding: 16px 30px 16px 30px;
    font-family: 'PingFang SC';
    font-size: 14px;
    line-height: 20px;
    color: $text_color;
  }

  .text_mid {
    padding: 16px 30px 16px 30px;

    p {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .title_mid {
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #D9D9D9;
    border-left: 1px dashed #D9D9D9;
    border-right: 1px dashed #D9D9D9;
    background: #F7F8FB;
  }

  .text_bottom {
    margin: 20px 10px;
    font-family: 'PingFang SC';
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1F5080;
  }

  .table {
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px dashed #D9D9D9;
    border-right: 1px dashed #D9D9D9;

    .bg {
      background-color: #F7F8FB;
    }

    td {
      height: 40px;
      border-left: 1px dashed #D9D9D9;
      border-top: 1px dashed #D9D9D9;
      padding: 10px 20px;
      text-align: center;
      font-family: 'PingFang SC';
      font-size: 14px;
      line-height: 20px;
      color: #1F5080;

      &:first-child {
        width: 180px;
      }

      &:nth-child(2) {
        width: 190px;
      }

      &:nth-child(3) {
        width: 164px;
      }
    }

    .red {
      width: 110px;
      height: 40px;
      margin: 0 auto;
      background: #F07B31;
      border-radius: 6px;
      font-family: 'PingFang SC';
      font-weight: 700;
      font-size: 14px;
      line-height: 40px;
      color: #ffffff;
    }

    .green {
      width: 110px;
      height: 40px;
      margin: 0 auto;
      background: #AED568;
      border-radius: 6px;
      font-family: 'PingFang SC';
      font-weight: 700;
      font-size: 14px;
      line-height: 40px;
      color: #1F5080;
    }
  }
}

</style>
