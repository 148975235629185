var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "FMCCC_container" },
    [
      _c("MethodIntroduc"),
      _c("FMCCCtop", {
        on: {
          uploaded: _vm.uploaded,
          computed: _vm.computed,
          computedNote: _vm.computedNote,
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isComputed,
              expression: "!isComputed",
            },
          ],
          staticClass: "init_table",
        },
        [_c("FileTable", { attrs: { data: _vm.fileData, id: _vm.id } })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isComputed,
              expression: "isComputed",
            },
          ],
          staticClass: "res_box",
        },
        [
          _c("Tabs", {
            ref: "tab",
            attrs: {
              baseFormData: _vm.baseFormData,
              note: _vm.note,
              id: _vm.id,
              isShowTab: _vm.isShowTab,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }