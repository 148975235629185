<template>
    <div class="mandatory-data-wrapper">
        <el-table
            ref="table"
            :data="data"
            size="mini"
            class="mandatory-data-table"
            width="100%"
            :height="height || 'calc(100%)'"
            :max-height="height || 'calc(100%)'"
            header-cell-class-name="header-cell"
            :cell-style="addCellStyle"
            :header-cell-style="headerStyle"
            :span-method="arraySpanMethod"
            :row-class-name="addRowClass"
            :cell-class-name="addCellClass"
            @cell-click="cellClickHandler"
            :border="true"
        >
            <el-table-column
                v-for="col in columns"
                :key="col.prop"
                :prop="col.prop"
                :label="col.label"
                :fixed="col.fixed"
                :min-width="'' + col.width"
                :class-name="col.className"
            >
                <template slot="header" slot-scope="{ column }">
                    {{ column.label }}
                    <el-tooltip
                        v-if="column.property.startsWith('coverage')"
                        content="Coverage = Σ(Current Value of securities, which the underlying data is available) / Total Current Value for the portfolio"
                        placement="bottom"
                        :transition="null"
                    >
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
            <span slot="empty"></span>
        </el-table>
    </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Array,
      default: null
    },
    columns: {
      type: Array,
      default: () => []
    },
    displayType: {
      type: String,
      default: null
    },
    height: {
      type: String || Number,
      default: null
    },
    hasDrillDownPermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  created() {
    this.impactObject = {}
    this.factorsObject = {}
    this.positionObject = {}
    console.log(
      'MandatoryTable hasDrillDownPermission:',
      this.hasDrillDownPermission
    )
  },
  watch: {
    data(list) {
      this.$refs.table.doLayout()
      console.log('data:', list)
    },
    columns() {
      this.$refs.table.doLayout()
      console.log('columns:', this.columns)
    }
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.displayType !== 'full') {
        if (row.isTitle) {
          // if (column.property === 'metric') {
          if (columnIndex === 0) {
            return [1, 10]
          } else {
            return [0, 0]
          }
        }
        return
      }

      if (columnIndex === 0) {
        if (row.impactSpan > 0) {
          return [row.impactSpan, 1]
        } else if (row.impactSpan === 0) {
          return [0, 0]
        }
      }

      if (columnIndex === 1) {
        if (row.factorSpan > 0) {
          return [row.factorSpan, 1]
        } else if (row.factorSpan === 0) {
          return [0, 0]
        }
      }

      if (row.isTitle) {
        // if (column.property === 'metric') {
        if (columnIndex === 2) {
          return [1, 12]
        } else {
          return [0, 0]
        }
      }
    },
    headerStyle({ row, column, rowIndex, columnIndex }) {
      const style = {}

      if (this.displayType === 'full') {
        row[1].colSpan = 2 // 第二个表头占两格
        row[0].colSpan = 0 // 第一个表头占零格
        if (columnIndex === 0) {
          // 隐藏第一个表头
          style['display'] = 'none'
        }

        if ([3, 4, 5, 6, 7, 8, 9, 10, 11].indexOf(columnIndex) > -1) {
          style['text-align'] = 'right'
        }
      } else {
        if ([1, 2, 3, 4, 5, 6, 7, 8, 9].indexOf(columnIndex) > -1) {
          style['text-align'] = 'right'
        }
      }
      return style
    },
    addCellStyle({ row, column, rowIndex, columnIndex }) {
      const style = {}
      if (row.isTitle) {
        style['text-align'] = 'left'
        style['font-weight'] = 'bold'
        return style
      }

      if (this.displayType === 'full') {
        if ([3, 4, 5, 6, 7, 8, 9, 10, 11].indexOf(columnIndex) > -1) {
          style['text-align'] = 'right'
        }
      } else {
        if ([1, 2, 3, 4, 5, 6, 7, 8, 9].indexOf(columnIndex) > -1) {
          style['text-align'] = 'right'
        }
      }
      return style
    },
    addCellClass({ row, column, rowIndex, columnIndex }) {
      //20230818 fix: Carbon footprint不可点击
      if (
        column.property.startsWith('calcValue') &&
        this.hasDrillDownPermission && row['metric'] !='Carbon footprint'
      ) {
        return 'drill-down-cell'
      }
      return 'normal-cell-class'
    },
    cellClickHandler(row, column, cell, event) {
      if (
        !column.property.startsWith('calcValue') ||
        !this.hasDrillDownPermission
      ) {
        return
      }
      const metricId = row.metricId
      const quarter = column.property.substring(column.property.length - 1)
      this.$emit('cellClick', {
        metricId,
        quarter
      })
    },
    addRowClass({ row, rowIndex }) {
      if (row.isTotal) {
        return 'sfdr-portfolio-table-total-row'
      }
    },
    groupBy(list, fn) {
      const groups = {}
      list.forEach(function(o) {
        const group = fn?.(o)
        if (!group) {
          return
        }
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return groups
    }
  }
}
</script>

<style lang="scss" scoped>
.mandatory-data-wrapper {
    font-family: PingFang SC-Regular;
    // height: calc(100%);
    height: calc(100% - 113px);

    flex: 1;

    .mandatory-data-table {
        height: calc(100%);

        ::v-deep .header-cell {
            color: #2f5584;
            background-color: #e0ecfa;
            font-size: 13px;
            font-weight: 400;
            color: #215796;
            background-color: #e1ebf8;
            font-family: PingFang SC-Regular, PingFang SC;
            // font-weight: 600;
            // line-height: 20px;
        }

        ::v-deep .normal-cell-class {
            div {
                word-break: break-word;
            }
        }
        ::v-deep .drill-down-cell:hover {
            div {
                word-break: break-word;
                color: #01579b;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
</style>
<style lang="scss">
.mandatory-data-table {
    // .el-table__body-wrapper {
    //     overflow-x: hidden !important;
    // }
}

.hide {
    display: none;
}
</style>