<template>
  <el-dialog :visible="visible" :title="fieldInfo.columnMetaId ? '编辑输出字段' : '编辑输出字段'" width="600px" @close="close">
    <el-form :model="formData" :rules="formRules" ref="form" label-width="120px">
      <el-form-item label="输出字段" prop="columnAlias">
        <el-input v-model="formData.columnAlias" placeholder="请输入输出字段"></el-input>
      </el-form-item>
      <el-form-item label="输出名称" prop="columnOutputTitle">
        <el-input v-model="formData.columnOutputTitle" placeholder="请输入输出名称"></el-input>
      </el-form-item>
      <el-form-item label="输出顺序" prop="finalOrder">
        <el-input-number v-model="formData.finalOrder" :precision="0" size="small" :controls="false" style="width: 100%"></el-input-number>
      </el-form-item>
      <el-form-item label="运算语句" prop="expressionContent">
        <!-- <el-radio-group v-model="formData.fieldType">
          <el-radio label="1">计算字段</el-radio>
          <el-radio label="2">应用函数字段</el-radio>
        </el-radio-group> -->
        <el-input v-model="formData.expressionContent" type="textarea" rows="5" placeholder="请输入字段定义表达式"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="close">取消</el-button>
      <el-button type="primary" size="small" @click="handleSave">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: ['visible', 'fieldInfo'],
  data () {
    return {
      formData: {
        columnAlias: '',
        columnOutputTitle: '',
        finalOrder: null,
        fieldType: '1',
        expressionContent: '' // 表达式
      },
      formRules: {
        columnAlias: [{ required: true, message: '输出字段不能为空', trigger: 'blur'}],
        columnOutputTitle: [{ required: true, message: '输出名称不能为空', trigger: 'blur'}],
        expressionContent: [{ required: true, message: '字段定义表达式不能为空', trigger: 'blur'}],
      }
    }
  },
  watch: {
    fieldInfo: {
      handler (val) {
        if (Object.keys(val).length) {
          this.formData = JSON.parse(JSON.stringify(val));
          if (val.isCalculationColumn) { // 是否计算字段
            this.formData.fieldType = '1';
          } else if (val.isApplyFuncColumn) { // 是否应用函数字段
            this.formData.fieldType = '2';
          } else {
            this.formData.fieldType = '';
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 保存
    handleSave () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.fieldType === '1') {
            this.formData.isCalculationColumn = 1;
            this.formData.isApplyFuncColumn = 0;
          } else if (this.formData.fieldType === '2') {
            this.formData.isCalculationColumn = 0;
            this.formData.isApplyFuncColumn = 1;
          }
          this.$emit('saveField', this.formData);
          this.close();
        }
      })
    },
    close () {
      this.$emit('close');
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-input-number .el-input__inner {
  text-align: left;
}
</style>
