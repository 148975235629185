export default {
    //数据浏览器表单标题
    elTable: {
        index: 'Index',
        secname: 'Security Name',
        seccode: 'Security ID',
    },

    // 树状图最外层标题
    treeTitle: {
        labelClimate: 'Climate Risk Data',
        labelCarbonData: 'Carbon Emission Data',
        indicatorName: 'Indicators',
        scopeName: 'Optional List'
    },

    // 自选清单
    selfSelect: {
        label: 'Self Selection',
        dialogTitle: 'Add Self Selection',
        dialogTitle2: 'Modify Self Selection',
        formLabel: 'Self Selection Name',
        delete: 'Delete Self Selection',
        rename: 'Rename',
        nameTip1: 'Please enter the name',
        nameTip2: 'The length of the name is 1~20',
    },
    // 指标
    indicator: {
        title: 'Add indicator information',
        time: 'Transaction Date',
        placeholder: 'Select Year',
        tip: 'Please select a transaction date',
    },
    //指标说明表头
    instructions: {
        title1: 'Data Point Type',
        title2: 'Data Point Name',
        title3: 'Explanation',
    },
    // 导出
    export: {
        title: 'Stock Carbon Indicator'
    },
    // 数据校验
    importCheck:{
     checkTitle:'Check Result'
    }
}

//指标说明
export const instructionsTableData = [
    {
        id: 1,
        type: "Carbon Emission Data",
        code: "span1",
        name: "Scope 1 Emissions(tCO2e)",
        desc: "Direct GHG emissions that occur from sources owned or controlled by the reporting company. Provided by GSG Carbon Discovery",
    },
    {
        id: 2,
        // type: "Carbon Emission Data",
        code: "span1",
        name: "Scope 2 Emissions(tCO2e)",
        desc: `Indirect GHG emissions from the generation of purchased or acquired electricity,
        steam, heating, or cooling consumed by the reporting company. Scope 2 emissions
        physically occur at the facility where the electricity, steam, heating, or cooling is generated. Provided by GSG Carbon Discovery`,
    },
    {
        id: 3,
        // type: "Carbon Emission Data",
        code: "span1",
        name: "Scope 1+2 Emissions(tCO2e)",
        desc: "Scope 1+2 emissions data, provided by GSG Carbon Discovery",
    },
    {
        id: 4,
        // type: "Carbon Emission Data",
        code: "span1",
        name: "Emission data type",
        desc: "Emission data type, representing either reported data or modelled data",
    }, {
        id: 5,
        // type: "Carbon Emission Data",
        code: "span1",
        name: "Intensity(tCO2e/Million)",
        desc: "Emission intensity, caculated based on Scope 1+2 Emissions(tCO2e) and Revenue",
    },
    // {
    //     id: 6,
    //     // type: "Carbon Emission Data",
    //     code: "span1",
    //     name: "Intensity(tCO2e/Million CNY)",
    //     desc: "Emission intensity, caculated based on Scope 1+2 Emissions(tCO2e) and Revenue",
    // },

    {
        id: 7,
        type: "Climate Risk Data",
        code: "span2",
        name: "SASB Industry",
        desc: "Industry classification based on SASB methodology",
    },
    {
        id: 8,
        // type: "Climate Risk Data",
        code: "span2",
        name: "PHYSICAL RISK SCORE",
        desc: "Assess the relevance and materiality of physical risk for each underlying asset based on SASB methodology as recommended by FMCC",
    },
    {
        id: 9,
        // type: "Climate Risk Data",
        code: "span2",
        name: "TRANSITION RISK SCORE A",
        desc: "Assess the relevance and materiality of transition risk for each underlying asset based on SASB methodology as recommended by FMCC",
    },
    {
        id: 10,
        // type: "Climate Risk Data",
        code: "span2",
        name: "TRANSITION RISK SCORE B",
        desc: "Assess the relevance and materiality of transition risk for each underlying asset based on SASB methodology as recommended by FMCC, and further step has been taken to evaluate the influence carbon prices could exert on EBITDA under transition economic development",
    },
    {
        id: 11,
        // type: "Climate Risk Data",
        code: "span2",
        name: "Shadow cost",
        desc: "Shadow carbon price reflecting the impact transition to a low carbon economy could bring about",
    },
]


// console.log(getToken())
// if (getToken()) {
    // 指标--气候相关风险
    // getDicts("tree_climate_risk_en").then((response) => {
    //     let arr = response.data;
    //     arr.map((item) => {
    //         treeClimateChildren1.push({
    //             id: item.dictValue,
    //             label: item.dictLabel
    //         })
    //         return treeClimateChildren1;
    //     })
    // }).catch((err) => {
    //     console.log(err)
    // });

    // // 指标--碳排放数据
    // getDicts("carbon_data_en").then((response) => {
    //     let arr = response.data;
    //     arr.map((item) => {
    //         treecarbonDataChildren1.push({
    //             id: item.dictValue,
    //             label: item.dictLabel
    //         })
    //         return treecarbonDataChildren1;
    //     })
    // }).catch((err) => {
    //     console.log(err)
    // });
// }
// // 股票集-A股
// getDicts("stock_a_en").then((response) => {
//     let arr = response.data;
//     arr.map((item) => {
//         treeStockAChildren1.push({
//             id: item.dictValue,
//             label: item.dictLabel,
//             type: 'stockA'
//         })
//         return treeStockAChildren1;
//     })
// }).catch((err) => {
//     console.log(err)
// });

// // 股票集-港股
// getDicts("stock_hk_en").then((response) => {
//     let arr = response.data;
//     arr.map((item) => {
//         treeStockHKChildren1.push({
//             id: item.dictValue,
//             label: item.dictLabel,
//             type: 'stockHK'
//         })
//         return treeStockHKChildren1;
//     })
// }).catch((err) => {
//     console.log(err)
// });