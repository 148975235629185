<template>
    <div class="review-menu">
        <div class="menu-group">
            <div class="menu-group-title">
                <img class="menu-icon" src="~@/assets/sfdr/images/file.png" />
                PAI Calculation
            </div>
            <div
                class="menu-item"
                :class="viewId === 'main' ? 'selected' : ''"
                @click="viewId = 'main'"
            >
                Mandatory PAI
            </div>
            <div
                class="menu-item"
                :class="viewId === 'option' ? 'selected' : ''"
                @click="viewId = 'option'"
            >
                Additional PAI
            </div>
            <div
                class="menu-item"
                :class="viewId === 'summary' ? 'selected' : ''"
                @click="viewId = 'summary'"
            >
                Summary
            </div>
        </div>
        <div class="menu-group">
            <div class="menu-group-title">
                <img class="menu-icon" src="~@/assets/sfdr/images/file.png" />
                Report Editor
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      viewId: 'summary'
    }
  },
  watch: {
    viewId(viewId) {
      this.$emit('showView', viewId)
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.review-menu {
    user-select: none;
    width: 100%;
    .menu-group {
        padding-bottom: 8px;
        .menu-icon {
            width: 16px;
            height: 16px;
            position: relative;
            top: 3px;
        }
        .menu-item {
            cursor: pointer;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 24px;
            text-align: left;
            // font-size: 13px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #215796;
            line-height: 20px;
            transition: border-color 0.3s, background-color 0.3s, color 0.3s;
        }
        .menu-item:hover {
            outline: none;
            background-color: #ecf5ff;
        }
        .menu-group-title {
            padding-top: 8px;
            padding-bottom: 8px;
            text-align: left;
            // font-size: 13px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #215796;
            line-height: 20px;
        }
    }
    @keyframes fadeBackground {
        from {
            background-color: transparent;
        }
        to {
            background-color: #dae8f7;
        }
    }
    .selected {
        background: #dae8f7;
        color: #fff;
        border-radius: 2px 2px 2px 2px;
        animation: fadeBackground 0.8s 1;
    }
}
</style>