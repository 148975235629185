var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "table_box",
    },
    [
      _c(
        "el-table",
        {
          staticClass: "fileTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.renderData.rows,
            stripe: "",
            "header-row-class-name": _vm.tableHead,
          },
        },
        [
          _c("template", { slot: "empty" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("fileTable.nodataText")))]),
          ]),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "120px",
              prop: "portfolioDate",
              label: _vm.$t("fileTable.portfolioDate"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100px",
              prop: "fundId",
              label: _vm.$t("fileTable.fundId"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "140px",
              prop: "fundName",
              label: _vm.$t("fileTable.fundName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "140px",
              prop: "totalNetAsset",
              label: _vm.$t("fileTable.totalNetAsset"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "130px",
              prop: "isin",
              label: _vm.$t("fileTable.isin"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "130px",
              prop: "ticker",
              label: _vm.$t("fileTable.ticker"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "200px",
              prop: "securityName",
              label: _vm.$t("fileTable.securityName"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "shares",
              label: _vm.$t("fileTable.shares"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "160px",
              prop: "marketValue",
              label: _vm.$t("fileTable.marketValue"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "120px",
              prop: "navCurrency",
              label: _vm.$t("fileTable.currency"),
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "120px",
              prop: "assetType",
              label: _vm.$t("fileTable.assetType"),
            },
          }),
        ],
        2
      ),
      _vm.renderData.total
        ? _c("el-pagination", {
            staticClass: "pagination",
            attrs: {
              background: "",
              layout: "prev, pager, next",
              total: _vm.renderData.total,
            },
            on: { "current-change": _vm.handleCurrentChange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }