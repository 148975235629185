var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.render
    ? _c(
        "div",
        { staticClass: "upload-page" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "sfdr-portfolio-data-tabs",
              attrs: { type: "card" },
              model: {
                value: _vm.selectedPortfolioDate,
                callback: function ($$v) {
                  _vm.selectedPortfolioDate = $$v
                },
                expression: "selectedPortfolioDate",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Portfolio Q1",
                    name: "Q1",
                    lazy: true,
                    disabled: !_vm.q1Data,
                  },
                },
                [
                  _c("portfolio-table", {
                    attrs: { data: _vm.q1Data, currency: _vm.currencyObj[1] },
                    on: { showUploadDialog: _vm.showDialog },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Portfolio Q2",
                    name: "Q2",
                    lazy: true,
                    disabled: !_vm.q2Data,
                  },
                },
                [
                  _c("portfolio-table", {
                    attrs: { data: _vm.q2Data, currency: _vm.currencyObj[2] },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Portfolio Q3",
                    name: "Q3",
                    lazy: true,
                    disabled: !_vm.q3Data,
                  },
                },
                [
                  _c("portfolio-table", {
                    attrs: { data: _vm.q3Data, currency: _vm.currencyObj[3] },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Portfolio Q4",
                    name: "Q4",
                    lazy: true,
                    disabled: !_vm.q4Data,
                  },
                },
                [
                  _c("portfolio-table", {
                    attrs: { data: _vm.q4Data, currency: _vm.currencyObj[4] },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !!_vm.q1Data
            ? _c("div", { staticClass: "footer" }, [
                !!_vm.currentWarningMessage
                  ? _c("p", { staticClass: "warning-message" }, [
                      _vm._v(
                        " Warning: " + _vm._s(_vm.currentWarningMessage) + " "
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "btn-container" },
                  [
                    _c("el-button", { on: { click: _vm.clearData } }, [
                      _vm._v("Quit"),
                    ]),
                    _c("el-button", { on: { click: _vm.showDialog } }, [
                      _vm._v("Re-upload"),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "primary",
                        attrs: { type: "primary" },
                        on: { click: _vm.nextStep },
                      },
                      [_vm._v("Next Step")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("UploadDialog", {
            attrs: { visible: _vm.showUploadDialog },
            on: {
              triggerUploadDialog: _vm.triggerUploadDialog,
              uploaded: _vm.uploadedSuccess,
              clearOperationData: function () {},
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }