var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "empty-block" },
    [
      _c("p", { staticClass: "empty-tips" }, [
        _vm._v(" " + _vm._s(_vm.$t("sfdr.upload.emptyWarning")) + " "),
      ]),
      _c(
        "el-button",
        {
          staticClass: "upload-btn",
          attrs: { size: "mini", type: "primary" },
          on: { click: _vm.clickUpload },
        },
        [_vm._v(_vm._s(_vm.$t("sfdr.upload.uploadBtn")))]
      ),
      _c(
        "el-button",
        {
          staticClass: "download-template",
          attrs: {
            type: "text",
            icon: "el-icon-download",
            underline: false,
            href: "/sfdr/downloadTemplate",
          },
          on: { click: _vm.downloadTemplate },
        },
        [_vm._v(" " + _vm._s(_vm.$t("sfdr.upload.downloadTemplate")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }