var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "production-page" },
    [
      _c("div", { staticClass: "page-title" }, [
        _c("span", { staticStyle: { "font-size": "28px" } }, [
          _vm._v("产品信息"),
        ]),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleAdd },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showSearch,
                  expression: "showSearch",
                },
              ],
              ref: "queryForm",
              attrs: {
                model: _vm.queryParams,
                inline: true,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "接口名称", prop: "apiName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入接口名称", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.apiName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "apiName", $$v)
                      },
                      expression: "queryParams.apiName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生效日期" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "-",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.daterangeEffectiveDate,
                      callback: function ($$v) {
                        _vm.daterangeEffectiveDate = $$v
                      },
                      expression: "daterangeEffectiveDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryParams.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "status", $$v)
                        },
                        expression: "queryParams.status",
                      },
                    },
                    _vm._l(_vm.statusList, function (status) {
                      return _c("el-option", {
                        key: status.value,
                        attrs: { label: status.label, value: status.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-area common-table-style" },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.productionList, height: "100%", stripe: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "产品ID", align: "center", prop: "productId" },
              }),
              _c("el-table-column", {
                attrs: { label: "接口名称", align: "center", prop: "apiName" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "生效日期",
                  align: "center",
                  prop: "effectiveDate",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parseTime(
                                scope.row.effectiveDate,
                                "{y}-{m}-{d}"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center", prop: "status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.listValue2label(_vm.statusList, row.status)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "更新者", align: "center", prop: "updateBy" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "更新时间",
                  align: "center",
                  prop: "updateTime",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parseTime(
                                scope.row.updateTime,
                                "{y}-{m}-{d} {h}:{i}:{s}"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-edit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "接口名称", prop: "apiName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入接口名称" },
                    model: {
                      value: _vm.form.apiName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "apiName", $$v)
                      },
                      expression: "form.apiName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生效日期", prop: "effectiveDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "请选择生效日期",
                    },
                    model: {
                      value: _vm.form.effectiveDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "effectiveDate", $$v)
                      },
                      expression: "form.effectiveDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusList, function (status) {
                      return _c("el-option", {
                        key: status.value,
                        attrs: { label: status.label, value: status.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }