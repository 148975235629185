var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navigation" }, [
    _c("div", {
      ref: "navigation",
      staticStyle: { display: "inline-block", width: "100%" },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "navigation-heading",
        style: { width: _vm.navigationWidth },
      },
      _vm._l(_vm.heading, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: "heading-item heading-" + item.level,
            on: {
              click: function ($event) {
                return _vm.headingItemClick(item)
              },
            },
          },
          [_vm._v(" " + _vm._s(item.text) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }