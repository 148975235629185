<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      title:
        this.$store.state.settings.dynamicTitle &&
        this.$store.state.settings.title,
      titleTemplate: (title) => {
        return title
          ? `${title} - ${process.env.VUE_APP_TITLE}`
          : process.env.VUE_APP_TITLE
      }
    }
  },
  data() {
    return {}
  }
  // computed: {
  //   key() {
  //     return this.$route.path;
  //   },
  // },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     deep: true,
  //     handler(to, from) {

  //       console.log(to, from);
  //     },
  //   },
  // },
}
</script>
<style lang="scss">
/* 修复表格fixed后错乱问题 */
body .el-table th.gutter {
  display: table-cell !important;
}

.el-table_fixed-right {
  height: 100% !important;
}

//滚动条样式
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent; //滚动槽
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 6px;
  background-color: #c2c2c2;
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 6px;
  background-color: #c2c2c2;
  -webkit-border-radius: 6px;
}

//滚动条鼠标移上去的样式
::-webkit-scrollbar-thumb:vertical:hover, ::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #808080;
}
</style>
