<template>
    <div class="upload-page" v-if="render">
        <!-- <el-button class="help-button" @click="$emit('showHelp')">
            Instructions
        </el-button> -->
        <el-tabs
            class="sfdr-portfolio-data-tabs"
            v-model="selectedPortfolioDate"
            type="card"
        >
            <el-tab-pane
                label="Portfolio Q1"
                name="Q1"
                :lazy="true"
                :disabled="!q1Data"
            >
                <portfolio-table
                    :data="q1Data"
                    :currency="currencyObj[1]"
                    @showUploadDialog="showDialog"
                ></portfolio-table>
            </el-tab-pane>
            <el-tab-pane
                label="Portfolio Q2"
                name="Q2"
                :lazy="true"
                :disabled="!q2Data"
            >
                <portfolio-table
                    :data="q2Data"
                    :currency="currencyObj[2]"
                ></portfolio-table>
            </el-tab-pane>
            <el-tab-pane
                label="Portfolio Q3"
                name="Q3"
                :lazy="true"
                :disabled="!q3Data"
            >
                <portfolio-table
                    :data="q3Data"
                    :currency="currencyObj[3]"
                ></portfolio-table>
            </el-tab-pane>
            <el-tab-pane
                label="Portfolio Q4"
                name="Q4"
                :lazy="true"
                :disabled="!q4Data"
            >
                <portfolio-table
                    :data="q4Data"
                    :currency="currencyObj[4]"
                ></portfolio-table>
            </el-tab-pane>
        </el-tabs>
        <div class="footer" v-if="!!q1Data">
            <p class="warning-message" v-if="!!currentWarningMessage">
                Warning: {{ currentWarningMessage }}
            </p>
            <div class="btn-container">
                <el-button @click="clearData">Quit</el-button>
                <el-button @click="showDialog">Re-upload</el-button>
                <el-button class="primary" type="primary" @click="nextStep"
                    >Next Step</el-button
                >
            </div>
        </div>
        <UploadDialog
            :visible="showUploadDialog"
            @triggerUploadDialog="triggerUploadDialog"
            @uploaded="uploadedSuccess"
            @clearOperationData="() => {}"
        ></UploadDialog>
    </div>
</template>

<script>
import PortfolioTable from '../components/upload/PortfolioTable.vue'
import UploadDialog from '../components/upload/UploadDialog.vue'
import {
  getPortfolioList,
  getPortfolioDetails
} from '@/api/data-platform/SFDR.js'
import numeral from 'numeral'

export default {
  components: {
    PortfolioTable,
    UploadDialog
  },
  data() {
    return {
      operationId: null,
      showUploadDialog: false,
      showInstructions: false,
      selectedPortfolioDate: 'Q1',
      q1Data: null,
      q2Data: null,
      q3Data: null,
      q4Data: null,
      warningMessage: {},
      render: true,
      currencyObj: {}
    }
  },
  computed: {
    currentWarningMessage() {
      console.log(
        'currentWarningMessage:',
        this.warningMessage[this.selectedPortfolioDate] ?? ''
      )
      return this.warningMessage[this.selectedPortfolioDate] ?? ''
    }
  },
  mounted() {
    // this.openNotification()
    window.addEventListener('resize', this.reload)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.reload)
  },
  methods: {
    reload() {
      // 重新渲染
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
    },
    clearData() {
      this.q1Data = null
      this.q2Data = null
      this.q3Data = null
      this.q4Data = null
      this.selectedPortfolioDate = 'Q1'
      this.$emit('updateOperationId', null)
    },
    triggerUploadDialog(isShow) {
      this.showUploadDialog = isShow
    },
    showDialog() {
      this.showUploadDialog = true
    },
    nextStep() {
      this.$emit('nextStep')
    },
    async uploadedSuccess(operationId, warningMessage) {
      this.operationId = operationId
      this.$emit('updateOperationId', operationId)
      const [portfolioList, portfolioDetails] = await Promise.all([
        getPortfolioList(operationId),
        getPortfolioDetails(operationId)
      ])
      const portfolioMasterIdObj = {
        1: '',
        2: '',
        3: '',
        4: ''
      }
      this.handlePortfolioList(portfolioList, portfolioMasterIdObj)
      this.handlePortfolioDetails(portfolioDetails, portfolioMasterIdObj)
      this.warningMessage = warningMessage
    },
    handlePortfolioList(portfolioList, portfolioMasterIdObj) {
      portfolioList?.data?.forEach((item) => {
        portfolioMasterIdObj[item?.quarter] = item?.id
        this.currencyObj[item?.quarter] = item?.originCurrency
      })
    },
    handlePortfolioDetails(portfolioDetails, portfolioMasterIdObj) {
      const q1List = []
      const q2List = []
      const q3List = []
      const q4List = []
      portfolioDetails?.data?.forEach((item) => {
        if (item.portfolioMasterId === portfolioMasterIdObj[1]) {
          q1List.push(item)
        } else if (item.portfolioMasterId === portfolioMasterIdObj[2]) {
          q2List.push(item)
        } else if (item.portfolioMasterId === portfolioMasterIdObj[3]) {
          q3List.push(item)
        } else if (item.portfolioMasterId === portfolioMasterIdObj[4]) {
          q4List.push(item)
        }
      })

      this.addSummary(q1List)
      this.addSummary(q2List)
      this.addSummary(q3List)
      this.addSummary(q4List)

      this.formatterData(q1List)
      this.formatterData(q2List)
      this.formatterData(q3List)
      this.formatterData(q4List)

      this.handleCashRow(q1List)
      this.handleCashRow(q2List)
      this.handleCashRow(q3List)
      this.handleCashRow(q4List)

      this.q1Data = q1List
      this.q2Data = q2List
      this.q3Data = q3List
      this.q4Data = q4List
    },
    handleCashRow(list) {
      let cashItem
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (item.isCashItem) {
          cashItem = list.splice(i, 1)?.[0]
          break
        }
      }
      if (cashItem) {
        delete cashItem.orderNumber
        delete cashItem.cvForOrigin
        cashItem.shares = null
        cashItem.ticker = 'CASH'
        cashItem.securityName = 'CASH - SFDR'
        list.unshift(cashItem)
      }
    },
    addSummary(list) {
      const totalItem = {
        securityName: 'Total',
        shares: 0,
        cvForOrigin: 0,
        cvForLocalCurrency: 0,
        cvForEur: 0,
        weightingForOrigin: 0,
        weightingForEur: 0,
        isTotal: true
      }
      list.forEach((item) => {
        totalItem.shares += item.shares
        totalItem.cvForOrigin += item.cvForOrigin
        totalItem.cvForLocalCurrency += item.cvForLocalCurrency
        totalItem.cvForEur += item.cvForEur
        totalItem.weightingForOrigin += item.weightingForOrigin
        totalItem.weightingForEur += item.weightingForEur
      })
      list.push(totalItem)

      return list
    },
    formatterData(list) {
      const handleValue = this.handleValue
      list?.forEach((item) => {
        item.shares = handleValue(item.shares, '0.00')
        item.cvForOrigin = handleValue(item.cvForOrigin, '0,0.00')
        item.cvForLocalCurrency = handleValue(item.cvForLocalCurrency, '0,0.00')
        item.cvForEur = handleValue(item.cvForEur, '0,0.00')
        item.weightingForOrigin = handleValue(item.weightingForOrigin, '0.00%')
        item.weightingForEur = handleValue(item.weightingForEur, '0.00%')
      })
    },
    handleValue(value, format = '0.000000') {
      return this.isInvalidNumber(value) ? '─' : numeral(value).format(format)
      // .replace(/\.(0+)$/g, '')
      // .replace(/(0+)$/g, '')
    },
    isInvalidNumber(number) {
      return !number || number == -999999999 || Number.isNaN(number)
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-page {
    height: calc(100%);
    // height: calc(100% - 214px);
    flex: 1;
    display: flex;
    flex-direction: column;

    @import "../assets/footer.scss";
}
</style>
<style lang="scss">
.sfdr-portfolio-data-tabs {
    // height: calc(100% - 42px);
    // max-height: 730px;
    height: 91%;
    // flex: 1;

    .el-tabs__header {
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    .el-tabs__content {
        height: calc(100% - 56px);
    }

    .el-tabs__content > div {
        height: calc(100%);
    }

    .el-tabs__item {
        box-sizing: border-box;
        font-size: 13px !important;
        border-bottom-color: #5998f0 !important;
    }

    .is-active {
        color: #5998f0;
        border: 1px solid #5998f0 !important;
        border-bottom-color: white !important;
        background-color: white;
        font-weight: 400;
        z-index: 100;
    }

    .el-tabs__header {
        margin-bottom: 0;
        position: relative;
        top: 2px;
        z-index: 0;
    }

    .el-table__empty-block {
        width: 100% !important;
    }
}
</style>
