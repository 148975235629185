<template>
    <div
        class="select-item"
        :class="isSelected ? 'selected' : ''"
        @click="handleSelect"
    >
        <span class="select-item-label" :alt="name">{{ name }}</span>
        <!-- <div v-show="isSelected" class="selected-icon"></div> -->
        <img
            v-show="isSelected"
            class="selected-icon"
            src="~@/assets/sfdr/images/selected-icon.png"
            alt=""
        />
    </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    impact: {
      type: String,
      default: ''
    },
    categoryId: {
      type: Number,
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSelected: this.selected
    }
  },
  methods: {
    handleSelect() {
      this.isSelected = !this.isSelected
      if (this.isSelected) {
        this.$emit('paiSelected', this.id, this.impact, this.categoryId)
      } else {
        this.$emit('paiUnSelected', this.id, this.impact, this.categoryId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-item {
    // width: 400px;
    height: 54px;
    flex: 1;
    flex-basis: 400px;
    cursor: pointer;
    background: #ffffff;
    // border-radius: 2px 2px 2px 2px;
    border: 1px solid #ebedf0;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 4px 0px 10px;
    margin: 0 12px 8px 0;
    .select-item-label {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 300;
        color: #606266;
        line-height: 20px;
        overflow: hidden;
        white-space: wrap;
        text-overflow: ellipsis;
    }
    .selected-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 24px;
        height: 18px;
    }
}
.selected {
    border: 2px solid #5998f0;
}
.select-item:hover {
    border: 1px solid #5998f0;
}
.selected:hover {
    border: 2px solid #5998f0;
}
</style>