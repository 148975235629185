<template>
  <div class="key-box">
    <el-card class="box-card">
      <el-form
        ref="form"
        :model="form"
        label-width="130px"
        label-position="center"
      >
        <el-form-item label="APP KEY:">
          <el-input v-model="form.appKey" class="app-input" readonly></el-input>
          <el-button
            class="btn"
            v-clipboard:copy="form.appKey || ''"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            >{{ $t("apiRepository.copyBtn") }}</el-button
          >
        </el-form-item>

        <el-form-item label="APP SECRUITY:">
          <el-input
            readonly
            class="app-input"
            v-model="form.appSecurity"
            :type="flag ? 'text' : 'password'"
            autocomplete="new-password"
          >
            <i
              slot="suffix"
              :class="[flag ? 'iconfont icon-eye' : 'iconfont icon-eye1']"
              style="margin-top: 8px; font-size: 18px"
              @click="flag = !flag"
            />
          </el-input>
          <el-button
            class="btn"
            v-clipboard:copy="form.appSecurity || ''"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            >{{ $t("apiRepository.copyBtn") }}</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { getSecretKey } from "@/api/system/config.js";
export default {
  name: "KeyForm",
  data() {
    return {
      form: {
        appKey: undefined,
        appSecurity: undefined,
      },
      flag: false,
    };
  },
  created() {
    this.getSecretKey();
  },
  methods: {
    // 获取api密钥
    getSecretKey() {
      getSecretKey()
        .then(({ data }) => {
          this.form.appKey = data.appKey;
          this.form.appSecurity = data.appSecret;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 复制成功
    onCopySuccess() {
      this.$message({
        message: this.$t("apiRepository.copySuccess"),
        type: "success",
      });
    },
    // 复制失败
    onCopyError() {
      this.$message.error(this.$t("apiRepository.copyFail"));
    },
  },
};
</script>
<style lang="scss" scoped>
.key-box {
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  .app-input {
    ::v-deep .el-input__inner {
      background-color: #dfebf9;
    }
  }
  .box-card {
    width: 480px;
    padding: 20px 10px;
  }
  ::v-deep .el-form-item__content {
    display: flex;
  }
  .btn {
    margin-left: 10px;
  }
}
</style>