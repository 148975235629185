<template>
    <div class="empty-block">
        <p class="empty-tips">
            {{ $t("sfdr.upload.emptyWarning") }}
        </p>
        <!-- <FileUpload>
                    <template slot="uploadButton">
                        <el-button size="mini" type="primary">{{
                            $t("sfdr.upload.uploadBtn")
                        }}</el-button>
                    </template>
                    <template slot="tip"> {{ " " }}</template>
                </FileUpload> -->
        <el-button
            class="upload-btn"
            size="mini"
            type="primary"
            @click="clickUpload"
            >{{ $t("sfdr.upload.uploadBtn") }}</el-button
        >
        <el-button
            type="text"
            icon="el-icon-download"
            :underline="false"
            class="download-template"
            href="/sfdr/downloadTemplate"
            @click="downloadTemplate"
        >
            {{ $t("sfdr.upload.downloadTemplate") }}
        </el-button>
    </div>
</template>

<script>
import UploadDialog from './UploadDialog.vue'
import downloadMixin from '../../mixin/downloadMixin'

export default {
  components: {
    UploadDialog
  },
  mixins: [downloadMixin],
  data() {
    return {}
  },
  methods: {
    clickUpload() {
      this.$emit('showUploadDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-block {
    user-select: none;
    font-family: PingFang SC-Regular;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    // padding: 100px 0 0;
    overflow-x: hidden !important;

    .empty-tips {
        font-size: 14px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 400;
        color: #215796;
        line-height: 20px;
    }
    .upload-btn {
        // height: 40px;
        // background: #5998f0;
        // border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        margin-bottom: 20px;
        margin-top: 28px;
    }
    .download-template {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #215796;
        line-height: 20px;
    }
}
</style>