import Vue from 'vue'
import Cookies from 'js-cookie'
import Element from 'element-ui'
Element.Dialog.props.lockScroll.default = false;
import './assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' // directive
import plugins from './plugins' // plugins
import { download } from '@/utils/request'
import i18n from './lang'//国际化
import '@/filters/filter.js'// 注册全局过滤器

import './assets/iconfont/iconfont.css'//阿里图标
import './assets/icons' // icon
import './permission' // permission control
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";

import InfiniteLoading from 'vue-infinite-loading'//滚动懒加载

// 分页组件
import Pagination from "@/components/Pagination";
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar"
// 富文本组件
import Editor from "@/components/Editor"
// 文件上传组件
import FileUpload from "@/components/FileUpload"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
// 字典标签组件
import DictTag from '@/components/DictTag'
import DictTagDL from '@/components/DictTagDL'
// 头部标签组件
import VueMeta from 'vue-meta'
// 字典数据组件
import DictData from '@/components/DictData'
// 多语言
import LangSelect from "@/components/LangSelectDL";

// 引入mock
import './mock'
import htmlToPdf from '@/utils/data-platform/htmlToPdf';
// 使用 Vue.use() 方法就会调用工具方法中的install方法

import mavonEditor from 'mavon-editor'//markdown
import 'mavon-editor/dist/css/index.css'
// import BaiduMap from '@/components/vue-baidu-map/components'
// import BaiduMap from 'vue-baidu-map'

Vue.use(htmlToPdf);
Vue.use(mavonEditor)

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree

// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('DictTagDL', DictTagDL)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Editor', Editor)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)
Vue.component('LangSelect', LangSelect)
Vue.component('InfiniteLoading', InfiniteLoading)

Vue.use(directive)
Vue.use(plugins)
Vue.use(VueMeta)
DictData.install()

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
  size: Cookies.get('size') || 'medium', // set element-ui default size
})

// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: 'SQpGAL0ge6XZxu7VoKP2zi8164KmwkGH'
// })

Vue.config.productionTip = false

window.vm = new Vue({
  i18n,
  el: '#app',
  router,
  store,
  render: h => h(App)
})
