<template>
    <div class="welcome">
        <img
            class="title-img"
            src="~@/assets/sfdr/images/welcome-title.png"
            alt=""
        />
        <div class="background flex-column">
            <Title class="title"> BACKGROUND </Title>
            <div class="background-bottom">
                <div class="text">
                    <!-- <p>
                        In March 2020, the EU introduced the Sustainable Finance
                        Disclosure Regulation (SFDR) in order to realize the
                        transition to a low-carbon, more sustainable,
                        resource-efficient and circular economy in line with the
                        SDGs.
                    </p>
                    <br />
                    <p>
                        On 6 April 2022, the European Commission adopted the
                        final Regulatory Technical Standards (RTS) under the
                        SFDR. Its Annexes 1 includes a mandatory reporting
                        template to illustrate the Principal Adverse Impacts
                        (PAI) of investment decisions on sustainability factors.
                        The disclosures are focused on a set of indicators for
                        both environmental and social adverse impacts. The RTS
                        provides a set of 18 mandatory indicators (14 of which
                        are applicable to investments in investee companies) and
                        46 additional opt-in indicators (33 of which are
                        applicable to investments in investee companies). The
                        Regulation shall apply from 1 January 2023.
                    </p> -->
                    <p>
                        In March 2020, the European Union introduced the Sustainable Finance Disclosure Regulation (SFDR) to
                        support the transition towards a low-carbon, sustainable, resource-efficient, and circular economy
                        in alignment with the Sustainable Development Goals (SDGs). Subsequently, on April 6, 2022, the
                        European Commission adopted the SFDR Delegated Regulation (Commission Delegated Regulation (EU)
                        2022/1288), which further enhances the transparency and credibility of sustainable finance.
                    </p>
                    <br />
                    <p>
                        The SFDR Delegated Regulation mandates a comprehensive reporting template to demonstrate the
                        Principal Adverse Impacts (PAI) of investment decisions on sustainability factors. These disclosures
                        emphasize a range of indicators that address both environmental and social adverse impacts. The
                        regulation prescribes 18 mandatory indicators (with 14 applicable to investments in investee
                        companies) and an additional 46 opt-in indicators (33 of which apply to investments in investee
                        companies). The regulation shall apply from 1st January 2023.
                    </p>
                </div>
                <img
                    class="history-img"
                    src="~@/assets/sfdr/images/welcome-history.png"
                    alt=""
                />
            </div>
        </div>
        <div class="steps flex-column">
            <Title> STEPS </Title>
            <div class="steps-bottom">
                <div class="background-line"></div>
                <div class="contents">
                    <div class="item flex-column-center">
                        <div class="icon-container">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>

                        <div class="number">01</div>
                        <div class="text">Upload Portfolio first</div>
                    </div>
                    <div class="item flex-column-center">
                        <div class="icon-container">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                        <div class="number">02</div>
                        <div class="text">Preview "Mandatory PAI"</div>
                    </div>
                    <div class="item flex-column-center">
                        <div class="icon-container">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                        <div class="number">03</div>
                        <div class="text" style="width: 204px">
                            Then you may go to the 'Additional PAI' section for
                            choosing additional indicators
                        </div>
                    </div>
                    <div class="item flex-column-center">
                        <div class="icon-container">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                        <div class="number">04</div>
                        <div class="text" style="width: 255px">
                            Click the calculated value, you may review the
                            underlying data for both "Mandatory PAI" and
                            "Additional PAI"
                        </div>
                    </div>
                    <div class="item flex-column-center">
                        <div class="icon-container">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                        <div class="number">05</div>
                        <div class="text">
                            Generate and download the PAI data
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-center" v-if="!isSystemDisabled">
            <el-button
                type="primary"
                class="enter-button"
                @click="$emit('showWelcome')"
                >Enter</el-button
            >
        </div>
        <el-dialog
            title="Notification"
            :visible.sync="isShowNotification"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
            :show-close="false"
        >
            <span>{{ isShowNotification && notificationData.notice }}</span>
            <span slot="footer">
                <el-button type="primary" @click="isShowNotification = false"
                    >Close</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
// import backgroundImg from '~@/assets/sfdr/images/welcome-title.png'
// import historyImg from '~@/assets/sfdr/images/welcome-history.png'
import Title from './Title.vue'

export default {
  components: {
    Title
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialogVisible: false,
      isShowNotification: false
    }
  },
  computed: {
    isSystemDisabled() {
      return this.notificationData.checkStatus === 1
    }
  },
  watch: {
    notificationData(val) {
      this.isShowNotification = val?.checkStatus === 1 || val?.checkStatus === 2
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome {
    position: absolute;
    top: 53px;
    left: 16px;
    right: 16px;
    bottom: 15px;
    z-index: 99;
    background: #fff;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    overflow-y: auto;
    overflow-x: auto;

    .title-img {
        width: 816px;
        height: 144px;
        position: absolute;
        left: 40px;
        top: 16px;
    }

    .background {
        z-index: 1;
        margin-top: 88px;
        .background-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 20px;
            .text {
                z-index: 2;
                // max-width: 756px;
                max-width: 642px;
                min-width: 642px;
            }

            .history-img {
                width: 380px;
                height: 230px;
                margin-left: 130px;
                position: relative;
                right: 58px;
                bottom: 37px;
            }
        }
    }
    .steps {
        margin-top: 56px;
        .steps-bottom {
            width: 1156px;
            height: 168px;
            background: #f5f9ff;
            margin-top: 20px;
            padding: 16px 25px 0 25px;

            .background-line {
                position: relative;
                z-index: 1;
                top: 12px;
                width: 90%;
                margin: auto;
                height: 2px;
                background: #01579b;
            }
            .contents {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                .item {
                    position: relative;
                    z-index: 2;
                    // width: 72px;
                    // background: #f5f9ff;
                    // overflow: visible;

                    .icon-container {
                        width: 72px;
                        background: #f5f9ff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .icon {
                        width: 24px;
                        height: 24px;
                        background: #01579b;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        margin-bottom: 16px;
                        i {
                            color: #fff;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                    .number {
                        margin-bottom: 16px;
                        font-size: 18px;
                        font-weight: 600;
                        color: #3d3d3d;
                    }
                    .text {
                        width: 100px;
                        max-height: 60px;
                        // display: flex;
                        // justify-content: center;
                        // align-items: center;
                        text-align: center;
                    }
                }
            }
        }
    }

    .enter-button {
        width: 96px;
        height: 40px;
        margin-top: 32px;
        margin-bottom: 32px;

        // position: absolute;
        // bottom: 56px;
    }
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-auto {
    flex: 1;
}
.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
</style>
