<template>
  <!-- 多语言切换按钮 -->
  <el-dropdown
    class="international"
    trigger="click"
    @command="handleSetLanguage"
  >
    <el-button type="primary" ref="btn1">
      {{ $store.getters.language === "zh" ? "中文" : "English" }}
      <!-- <i class="el-icon-caret-bottom"></i> -->
      <!-- 小图标 -->
      <div class="el-arrow-white">
        <svg-icon slot="prefix" icon-class="arrow-white" />
      </div>
    </el-button>

    <el-dropdown-menu slot="dropdown" class="lang-select">
      <el-dropdown-item command="en">English</el-dropdown-item>
      <el-dropdown-item command="zh">中文</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { switchLang } from "@/api/system/config.js";

export default {
  data() {
    return {
      urlPath: window.location.href,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.urlPath = window.location.pathname;

     this.$store.dispatch("app/setLanguage", lang);

      // 切换语言接口
      switchLang(lang)
        .then(() => {
          this.$message({
            message: this.$t("tips.langTip"),
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
      });

      // 如果url包含语言,就刷新页面
      if (this.urlPath.indexOf("zh") > -1 || this.urlPath.indexOf("en") > -1) {
        // 如果url包含语言，但不是当前语言
        if (this.urlPath.indexOf(lang) == -1) {
          let pathnameNew = this.sliceURL(this.urlPath, lang).join("/");
          let urlNew =
            window.location.protocol +
            "//" +
            window.location.host +
            "" +
            pathnameNew;

          window.location.replace(urlNew); //刷新页面
        }
      }
    },

    sliceURL(url, lang) {
      let arr = url.split("/");
      if (!arr.includes(lang)) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === "zh" || arr[i] === "en") {
            arr[i] = lang;
          }
        }
      }

      return arr;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-button--primary {
  background-color: transparent;
  border-color: transparent;
  height: 30px;
  width: 82px;
  padding: unset;
}

::v-deep .el-button--primary:hover,
::v-deep .el-button--primary:focus {
  background: #2e74b9;
  background-color: #2e74b9;
  border-color: #2e74b9;
  // 下拉图标旋转
  .el-arrow-white {
    transition: all 0.3s ease;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
}
.lang-select.el-dropdown-menu.el-dropdown-menu--medium {
  z-index: 20001 !important;
  top: 32px !important;
  // left: 1125px !important;
}
// 隐藏小三角
::v-deep .popper__arrow {
  display: none !important;
}
.el-dropdown-menu__item:not(.is-disabled):hover,
.el-dropdown-menu__item:focus {
  width: 100%;
  background-color: #dfebf9;
  color: #14487b !important;
}
.el-dropdown-menu--medium {
  padding: unset;
}
.el-dropdown-menu__item[data-v-0ea2bf94]:not(.is-disabled):hover,
.el-dropdown-menu__item[data-v-0ea2bf94]:focus {
  border-radius: 4px;
}
.el-dropdown-menu__item {
  color: #01579b;
}
// 更换的小图标
.el-arrow-white {
  display: inline-block;
  .svg-icon {
    width: 0.7em;
  }
}
</style>
