export default {
  sfdr: {
    nav: {
      home: "Home",
      sfdr: "SFDR",
      upload: "Upload Portfolio",
      mandatory: "Mandatory PAI",
      portfolioCalculation: "Portfolio Calculation",
      additional: "Additional PAI",
      review: "Review Full PAI",

    },
    upload: {
      uploadBtn: "Upload Portfolio",
      emptyWarning: "Your Portfolio list is empty, please upload your file first.",
      downloadTemplate: "download template",
      routerName: "Upload",
      notesTitle: "Notes:",
      notesContent: "According to the RTS, such annual disclosures should be based on the average of indicators observed on 31 March, 30 June, 30 September and 31 December for any reference period. This disclosure should therefore consist in the average of four different data inputs. Please upload portfolio files at each period end date.",
      notesContent2: ""
    },

  }
}
