<template>
  <el-dialog :visible="visible" title="检测数据" width="900px" class="common-table-style" custom-class="apiCheckDialog" @close="dialogClose">
    <el-table :data="tableData" border>
      <el-table-column label="表名" prop="tableName" min-width="200" show-overflow-tooltip></el-table-column>
      <el-table-column label="字段名" prop="fieldName" show-overflow-tooltip></el-table-column>
      <el-table-column label="变量值" prop="variable" show-overflow-tooltip></el-table-column>
      <el-table-column label="值">
        <template #default="{ row }">
          <el-input v-model="row.value" :disabled="checkValue(row.variable)"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <el-button size="small" @click="dialogClose">取消</el-button>
      <el-button type="primary" size="small" @click="handleSave">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getConfigAPIByApiId, loadOpenApiDataByGetFromDataBrowser, loadOpenApiDataByPostFromDataBrowser } from "@/api/data-platform/admin-center.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    apiId: {
      type: [String, Number],
      default: () => ''
    }
  },
  data () {
    return {
      tableData: [],
      info: {}
    }
  },
  created () {
    this.getDetailInfo();
  },
  methods: {
    getDetailInfo () {
      getConfigAPIByApiId({apiId: this.apiId}).then((res) => {
        if (res.code === 200) {
          const data = res.data.whereConditionList || [];
          this.info = res.data.apiMeta || {};
          for (const item of data) {
            if (item.queryConditionValue1ExpPattern) {
              this.tableData.push({
                tableName: item.queryConditionFieldTableName,
                fieldName: item.queryConditionFieldName,
                variable: item.queryConditionValue1ExpPattern,
                value: this.checkValue(item.queryConditionValue1ExpPattern) ? item.queryConditionValue1ExpPattern : ''
              })
            }
            if (item.queryConditionValue2ExpPattern) {
              this.tableData.push({
                tableName: item.queryConditionFieldTableName,
                fieldName: item.queryConditionFieldName,
                variable: item.queryConditionValue2ExpPattern,
                value: this.checkValue(item.queryConditionValue2ExpPattern) ? item.queryConditionValue2ExpPattern : ''
              })
            }
          }
        }
      })
    },
    checkValue (val) {
      const arr = val.split('');
      if (arr[0] === '{' && arr[arr.length - 1] === '}') {
        return false;
      } else {
        return true;
      }
    },
    handleSave () {
      const list = this.tableData.filter((e) => !e.value);
      if (list.length) {
        this.$modal.msgWarning('值不能为空，请检查');
        return;
      }
      const params = {
        apiName: this.info.apiName,
        apiVersion: this.info.apiVersion,
        pageSize: 100,
        pageIndex: 1,
        parameterCheck: true,
        showSqlError: true
      }
      for (const item of this.tableData) {
        if (this.checkValue(item.variable)) {
          params[item.fieldName] = item.value;
        } else {
          params[item.variable.slice(1, item.variable.length - 1)] = item.value;
        }

      }
      const requestMethod = this.info.apiRequestMethod === 'Post' ? loadOpenApiDataByPostFromDataBrowser: loadOpenApiDataByGetFromDataBrowser;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      requestMethod(params).then((res) => {
        loading.close();
        if (res.code === 200) {
          this.$emit('confirmRelease');
          this.dialogClose();
        }
      }).catch((e) => { loading.close(); })
    },
    dialogClose () {
      this.$emit('dialogClose');
    }
  }
}
</script>
<style lang="scss" scoped>
.apiCheckDialog {
  max-height: 80%;
}
</style>
