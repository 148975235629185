<template>
    <el-dialog
        :visible.sync="showSelf"
        width="460px"
        class="upload-dialog"
        center
        :close-on-click-modal="false"
        :before-close="beforeClose"
    >
        <el-upload
            ref="upload"
            :limit="1"
            accept=".xlsx, .xls"
            :headers="upload.headers"
            :action="upload.url"
            :disabled="upload.isUploading"
            :on-progress="handleFileUploadProgress"
            :on-success="handleFileSuccess"
            :auto-upload="false"
            :on-exceed="handleExceed"
            drag
        >
            <div v-loading="upload.isUploading">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    {{ $t("tips.importTip") }}<em>{{ $t("tips.clickTip") }}</em>
                </div>
            </div>

            <div class="el-upload__tip text-center" slot="tip">
                <el-button
                    type="text"
                    icon="el-icon-download"
                    :underline="false"
                    class="download-template"
                    href="/sfdr/downloadTemplate"
                    @click="downloadTemplate"
                >
                    {{ $t("sfdr.upload.downloadTemplate") }}
                </el-button>
                <div class="notes-container">
                    <p class="notes">{{ $t("sfdr.upload.notesTitle") }}</p>
                    <p class="notes">{{ $t("sfdr.upload.notesContent") }}</p>
                </div>
            </div>
        </el-upload>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitFileForm"
                >{{ $t("FMCCTop.confirmBtn") }}
            </el-button>
            <el-button class="right_btn" @click="hideDialog"
                >{{ $t("FMCCTop.cancelBtn") }}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import downloadMixin from '../../mixin/downloadMixin'
import { getToken } from '@/utils/auth'

export default {
  mixins: [downloadMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      operationId: null,
      showSelf: false,
      upload: {
        // 是否显示弹出层（用户导入）
        // open: false,
        // 弹出层标题（用户导入）
        title: '',
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: 'Bearer ' + getToken() },
        // 导入--自选清单
        url: process.env.VUE_APP_BASE_API + '/sfdr/portfolioUpload'
      }
    }
  },
  watch: {
    visible() {
      this.showSelf = this.visible
    }
  },
  methods: {
    beforeClose(done) {
      this.hideDialog()
    },
    submitFileForm() {
      // if (this.operationId) {
      //   this.$emit('clearOperationData', this.operationId)
      // }
      this.$refs.upload.submit()
    },
    hideDialog() {
      this.$emit('triggerUploadDialog', false)
    },
    showDialog() {
      this.$emit('triggerUploadDialog', true)
    },
    handleFileUploadProgress() {
      this.upload.isUploading = true // 状态
    },
    handleFileSuccess(response) {
      this.upload.isUploading = false // 状态
      this.$refs.upload.clearFiles()
      // const errorMessage = response?.data?.errorMessage
      const errorMessageList = response?.data?.errorMessageVOList
      
      if (response?.code !== 200 || errorMessageList?.length) {
        const errorMsgHTML = errorMessageList?.map((item)=>{
        const str = item.messageList?.map(s=>`<p>${s}</p>`).join("");
          return `<p><strong>${item.source}</strong>:</p><p>${str}</p>`
        }).join("");

        this.$alert(errorMsgHTML, 'Error', {
          dangerouslyUseHTMLString: true,
          type: 'error',
          showCancelButton: true,
          confirmButtonText: 'Re-upload',
          cancelButtonText: 'OK',
          customClass: 'sfdr-upload-error-box',
          // iconClass: "sfdr-upload-error-icon",
          center: true
        }).then(() => {
          this.showDialog()
        })
        this.hideDialog()
        return
      }

      // 渲染结果区
      if (response?.code === 200) {
        this.$message({
          message: 'File upload succeeded.',
          type: 'success',
          duration: 2000
        })

        this.operationId = response.data.operationId
        const warningMessages = this.handleWarningMessage(response)
        this.$emit('uploaded', this.operationId, warningMessages)
        this.hideDialog()
      }
    },
    handleWarningMessage(response) {
      const warningMessage = response?.data?.warningMessage
      // const warningMessage = "Q1:The input Id(ISIN=CQE100002XM8,Ticker= ) isn't converted.\nQ2:The input Id(ISIN=CQE100002XM8,Ticker= ) isn't converted 2.\nQ3:The input Id(ISIN=CQE100002XM8,Ticker= ) isn't converted 3.\nQ4:The input Id(ISIN=CQE100002XM8,Ticker= ) isn't converted 4.\n"
      if (!warningMessage) {
        return {}
      }
      const result = {}
      warningMessage
        .split('\n')
        ?.map((str) => str.trim())
        .forEach((warningItem) => {
          const splitIndex = warningItem.indexOf(':')
          const quarter = warningItem.substring(0, splitIndex)
          if (quarter) {
            result[quarter] = warningItem.substring(splitIndex + 1)
          }
        })
      return result
    },
    handleExceed() {
      this.$message({
        message:
          'Only one file can be uploaded. Please delete the existing one and add a new one again.',
        type: 'warning',
        duration: 4000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-dialog {
    padding-top: 5vh;
    font-family: PingFang SC-Regular;

    .download-template {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #215796;
        line-height: 20px;
    }
    .notes-container {
        margin-top: 20px;
    }
    .notes {
        // margin-top: 5px;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        opacity: 0.8;
        text-align: left;
        line-height: 20px;
        word-break: normal;
        white-space: pre-wrap;
        word-wrap: break-word;
        // -webkit-background-clip: text;
        -webkit-text-fill-color: #606266;
    }
    .dialog-footer {
        width: 360px;
        margin: 0 auto 20px auto;
        text-align: center;

        button {
            width: 170px;

            &.right_btn {
                margin-left: 20px;
            }
        }
    }
    ::v-deep .el-upload-dragger {
        background: linear-gradient(
            0deg,
            #f4f9ff 0%,
            rgba(223, 235, 249, 0) 100%
        );
    }

    ::v-deep .el-dialog__body {
        padding: 25px 50px 0px;
    }

    ::v-deep .el-upload__tip {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
</style>
<style lang="scss">
.sfdr-upload-error-box {
    // border-color: red;
    .el-message-box__header {
        padding-top: 18px;
        box-shadow: inset 0px -1px 0px 0px #ebedf0;
        .el-message-box__title {
            justify-content: flex-start;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            // font-weight: 500;
            // color: #d40000;
            line-height: 20px;
        }
        .el-message-box__status {
            font-size: 20px !important;
        }
    }
    .el-message-box__content {
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        .el-message-box__message {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            // color: #d40000;
            line-height: 20px;
        }
    }
    // .el-message-box__btns {
    //     .el-button--primary {
    //         background: #5998f0;
    //     }
    // }
}
</style>