import request from '@/utils/request'

// 查询api清单列表
export function getApiMetaList(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/getApiMetaList',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 分页查询api清单
export function getApiMetaListWithPaging(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/getApiMetaListWithPaging',
    method: 'get',
    params: {
      ...params
    }
  })
}


// 查询数据库表
export function getTableListByTableSchemaName(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/getTableListByTableSchemaName',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 通过表名获取字段的清单
export function getColumnsByTableName(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/getColumnsByTableName',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 查询所有已上线的业务模板
export function loadAllBusinessTemplateApi(params) {
  return request({
    url: '/DingLiApi/DataDictionary/loadAllBusinessTemplateApi',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 新增或修改api配置数据
export function saveOrUpdateApi(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/saveOrUpdateApi',
    method: 'post',
    data: params
  })
}

// 查询api详情
export function getConfigAPIByApiId(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/getConfigAPIByApiId',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 发布api
export function onlineApi(params) {
  return request({
    url: `/DingLiApi/ConfigApiUI/onlineApi?apiId=${params.apiId}`,
    method: 'post',
    // data: params
  })
}

// 停用api
export function offlineApi(params) {
  return request({
    url: `/DingLiApi/ConfigApiUI/offlineApi?apiId=${params.apiId}`,
    method: 'post',
    // data: params
  })
}

// 根据api名称获取数据源列表
export function getDataSourceListByApiName(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/getDataSourceListByApiName',
    method: 'get',
    params: {
      ...params
    }
  })
}


// 根据api名称获取数据点列表
export function getDataPointListByApiName(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/getDataPointListByApiName',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 通过Get方法获取API数据
export function loadOpenApiDataByGetFromDataBrowser(params) {
  return request({
    url: '/DingLiApi/DataBrowser/loadOpenApiDataByGetFromDataBrowser',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 通过Post方法获取API数据
export function loadOpenApiDataByPostFromDataBrowser(params) {
  return request({
    url: '/DingLiApi/DataBrowser/loadOpenApiDataByPostFromDataBrowser',
    method: 'post',
    data: params
  })
}

// 删除api
export function deleteConfigApiByApiId(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/deleteConfigApiByApiId',
    method: 'delete',
    params: {
      ...params
    }
  })
}

// 获取当前用户绑定关系树
export function getApiUserBindingTree(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/getApiUserBindingTree',
    method: 'get',
    params: {
      ...params
    }
  })
}

// 保存API用户权限关联绑定
export function updateApiUserBinding(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/updateApiUserBinding',
    method: 'post',
    data: params
  })
}

// 删除API用户权限关联绑定
export function deleteApiUserBinding(idList) {
  return request({
    url: `/DingLiApi/ConfigApiUI/deleteApiUserBinding/${idList}`,
    method: 'delete',
  })
}

// 获取当前API绑定的用户清单
export function getApiUserBindingByApiId(params) {
  return request({
    url: `/DingLiApi/ConfigApiUI/getApiUserBindingByApiId`,
    method: 'get',
    params: {
      ...params
    }
  })
}

// 复制curl命令
export function exportCurlCommand(id) {
  return request({
    url: `/DingLiApi/ConfigApiUI/exportCurlCommand/${id}`,
    method: 'get',
  })
}

// 获取特定用户绑定的ConfigAPI定义清单
export function getConfigAPIMetaListByUserId(params) {
  return request({
    url: `/DingLiApi/ConfigApiUI/getConfigAPIMetaListByUserId`,
    method: 'get',
    params: {
      ...params
    }
  })
}

// 保存API附件
export function uploadHelpDoc(params) {
  return request({
    url: '/DingLiApi/ConfigApiUI/uploadHelpDoc',
    method: 'post',
    data: params
  })
}


// 下载帮助指引
export function downloadHelpDoc(params) {
  return request({
    url: `/DingLiApi/ConfigApiUI/downloadHelpDoc`,
    method: 'get',
    responseType:'blob',
    params: {
      ...params
    }
  })
}

// 查看帮助指引
export function viewHelpDoc(params) {
  return request({
    url: `/DingLiApi/ConfigApiUI/viewHelpDoc`,
    method: 'get',
    responseType:'blob',
    params: {
      ...params
    }
  })
}