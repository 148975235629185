var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mandatory-page" },
    [
      !_vm.showDrillDownTable
        ? _c("div", { staticClass: "mandatory-header" }, [
            _c(
              "div",
              { staticClass: "impact-select-wrapper" },
              [
                _c("span", [_vm._v("PAI Category")]),
                _c(
                  "el-select",
                  {
                    staticClass: "impact-select",
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.currentImpact,
                      callback: function ($$v) {
                        _vm.currentImpact = $$v
                      },
                      expression: "currentImpact",
                    },
                  },
                  [
                    _c("el-option", {
                      key: "All",
                      attrs: { label: "All", value: "All" },
                    }),
                    _vm._l(_vm.impactList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "display-select-wrapper" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "full" },
                    model: {
                      value: _vm.currentDisplayType,
                      callback: function ($$v) {
                        _vm.currentDisplayType = $$v
                      },
                      expression: "currentDisplayType",
                    },
                  },
                  [_vm._v("Full Display")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "simple" },
                    model: {
                      value: _vm.currentDisplayType,
                      callback: function ($$v) {
                        _vm.currentDisplayType = $$v
                      },
                      expression: "currentDisplayType",
                    },
                  },
                  [_vm._v("Summary Display")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.showDrillDownTable
        ? _c("MandatoryTable", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              data: _vm.tableData,
              columns: _vm.tableColumn,
              displayType: _vm.currentDisplayType,
              hasDrillDownPermission: _vm.hasDrillDownPermission,
            },
            on: { cellClick: _vm.cellClickHandler },
          })
        : _vm._e(),
      _vm.showDrillDownTable
        ? _c("DrillDownTable", {
            attrs: { data: _vm.drillDownData, columns: _vm.drillDownColumns },
            on: { cellClick: _vm.cellClickHandler },
          })
        : _vm._e(),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            !_vm.showDrillDownTable
              ? _c("el-button", { on: { click: _vm.backStep } }, [
                  _vm._v("Back"),
                ])
              : _vm._e(),
            !_vm.showDrillDownTable
              ? _c(
                  "el-button",
                  {
                    staticClass: "primary",
                    attrs: { type: "primary" },
                    on: { click: _vm.nextStep },
                  },
                  [_vm._v("Select Additional PAI")]
                )
              : _vm._e(),
            _vm.showDrillDownTable
              ? _c(
                  "el-button",
                  {
                    staticClass: "primary",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.showDrillDownTable = false
                      },
                    },
                  },
                  [_vm._v("Close")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }