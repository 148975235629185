/**
* v-table-scroll 滚动懒加载数据
*/

export default {
  bind(el, binding, vnode, oldVnode) {
    let scrollTop = 0;
    let dom = el.querySelector(".el-table__body-wrapper");
    dom.addEventListener("scroll", (e) => {
      // 元素内容的高度，包括溢出的不可见内容-向上卷曲的高度 === 元素的视口高度
      let distance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;//滑动条与底部的距离
      let isHorizontal = scrollTop == dom.scrollTop;
      if (distance <= 70 && !isHorizontal && dom.scrollTop != 0) {

        if (binding.value) {
          binding.value()
        }
      }
      if (!isHorizontal) {
        scrollTop = dom.scrollTop;

      }
    });
  }
}
