import { Message, MessageBox, Notification, Loading } from 'element-ui'
// 多语言
import i18n from '@/lang/index.js'
let loadingInstance;

export default {
  // 消息提示
  msg(content) {
    Message.info(content)
  },
  // 错误消息
  msgError(content) {
    Message.error(content)
  },
  // 成功消息
  msgSuccess(content) {
    Message.success(content)
  },
  // 警告消息
  msgWarning(content) {
    Message.warning(content)
  },
  // 弹出提示
  alert(content) {
    MessageBox.alert(content, "系统提示")
  },
  // 错误提示
  alertError(content) {
    MessageBox.alert(content, "系统提示", { type: 'error' })
  },
  // 成功提示
  alertSuccess(content) {
    MessageBox.alert(content, "系统提示", { type: 'success' })
  },
  // 警告提示
  alertWarning(content) {
    MessageBox.alert(content, "系统提示", { type: 'warning' })
  },
  // 通知提示
  notify(content) {
    Notification.info(content)
  },
  // 错误通知
  notifyError(content) {
    Notification.error(content);
  },
  // 成功通知
  notifySuccess(content) {
    Notification.success(content)
  },
  // 警告通知
  notifyWarning(content) {
    Notification.warning(content)
  },
  // 确认窗体(系统提示,确定,取消)
  confirm(content, confirmBtn) {
    return MessageBox.confirm(content, i18n.t("elbtn.sysTips"), {
      confirmButtonText: confirmBtn || i18n.t("elbtn.apply"),
      cancelButtonText: i18n.t("elbtn.cancel"),
      type: "warning",
    })
  },

  // 数据校验-确认窗体(系统提示,确定,取消)
  confirmForImportCheck(content,title) {
    return MessageBox.confirm(content, title, {
      confirmButtonText: i18n.t("elbtn.apply"),
      cancelButtonText: i18n.t("elbtn.cancel"),
      type: "warning",
      dangerouslyUseHTMLString: true,
    })
  },
  // 提交内容
  prompt(content) {
    return MessageBox.prompt(content, "系统提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: "warning",
    })
  },
  // 打开遮罩层
  loading(content) {
    loadingInstance = Loading.service({
      lock: true,
      text: content,
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    })
  },
  // 关闭遮罩层
  closeLoading() {
    loadingInstance.close();
  }
}
