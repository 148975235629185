import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang en
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang cn

import enLocale from './en'
import zhLocale from './zh'
import tipsEnLocale from './tipsEn'
import tipsZhLocale from './tipsZh'
import dataBrowserEnLocale from './dataBrowserEn'
import dataBrowserZhLocale from './dataBrowserZh'
import FMCCEn from './FMCCEn'
import FMCCZh from './FMCCZh'
import userEn from './userEn'
import userZh from './userZh'
import helpZh from './helpZh'
import helpEn from './helpEn'
import sfdrEn from './sfdrEn'
import sfdrZh from './sfdrZh'

Vue.use(VueI18n)
const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
    ...tipsEnLocale,
    ...dataBrowserEnLocale,
    ...FMCCEn,
    ...userEn,
    ...helpEn,
    ...sfdrEn
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
    ...tipsZhLocale,
    ...dataBrowserZhLocale,
    ...FMCCZh,
    ...userZh,
    ...helpZh,
    ...sfdrZh
  }
}

const i18n = new VueI18n({
  globalInjection: true,
  // 设置语言 选项 en | zh
  locale: Cookies.get('language') || 'zh',
  // 设置文本内容
  messages
})

export default i18n
