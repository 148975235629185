<template>
    <div class="mandatory-page">
        <div class="mandatory-header" v-if="!showDrillDownTable">
            <div class="impact-select-wrapper">
                <span>PAI Category</span>
                <el-select
                    v-model="currentImpact"
                    class="impact-select"
                    size="mini"
                >
                    <el-option key="All" label="All" value="All"> </el-option>
                    <el-option
                        v-for="item in impactList"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="display-select-wrapper">
                <el-radio v-model="currentDisplayType" label="full"
                    >Full Display</el-radio
                >
                <el-radio v-model="currentDisplayType" label="simple"
                    >Summary Display</el-radio
                >
            </div>
        </div>
        <MandatoryTable
            v-if="!showDrillDownTable"
            :data="tableData"
            :columns="tableColumn"
            :displayType="currentDisplayType"
            :hasDrillDownPermission="hasDrillDownPermission"
            @cellClick="cellClickHandler"
            v-loading="loading"
        ></MandatoryTable>
        <DrillDownTable
            v-if="showDrillDownTable"
            :data="drillDownData"
            :columns="drillDownColumns"
            @cellClick="cellClickHandler"
        ></DrillDownTable>
        <div class="footer">
            <div class="btn-container">
                <el-button @click="backStep" v-if="!showDrillDownTable"
                    >Back</el-button
                >
                <el-button
                    class="primary"
                    type="primary"
                    @click="nextStep"
                    v-if="!showDrillDownTable"
                    >Select Additional PAI</el-button
                >
                <el-button
                    class="primary"
                    type="primary"
                    @click="showDrillDownTable = false"
                    v-if="showDrillDownTable"
                    >Close</el-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import MandatoryTable from '../components/mandatory-pai/MandatoryTable.vue'
import DrillDownTable from '../components/mandatory-pai/DrillDownTable.vue'
import messageMixin from '../mixin/messageMixin'
import tableDataHandleMixin from '../mixin/tableDataHandleMixin'

export default {
  components: {
    MandatoryTable,
    DrillDownTable
  },
  mixins: [messageMixin, tableDataHandleMixin],
  data() {
    return {
      currentImpact: 'All'
    }
  },
  watch: {
    operationId(val) {
      console.log('operationId change:', operationId)
      // this.ownImpactList = val
    },
    async paiGroup(val) {
      console.log('paiGroup change:', val)
      if (val && val?.All) {
        this.requestCalculatePaiData()
      }
    },
    currentImpact() {
      this.tableData = this.handleTableData(this.originalData)
    },
    tableColumn(cols) {
      if (this.currentDisplayType !== 'full') {
        return
      }
      this.$emit('updateColumns', cols)
    }
  },
  mounted() {
    this.requestCalculatePaiData()
  },
  computed: {
    paiIDList() {
      console.log('paiGroup:', this.paiGroup)
      const currentPaiList = this.paiGroup?.[this.currentImpact] || []
      return currentPaiList?.map((item) => item.id)
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/footer.scss";

.footer {
    padding: 16px 0;
}

.mandatory-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100% - 214px);

    .mandatory-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        .impact-select-wrapper {
            span {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 20px;
                margin-right: 8px;
            }
            .impact-select {
                width: 250px;
                border-color: transparent;

                ::v-deep .el-input__inner {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    user-select: none;
                }
            }
        }

        .display-select-wrapper {
            ::v-deep .el-radio.is-checked {
                .el-radio__inner {
                    background: #ffffff;
                }
                .el-radio__inner::after {
                    width: 6px;
                    height: 6px;
                    background: #1890ff;
                }
                .el-radio__label {
                    color: #606266;
                }
            }
            .el-radio:first-child {
                margin-right: 22px;
            }
        }
    }
}
</style>
<style lang="scss">
.el-select-dropdown__item {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #323233;
}
.el-select-dropdown__item:hover {
    background: #e1ebf8;
}

.el-select-dropdown {
    margin-top: 2px !important;
    .popper__arrow {
        display: none;
    }
}
</style>