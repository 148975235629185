var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tip_container" }, [
    _c("table", { staticClass: "table", attrs: { cellspacing: "20" } }, [
      _c("tr", { staticClass: "tr" }, [
        _c("td", { staticClass: "column1" }),
        _c("td", { staticClass: "column4" }, [
          _c("div", { staticClass: "item item_title" }, [
            _vm._v(_vm._s(_vm.$t("baseTip.table3"))),
          ]),
        ]),
      ]),
      _c("tr", { staticClass: "tr" }, [
        _c("td", { staticClass: "column1" }, [
          _c("div", { staticClass: "item item_title" }, [
            _vm._v(_vm._s(_vm.$t("baseTip.table4"))),
          ]),
        ]),
        _c("td", { staticClass: "column4" }, [
          _c(
            "div",
            { staticClass: "item_img" },
            [
              _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: require("@/assets/tip/equation6.jpg") },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("tr", { staticClass: "tr" }, [
        _c("td", { staticClass: "column1" }, [
          _c("div", { staticClass: "item item_title" }, [
            _vm._v(_vm._s(_vm.$t("baseTip.table5"))),
          ]),
        ]),
        _c("td", { staticClass: "column4" }, [
          _c(
            "div",
            { staticClass: "item_img" },
            [
              _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: require("@/assets/tip/equation6.jpg") },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("tr", { staticClass: "tr" }, [
        _c("td", { staticClass: "column1" }, [
          _c("div", { staticClass: "item item_title" }, [
            _vm._v(_vm._s(_vm.$t("baseTip.table6"))),
          ]),
        ]),
        _c("td", { staticClass: "column4" }, [
          _c(
            "div",
            { staticClass: "item_img" },
            [
              _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: require("@/assets/tip/equation6.jpg") },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("tr", { staticClass: "tr" }, [
        _c("td", { staticClass: "column1" }, [
          _c("div", { staticClass: "item item_title" }, [
            _vm._v(_vm._s(_vm.$t("baseTip.table7"))),
          ]),
        ]),
        _c("td", { staticClass: "column4" }, [
          _c(
            "div",
            { staticClass: "item_img" },
            [
              _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: require("@/assets/tip/equation6.jpg") },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }