import {
  fullColumns,
  simplifiedColumns
} from '../constants/mandatory-constants'
import numeral from 'numeral'
import {
  calculatePAIData,
  getPAICalculationData,
  requestDrillDownData,
  requestDrillDownPermission
} from '@/api/data-platform/SFDR.js'
const impactObject = {}
const factorsObject = {}
const mixin = {
  props: {
    operationId: {
      type: String,
      default: null
    },
    impactList: {
      type: Array,
      default: () => [],
      require: true
    },
    paiGroup: {
      type: Object,
      default: () => { },
      require: true
    },
    hasDrillDownPermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentDisplayType: 'full', // full,simple
      loading: false,
      originalData: [],
      tableData: [],
      tableColumn: [],
      showDrillDownTable: false,
      drillDownData: [],
      drillDownColumns: [],
    }
  },
  watch: {
    currentDisplayType() {
      this.handleResponse()
    },
    tableData(data) {
      this.$emit("updateData", data)
    },
    showDrillDownTable(val) {
      if (!val) {
        this.$emit("clickDrillDown", null)
      }
    },
  },
  methods: {
    async requestCalculatePaiData() {
      if (
        !this.paiIDList.length ||
        this.tableData.length ||
        !this.operationId
      ) {
        return
      }
      this.loading = true
      const resp = await calculatePAIData(this.operationId, this.paiIDList)
      if (resp.code !== 200) {
        this.popError('Calculation failed')
        return
      }

      const tableDataResp = await getPAICalculationData(this.operationId)
      if (tableDataResp.code !== 200) {
        this.popError('Get calculation data failed')
      }
      this.originalData = tableDataResp.data || []
      this.handleResponse()
      this.loading = false
    },
    handleResponse() {
      this.tableColumn = this.handleTableColumn(this.originalData)
      this.tableData = this.handleTableData(this.originalData)
    },
    handleTableData(data) {
      const dataList = data.data;
      const paiList = this.paiGroup.All.filter(item => {
        const result = this.paiIDList.indexOf(item.paiOrder);
        return result > -1
      })
      const result = paiList.map((item) => {
        const tableItem = dataList.find((e) => item.paiOrder === e.paiOrder)
        return {
          ...item,
          ...tableItem
        }
      })
      this.addSpanData(result);
      this.formatterData(result);
      this.addTitleRows(result);
      return result;
    },
    groupBy(list, fn) {
      const groups = {}
      list.forEach(function (o) {
        const group = fn(o)
        if (!group) {
          return
        }
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return groups
    },
    addSpanData(result) {
      const impactObject = this.groupBy(
        result,
        (o) => o.adverseSustainabilityImpact
      )
      const factorsObject = this.groupBy(
        result,
        (o) => o.adverseImpactOnSustainabilityFactors
      )
      result.forEach((item) => {
        if (impactObject['span_' + item.adverseSustainabilityImpact]) {
          item.impactSpan = 0;
        } else {
          const length = impactObject[item.adverseSustainabilityImpact].length;
          impactObject['span_' + item.adverseSustainabilityImpact] = true;
          item.impactSpan = length;
        }

        if (factorsObject['span_' + item.adverseImpactOnSustainabilityFactors]) {
          item.factorSpan = 0;
        } else {
          const length = factorsObject[item.adverseImpactOnSustainabilityFactors].length;
          factorsObject['span_' + item.adverseImpactOnSustainabilityFactors] = true;
          item.factorSpan = length;
        }
      })

    },
    addTitleRows(result) {
      // if (this.currentDisplayType !== 'full') {
      //   return
      // }
      // 添加两个大标题
      for (let i = 0; i < result.length; i++) {
        // parOrder为13的PAI名字为 Social and employee matters
        if (result[i].categoryId === 1) {
          result.splice(i, 0, {
            metric: 'CLIMATE AND OTHER ENVIRONMENT-RELATED INDICATORS',
            simpleMetric: "CLIMATE AND OTHER ENVIRONMENT-RELATED INDICATORS",
            isTitle: true,
            isClimate: true,
          })
          break
        }
      }
      for (let i = 0; i < result.length; i++) {
        // parOrder为13的PAI名字为 Social and employee matters
        if (result[i].categoryId === 2) {
          result.splice(i, 0, {
            metric: 'SOCIAL AND EMPLOYEE, RESPECT FOR HUMAN RIGHTS, ANTI-CORRUPTION AND ANTI-BRIBERY MATTERS',
            simpleMetric: "SOCIAL AND EMPLOYEE, RESPECT FOR HUMAN RIGHTS, ANTI-CORRUPTION AND ANTI-BRIBERY MATTERS",
            isTitle: true,
            isSocial: true,
          })
          break
        }
      }
    },
    formatterData(list) {
      const handleValue = this.handleValue
      list.forEach((item) => {
        item.coverageQ1 = handleValue(item.coverageQ1, '0.00%')
        item.coverageQ2 = handleValue(item.coverageQ2, '0.00%')
        item.coverageQ3 = handleValue(item.coverageQ3, '0.00%')
        item.coverageQ4 = handleValue(item.coverageQ4, '0.00%')

        item.calcValueQ1 = item.calcValueQ1
        item.calcValueQ2 = item.calcValueQ2
        item.calcValueQ3 = item.calcValueQ3
        item.calcValueQ4 = item.calcValueQ4

        item.yearlyValue =item.yearlyValue
      })
    },
    handleValue(value, format = '0.000000') {
      return this.isInvalidNumber(value) ?
        '─' :
        numeral(value)
          .format(format)
      // .replace(/\.(0+)$/g, '')
      // .replace(/(0+)$/g, '')
    },
    isInvalidNumber(number) {
      return !number || number == -999999999 || Number.isNaN(number)
    },
    handleTableColumn(data) {
      const baseColumns =
        this.currentDisplayType === 'full' ? fullColumns : simplifiedColumns

      const dynamicColumns = data.dynamicColumns.map(item => {
        return {
          prop: item.id,
          label: item.name,
          width: item.id.startsWith("coverage") ? 160 : 140,
        }
      });

      const columns = baseColumns.concat(dynamicColumns);
      return columns
    },
    backStep() {
      this.$emit('backStep')
    },
    nextStep() {
      this.$emit('nextStep')
    },
    async cellClickHandler({
      metricId,
      quarter
    }) {
      try {
        this.loading = true;
        const result = await requestDrillDownData(this.operationId, metricId, quarter);
        const drillDownData = result.data || {};
        this.loading = false;

        const columns = this.drillDownHandler(drillDownData);
        this.formatDrillDownData(drillDownData.rows);

        this.drillDownData = drillDownData.rows
        this.drillDownColumns = columns

        this.$emit("clickDrillDown", metricId);

        this.showDrillDownTable = true;
      } catch (error) {
        this.loading = false;
        this.popError(error);
      }

    },
    drillDownHandler(drillDownData) {
      const widthMapping = {
        orderNumber: 40,
        isin: 80,
        ticker: 80,
        securityName: 150,
        weightingForOrigin: 100,
        weightingForEur: 100,
      };
      return drillDownData.columns.map(item => {
        return {
          prop: item.id,
          label: item.name,
          width: widthMapping[item.id]
        }
      });
    },
    formatDrillDownData(list = []) {
      list.forEach((item) => {
        Object.keys(item).forEach((key) => {
          const value = item[key];
          if (["weightingForOrigin", "weightingForEur"].indexOf(key) > -1) {
            item[key] = numeral(value).format('0.00%')
            return;
          }
          if (typeof value === 'number' && key !== "orderNumber") {
            item[key] = numeral(value).format('0,0.00')
            return;
          }
          if (typeof value === 'string') {
            item[key] = value.trim() ? value : "-"
          }
        })
      })
    }
  }
};
export default mixin;
