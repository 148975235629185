export default {
    //个人中心
    profile: {
        nickName: '用户昵称',
        email: '用户邮箱',
        deptName: '公司名称',
        postGroup: '公司职务',
        avatorTitle1: '点击上传头像',
        avatorTitle2: '修改头像',
        selec: '选择',
        submit: '提交',
    },
    // api 库
    apiRepository: {
        copyBtn: '复制',
        document: 'API 文档',
        key: 'API 密钥',
        copySuccess:'复制成功',
        copyFail:'复制失败'
    }
}